import React from 'react';
import {useState} from 'react';
import {Pagination} from 'swiper';
// import Swiper from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import SliderRandomProduct from '../../../../HomePage/components/SliderRandomProduct';
import {CarouselItem} from './CarouselItem';
import './style.scss';

const settingsRandomProductSlider = {
  // dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesPerRow: 1,
  rows: 2,
  autoplay: true,
  variant: 'variant2',
  autoplaySpeed: 5000,
};

export const ProductInlineCarousel = ({
  setPromoProductsCount,
  setPromoDataLoading,
}) => {
  const [photosGallery, setPhotosGallery] = useState();

  return (
    <div className="col-xl-2 col-md-4 col-12 d-xxl-block d-none">
      <div className="product__inline__slider swiper" style={{height: '100%'}}>
        {/* <Swiper
          modules={[Pagination]}
          pagination={{clickable: true}}
          spaceBetween={10}>
          {new Array(10).fill(10).map((item, index) => (
            <SwiperSlide key={index}>
              <CarouselItem />
              <CarouselItem />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination"></div> */}
        {/* <div> */}
        <SliderRandomProduct
          setPromoProductsCount={setPromoProductsCount}
          settingsRandomProductSlider={settingsRandomProductSlider}
          setPhotosGallery={setPhotosGallery}
          setPromoDataLoading={setPromoDataLoading}
        />
        {/* </div> */}
      </div>
    </div>
  );
};
