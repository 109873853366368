import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        gap: '24px',
        color: 'rgb(0, 0, 0)',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
    },
    span: {
        width: '32px',
        height: '32px',
        minWidth: '32px',
        borderRadius: '50%',
        background: '#D9D9D9',
        color: 'color: rgb(28, 42, 83)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px'
    }
}))

function ListItem(props) {
    const { content, number, style } = props;
    const classes = useStyles();

    return (
        <li style={style} className={classes.listItem}>
            <span className={classes.span}>
                {number}
            </span>
            {content}
        </li>
    );
}

export default ListItem;