import React, { useEffect, useState } from 'react'
// import material ui
import { makeStyles, Tooltip, IconButton } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import LinkTo from '../../../_components/LinkTo';
import PriceToStr from '../../../_components/PriceToStr';
// import router
import { Link } from 'react-router-dom';
// import fetch
import axInst from '../../../_helpers/axios-instance';

const useStyles = makeStyles(theme => ({
    item: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
    },
    table: {
        paddingTop: 24,
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#ccc",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#8E95A9",
            borderRadius: 10,
        },
    },

    btn_wrapper: {
        display: "flex",
        alignItems: "center",
        gap: 5,
        justifyContent: "flex-end",
    },

    button: {
        backgroundColor: "transparent",
        padding: "0 5px",
        border: 0,
        cursor: "pointer",
        transition: "0.3s",

        "&:hover": {
            "& svg": {
                stroke: "#000",
            }
        },

        "& svg": {
            transition: "0.3s",
            stroke: "#C8CAD8",
        },
    },
}));

const Row = (props) => {
    const { data, currency, onDelete, disabled } = props;
    const {
        id,
        city__name,
        order_qty,
        order_sum,
        phone,
        user__first_name,
        user__last_name,
        is_main_manager,
    } = data;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <TableBody>
                <TableRow>
                    <TableCell style={{ fontWeight: 700, color: "#000" }}>#{id}</TableCell>
                    <TableCell style={{ color: "#1C2A53" }}>{user__last_name} {user__first_name}</TableCell>
                    <TableCell>{city__name}</TableCell>
                    <TableCell><a href={`tel: ${phone}`}>{phone}</a></TableCell>
                    <TableCell>{order_qty}</TableCell>
                    <TableCell>
                        {currency}
                        <PriceToStr
                            data={order_sum}
                            toFixed={0}
                            beforeText={''}
                        />
                    </TableCell>
                    <TableCell>
                        <div className={classes.btn_wrapper}>
                            <Link className={classes.button} to={`/dashboard/manager/editing/${id}`}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 13.3335H14" stroke="#C8CAD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 2.33316C11.2652 2.06794 11.6249 1.91895 12 1.91895C12.1857 1.91895 12.3696 1.95553 12.5412 2.0266C12.7128 2.09767 12.8687 2.20184 13 2.33316C13.1313 2.46448 13.2355 2.62038 13.3066 2.79196C13.3776 2.96354 13.4142 3.14744 13.4142 3.33316C13.4142 3.51888 13.3776 3.70277 13.3066 3.87435C13.2355 4.04593 13.1313 4.20184 13 4.33316L4.66667 12.6665L2 13.3332L2.66667 10.6665L11 2.33316Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Link>
                            {is_main_manager ? (
                                <Tooltip style={{ padding: 0 }} title={t("common:cannot_delete_manager_label")}>
                                    <IconButton>
                                        <button className={classes.button}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 4H3.33333H14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M5.3335 3.99992V2.66659C5.3335 2.31296 5.47397 1.97382 5.72402 1.72378C5.97407 1.47373 6.31321 1.33325 6.66683 1.33325H9.3335C9.68712 1.33325 10.0263 1.47373 10.2763 1.72378C10.5264 1.97382 10.6668 2.31296 10.6668 2.66659V3.99992M12.6668 3.99992V13.3333C12.6668 13.6869 12.5264 14.026 12.2763 14.2761C12.0263 14.5261 11.6871 14.6666 11.3335 14.6666H4.66683C4.31321 14.6666 3.97407 14.5261 3.72402 14.2761C3.47397 14.026 3.3335 13.6869 3.3335 13.3333V3.99992H12.6668Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.6665 7.33325V11.3333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.3335 7.33325V11.3333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <button disabled={disabled} className={classes.button} onClick={() => onDelete(id, { first_name: user__first_name, last_name: user__last_name })}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 4H3.33333H14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.3335 3.99992V2.66659C5.3335 2.31296 5.47397 1.97382 5.72402 1.72378C5.97407 1.47373 6.31321 1.33325 6.66683 1.33325H9.3335C9.68712 1.33325 10.0263 1.47373 10.2763 1.72378C10.5264 1.97382 10.6668 2.31296 10.6668 2.66659V3.99992M12.6668 3.99992V13.3333C12.6668 13.6869 12.5264 14.026 12.2763 14.2761C12.0263 14.5261 11.6871 14.6666 11.3335 14.6666H4.66683C4.31321 14.6666 3.97407 14.5261 3.72402 14.2761C3.47397 14.026 3.3335 13.6869 3.3335 13.3333V3.99992H12.6668Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.6665 7.33325V11.3333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.3335 7.33325V11.3333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            )}
                        </div>
                    </TableCell>
                </TableRow>
            </TableBody>
        </>
    )
}

const TableManagers = (props) => {
    const { data, currency, setSnack } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [managers, setManagers] = useState([]);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const currentData = data?.filter((item) => !item?.is_deactivate && item?.id != 1126 && item?.id != 1939) || [];
        setManagers(currentData);
    }, [data]);

    const onDelete = (id, name) => {
        setDisabled(false);
        axInst.put("/api/deactivate-manager", { customer_user_id: id }).then(response => {
            setSnack({
                active: true,
                variant: "success",
                text: `${t("common:manager_was_deleted").split(' ')[0]} ${name?.first_name} ${name?.last_name} ${t("common:manager_was_deleted").split(' ').slice(1).join(' ')}`
            });
            setManagers((prev) => prev?.filter((item) => item?.id !== id));
        }).catch(err => {
            setSnack({
                active: true,
                variant: "error",
                text: err.message || "Delete error",
            });
        }).finally(() => {
            setDisabled(false);
        });
    };

    return (
        <>
            <div className={`dashboard-card ${classes.item}`}>
                <div className={classes.title_link}>
                    <h2 className="dashboard-title">{t('common:managers_label')}</h2>
                    <LinkTo title={t('common:read_mode_label')} adaptive={true} path={"/dashboard/managers"} />
                </div>
                <div className={classes.table}>
                    <Table className="dashboard-table" aria-label="full width table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('common:identifier_label')}</TableCell>
                                <TableCell>{t('common:last_name_and_first_name_label')}</TableCell>
                                <TableCell>{t('common:city_label')}</TableCell>
                                <TableCell>{t('common:Phone')}</TableCell>
                                <TableCell>{t('common:number_of_sales_label')}</TableCell>
                                <TableCell>{t('common:sales_amount_label')}</TableCell>
                                <TableCell>{t('common:actions_label')}</TableCell>
                            </TableRow>
                        </TableHead>
                        {managers?.map((item) => <Row data={item} currency={currency} onDelete={onDelete} disabled={disabled} />)}
                    </Table>
                </div>
            </div>
        </>
    )
}

export default TableManagers