import PropTypes from 'prop-types';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

const CatalogListItemBundle = props => {
  const {item, index, closeCatalogModal} = props;
  const listItemBundle = (item, index) => itemWithoutChildren(item, index);

  function itemWithoutChildren(item, index) {
    return (
      <ListItem
        disableGutters={true}
        className={item.level === 'main' ? 'selectIdItem' : ''}
        key={index}>
        <Button
          onClick={closeCatalogModal}
          fullWidth={true}
          disableElevation={true}>
          <Link to={item.url}>
            <Typography variant={item.level === 'main' ? 'body1' : 'body2'}>
              {item.name}
            </Typography>
          </Link>
        </Button>
      </ListItem>
    );
  }

  return listItemBundle(item, index);
};

CatalogListItemBundle.propTypes = {
  index: PropTypes.any,
  item: PropTypes.any,
};
export default CatalogListItemBundle;
