import React, { useState } from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import LinkTo from '../../../_components/LinkTo';
// import moment
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    item: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
    },
    table: {
        marginTop: 24,
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#ccc",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#8E95A9",
            borderRadius: 10,
        },
    },
}));

const Row = (props) => {
    const { data } = props;
    const {
        order__id,
        sum_sell,
        currency_sell__simbol,
        dates_of_deliveries,
        last_status_message__status__name,
        last_status_message__status_id,
        quantity,
    } = data;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TableBody>
            <TableRow>
                <TableCell>#{order__id}</TableCell>
                <TableCell>{quantity}{t('common:pieces')}</TableCell>
                <TableCell>{moment(dates_of_deliveries).format('DD.MM.YY')}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{currency_sell__simbol} {sum_sell}</TableCell>
                <TableCell>{last_status_message__status__name}</TableCell>
                {/* <TableCell>Дії</TableCell> */}
            </TableRow>
        </TableBody>
    )
}

const ManagerLastOrders = (props) => {
    const { data, notAllManagerDataCondition } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={`dashboard-card ${classes.item}`}>
            {notAllManagerDataCondition === true ? <div className="dashboard-card-empty">{t('common:fill_user_details_label')}</div> : data?.length === 0 ? (
                <div className="dashboard-card-empty">{t('common:no_orders_label')}</div>
            ) : null}
            <div className={classes.title_link}>
                <h2 className="dashboard-title">{t("common:last_orders_label")}</h2>
                {/* <LinkTo title={t('common:read_mode_label')} adaptive={true} path={"/dashboard/partner-stock"} /> */}
            </div>
            <div className={classes.table}>
                <Table className="dashboard-table" aria-label="full width table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('common:orders')}</TableCell>
                            <TableCell>{t('common:quantity_label')}</TableCell>
                            <TableCell>{t('common:date')}</TableCell>
                            <TableCell>{t('common:sum')}</TableCell>
                            <TableCell>{t('common:orderTableHeaderLabelStatus')}</TableCell>
                            {/* <TableCell>{t('common:actions_label')}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    {data?.length > 0 ? data?.map((item) => <Row data={item} />) : [0, 1, 2].map((item) => <Row data={{
                        order__id: 1010101,
                        sum_sell: 100,
                        currency_sell__simbol: "$",
                        dates_of_deliveries: "2024-04-02",
                        last_status_message__status__name: "Прийнято",
                        last_status_message__status_id: 2,
                        quantity: 1,
                    }} />)}
                </Table>
            </div>
        </div>
    )
}

export default ManagerLastOrders