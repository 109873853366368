import React from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
import { LineChart } from '@mui/x-charts/LineChart';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import LinkTo from '../../../_components/LinkTo';

const useStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 15,
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
    },
    chart: {
        position: "relative",
        width: 90,
        height: 54,
        textAlign: "right",
        "& h4": {
            fontSize: 14,
            fontWeight: 700,
        },
    },
}));

const StatisticsCard = (props) => {
    const {
        title,
        description,
        data,
        chart,
        path,
        percentage,
        style,
        color,
        currency,
        notAllManagerDataCondition,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    // when +20%
    // 0.2, 0.4, 0.7, 0.5, 0.8, 1
    // #1F1F1F #279F51

    // when +1.9%
    // 0.1, 0.5, 0.6, 0.4, 1, 0.
    // #FFA000

    // when -100%
    // 0.15, 0.35, 0.6, 0.55, 0.3, 0.1
    // #FF392B

    const currentChartData = () => {
        if (data?.percent == 0) {
            return [0.1, 0.5, 0.6, 0.4, 1, 0.4];
        } else if (data?.percent > 0) {
            return [0.2, 0.4, 0.7, 0.5, 0.8, 1];
        } else {
            return [0.15, 0.35, 0.6, 0.55, 0.3, 0.1];
        }
    }

    const currentColor = () => {
        if (data?.percent == 0) {
            return "#1F1F1F";
        } else if (data?.percent > 0) {
            return "#279F51";
        } else {
            return "#FF392B";
        }
    }

    return (
        data ? (
            <div className={`dashboard-card ${classes.item}`} style={style && style}>
                {notAllManagerDataCondition === true && <div className="dashboard-card-empty">{t('common:fill_user_details_label')}</div>}
                <div style={{
                    width: "100%",
                }}>
                    <div className={classes.title_link}>
                        <h2 className="dashboard-text">{title}</h2>
                        {!chart && !percentage && path && <LinkTo title={t('common:use_your_points_label')} adaptive={true} path={"/"} />}
                    </div>
                    <div style={{
                        paddingTop: 16,
                    }}>
                        <span className="dashboard-title" style={{
                            fontSize: 24,
                        }}>{currency?.current_symbol || null} {data?.main_numbers} </span>
                        {description && <span className='dashboasrd-text'>{description}</span>}
                    </div>
                </div>
                {!path && !chart && percentage && (
                    <h4 style={{
                        color: color ? color : currentColor(),
                        fontSize: 20,
                        fontWeight: 700,
                        alignSelf: "center",
                    }}>{data?.percent > 0 ? `+${data?.percent}` : data?.percent}%</h4>
                )}
                {!path && !percentage && chart && (
                    <div className={classes.chart} style={{
                        color: color ? color : currentColor(),
                    }}>
                        <h4>{data?.percent > 0 ? `+${data?.percent}` : data?.percent}%</h4>
                        <div style={{
                            position: "absolute",
                            right: -45,
                            top: -15,
                            pointerEvents: "none",
                        }}>
                            <LineChart
                                leftAxis={null}
                                bottomAxis={null}
                                series={[
                                    {
                                        data: currentChartData(),
                                        color: color ? color : currentColor(),
                                        showMark: ({ index }) => index === 4,
                                    },
                                ]}
                                width={170}
                                height={130}
                                axisHighlight={{ x: "none", y: "none" }}
                            />
                        </div>
                    </div>
                )}
            </div>
        ) : null
    )
}

export default StatisticsCard