import React from 'react';
import {useTranslation} from 'react-i18next';

export const DialogHeader = ({activeStep, title}) => {
  const {t} = useTranslation();
  return (
    <div className="d-flex items-center justify-between mb-4">
      <h2 className="title">{title}</h2>
      <div className="styled-text">
        {t('common:Step')} {activeStep} {t('common:out_of')} 4
      </div>
    </div>
  );
};
