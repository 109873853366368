import { Drawer, makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiDrawer-paperAnchorLeft': {
            backgroundColor: "#F5F7FB"
        }
    }
}))

export const MenuDrawer = ({isOpen, children, side, onClose}) => {

    const classes = useStyles()

    return (
        <Drawer 
            className={classes.root}
            anchor={side}
            onClose={onClose}
            open={isOpen}
        >
        {children}
    </Drawer>
    )
}