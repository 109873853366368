import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';

export const newsService = {
  getNews,
  getBlogs,
  getSingleBlog,
};

function getNews(pageName) {
  if (!pageName)
    return axInst
      .post('/api/get-news/', {main_news: true})
      .then(handleResponse);

  return axInst
    .post('/api/get-news/', {news_type: pageName, main_news: false})
    .then(handleResponse);
}

function getBlogs() {
  return axInst
    .post('/api/get-news/all/internal-blog/')
    .then(handleResponse);
}

function getSingleBlog(blog_id) {
  return axInst
    .post('/api/get-news/internal-blog/', {blog_id: blog_id})
    .then(handleResponse);
}