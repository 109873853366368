import React, {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import FormField from '../../../Authorization/FormField';
import {TableProductItem} from '../TableProductItem';
import {TableHeader} from './TableHeader';

export const SecondStep = props => {
  const {
    selectedResults,
    setSelectedResults,
    setDisableBtn,
    stepIsActive,
    returnReason,
    setSelectedReason,
    setActiveStep,
    setComment
  } = props;

  const [showCommentInput, setShowCommentInput] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      comment: '',
    },
    touched: {},
    errors: {},
  });
  const {t} = useTranslation();

  useEffect(() => {
    if (selectedResults.length == 0) setActiveStep(0);
    if (stepIsActive) {
      const arr = selectedResults.map(item => item.returnReason);
      setDisableBtn(arr.includes(undefined));
    }
  }, [selectedResults, stepIsActive]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    setComment(formState.values.comment)
  };

  return (
    <div className="results-by-search">
      <TableHeader />
      <div className="products-all pb-10">
        {selectedResults?.map((order, orders) => (
          <React.Fragment key={order.id}>
            <TableProductItem
              order={order}
              setSelectedResults={setSelectedResults}
              setDisableBtn={setDisableBtn}
              selectedResults={selectedResults}
              setSelectedReason={setSelectedReason}
              returnReason={returnReason}
            />
          </React.Fragment>
        ))}
      </div>
      {!showCommentInput ? (
        <div
          onClick={() => setShowCommentInput(!showCommentInput)}
          className="d-flex items-center gap-10 cursor-pointer mt-4">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 7L12.2511 7.06262L1 7ZM6.65685 1.34315L6.71947 12.5942L6.65685 1.34315Z"
              fill="#315EFB"
            />
            <path
              d="M6.65685 1.34315L6.71947 12.5942M1 7L12.2511 7.06262L1 7Z"
              stroke="#315EFB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="add-comment">{t('common:return_terms_text_3')}</span>
        </div>
      ) : (
        <div className="user-form p-0">
          <FormField
            name="comment"
            type="textarea"
            handleChange={handleChange}
            formState={formState}
            title={'Ваш комментар'}
            hasErrorText={t('common:enter_email2')}
          />
        </div>
      )}
    </div>
  );
};
