import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Packing as PackingIcon,
  Ball as BallIcon,
  Bob as BobIcon,
} from '../../../../../icons';

export const TabPanelPacking = ({result}) => {
  const {t} = useTranslation();

  return (
    <div className="d-flex items-center" style={{justifyContent: 'center'}}>
      <div
        className={'packing_container'}
        style={{position: 'relative', width: '550px'}}>
        <div style={{
          position: 'relative',
        }}>
          <PackingIcon
          height={result.article.height}
          length={result.article.length}
          width={result.article.width}
          />
        </div>
      </div>
      <div>
        <div
          className={'ball_icon'}
          style={{position: 'relative', width: '178px', height: '148px'}}>
          <BallIcon
          // volume={result.article.volume}
          />
          <div
            style={{
              position: 'absolute',
              color: '#315EFB',
              fontSize: '13px',
              left: '50%',
              top: '38px',
              transform: 'translateX(-50%)',
            }}>
            {result.article.volume}
          </div>
        </div>
        <div
          className={'bob_icon'}
          style={{position: 'relative', width: '178px', height: '100px'}}>
          <BobIcon
          // weight={result.article.weight}
          />
          <div
            style={{
              position: 'absolute',
              color: '#315EFB',
              fontSize: '18px',
              left: '50%',
              top: '50px',
              transform: 'translateX(-50%)',
            }}>
            {result.article.weight + t('common:kg')}
          </div>
        </div>
      </div>
    </div>
  );
};
