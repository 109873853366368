import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import logoImg from '../../images/logo-1.png';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    marginRight: 20,
    '& .product__logo': {
      maxWidth: 150,
    },

    '& .title': {
      marginBottom: 8,
      whiteSpace: 'nowrap',
    },

    '& .product__item-info__inner': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },

  info: {
    maxHeight: 140,
    height: '100%',
    overflow: 'auto',
    padding: '0 15px 0 0',
  },
}));

export const ProductInfo = ({
  image,
  country,
  HTML,
  name,
  url_repr,
  description,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const desc =
    description?.length >= 290
      ? description.split(0, 290) + '...'
      : description;

  const createMarkup = html => ({__html: html});

  return (
    <div className={classes.root}>
      <h2 className="title product__brand-info">{name}</h2>
      <div className={clsx('product__item product__item-info')}>
        <div className="product__item-info__inner">
          <div className="top-side">
            <div className="product__logo">
              <img src={image} alt="logo" />
            </div>
            <div className="full about-context1">
              <p className="description">
                {t('common:Country')}: {country}
              </p>
              {/* <div
                className={clsx(
                  'description about-context__inner',
                  classes.info,
                )}
                dangerouslySetInnerHTML={createMarkup(HTML)}
              /> */}
              <div className="description">{desc}</div>
            </div>
          </div>
          <button
            onClick={() => history.push(`/brand_list/${url_repr}`)}
            className="btn btn-white btn--w100">
            {t('common:all_products')}
          </button>
        </div>
      </div>
    </div>
  );
};
