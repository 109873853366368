import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    banner: {
        background: '#FFBE00',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'center',
        padding: '18px 16px',
        position: 'sticky',
        left: 0,
        right: 0,
        zIndex: 999,
        top: 74,
        '@media (max-width: 1000px)': {
            top: 70,
        },
        '@media (max-width: 741px)': {
            top: 68,
            flexDirection: "column",
        },
        '@media (max-width: 540px)': {
            top: 58,
        },
    },
    banner__text: {
        fontWeight: '700',
    },
    banner__link: {
        color: '#2F80ED',
        textDecoration: 'underline',
        fontWeight: '700',
        cursor: 'pointer',
        whiteSpace: "nowrap",
    },
}));

const DashboardBanner = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.banner}>
            <p className={classes.banner__text}>
                {t('common:you_can_help_relife')}
            </p>
            <a
                className={classes.banner__link}
                target="_blank"
                href="https://www.instagram.com/restore_life_rivne/"
            >
                {t('common:to_learn_more')}
            </a>
        </div>
    )
}

export default DashboardBanner;