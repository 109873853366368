import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { transform } from 'lodash';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        gridColumn: '1 / 5',

        [theme.breakpoints.down('sm')]: {
            height: '100%',
            gridRow: '1 / 5',
            gridColumn: '1 / 2',
            minWidth: '67px',
        },

        [theme.breakpoints.down(600)]: {
            minWidth: '48px'
        },
    },

    yellowStrip: {
        position: 'absolute',
        top: '50%',
        left: 0,
        width: '100%',
        height: '1px',
        background: 'rgb(255, 190, 0)',
        transform: 'translateY(-50%)',

        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '1px',
            transform: 'translate(50%, 0)',
            left: '50%',
            top: '0'
        },
    },
    digits: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',

        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr 1fr 48px',
            height: '100%',
            gap: '8px',
        },
    },
    digitContainer: {

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            height: '100%',
        },
    },
    digit: {
        color: 'rgb(28, 42, 83)',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        width: '67px',
        height: '67px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        background: 'rgb(255, 190, 0)',
        position: 'relative',
        zIndex: '2',

        [theme.breakpoints.down('sm')]: {
            width: '48px',
            height: '48px',
        },

        "&--green": {
            background: 'rgb(39, 159, 81)',

            [theme.breakpoints.down('sm')]: {
                maxHeight: '48px'
            },
        }
    },
}))

const Digits = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.yellowStrip}>
            </div>
            <div className={classes.digits}>
                <div className={classes.digitContainer}>
                    <div className={classes.digit}>1</div>
                </div>
                <div className={classes.digitContainer}>
                    <div className={classes.digit}>2</div>
                </div>
                <div className={classes.digitContainer}>
                    <div className={classes.digit}>3</div>
                </div>
                <div className={classes.digitContainer}>
                    <div className={`${classes.digit} ${classes.digit}--green`}>
                        <svg width="23.778320" height="17.814453" viewBox="0 0 23.7783 17.8145" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <defs />
                            <path id="Vector" d="M7.35 14.43L2.39 9.48C2.26 9.34 2.11 9.24 1.94 9.17C1.77 9.1 1.59 9.06 1.4 9.06C1.22 9.06 1.03 9.1 0.86 9.17C0.69 9.24 0.54 9.34 0.41 9.48C0.28 9.61 0.17 9.76 0.1 9.93C0.03 10.1 0 10.28 0 10.47C0 10.65 0.03 10.83 0.1 11.01C0.17 11.18 0.28 11.33 0.41 11.46L6.35 17.4C6.9 17.95 7.79 17.95 8.34 17.4L23.36 2.39C23.49 2.26 23.59 2.11 23.67 1.94C23.74 1.77 23.77 1.59 23.77 1.4C23.77 1.22 23.74 1.03 23.67 0.86C23.59 0.69 23.49 0.54 23.36 0.41C23.23 0.28 23.08 0.17 22.91 0.1C22.74 0.03 22.55 0 22.37 0C22.18 0 22 0.03 21.83 0.1C21.66 0.17 21.51 0.28 21.38 0.41L7.35 14.43Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Digits;