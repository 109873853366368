import { cities as citiesConstants } from "../_constants/cities.constants";

const initialState = {
    data: [],
    loading: false,
    error: '',
}

export function cities(state = initialState, action) {
    switch (action.type) {
        case citiesConstants.GET_CITIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case citiesConstants.GET_CITIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            }
        case citiesConstants.GET_CITIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}
