import React from 'react';
import {connect} from 'react-redux';
import {static_pages_Actions} from '../../../_actions/staticPages.actions';
import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';
import slotsImage from '../../../images/slots.svg';

const Payment = props => {
  const {staticPage, match} = props;
  const {html, name, image} = staticPage;
  const createMarkup = () => {
    return {__html: html};
  };
  return (
    <div className="products-block pt-1">
      <BreadCrumbs items={[{title: name, path: match?.params.static_page}]} />
      <div className="wrapper-inner">
        <div className="products-block__header mb-4">
          <h2 className="title">{name}</h2>
        </div>
        <ul className="slots-list" dangerouslySetInnerHTML={createMarkup()} />
        <div className="row mb-4">
          <div className="col-md-9 col-12"></div>
          <div className="col-md-3 col-12">
            <div className="image-block">
              <img src={image ? image[0] : ''} alt="slots" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  const {staticPage} = state;

  return {staticPage};
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('payment');
  }

  render() {
    return <Payment {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
