import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from "clsx";
import axInst from "../../../_helpers/axios-instance";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    // root: {
    //     padding: theme.spacing(3),
    // },
}));

const AboutBrand = props => {
    const {history} = props;
    const classes = useStyles();

    const {params} = props.match;
    const {t} = useTranslation();


    const [partnersList, setPartnersList] = useState([]);

    const createMarkup = (html) => {
        return {__html: html};
    };
    const handleBack = () => {
        history.goBack();
    };

    useEffect(() => {
        //get partners
        /*axInst.post('/api/get-trademarks-detail/', {url_repr: params.url_repr}).then(res => {
            setPartnersList(res.data.data);
        });*/
        axInst.post('/api/get-trademarks/all/', {}).then(res => {
            let arr = res.data.data;
            let tmp = arr.filter(arr => arr.url_repr === params.url_repr);
            setPartnersList(tmp[0]);
        });
    }, [params]);
    if (partnersList && partnersList.length === 0) {
        return null;
    }

    /*
    id: 3
    name: "MACK"
    image: "https://dev-api.daniparts.com/files_media/trade_marks/mack_logo.png"
    url_repr: "mack"
    archive: false
    country: "Америка"
    url_site: "https://www.macktrucks.com/"
    catalog: "https://dev-site.daniparts.com/products?q=mack"
    priority: 1
    HTML: "<p>Mack Trucks&nbsp;&m..............."
    manufacturer_catalog: null
    */


    return (
        <>
        {partnersList &&
        <Grid container
              spacing={2}
              className={clsx(classes.root, 'static_page_container about_brand_page')}>
            <Grid item xs={12} className="back_button_container">
                <Button className="back" startIcon={<ChevronLeftIcon/>} onClick={handleBack}>
                    <Typography variant="body2">
                        {t('common:Back')}
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={12} lg={3} className="left_info_panel">
                <img src={partnersList.image} alt=""/>
                {/*<Typography>*/}
                {/*    <LocationOnIcon/>*/}
                {/*    Страна: {partnersList.country}*/}
                {/*</Typography>*/}
                {/*<Typography>*/}
                {/*    <SearchIcon/>*/}
                {/*    Сайт производителя:*/}
                {/*    <Link href={partnersList.url_site}>*/}
                {/*        {partnersList.url_site}*/}
                {/*    </Link>*/}
                {/*</Typography>*/}
                <List className="list_info">
                    <ListItem disableGutters={true}>
                        <ListItemIcon>
                            <LocationOnIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography>
                                    {t('common:Country')}: <span className="country">{partnersList.country}</span>
                                </Typography>
                            }/>
                    </ListItem>
                    <ListItem disableGutters={true}>
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography>
                                    {t('common:Products_webpage')}:
                                    <Link href={partnersList.url_site}>
                                        {partnersList.url_site}
                                    </Link>
                                </Typography>
                            }/>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} lg={9}>
                <div className="description_block">
                    <div className="header_container">
                        <Typography variant="h4">{partnersList.name}</Typography>
                        {/*<Typography variant="body2">*/}
                        {/*    <Link  href={`${'/brand_list/'}${partnersList.url_repr}`}>*/}
                        {/*        {t('common:Product_search')}*/}
                        {/*    </Link>*/}
                        {/*</Typography>*/}
                    </div>

                    <div className="description_text" dangerouslySetInnerHTML={createMarkup(partnersList.HTML)}/>
                    <Button href={`${'/brand_list/'}${partnersList.url_repr}`}
                            size="large"
                            className="search_button"
                            color="secondary"
                            variant="contained">
                        {t('common:Product_search')}
                    </Button>
                </div>
            </Grid>
        </Grid>
        }
        </>
    );
};

AboutBrand.propTypes = {
    history: PropTypes.object,
};
export default withRouter(AboutBrand);
