import React from 'react';
import {useSwiper} from 'swiper/react';

export const PrevBtn = ({isDeactive = false, arrowType}) => {
  const swiper = useSwiper();

  if (arrowType === 'home-slider') {
    return (
      <div
        style={{pointerEvents: !isDeactive ? 'auto' : 'none'}}
        className="home-slider-prev"
        onClick={() => swiper.slidePrev()}>
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9999 5.54918L5.76499 1.00264L0.999924 5.54918M5.81111 1L5.81111 13L5.81111 1Z"
            stroke="#1F1F1F"
            strokeWidth="1.8"
            strokeOpacity={isDeactive ? '0.5' : '1'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  return (
    <div
      style={{pointerEvents: !isDeactive ? 'auto' : 'none'}}
      className="prev"
      onClick={() => swiper.slidePrev()}>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.63934 1L1.00211 5.18795L4.63934 9M1 5.15105H10.6H1Z"
          stroke="#414D5F"
          strokeOpacity={isDeactive ? '0.5' : '1'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
