import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  per_view: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    cursor: 'pointer',
    [theme.breakpoints.down(992)]: {
      marginRight: 10,
    },
  },
  title: {
    fontStyle: 'normal',
    color: '#414D5F',
    fontWeight: 500,
    fontSize: 13,

    '& span': {
      fontWeight: 600,
    },
  },
  popup: {
    position: 'absolute',
    background: '#fff',
    width: '100%',
    padding: '5px 0',
    transition: '.3s all ease',
    transform: 'translateY(-20px)',
    visibility: 'hidden',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 6px rgba(0, 0, 0, 0.02)',
    zIndex: 20,
    opacity: 0,
    top: 20,
    borderRadius: 4,

    [theme.breakpoints.down(500)]: {
      top: 31,
    },

    [theme.breakpoints.down(453)]: {
      top: 51,
    },

    '&.active': {
      transform: 'translateY(0)',
      visibility: 'visible',
      opacity: 1,
    },

    '& ul': {
      padding: 0,

      '& li': {
        padding: '5px 10px',
        cursor: 'pointer',
        transition: '.3s all ease',

        '&:hover': {
          background: '#ededed',
        },
        '&.active': {
          background: '#ededed',
        },
      },
    },
  },

  sidebar: {
    background: '#fff',
    width: '100%',
    padding: '5px 0',
    zIndex: 20,
    borderRadius: 4,

    '& ul': {
      padding: 0,

      '& li': {
        padding: '5px 10px',
        cursor: 'pointer',
        transition: '.3s all ease',

        '&:hover': {
          background: '#ededed',
        },
        '&.active': {
          background: '#ededed',
        },
      },
    },
  },
}));

export const PerView = (props) => {
  const { onChange, onSideBar } = props;
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);
  const [selectedPerView, setSelectedPerView] = useState(() => {
    const savedValue = localStorage.getItem('perViewValue');
    const validValues = [9, 18, 27];
    return validValues.includes(Number(savedValue)) ? Number(savedValue) : 9;
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const currentPage = searchParams.get('page') || '1';

    if (![9, 18, 27].includes(selectedPerView)) {
      const validValue = 9;
      setSelectedPerView(validValue);
      localStorage.setItem('perViewValue', validValue);

      if (currentPage !== '1') {
        searchParams.set('page', '1');
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }
    }

    if (onChange) {
      onChange(selectedPerView);
    }
  }, [selectedPerView, onChange]);

  const handleChange = (value) => {
    const searchParams = new URLSearchParams(window.location.search);

    setSelectedPerView(value);
    setShowPopup(false);

    localStorage.setItem('perViewValue', value);
    searchParams.set('page', 1);

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;

    window.history.replaceState(null, '', newUrl);
    window.location.reload();
  };

  const perViewItems = [
    { value: 9, label: '9' },
    { value: 18, label: '18' },
    { value: 27, label: '27' },
  ];

  if (onSideBar) {
    return (
      <div className="py-4">
        <h4 className="ml-2 mb-2">{t('common:per_view')}:</h4>
        <div className={clsx(classes.sidebar)}>
          <ul>
            {perViewItems.map((item) => (
              <li
                key={item.value}
                onClick={() => handleChange(item.value)}
                className={selectedPerView === item.value ? 'active' : ''}>
                {item.label} {t('common:goods')?.slice(0, -1) || ''}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.per_view} onClick={() => setShowPopup(!showPopup)}>
      <div className={classes.title}>
        {t('common:per_view')}: <span>{selectedPerView}</span>
      </div>
      <svg
        style={{
          transform: showPopup ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'ease all .3s',
        }}
        width="8"
        height="5"
        viewBox="0 0 8 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L4.13971 4L7 1"
          stroke="#414D5F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div className={clsx(classes.popup, showPopup ? 'active' : '')}>
        <ul>
          {perViewItems.map((item) => (
            <li
              key={item.value}
              onClick={() => handleChange(item.value)}
              className={selectedPerView === item.value ? 'active' : ''}>
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};