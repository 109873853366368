import { useEffect } from 'react'

const useAnimate = () => {
    useEffect(() => {
        setTimeout(() => {
            const animItems = document.querySelectorAll(".anim-items");

            function offset(el) {
                const rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
            }

            function animOnScroll() {
                for (let index = 0; index < animItems.length; index++) {
                    const animItem = animItems[index];
                    const animItemHeight = animItem.offsetHeight;
                    const animItemOffset = offset(animItem).top;
                    const animStart = 4;

                    let animItemPoint = window.innerHeight - animItemHeight / animStart;

                    if (animItemHeight > window.innerHeight) {
                        animItemPoint = window.innerHeight - window.innerHeight / animStart;
                    }

                    if ((window.pageYOffset > animItemOffset - animItemPoint) && window.pageYOffset < (animItemOffset + animItemHeight)) {
                        animItem.classList.add('scroll-effect');
                    } else {
                        if (!animItem.classList.contains("anim-hide")) {
                            animItem.classList.remove('scroll-effect');
                        }
                    }
                }
            }

            if (animItems.length > 0) {
                window.addEventListener("scroll", animOnScroll);
                setTimeout(() => {
                    animOnScroll();
                }, 300);
            };

            return () => {
                window.removeEventListener("scroll", animOnScroll);
            };
        }, 150);
    }, [])
}

export default useAnimate