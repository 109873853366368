import React, { useEffect } from 'react'
import useAnimate from '../../hooks/useAnimate';
import img from './images/imgBlog.png'
import { useTranslation } from "react-i18next";
// import material ui
import { makeStyles } from '@material-ui/core';
// import components
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import DashboardAnimate from '../../_components/DashboardAnimate';
import Preloader from '../../_components/Preloader';
// import router
import { useParams } from 'react-router-dom';
// import redux
import { connect } from 'react-redux';
import { newsActions } from '../../_actions';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px",
        overflowX: "hidden",
    },

    body: {
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0"
        },
    },

    back: {
        paddingBottom: "50px",
        [theme.breakpoints.down(768)]: {
            paddingBottom: "30px",
        },
    },

    content: {
        paddingTop: "24px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: "column",
        gap: 32,
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
    },

    item: {
        display: "grid",
        gridTemplateColumns: "100%",
        gap: "46px",
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
            gap: "24px",
        },

        "& p": {
            color: "#555F7E",
            fontSize: "14px",
            fontWeight: "400",
        },
    },

    main_content: {
        width: "100%",

        "& div:first-child": {
            width: "40%",
        },

        "& div:last-child": {
            width: "57.73%"
        },

        [theme.breakpoints.down(992)]: {
            flexDirection: "column",

            "& div:first-child": {
                width: "100%",
            },

            "& div:last-child": {
                width: "100%"
            },
        },
    },

    img_wrapper: {
        width: "100%",
        float: "left",
        position: "relative",
        paddingBottom: "28.2%",
        margin: "0 24px 24px 0",

        [theme.breakpoints.down(992)]: {
            paddingBottom: "65%",
        },

        "& img": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "12px",
        },
    },
}));

const SlugBlog = (props) => {
    useAnimate();
    const { getSingleBlog, blog } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        getSingleBlog(Number(id));
    }, []);

    return (
        <main className="dashboard">
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <div className={classes.content}>
                        <DashboardAnimate style={{ transform: "scale(0.97)" }}>
                            {blog?.loading || blog?.error ? (
                                <article className={`dashboard-card ${classes.item}`}>
                                    {blog?.loading && (
                                        <div className="dashboard-card-empty">
                                            <Preloader />
                                        </div>
                                    )}
                                    {blog?.error && (
                                        <div className="dashboard-card-empty">
                                            {t("common:unknown_error_label")}
                                        </div>
                                    )}
                                    <h1 className="dashboard-title">Оригінальні запчастини завжди кращі</h1>
                                    <div className={classes.main_content}>
                                        <div className={classes.img_wrapper}>
                                            <img src={img} alt="Оригінальні запчастини завжди кращі" />
                                        </div>
                                        <div className="dashboard-structure" style={{ width: "100%" }}>
                                            <p>У світі автомобільних ремонтів і обслуговування, постійно постає питання: "Оригінальні запчастини чи аналоги?". Деякі люди вірять, що оригінальні запчастини завжди кращі, ніж будь-які інші альтернативи. І є свої причини для такої думки, але чи завжди це правда?</p>
                                            <h5>Що таке оригінальні запчастини?</h5>
                                            <p>Оригінальні запчастини - це компоненти, що виготовляються тим же виробником, який виготовив автомобіль. Їх часто називають OEM (Original Equipment Manufacturer) запчастинами. Вони розроблені та вироблені з урахуванням специфікацій конкретного автомобіля, зазвичай мають високу якість і, як правило, гарантують сумісність та найкращу продуктивність.</p>
                                        </div>
                                    </div>
                                </article>
                            ) : (
                                <article className={`dashboard-card ${classes.item}`}>
                                    <h1 className="dashboard-title">{blog?.data?.name}</h1>
                                    <div className={classes.main_content}>
                                        <div className={classes.img_wrapper}>
                                            <img src={blog?.data?.image_url || img} alt={blog?.data?.name || ""} />
                                            {!blog?.data?.image_url && <div className="dashboard-card-empty" style={{ borderRadius: "12px", color: "#fff", width: "100%" }}>{t("common:clear_photo_label")}</div>}
                                        </div>
                                        <div className="dashboard-structure" style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: blog?.data?.HTML }} />
                                    </div>
                                </article>
                            )}
                        </DashboardAnimate>
                    </div>
                </div>
            </div>
        </main>
    )
}

function mapState(state) {
    const {
        blog,
    } = state;
    return {
        blog,
    };
}

const actionCreators = {
    getSingleBlog:
        newsActions.getSingleBlog,
};

export default connect(mapState, actionCreators)(SlugBlog);