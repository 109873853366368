import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ReactInputMask from 'react-input-mask';

const useStyles = makeStyles(theme => ({
  text_field_loginusername: {
    marginTop: '10px !important',
  },
  
  text_field: {
    marginTop: 30,
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      // marginBottom: '30px !important',
    },
  },
  error_text: {
    color: '#FF5252',
    position: 'absolute',
    left: 'auto',
    right: '50%',
    transform: 'translate(50%, -50%) translateY(19px) scale(0.8)',
    textAlign: 'center',
    width: '100%',
    bottom: '-25px',
    height: '32px',
  },
  error_field: {
    color: '#FF5252 !important',
    border: '1px solid #FF5252 !important',
  },
  error_label: {
    color: '#FF5252 !important',
  },
  iconButton: {
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  icons: {
    width: 22,
    // height:
  },
  telInput: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input': {
      '-moz-appearance': 'textfield',
    },
  },
}));

const FormField = props => {
  const {
    handleChange,
    name,
    type,
    title,
    formState,
    hasErrorText,
    authentication,
    setIsFocused,
    mask,
    variant,
    pattern,
    signInUsername
  } = props;
  const [focused, setFocused] = useState(false);
  const [passwordIsShow, togglePasswordType] = useState(true);

  const {t} = useTranslation();

  useEffect(() => {
    if (setIsFocused) {
      setIsFocused(focused);
    }
  }, [focused]);

  const hasError = field => {
    return !!(formState.touched[field] && formState.errors[field]);
  };

  const getAuthError = () => {
    const responseError = authentication?.error?.response?.data;

    if (responseError?.non_field_errors?.length > 0) {
      return responseError.non_field_errors[0];
    } else if (responseError?.code?.length > 0) {
      return true;
    }
    if (responseError?.detail?.length > 0) {
      return responseError.detail[0];
    }
    if (responseError?.password?.length > 0) {
      return responseError.password[0];
    }
    if (responseError?.email?.length > 0) {
      return responseError.email[0];
    }
    if (responseError?.password1?.length > 0) {
      return responseError.password1[0];
    }
    if (responseError?.phone?.phone?.length > 0) {
      return responseError.phone.phone[0];
    }

    return null;
  };

  const classes = useStyles();

  if (type === 'textarea') {
    return (
      <div
        className={clsx(
          'form__field textarea-field mb-2',
          formState?.values[name] || focused ? 'focused' : '',
        )}>
        <div className="form__field-label">{title}</div>
        <textarea
          onChange={handleChange}
          value={formState?.values[name]}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          name={name}
          required
          className="form__field-textarea"></textarea>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'form__field',
        formState?.values[name] && 'focused',
        signInUsername && classes.text_field_loginusername,
        focused && 'focused',
        classes.text_field,
        type === 'tel' && classes.telInput,
      )}>
      <div
        className={clsx(
          'form__field-label',
          hasError(name) && classes.error_label,
          getAuthError() && classes.error_label,
        )}>
        {title}
      </div>
      {mask ? (
        <ReactInputMask
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={e => handleChange(e, variant)}
          value={formState?.values[name]}
          mask={mask}
          required
          className="form__field-input"
          type="text"
          name={name}></ReactInputMask>
      ) : (
        <input
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          name={name}
          pattern={pattern}
          onChange={e => handleChange(e, variant)}
          type={type === 'password' ? (passwordIsShow ? type : 'text') : type}
          required
          className={clsx(
            'form__field-input',
            (hasError(name) ||
            (authentication.error &&
              authentication.error.response &&
              authentication.error.response.data.password_incorrect &&
              name === 'password') ||
              (authentication.error &&
                authentication.error.response &&
                authentication.error.response.data.user_not_found &&
                name === 'username')) &&
              classes.error_field,
          )}
          value={formState.values[name]}
          min="0"
        />
      )}

      {type === 'password' && (
        <IconButton
          className={classes.iconButton}
          aria-label="password"
          onClick={() => togglePasswordType(!passwordIsShow)}>
          {passwordIsShow ? (
            <VisibilityOffOutlinedIcon className={classes.icons} />
          ) : (
            <VisibilityOutlinedIcon className={classes.icons} />
          )}
        </IconButton>
      )}

      {type === 'password' ? (
        <p className={clsx(classes.error_text)}>
          {hasError(name) && formState.values[name]?.length < 8
            ? t('common:Password must be at least 8 characters')
            : authentication.error &&
              authentication.error.response &&
              authentication.error.response.data.password_incorrect
            ? authentication.error.response.data.password_incorrect
            : authentication.error &&
              authentication.error.response &&
              authentication.error.response.data.password &&
              authentication.error.response.data.password.length > 0
            ? authentication.error.response.data.password.join(', ')
            : null}
        </p>
      ) : type === 'email' || type === 'text' ? (
        <p className={clsx(classes.error_text)}>
          {hasError(name)
            ? hasErrorText
            : authentication.error &&
              authentication.error.response &&
              authentication.error.response.data.user_not_found
            ? authentication.error.response.data.user_not_found
            : authentication.error &&
              authentication.error.response &&
              authentication.error.response.data.email &&
              authentication.error.response.data.email.length > 0
            ? authentication.error.response.data.email[0]
            : null}
        </p>
      ) : type === 'tel' ? (
        <p className={clsx(classes.error_text)}>
          {hasError(name)
            ? hasErrorText
            : authentication.error &&
              authentication.error.response &&
              authentication.error.response.data.phone &&
              authentication.error.response.data.phone.phone &&
              authentication.error.response.data.phone.phone.length > 0
            ? authentication.error.response.data.phone.phone[0]
            : null}
        </p>
      ) : (
        <p className={clsx(classes.error_text)}>
          {hasError(name) && hasErrorText}
        </p>
      )}
    </div>
  );
};

const mapState = state => ({
  authentication: state.authentication,
});

export default connect(mapState, {})(FormField);
