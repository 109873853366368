import { store } from '../_helpers';
import axInst from '../_helpers/axios-instance';

const state = store.getState();
const { userSelectedData, contragentData } = state;
const { customer_data } = contragentData;
const { customer_id, sell_currency_id } = userSelectedData;

export const productService = {
  getBoundProductsByGroupId,
  getAnalogProducts,
  getProductByTradeMarks,
  createReview,
  getReviewById,
  createQuestion,
  getQuestionById,
  getBonusProducts,
};

function getProductByTradeMarks(tradeMarkId, userId, sellCurencyId) {
  // if (
  //   !customer_id ||
  //   !sell_currency_id ||
  //   !customer_data ||
  //   !customer_data.credit_limit_currency_id
  // )
  //   return Promise.reject('Wrong params');

  return axInst.post('/api/get-products-by-trandmark/', {
    trademarks_id: tradeMarkId,
    customer_id: userId,
    sell_currency_id: sellCurencyId,
  });
}

function getBoundProductsByGroupId(product_group_id) {
  if (
    !customer_id ||
    !sell_currency_id ||
    !customer_data ||
    !customer_data.credit_limit_currency_id
  )
    return Promise.reject('Wrong params');

  const data = {
    product_group_id,
    customer_id,
    sell_currency_id: customer_data.credit_limit_currency_id,
  };

  return axInst.post('/api/get-bound-products-by-group/', data);
}

function getAnalogProducts(analogList) {
  if (
    !customer_id ||
    !sell_currency_id ||
    !customer_data ||
    !customer_data.credit_limit_currency_id
  )
    return Promise.reject('Wrong params');
  return axInst.post('/api/get-analog-products/', {
    analog_list: analogList,
    customer_id,
    sell_currency_id: customer_data.credit_limit_currency_id,
  });
}

function createReview({ product_id, feedback, benefits, disadvantages, rate }) {
  if (
    !customer_id ||
    !sell_currency_id ||
    !customer_data ||
    !customer_data.credit_limit_currency_id
  )
    return Promise.reject('Wrong params');
  return axInst.post('/api/create-product-review/', {
    product_id,
    feedback,
    benefits,
    disadvantages,
    rate,
    customer_id,
  });
}

function getReviewById(product_id) {
  return axInst.post('/api/get-product-review/', {
    product_id,
  });
}

function createQuestion({ product_id, name, phone_number, question }) {
  if (
    !customer_id ||
    !sell_currency_id ||
    !customer_data ||
    !customer_data.credit_limit_currency_id
  )
    return Promise.reject('Wrong params');
  return axInst.post('/api/create-product-question/', {
    product_id,
    name,
    phone_number,
    question,
    customer_id,
  });
}
function getQuestionById(product_id) {
  return axInst.post('/api/get-product-question/', {
    product_id,
  });
}

function getBonusProducts() {
  return axInst.get('/api/get-bonus-products/').then(handleResponse);
}

function handleResponse(response) {
  if (response.statusText === 'OK' || response.statusText === 'Created') {
    return response.data;
  }
  // const error = (data && data.message) || response.statusText;
  // return Promise.reject(error);
  //
  // debugger
  // return response.text().then(text => {
  //     const data = text && JSON.parse(text);
  //     debugger
  //     if (!response.ok) {
  //         if (response.status === 401) {
  //             // auto logout if 401 response returned from api
  //             logout();
  //             window.location.reload();
  //         }
  //
  //         const error = (data && data.message) || response.statusText;
  //         return Promise.reject(error);
  //     }
  //
  //     return data;
  // });
  debugger;
  return Promise.reject('Errrrr');
}