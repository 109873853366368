import React from 'react'
// import material ui
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Tooltip, IconButton } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import LinkTo from '../../../_components/LinkTo';
// import router
import { Link } from 'react-router-dom';
// import img
import edit from "../../../images/Edit.png"
// import icons
import MoneyIcon from '../../../icons/Money';

const useStyles = makeStyles(theme => ({
    item: {
        border: "1px solid #FFBE00",
        paddingTop: 12,
        paddingBottom: 26,
        [theme.breakpoints.down(476)]: {
            paddingBottom: 16,
        },
    },
    edit_btn: {
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
    },
    managerPage_user_data: {
        flexDirection: "column"
    },
    managerPage_user_data_card: {
        width: "100%",
    },
    message: {
        fontSize: 14,
        fontWeight: 400,
        color: "#1C2A53",
        backgroundColor: "#A6FFB4",
        padding: "4px 8px",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: 8,
    },

    links_wrapper: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 5,
        alignItems: "center",
    },

    edit_btn: {
        borderRadius: "50%",
        border: "1px solid #2F80ED",
        height: "35px",
        padding: "0 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.3s",

        "&:hover": {
            color: "#315EFB",
            borderColor: "#315EFB",
        },
    },

    error: {
        backgroundColor: "#FFBE00",
        color: "#000",
        fontWeight: 700,
        textAlign: "left",
    },
}));

const UserInformation = (props) => {
    const { data, managerPage, style } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const isTablet = useMediaQuery(theme.breakpoints.down(960), {
        defaultMatches: true,
    });

    const isMobile = useMediaQuery(theme.breakpoints.down(476), {
        defaultMatches: true,
    });

    const currentPath = data?.id ? `/dashboard/manager/editing/${data?.id}` : "/dashboard/create-manager";

    const emptyData = () => {
        return (
            <Tooltip style={{ padding: 0 }} title={t("common:edit_your_profile_label")}>
                <IconButton>
                    <Link className={`${classes.message} ${classes.error}`} to={currentPath}>{t("common:field_is_required_label")}</Link>
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <div className={`dashboard-card ${classes.item}`} style={style && style}>
            {!managerPage && (
                <div className="dashboard-status">
                    {data?.is_main_manager && (
                        <div className="dashboard-status__item" style={{
                            backgroundColor: "#315EFB",
                            color: "white",
                        }}>
                            <MoneyIcon width={!isMobile ? "16" : "12"} height={!isMobile ? "16" : "12"} color={"white"} />
                            <span>{t('common:admin_label')}</span>
                        </div>
                    )}
                </div>
            )}
            {!managerPage ? (
                <div className={classes.links_wrapper}>
                    <Link className={classes.edit_btn} to={currentPath}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2288_21271)">
                                <path d="M16.9359 4.1595L14.0009 1.2095C13.8069 1.01653 13.5445 0.908203 13.2709 0.908203C12.9973 0.908203 12.7348 1.01653 12.5409 1.2095L2.13588 11.5995L1.18588 15.6995C1.15311 15.8494 1.15423 16.0047 1.18917 16.1541C1.22411 16.3035 1.29197 16.4432 1.38781 16.563C1.48365 16.6828 1.60505 16.7796 1.74312 16.8465C1.8812 16.9134 2.03247 16.9486 2.18588 16.9495C2.25734 16.9572 2.32942 16.9572 2.40088 16.9495L6.54588 15.9995L16.9359 5.6195C17.1289 5.42555 17.2372 5.16309 17.2372 4.8895C17.2372 4.61591 17.1289 4.35344 16.9359 4.1595ZM6.04588 15.0995L2.16088 15.9145L3.04588 12.1045L10.8309 4.3495L13.8309 7.3495L6.04588 15.0995ZM14.5009 6.6245L11.5009 3.6245L13.2409 1.8945L16.1909 4.8945L14.5009 6.6245Z" fill="#2F80ED" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2288_21271">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                    <LinkTo title={t('common:profile_menu')} adaptive={true} path={"/dashboard/manager"} />
                </div>
            ) :
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                }}>
                    <Link to={currentPath}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2288_21271)">
                                <path d="M16.9359 4.1595L14.0009 1.2095C13.8069 1.01653 13.5445 0.908203 13.2709 0.908203C12.9973 0.908203 12.7348 1.01653 12.5409 1.2095L2.13588 11.5995L1.18588 15.6995C1.15311 15.8494 1.15423 16.0047 1.18917 16.1541C1.22411 16.3035 1.29197 16.4432 1.38781 16.563C1.48365 16.6828 1.60505 16.7796 1.74312 16.8465C1.8812 16.9134 2.03247 16.9486 2.18588 16.9495C2.25734 16.9572 2.32942 16.9572 2.40088 16.9495L6.54588 15.9995L16.9359 5.6195C17.1289 5.42555 17.2372 5.16309 17.2372 4.8895C17.2372 4.61591 17.1289 4.35344 16.9359 4.1595ZM6.04588 15.0995L2.16088 15.9145L3.04588 12.1045L10.8309 4.3495L13.8309 7.3495L6.04588 15.0995ZM14.5009 6.6245L11.5009 3.6245L13.2409 1.8945L16.1909 4.8945L14.5009 6.6245Z" fill="#2F80ED" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2288_21271">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                </div>
            }
            <div className="dashboard__user">
                <div className="dashboard__user_icon">
                    <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.7755 0.93159C19.6954 0.724647 21.6778 1.08737 23.3905 1.9854C25.5047 3.06822 27.1968 4.94521 28.0664 7.1582C28.8103 9.02069 28.9589 11.1123 28.5108 13.0664C28.0832 14.9281 27.103 16.6593 25.7265 17.9819C24.1382 19.5297 22.0155 20.5171 19.8082 20.724C17.6901 20.9386 15.5088 20.4461 13.6902 19.335C11.7345 18.1537 10.2101 16.2751 9.46013 14.1141C8.72235 12.0126 8.7193 9.66061 9.45327 7.55835C10.2086 5.35605 11.7711 3.44621 13.7725 2.26412C14.9913 1.5402 16.3647 1.07668 17.7755 0.93159ZM17.8685 4.39693C16.0865 4.65504 14.4394 5.70273 13.4448 7.20555C12.5233 8.56939 12.1567 10.2929 12.4395 11.9148C12.6559 13.2367 13.303 14.4806 14.2527 15.4229C15.4295 16.6111 17.0812 17.3122 18.7534 17.3206C19.982 17.3412 21.2137 16.9983 22.2548 16.3446C23.7045 15.4489 24.7769 13.9575 25.1526 12.2913C25.488 10.8488 25.3165 9.29025 24.6572 7.96307C24.033 6.67865 22.9697 5.61415 21.6878 4.98721C20.514 4.40686 19.1627 4.20144 17.8685 4.39693Z" fill="#315EFB" />
                        <path d="M14.1294 21.6432C17.4022 20.7651 20.9181 20.83 24.1589 21.8196C27.53 22.8398 30.5925 24.8589 32.8561 27.5613C35.6061 30.8159 37.1693 35.0563 37.1662 39.3219C37.1647 39.9229 37.1662 40.5239 37.1647 41.1249C24.9417 41.1256 12.7186 41.1272 0.495567 41.1249C0.503189 40.0894 0.468129 39.0516 0.549682 38.0184C0.763092 35.1533 1.65865 32.3401 3.14947 29.8851C5.56405 25.8531 9.58835 22.8299 14.1294 21.6432ZM12.5128 25.889C10.112 27.0092 8.02894 28.7931 6.53659 30.9831C5.17763 32.9655 4.31789 35.2839 4.04046 37.671C13.9023 37.6695 23.7633 37.6717 33.6252 37.6702C33.2807 34.6455 31.9781 31.7391 29.9515 29.4712C27.5125 26.7068 24.0103 24.9184 20.3457 24.5542C17.6781 24.2777 14.9388 24.7436 12.5128 25.889Z" fill="#315EFB" />
                    </svg>
                </div>
                <div className="dashboard__user_information">
                    {!data?.user__last_name || !data?.user__first_name ? (
                        emptyData()
                    ) : <h4>{data?.user__last_name} {data?.user__first_name}</h4>}
                    {!data?.user__email ? (
                        emptyData()
                    ) : (
                        <p><a href={`mailto: ${data?.user__email}`}>{data?.user__email}</a> <a href={`tel: ${data?.phone}`}>{data?.phone}</a></p>
                    )}
                </div>
            </div>
            <div className={`dashboard__user-data ${managerPage && !isTablet && classes.managerPage_user_data}`}>
                <div className={`dashboard__user-data_card ${managerPage && !isTablet && classes.managerPage_user_data_card}`}>
                    <div className="dashboard__user-data_item">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.1111 4.16652H11.5993C11.526 3.26227 11.115 2.41873 10.4481 1.80369C9.7812 1.18865 8.90722 0.847168 8 0.847168C7.09278 0.847168 6.2188 1.18865 5.55188 1.80369C4.88497 2.41873 4.47399 3.26227 4.40069 4.16652H1.88889C1.52053 4.16652 1.16726 4.31285 0.906796 4.57332C0.646329 4.83378 0.5 5.18705 0.5 5.55541V13.8887C0.5 14.2571 0.646329 14.6104 0.906796 14.8708C1.16726 15.1313 1.52053 15.2776 1.88889 15.2776H14.1111C14.4795 15.2776 14.8327 15.1313 15.0932 14.8708C15.3537 14.6104 15.5 14.2571 15.5 13.8887V5.55541C15.5 5.18705 15.3537 4.83378 15.0932 4.57332C14.8327 4.31285 14.4795 4.16652 14.1111 4.16652ZM8 2.49985C8.46754 2.4999 8.91941 2.66841 9.27282 2.9745C9.62623 3.28059 9.85752 3.70378 9.92431 4.16652H6.07569C6.14249 3.70378 6.37377 3.28059 6.72718 2.9745C7.08059 2.66841 7.53246 2.4999 8 2.49985ZM13.8333 13.611H2.16667V5.83319H13.8333V13.611Z" fill="#2F80ED" />
                        </svg>
                        <div className="dashboard__user-data_data">
                            <h4>{t('common:position_label')}</h4>
                            {data?.position__position ? (
                                <p>{data?.position__position}</p>
                            ) : emptyData()}
                        </div>
                    </div>
                </div>
                <div className={`dashboard__user-data_card ${managerPage && !isTablet && classes.managerPage_user_data_card}`}>
                    <div className="dashboard__user-data_item">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 6.72727C14 11.1818 8 15 8 15C8 15 2 11.1818 2 6.72727C2 5.20831 2.63214 3.75155 3.75736 2.67748C4.88258 1.60341 6.4087 1 8 1C9.5913 1 11.1174 1.60341 12.2426 2.67748C13.3679 3.75155 14 5.20831 14 6.72727Z" stroke="#2F80ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 8.66675C9.10457 8.66675 10 7.77132 10 6.66675C10 5.56218 9.10457 4.66675 8 4.66675C6.89543 4.66675 6 5.56218 6 6.66675C6 7.77132 6.89543 8.66675 8 8.66675Z" stroke="#2F80ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div className="dashboard__user-data_data">
                            <h4>{t('common:city_label')}</h4>
                            {data?.city__name ? (
                                <p>{data?.city__name}</p>
                            ) : emptyData()}
                        </div>
                    </div>
                </div>
                <div className={`dashboard__user-data_card ${managerPage && !isTablet && classes.managerPage_user_data_card}`}>
                    <div className="dashboard__user-data_item">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6315 0.719588C16.5071 0.61233 16.3558 0.541034 16.1939 0.513379C16.032 0.485724 15.8656 0.502757 15.7127 0.562643L1.6391 6.07028C1.38366 6.16977 1.16744 6.34952 1.02297 6.58249C0.878507 6.81546 0.813609 7.08905 0.838047 7.36209C0.862484 7.63513 0.974933 7.89284 1.15848 8.09646C1.34202 8.30007 1.58672 8.43857 1.85577 8.49112L5.27799 9.16264V13.0001C5.27798 13.277 5.36073 13.5476 5.51561 13.7771C5.67049 14.0066 5.89042 14.1846 6.14719 14.2882C6.40395 14.3918 6.68582 14.4163 6.95661 14.3585C7.22739 14.3008 7.47473 14.1634 7.66688 13.964L9.24119 12.3314L11.8301 14.5974C12.0107 14.7562 12.2294 14.8654 12.4649 14.9143C12.7003 14.9632 12.9445 14.9501 13.1734 14.8763C13.4023 14.8026 13.6081 14.6706 13.7707 14.4934C13.9333 14.3162 14.0471 14.0998 14.1009 13.8654L16.9217 1.605C16.9584 1.44497 16.9508 1.27797 16.8997 1.12195C16.8485 0.965938 16.7558 0.826826 16.6315 0.719588ZM10.237 4.49459L5.91827 7.58973L3.5273 7.12098L10.237 4.49459ZM6.94466 12.3099V10.3161L7.98632 11.2293L6.94466 12.3099ZM12.5808 13.0404L7.45021 8.5425L14.8329 3.25223L12.5808 13.0404Z" fill="#2F80ED" />
                        </svg>
                        <div className="dashboard__user-data_data">
                            <h4>{t('common:telegram_label')} ID</h4>
                            {data?.telegram ? <p>{data?.telegram}</p> : <Link className={classes.message} to={currentPath}>{t('common:add_telegram')}</Link>}
                        </div>
                    </div>
                </div>
                <div className={`dashboard__user-data_card ${managerPage && !isTablet && classes.managerPage_user_data_card}`}>
                    <div className="dashboard__user-data_item">
                        <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.42836 0.863525H2.57122C2.11656 0.863525 1.68053 1.04551 1.35904 1.36943C1.03755 1.69336 0.856934 2.1327 0.856934 2.5908V16.409C0.856934 16.8671 1.03755 17.3064 1.35904 17.6303C1.68053 17.9543 2.11656 18.1363 2.57122 18.1363H9.42836C9.88302 18.1363 10.3191 17.9543 10.6405 17.6303C10.962 17.3064 11.1426 16.8671 11.1426 16.409V2.5908C11.1426 2.1327 10.962 1.69336 10.6405 1.36943C10.3191 1.04551 9.88302 0.863525 9.42836 0.863525ZM6.85693 17.2726H5.14265V16.409H6.85693V17.2726ZM9.42836 15.5453H2.57122V3.45443H9.42836V15.5453Z" fill="#2F80ED" />
                        </svg>
                        <div className="dashboard__user-data_data">
                            <h4>{t('common:Phone')}</h4>
                            {data?.phone ? (
                                <p><a href={`tel: ${data?.phone}`}>{data?.phone}</a></p>
                            ) : emptyData()}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UserInformation;