import React, { useState, useEffect } from 'react'
// import material ui
import { makeStyles } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import DashboardButtonBack from '../../../_components/DashboardButtonBack'
import { getOffsetValue } from '../helpers/getOffsetValue';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px",
        overflowX: "hidden",
    },

    back: {
        paddingBottom: "20px",
    },

    title: {
        padding: '0 0 20px 0',
        [theme.breakpoints.down(767)]: {
            padding: '0'
        },
    },

    top_bar: {
        width: "100%",
        position: "sticky",
        zIndex: 5,
        top: "74px",
        left: 0,
        backgroundColor: "#fff",
        boxShadow: "0px 12px 32px 0px rgba(51, 38, 174, 0.08)",
        padding: "16px 0",

        [theme.breakpoints.down(950)]: {
            top: "70px",
        },

        [theme.breakpoints.down(767)]: {
            padding: "26px 0 16px",
            position: 'static'
        },
        [theme.breakpoints.down(550)]: {
            padding: "16px 0",
            position: 'static'
        },
    },

    nav: {
        "& ul": {
            padding: 0,
            margin: 0,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "8px",
            [theme.breakpoints.down(767)]: {
                display: 'none'
            },

            "& li": {
                color: "#000000",
            },
        },
    },
    selected: {
        color: 'rgb(47, 128, 237)'
    }
}));

const TopBar = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [selectedSection, setSelectedSection] = useState(null);

    const offsetValue = getOffsetValue();

    useEffect(() => {
        const handleScroll = () => {
            const sectionOffsets = {
                main: document.getElementById('main').offsetTop - offsetValue,
                about: document.getElementById('about').offsetTop - offsetValue,
                manager: document.getElementById('manager').offsetTop - offsetValue,
                integration: document.getElementById('integration').offsetTop - offsetValue,
                store: document.getElementById('store').offsetTop - offsetValue
            };

            const scrollPosition = window.scrollY;

            for (const section in sectionOffsets) {
                if (scrollPosition >= sectionOffsets[section]) {
                    setSelectedSection(section);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={classes.top_bar}>
            <div className={classes.container}>
                <div className={classes.back}><DashboardButtonBack /></div>
                <h1 className={`dashboard-title ${classes.title}`}>{t('common:instruction_title')}</h1>
                <nav className={classes.nav}>
                    <ul>
                        <li><a className={selectedSection === 'main' ? classes.selected : ''} href='#main'>{t('common:home_page')}</a></li>
                        <li>/</li>
                        <li><a className={selectedSection === 'about' ? classes.selected : ''} href='#about'>{t('common:info_about_company')}</a></li>
                        <li>/</li>
                        <li><a className={selectedSection === 'manager' ? classes.selected : ''} href='#manager'>{t('common:info_about_manager')}</a></li>
                        <li>/</li>
                        <li><a className={selectedSection === 'integration' ? classes.selected : ''} href='#integration'>{t('common:integration_label')}</a></li>
                        <li>/</li>
                        <li><a className={selectedSection === 'store' ? classes.selected : ''} href='#store'>{t("common:store_instruction")}</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default TopBar