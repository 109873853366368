//import React, {useState} from 'react';
import Pagination from '@mui/material/Pagination';
import React, {useEffect} from 'react';
/*import clsx from 'clsx';*/
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  //IconButton,
  Button,
  //Badge,
  //withStyles,
} from '@material-ui/core';

//import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
//import ShoppingCart from '@material-ui/icons/ShoppingCart';

//import Info from '@material-ui/icons/Info';
//import MoreInfo from "@material-ui/icons/KeyboardArrowDown";
import Grid from '@material-ui/core/Grid';
//import ListItem from '@material-ui/core/ListItem';
//import moment, {relativeTimeRounding, relativeTimeThreshold} from "moment";
//import moment from "moment";
import PriceToStr from '../../../../_components/PriceToStr';
//import CountDay from "../../../../_components/CountDay";
import BasketAdd from '../../../../icons/BasketAdd';
//import BasketIcon from "../../../../icons/Basket";
import DiscountLabel from '../../../../_components/DiscountLabel';
//import clsx from "clsx";
import StyledBadgeOne from '../../../../_components/StyledBadgeOne';
import {useTranslation} from 'react-i18next';

import product from '../../../../images/product.png';
import StorageBlockHide from '../StorageBlockHide';
import clsx from 'clsx';

import {Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {dataProcessing} from '../../../../_helpers';
import AddToFavorite from '../AddToFavorite/AddToFavorite';
import {connect} from 'react-redux';
import PromoLabel from '../../../../_components/PromoLabel';
import IconDiscount from '../../../../images/icon_discount.svg';
import {catalogActions, customerActions, searchActions} from '../../../../_actions';
import {searchService} from '../../../../_services';
import axInst from '../../../../_helpers/axios-instance';
import { useHistory } from 'react-router-dom';


let addCountRoes = 40;
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
    borderRadius: 10,
    paddingBottom: 30,
    marginBottom: 30,
    /*overflowX: 'auto',*/
  },
  /*table: {
        minWidth: 700,
    },*/
  
  paper: {
    /*padding: theme.spacing(2),*/
    padding: '16px 4px',
    textAlign: 'center',
    borderRadius: 0,
    backgroundColor: '#eee',
    fontsize: '14px!important',
  },
  
  gridItem: {
    // padding: '5px 5px',
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    // wordWrap: 'break-word',
    // display: 'flex',
    /*justifyContent: 'center',*/
    // alignItems: 'center',
  },
  gridItemBasket: {
    padding: '0 0px',
    borderStyle: 'solid!important',
    borderColor: '#eee',
    borderWidth: 1,
    wordWrap: 'break-word',
    display: 'flex',
    /*justifyContent: 'center',*/
    alignItems: 'center',
  },
  
  gridItemHead: {
    textAlign: 'center',
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    padding: '16px 4px',
    fontWeight: 500,
    fontsize: '14px',
    backgroundColor: '#EBF0F8',
  },
  
  row: {
    '&:hover': {backgroundColor: '#fafafa'},
  },
  
  mainImg: {maxWidth: '100%', maxHeight: 50, verticalAlign: 'middle'},
  
  otherOffersHr: {
    transitionProperty: 'opacity',
    transitionDuration: '300ms',
    opacity: 0,
    zIndex: 2,
    width: 350,
    backgroundColor: 'white',
    marginLeft: 'calc(100% - 371px - 5px)',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'calc(66% - 280px - 10px)',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 585,
    },
    marginTop: 60,
    position: 'absolute',
    display: 'none',
    //maxHeight: 350,
  },
  stBalance: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stDelivery: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  signOutButton: {
    marginRight: 5,
    minWidth: 40,
    padding: '4px 0',
    //backgroundColor: 'darkorange',//forestgreen
  },
  gridContainerSubBlPrice: {
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    borderBottom: '1px solid #eee',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end', //'space-between',
  },
  
  stBrand: {
    // fontWeight: 600,
    // padding: 0,
    // marginBottom: 5,
    // overflow: 'auto',
    // color: '#232323',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis', /* Многоточие */
  },
  
  stBlockDelivery: {display: 'grid', lineHeight: 1.3, width: '100%'},
  
  stArticle: {
    color: '#315EFB',
  },
  stName: {
    // fontSize: 16
  },
  stInfo: {
    fontSize: 14,
    color: '#546e7a',
  },
  stPrice: {
    padding: '8px 10px 8px 2px',
    fontSize: 14,
    fontWeight: 'bold',
  },
  
  stMoreInfo: {
    cursor: 'pointer',
    backgroundColor: 'darkgray',
    borderRadius: '50%',
    color: 'white',
  },
  
  stShowProductCart: {
    // display: "contents",
    // cursor: "pointer",
    textDecoration: 'none',
    color: 'inherit',
    // display: "contents",
    // cursor: "pointer",
    '&:hover': {
      // color: '#3f51b5',
    },
  },
  
  labelBlock: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  
  pagination: {
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginBottom: 30,
    marginTop: 30,
    width: 'max-content',
    '& > ul > li > .Mui-selected': {
      backgroundColor: '#FFBE00',
    },
  },
}));

// lg={3}
// sm={6}
// xl={3}
// xs={12}

const PtoductsNewTable = props => {
  /*const {className, data, addToCart, setDialogData, ...rest} = props;*/
  /*const {results, warehouses} = data;*/
  /*const handleChange = panel => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };*/
  /*const [expanded, setExpanded] = React.useState(false);*/
  
  const {
    type,
    typePage,
    data,
    setDialogData,
    setPhotosGallery,
    currencyMainContr,
    //currencyMainSite,
    currencyMain,
    cartAll,
    handleUuidInfo,
    sorting,
    //showProductCart,
    searchAvailability,
    listMyFavorite,
    promotionsByProductsList,
    removeFromWishlist,
    addToWishlist,
    userSelectedData,
    searchPromotionList,
    requestForCatalogByGroup,
    getCatalogByGroup,
  } = props;
  const {results} = data;
  const {warehouses} = data;
  
  const classes = useStyles();
  const history = useHistory();
  
  const [countRows, setCountRows] = React.useState(addCountRoes);
  const addRows = () => {
    setCountRows(countRows + addCountRoes);
  };
  
  const {t} = useTranslation();
  const processedData = React.useMemo(
    () =>
      dataProcessing(
        currencyMain,
        data.results,
        cartAll,
        listMyFavorite,
        sorting,
        searchAvailability,
        promotionsByProductsList,
      ).results,
    [
      data.results,
      currencyMain,
      cartAll,
      listMyFavorite,
      sorting,
      searchAvailability,
      promotionsByProductsList,
    ],
  );
  
  const {wish_list} = userSelectedData;
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [resultsMod, setResultMod] = React.useState([]);
  const [maxPages, setMaxPages] = React.useState(data?.num_of_pages);
  const [pageClick, setPageClick] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(
    data?.current_page_number || 1,
  );
  
  const handlePageChange = (event, page) => {
    setPageClick(page);
    window.scrollTo(0, -100);
    
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page);
    
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    history.push(newUrl);
  };
  
  const handleLike = (event, data) => {
    event.preventDefault();
    if (wish_list.find(i => i.article === data.article)) {
      removeFromWishlist(data);
      axInst.post('/api/delete-wish-list-product/', JSON.stringify({
        customer_id: userSelectedData.customer_id,
        article: data.main_article,
      }));
    } else {
      addToWishlist(data);
      axInst.post('/api/add-wish-list-product/', JSON.stringify({
        customer_id: userSelectedData.customer_id,
        article: data.main_article,
      }));
    }
  };
  
  React.useEffect(() => {
    if (data && data.results) {
      const newResults = processedData.map((item, index) => ({
        ...item,
        id: index + 1,
        uniqueId: `page${currentPage}-${index + 1}`,
      }));
      
      setResultMod(newResults);
    }
  }, [data, processedData, currentPage]);
  
  return (
    <div className={clsx(classes.root, 'search_result_product_table')}>
      <Grid container spacing={0}>
        <Grid container item xs={12} spacing={0} style={{fontSize: 14}}>
          <Grid
            item
            xs={1}
            style={{borderRadius: '10px 0 0 0'}}
            align='center'
            className={classes.gridItemHead}>
            <div>{t('common:photo')}</div>
          </Grid>
          
          <Grid item xs={2} align='center' className={classes.gridItemHead}>
            <div>{t('common:article_brand')}</div>
          </Grid>
          <Grid item xs={4} className={classes.gridItemHead}>
            <div>{t('common:name')}</div>
          </Grid>
          <Grid container item xs={5} spacing={0}>
            <Grid item xs={5} align='center' className={classes.gridItemHead}>
              <div>{t('common:availability')}</div>
            </Grid>
            <Grid
              style={{borderRadius: '0 10px 0 0'}}
              item
              xs={7}
              align='center'
              className={classes.gridItemHead}>
              <div>{t('common:price')}</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {resultsMod.map(
        (row, index) =>
          row.count > 0 &&
          index < countRows && (
            <Grid
              container
              className={clsx(
                'search_result_product_table_row',
                row.balance.every(i => i.quantity === '0')
                  ? 'product-empty'
                  : '',
              )}
              spacing={0}
              // key={`${row.article}${row.brand}${row.sku}${index}`}
              key={`${row.product_id}`}>
              <Grid
                container
                spacing={0}
                style={{display: 'flex'}}
                className={classes.row}>
                <Grid
                  item
                  xs={1}
                  align='center'
                  className={clsx(classes.gridItem, 'grid_item')}>
                  <Grid container alignItems='center'>
                    <Grid item align='center' xs={12}>
                      {row.images.thumb ? (
                        row.images.large.length > 0 ? (
                          <img
                            className={classes.mainImg}
                            src={row.images.thumb}
                            alt={''}
                            onClick={() =>
                              setPhotosGallery({row: row, type: 'search'})
                            }
                            style={{cursor: 'pointer'}}
                          />
                        ) : (
                          <img
                            className={classes.mainImg}
                            src={row.images.thumb}
                            alt={''}
                          />
                        )
                      ) : (
                        <img
                          className={classes.mainImg}
                          /*src={'https://pypik.ru/uploads/posts/2018-09/1536907413_foto-net-no-ya-krasivaya-4.jpg'}*/
                          src={product}
                          alt={t('common:No photo')}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <RouterLink
                  className={clsx(
                    classes.stShowProductCart,
                    'show_product_cart',
                  )}
                  //onClick={showProductCart(row.brand_clean, row.article_clean)}
                  to={`${'/product/'}${row.brand_clean}${'/'}${
                    row.article_clean
                  }`}
                  title='Показать карточку товара'>
                  {row.balance && row.offers && (
                    <AddToFavorite
                      row={row}
                      favoriteSost={row.favorite_id ? true : false}
                      forTable={1}
                    />
                  )}
                  
                  <Grid
                    item
                    xs={2}
                    className={clsx(classes.gridItem, 'grid_item')}>
                    <div>
                      <Typography
                        variant={'body2'}
                        className={clsx(classes.stBrand, 'brand')}>
                        {row.brand}
                      </Typography>
                      <Typography
                        variant={'body2'}
                        className={clsx(classes.stArticle, 'article')}>
                        {row.article}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    align='left'
                    className={clsx(classes.gridItem, 'grid_item')}>
                    <div style={{display: 'grid', width: '100%'}}>
                      <Typography variant={'body2'} className={classes.stName}>
                        {row.name}
                      </Typography>
                      {/*<span className={classes.stInfo}>{`${t('common:code')}: ${row.sku}`}</span>*/}
                      <span className={classes.stInfo}>
                        {row.weight > 0
                          ? t('common:weight') +
                          ': ' +
                          row.weight +
                          ' ' +
                          t('common:kg')
                          : ''}
                      </span>
                      <div className={classes.labelBlock}>
                        {row.promoLabel && row.promoLabel[0] !== undefined && (
                          <PromoLabel promoLabel={row.promoLabel} />
                        )}
                        {row.isDiscount !== 0 ? (
                          <DiscountLabel
                            discount={`${'- '}${row.isDiscount}${'%'}`}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Grid>
                </RouterLink>
                
                <Grid container item xs={5} spacing={0}>
                  <Grid
                    item
                    xs={5}
                    align='left'
                    className={clsx(classes.gridItem, 'grid_item')}>
                    <StorageBlockHide
                      type={type}
                      warehouses={warehouses}
                      typePage={'card'}
                      currencyMainContr={currencyMainContr}
                      //currencyMainSite={currencyMainSite}
                      currencyMain={currencyMain}
                      index={index}
                      row={row}
                      setDialogData={setDialogData}
                      //addToCart={}
                      classStDelivery={classes.stDelivery}
                      classStBalance={classes.stBalance}
                      handleUuidInfo={handleUuidInfo}
                    />
                  </Grid>
                  
                  <Grid
                    container
                    item
                    xs={7}
                    align='center'
                    className={classes.gridContainerSubBlPrice}>
                    <Grid
                      item
                      xs={8}
                      align='center'
                      className={classes.stPrice}>
                      {currencyMain && currencyMain.simbol && (
                        <div
                          style={{display: 'inline-grid', textAlign: 'right'}}>
                          {row.isDiscount !== 0 && (
                            <PriceToStr
                              stSumma={{
                                fontStyle: 'normal',
                                fontSize: 12,
                                fontWeight: 'normal',
                                color: '#546e7a',
                                textDecoration: 'line-through',
                              }}
                              data={
                                row.offers
                                  ? row.offers[0].priceCurrent
                                  : row.regularPrice
                              }
                              beforeText={''}
                              text={currencyMain.simbol}
                              grn={t('common:uah')}
                            />
                          )}
                          <PriceToStr
                            stSumma={{
                              fontStyle: 'normal',
                              color: row.isDiscount !== 0 && 'red',
                            }}
                            // data={row.offers ? row.offers[0].price * currencyMainContr.koef : row.price * currencyMainContr.koef}
                            data={
                              row.offers
                                ? row.offers[0].priceCurrent
                                : row.priceCurrent
                            }
                            beforeText={''}
                            text={currencyMain.simbol}
                            grn={t('common:uah')}
                          />
                        </div>
                      )}
                      {row.discounts_by_quantity_sum_of_order &&
                      row.discounts_by_quantity_sum_of_order.best_discount !==
                      undefined &&
                      row.discounts_by_quantity_sum_of_order.best_discount
                        .discount_conditions_document__promotion_type !==
                      'NO_CONDITION' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img
                              style={{width: '20px', margin: '0 5px 0 0'}}
                              src={IconDiscount}
                              alt={'IconDiscount'}
                            />
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: 12,
                                margin: '0 5px 0 0',
                              }}>
                              {t('common:best_price')}
                            </span>
                          </div>
                          <PriceToStr
                            stSumma={{
                              fontStyle: 'normal',
                              fontSize: '12px',
                              color: 'red',
                              fontWeight: '600',
                            }}
                            data={
                              row.discounts_by_quantity_sum_of_order
                                .best_discount.price_with_discount
                            }
                            beforeText={''}
                            // text={currencyMain.simbol} grn={t('common:uah')}
                          />
                        </div>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{display: 'flex', alignItems: 'center', gap: 10}}>
                      <Grid item>
                        <div
                          className={clsx(
                            'like table-type',
                            wish_list?.find(
                              i => i.article === row.article_clean,
                            )
                              ? 'active'
                              : '',
                          )}
                          onClick={e =>
                            handleLike(e, {
                              article: row.article_clean,
                              brand: row.brand,
                              main_article: row.article,
                            })
                          }>
                          <svg
                            class='like-default'
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M1.04853 4.15142C0.750873 2.53645 1.85092 1.1375 3.56571 1.00537C5.07558 0.890012 5.82404 2.66858 5.97503 3.00835C5.9767 3.01405 5.98024 3.01906 5.98511 3.02263C5.98997 3.0262 5.9959 3.02813 6.00199 3.02813C6.00809 3.02813 6.01401 3.0262 6.01888 3.02263C6.02374 3.01906 6.02728 3.01405 6.02895 3.00835C6.72997 1.1396 8.00689 1.0599 8.45553 1.01585C9.66343 0.890013 11.2833 1.79817 10.9576 3.91442C10.4917 6.85073 5.97072 10.1667 5.97072 10.1667C5.97072 10.1667 1.63738 7.30796 1.04853 4.15142Z'
                              stroke='#315EFB'
                              strokeWidth='1.6'></path>
                          </svg>
                        </div>
                      </Grid>
                      <Grid item>
                        <button
                          disabled={
                            !(
                              row.countInStoks > 0 &&
                              (row.price || row.offers[0].price)
                            )
                          }
                          onClick={() =>
                            setDialogData({row, indexRows: '', type: type})
                          }
                          className='btn'
                          style={{padding: '12px 12px'}}>
                          <svg
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M0.304453 0.654591C0.49957 0.454903 0.778008 0.344864 1.05645 0.348731C1.87699 0.348731 2.7225 0.519591 3.41332 0.980841C3.95227 1.33697 4.36184 1.871 4.59633 2.47076C4.72289 2.77803 4.79637 3.10322 4.86 3.42877C5.30719 5.68791 5.75227 7.94776 6.21633 10.2037C6.29121 10.5493 6.47402 10.8844 6.77109 11.0865C7.11879 11.3375 7.55789 11.4237 7.98012 11.4237C9.72738 11.4258 11.475 11.4212 13.2223 11.4258C13.5197 11.0809 13.7602 10.6928 13.9971 10.3053C14.3768 9.68939 14.7104 9.04709 15.0525 8.41006C15.4093 7.75932 15.7127 7.08115 16.0555 6.42303C16.2605 6.03631 16.7295 5.81904 17.1577 5.90729C17.6295 5.9899 17.9975 6.43252 18 6.911V6.97252C17.9845 7.13108 17.9395 7.28576 17.8703 7.42885C17.216 8.79854 16.5414 10.1619 15.7419 11.4535C15.4009 11.99 15.0441 12.5237 14.5948 12.9772C14.3205 13.2497 13.9771 13.5067 13.5714 13.4975C11.707 13.5035 9.84234 13.4993 7.97801 13.4996C7.14656 13.5014 6.30141 13.2873 5.6148 12.8074C4.88426 12.3092 4.37379 11.5161 4.18992 10.6537C3.70934 8.33693 3.25512 6.01486 2.79422 3.69385C2.73727 3.39854 2.64937 3.09338 2.43914 2.86803C2.22469 2.63072 1.90898 2.51787 1.60031 2.46865C1.26105 2.4008 0.891211 2.47674 0.574453 2.31045C0.237656 2.14697 0.0158203 1.79049 0 1.41854V1.34049C0.0189844 1.08561 0.120234 0.834591 0.304453 0.654591Z'
                              fill='#1F1F1F'
                            />
                            <path
                              d='M6.94234 14.2255C7.36633 14.1379 7.81563 14.2339 8.18266 14.4582C8.54617 14.6867 8.82426 15.0527 8.93324 15.4696C9.15649 16.1991 8.79613 17.0464 8.13449 17.4159C7.74109 17.6448 7.25137 17.7228 6.81297 17.5868C6.39004 17.4753 6.01809 17.1905 5.79273 16.8168C5.54453 16.4059 5.46227 15.8852 5.61836 15.4264C5.78992 14.8193 6.32676 14.3464 6.94234 14.2255Z'
                              fill='#1F1F1F'
                            />
                            <path
                              d='M13.165 14.227C13.5724 14.1415 14.0073 14.2224 14.3655 14.4312C15.0402 14.8088 15.3998 15.6807 15.1509 16.4193C14.9523 17.1358 14.2404 17.6586 13.4996 17.6529C13.0155 17.6512 12.5332 17.436 12.2129 17.0721C11.8934 16.7164 11.7292 16.2238 11.7798 15.7471C11.8304 15.2996 12.0554 14.8703 12.4091 14.5884C12.6239 14.4034 12.8911 14.2913 13.165 14.227Z'
                              fill='#1F1F1F'
                            />
                            <rect
                              x='7.875'
                              y='5.625'
                              width='5.625'
                              height='2.25'
                              rx='1.125'
                              fill='#1F1F1F'
                            />
                          </svg>
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ),
      )}
      {maxPages > 1 && (
        <Pagination
          count={maxPages}
          page={currentPage}
          onChange={handlePageChange}
          size='large'
          className={classes.pagination}
        />
      )}
    
    </div>
  );
};

class Wrapper extends React.Component {
  componentDidMount() {
  }
  
  componentDidUpdate(prevProps) {
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    //При открытии окна СЛАЙДЕНРА и ДОБАВЛЕНИЯ В КОРЗИНУ не перерисовывать окно поиска
    if (this.props.dialogData !== nextProps.dialogData) return false;
    else if (this.props.photosGallery !== nextProps.photosGallery) return false;
    else if (this.props.anchorElInfo !== nextProps.anchorElInfo) return false;
    else return true;
  }
  
  render() {
    return <PtoductsNewTable {...this.props} />;
  }
}

function mapState(state) {
  const {listMyFavorite, promotionsByProductsList} = state;
  const {userSelectedData} = state;
  
  return {
    listMyFavorite,
    promotionsByProductsList,
    userSelectedData,
  };
}

const actionCreators = {
  addToWishlist: customerActions.addToWishlist,
  removeFromWishlist: customerActions.removeFromWishlist,
  searchPromotionList: searchActions.searchPromotionList,
  getCatalogByGroup: catalogActions.getCatalogByGroup,
};
PtoductsNewTable.propTypes = {
  //history: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  getCatalogByGroup: PropTypes.func.isRequired,
};

export default connect(mapState, actionCreators)(Wrapper);
//export default PtoductsNewTable;
