import {Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  skeleton_inner: {
    backgroundColor: '#DBDDE0',
    borderRadius: 4,
    width: '100%',
    height: '100%',
    // position: 'relative',

    '& .skeleton-image-hr': {
      maxWidth: 170,
      width: '100%',
    },
    // '& .product-item-skeleton': {
    // },
  },
  skeleton_inner_hr: {
    maxWidth: 'calc(33% - 20px)',
    justifyContent: 'space-between',
    minHeight: 200,
    backgroundColor: '#DBDDE0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // borderRadius: 10,
    '& .skeleton-top': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 20,
      '& .skeleton-image-hr': {
        maxWidth: 170,
        width: '100%',
      },
    },

    '& .skeleton-bottom': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 10,
    },
  },
}));

export const ProductSkeleton = ({typePage}) => {
  const classes = useStyles();
  const theme = useTheme();
  const skeletonInnerBreakpoint = useMediaQuery(theme.breakpoints.down(450));

  if (typePage === 'cardHr') {
    return (
      <div className={classes.skeleton_inner_hr}>
        <div className="skeleton-top">
          <Skeleton
            height={'100%'}
            variant="rect"
            className="skeleton-image-hr"
          />
          <div style={{width: '100%', padding: '5px 10px'}}>
            <div>
              <Typography variant="h4" style={{marginBottom: 0}}>
                <Skeleton width={120} />
              </Typography>
              <Typography
                variant="h4"
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Skeleton width={140} />
                <Skeleton width={20} />
              </Typography>
              <Typography variant="p">
                <Skeleton />
              </Typography>
            </div>
            <div style={{marginTop: 15}}>
              <Typography
                variant="h5"
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Skeleton width={120} />
                <Skeleton width={20} />
              </Typography>
              <Typography
                variant="h5"
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Skeleton width={100} />
                <Skeleton width={20} />
              </Typography>
              <Typography
                variant="h5"
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Skeleton width={150} />
                <Skeleton width={20} />
              </Typography>
              <Typography
                variant="h5"
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Skeleton width={90} />
                <Skeleton width={20} />
              </Typography>
            </div>
          </div>
        </div>
        <div className="skeleton-bottom">
          <Typography variant="h4">
            <Skeleton width={90} />
          </Typography>
          <Skeleton
            variant="rect"
            width={58}
            height={42}
            style={{borderRadius: 20}}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.skeleton_inner}>
      <Skeleton
        width={'100%'}
        height={skeletonInnerBreakpoint ? 120 : 180}
        variant="rect"
        className="skeleton-image"
      />
      <div className="p-2">
        <Typography variant={skeletonInnerBreakpoint ? 'h3' : 'h2'}>
          <Skeleton width="120px" />
        </Typography>
        <div className="d-flex">
          <Typography variant={skeletonInnerBreakpoint ? 'h3' : 'h2'}>
            <Skeleton width="100px" />
          </Typography>
          <Typography variant={skeletonInnerBreakpoint ? 'h3' : 'h2'}>
            <Skeleton width="30px" />
          </Typography>
        </div>
        <Skeleton />
        <Skeleton width="95%" />
        <Skeleton width="99%" />
        <Typography variant={skeletonInnerBreakpoint ? 'h2' : 'h1'}>
          <Skeleton width="150px" />
        </Typography>
        {!skeletonInnerBreakpoint && (
          <Typography variant="h1" className="mt-2">
            <Skeleton width="70px" />
          </Typography>
        )}
      </div>
    </div>
  );
};
