import axios from 'axios';
import config from '../_services/config';
// import {store} from './store';
import {setupCache} from 'axios-cache-adapter';

// Create `axios-cache-adapter` instance

const cache = setupCache({
  maxAge: 15 * 60 * 1000000,
});


const axInstTest = axios.create({
  baseURL: "https://new-api.daniparts.com",
  adapter: cache.adapter,
  withCredentials: true,
});
axInstTest.defaults.headers.post['Content-Type'] = 'application/json';

// const state = store.getState();
// const {
//   // authentication,
//   // languageReducer
// } = state;

// перенес язык в \src\_HOC\Language.js
// axInst.defaults.headers.common['Accept-Language'] = languageReducer.ln_ch;

// let currentAuthHeader = `Token ${config.guestToken}`;
//
// if (authentication && authentication.loggedIn) {
//   currentAuthHeader = `Token ${authentication.key}`;
// }
// axInst.defaults.headers.common['Authorization'] = currentAuthHeader;

export default axInstTest;
