import React, { useState } from 'react'
import useAnimate from '../../hooks/useAnimate';
// import material ui
import { makeStyles, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import DashboardAnimate from '../../_components/DashboardAnimate';
import DashboardBanner from '../../_components/DashboardBanner';
import EditManagerForm from './components/Form'
// import router
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px"
    },

    body: {
        padding: "30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "15px 0"
        },
    },
}));

const ManagerEditingPage = () => {
    const { id } = useParams();
    const classes = useStyles();
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [snack, setSnack] = useState(false);
    useAnimate();

    const handleCloseAction = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnack(false);
    };

    const action = (
        <React.Fragment>
            <btn onClick={handleCloseAction}>UNDO</btn>
        </React.Fragment>
    );

    return (
        <main className="dashboard">
            <DashboardBanner />
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <h1 className="dashboard__title" style={{ padding: "25px 0" }}>{t("common:editing")}</h1>
                    <DashboardAnimate>
                        <EditManagerForm
                            manager_id={Number(id)}
                            setSnack={setSnack}
                            error={error}
                            setError={setError}
                        />
                    </DashboardAnimate>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snack}
                action={action}
                autoHideDuration={6000}
                onClose={handleCloseAction}
                message="Помилка">
                <Alert severity="error" variant="filled">
                    {error ? t('common:error_empty_input_label') : t('common:DataNotSent')}
                </Alert>
            </Snackbar>
        </main>
    )
}

export default ManagerEditingPage;