import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import axInst from '../../../../_helpers/axios-instance';
import {GoogeMap} from '../GoogleMap';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  section: {
    width: '100%',
    padding: '0 15px',
    marginBottom: '15px',
    marginTop: '50px',

    '&>div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '32px',

      [theme.breakpoints.down(768)]: {
        padding: '16px',
      },
    },
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '45.38% auto',
    alignItems: 'center',
    gap: '32px',

    [theme.breakpoints.down(992)]: {
      gridTemplateColumns: '100%',
    },
  },

  maps: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: '16px',

    '& iframe': {
      borderRadius: '8px',
    },
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '27px',

    '&>div': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '32px',

      [theme.breakpoints.down(1200)]: {
        gap: '16px',
      },

      [theme.breakpoints.down(768)]: {
        gridTemplateColumns: '100%',
      },

      '&:last-child': {
        paddingTop: '27px',
        borderTop: '1px solid #FFBE00',
      },

      '&>article': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',

        '&>h4': {
          display: 'flex',
          alignItems: 'center',
          gap: '5px',

          fontSize: '14px',
          color: '#1C2A53',
        },

        '&>a': {
          fontSize: '14px',
          color: 'black',
          transition: '0.5s',

          '&>span': {
            fontWeight: '500',
          },

          '&:hover': {
            color: '#FFBE00',
          },
        },

        '&>p': {
          fontSize: '14px',
          color: 'black',
        },
      },
    },
  },
}));

export const Contacts = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  // const [data, setData] = useState(null);

  // useEffect(() => {
  //   axInst
  //     .post('/api/static-page/', { slug: 'contacts' })
  //     .then(res => setData(res.data));
  // }, []);

  // if (!data) return <></>;
  // const createMarkup = html => {
  //   return { __html: html };
  // };

  return (
    <section className={classes.section}>
      <div className="dashboard-card">
        <h4 className="dashboard__title">{t('common:contacts')}</h4>
        <div className={classes.grid}>
          <div className={classes.maps}>
            <iframe
              style={{
                width: '100%',
                minHeight: '235px',
                height: '100%',
                border: 0,
                outline: 0,
              }}
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB0pXWFUwYNWv8QExnAYI8kX0L_mUcel6c
    &q=Дані-партс,+ТОВ+Unnamed+Road+Корнин,+Рівненська+область+35304"></iframe>
            <iframe
              style={{
                width: '100%',
                minHeight: '235px',
                height: '100%',
                border: 0,
                outline: 0,
              }}
              src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d20600.794566429897!2d23.8692708!3d49.8030004!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDQ4JzQ3LjciTiAyM8KwNTInMzEuMyJF!5e0!3m2!1suk!2sua!4v1721311833765!5m2!1suk!2sua"></iframe>
          </div>
          <div className={classes.info}>
            <div>
              <article>
                <h4>
                  <svg
                    width="13"
                    height="17"
                    viewBox="0 0 13 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0909 6.68182C12.0909 3.25758 9.39416 0.5 6.04545 0.5C2.69675 0.5 0 3.25758 0 6.68182C0 10.1061 6.04545 16.5 6.04545 16.5C6.04545 16.5 12.0909 10.1061 12.0909 6.68182ZM3.23017 6.56061C3.23017 4.98485 4.50445 3.68182 6.04545 3.68182C7.58645 3.68182 8.86074 4.95455 8.86074 6.56061C8.86074 8.13636 7.61608 9.4394 6.04545 9.4394C4.50445 9.4394 3.23017 8.13636 3.23017 6.56061Z"
                      fill="#1C2A53"
                    />
                  </svg>
                  <span>{t('common:address')}:</span>
                </h4>
                <a href="https://www.google.com/maps/place/%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%A2%D1%80%D0%B0%D0%BA%D1%82%D0%BE%D1%80%D0%BD%D0%B0,+%D0%A0%D1%96%D0%B2%D0%BD%D0%B5%D0%BD%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C/@50.5599824,26.2929226,17z/data=!3m1!4b1!4m5!3m4!1s0x472f12389c957165:0xd46b41d4e1e70d55!8m2!3d50.5599824!4d26.2951113?shorturl=1">
                  {t('common:company_location')}
                </a>
              </article>
              <article>
                <h4>
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 2C13.1423 2 16.5 5.35775 16.5 9.5C16.5 13.6423 13.1423 17 9 17C4.85775 17 1.5 13.6423 1.5 9.5C1.5 5.35775 4.85775 2 9 2ZM9 3.5C7.4087 3.5 5.88258 4.13214 4.75736 5.25736C3.63214 6.38258 3 7.9087 3 9.5C3 11.0913 3.63214 12.6174 4.75736 13.7426C5.88258 14.8679 7.4087 15.5 9 15.5C10.5913 15.5 12.1174 14.8679 13.2426 13.7426C14.3679 12.6174 15 11.0913 15 9.5C15 7.9087 14.3679 6.38258 13.2426 5.25736C12.1174 4.13214 10.5913 3.5 9 3.5ZM9 5C9.1837 5.00002 9.361 5.06747 9.49828 5.18954C9.63556 5.31161 9.72326 5.47981 9.74475 5.66225L9.75 5.75V9.1895L11.7802 11.2198C11.9148 11.3547 11.9929 11.5358 11.9987 11.7263C12.0045 11.9167 11.9376 12.1023 11.8116 12.2452C11.6855 12.3881 11.5098 12.4777 11.3201 12.4958C11.1305 12.5139 10.941 12.4591 10.7902 12.3425L10.7198 12.2802L8.46975 10.0302C8.35318 9.91358 8.27832 9.76175 8.25675 9.59825L8.25 9.5V5.75C8.25 5.55109 8.32902 5.36032 8.46967 5.21967C8.61032 5.07902 8.80109 5 9 5Z"
                      fill="#1C2A53"
                    />
                  </svg>
                  <span>{t('common:schedule')}:</span>
                </h4>
                <p>{t('common:monday_friday')}: 8:30 - 17:30</p>
                <p>{t('common:Saturday')}: 9:00 - 14:00</p>
                <p>{t('common:schedule4')}</p>
                <p>{t('common:schedule5')}</p>
              </article>
            </div>
            <div>
              <article>
                <a href="tel:+38 067 360-12-00">
                  +38 067 360-12-00 ({t('common:cargo_department')})
                </a>
                <a href="tel:+38 093 311-69-83 ">
                  +38 093 311-69-83 ({t('common:electronics_department')})
                </a>
                <a href="tel:+38 063 112-74-25">
                  +38 063 112-74-25 ({t('common:car_department')})
                </a>
                <a href="tel:+38 063 602-72-50">
                  +38 063 602-72-50 ({t('common:alternative_energy_department')}
                  )
                </a>
                <a href="tel:+38 050 086-05-85">
                  +38 050 086-05-85 ({t('common:electro_vegicle_group')})
                </a>
                <a href="tel:+38 067 616-12-00">
                  +38 067 616-12-00 ({t('common:bookkeeping')})
                </a>
              </article>
              <article>
                <a href="skype:rviparts?chat">
                  <span>Skype:</span> rviparts
                </a>
                <a href="tel:+380672316934">
                  <span>Факс:</span> +380672316934
                </a>
                <a href="mailto:rviparts@gmail.com">
                  <span>E-mail: </span> rviparts@gmail.com
                </a>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>

    // <div className="products-block contact mt-5">
    //   <div className="wrapper-inner">
    //     <div className="products-block__header">
    //       <h2 className="title mb-4">{data.name}</h2>
    //     </div>
    //     <div className="row">
    //       <div className="col-lg-8 col-12">
    //         <div id="map">
    //           <div className={classes.maps}>
    //             <iframe style={{
    //               width: '100%',
    //               height: '100%',
    //               border: 0,
    //               outline: 0
    //             }}
    //               src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB0pXWFUwYNWv8QExnAYI8kX0L_mUcel6c
    // &q=Дані-партс,+ТОВ+Unnamed+Road+Корнин,+Рівненська+область+35304"></iframe>
    //             <iframe
    //               style={{
    //                 width: '100%',
    //                 height: "100%",
    //                 border: 0,
    //                 outline: 0
    //               }}
    //               src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d20600.794566429897!2d23.8692708!3d49.8030004!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDQ4JzQ3LjciTiAyM8KwNTInMzEuMyJF!5e0!3m2!1suk!2sua!4v1721311833765!5m2!1suk!2sua"></iframe>
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         className="col-lg-4 col-12 mt-lg-0 mt-4  contact-right"
    //         dangerouslySetInnerHTML={createMarkup(data.html)}></div>
    //     </div>
    //   </div>
    // </div>
  );
};
