import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Alert, AlertTitle} from '@mui/material';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {makeStyles} from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {userActions} from '../../_actions';
import {connect} from 'react-redux';
import {GoogleLogin} from 'react-google-login';
import {useTranslation} from 'react-i18next';
import validate from 'validate.js';
import clsx from 'clsx';
import FormField from '../../_components/Authorization/FormField';
import {SignInLogo} from '../../_components/Authorization/SignInLogo';
import {FormHeader} from '../../_components/Authorization/FormHeader';
import SignInSvg from './components/SignInSvg';

const useStyles = makeStyles(theme => ({
  alert: {
    position: 'absolute',
    maxWidth: '500px',
    bottom: '20px',
    left: '20px',
    right: '20px',
    zIndex: '30',
  },

  alert_btn: {
    marginTop: '15px',
    cursor: 'pointer',
    border: '1px solid transparent',
    boxSizing: 'border-box',
    padding: '6px 12px',
    backgroundColor: '#FFBE00',
    borderRadius: '22px',
    transition: 'all ease-in-out 0.3s',

    '&:hover': {
      backgroundColor: '#ffe000',
    },
  },

  radioGroup: {
    gap: 8,
    marginBottom: 15,
    width: '100%',
    justifyContent: 'center',
  },

  radioLabel: {
    margin: 0,
    gap: 4,
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      fontWeight: 200,
    },
  },

  radio: {
    color: 'blue',
    padding: 0,
    '&.Mui-checked': {
      color: '#FFBE00',
    },
  },
}));

const schema = {
  username: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 4, maximum: 64},
  },
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8, maximum: 64},
  },
  captcha: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8},
  },
};

const SignIn = props => {
  const {t} = useTranslation();
  const {login, loginSocial, authentication, resendEmailConfirmation} = props;
  const classes = useStyles();
  const [disabledBtn, setDisable] = useState(false);
  const theme = useTheme();
  const tab = useMediaQuery(theme.breakpoints.down(780));
  const [verificationError, setVerificationError] = useState(false);
  const [loginType, setLoginType] = useState('email');

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      username: '',
      password: '',
      captcha: null,
    },
    touched: {},
    errors: {},
  });

  const handleLoginTypeChange = event => {
    const newLoginType = event.target.value;
    setLoginType(newLoginType);

    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        username: newLoginType === 'phone' ? '+380 ' : '',
      },
      touched: {
        ...prevState.touched,
        username: false,
      },
      errors: {
        ...prevState.errors,
        username: [],
      },
    }));
  };

  function formatPhoneNumber(value) {
    const cleanValue = value.replace(/\D/g, '');

    if (cleanValue.length === 0) {
      return '';
    }

    let formattedNumber = '';

    if (cleanValue.length > 0) {
      formattedNumber += cleanValue.slice(0, 2);
    }
    if (cleanValue.length > 2) {
      formattedNumber += ' ' + cleanValue.slice(2, 5);
    }
    if (cleanValue.length > 5) {
      formattedNumber += ' ' + cleanValue.slice(5, 7);
    }
    if (cleanValue.length > 7) {
      formattedNumber += ' ' + cleanValue.slice(7, 9);
    }

    return formattedNumber;
  }

  const myvalidate = (formData, errors) => {
    const value = formData.username;
    if (!value) return errors;

    if (!errors) errors = {};
    if (!errors.username) errors.username = [];

    if (loginType === 'phone') {
      const phoneNumber = value.replace(/\D/g, '');
      if (phoneNumber.length !== 12 || !phoneNumber.startsWith('380')) {
        errors.username.push('Invalid phone number format');
      }
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(value)) {
        errors.username.push('Invalid email format');
      }
    }

    if (errors.username && errors.username.length === 0) {
      delete errors.username;
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    const customErrors = myvalidate(formState.values, errors);

    const hasErrors = customErrors !== null;
    const isEmptyFields =
      !formState.values.username ||
      !formState.values.password ||
      !formState.values.captcha;

    setDisable(hasErrors || isEmptyFields);

    setFormState(formState => ({
      ...formState,
      isValid: !hasErrors,
      errors: customErrors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    const error = authentication?.error?.response?.data?.email_not_verified;

    if (error) {
      setVerificationError(true);
    }
  }, [authentication?.error?.response?.data?.email_not_verified]);

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  const [passwordIsShow, togglePasswordType] = useState(false);

  const handleCaptcha = value => {
    const captcha = 'captcha';
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [captcha]: value,
      },
    }));
  };

  const handleResendMail = event => {
    event.preventDefault();
    const {username} = formState.values;
    resendEmailConfirmation(username);
    setVerificationError(false);
    window.grecaptcha.reset();
  };

  const handleSignIn = event => {
    event.preventDefault();
    const {username, password, captcha} = formState.values;

    login(username, password, captcha, loginType === 'phone');
    window.grecaptcha.reset();
  };

  const responseGoogle = response => {
    if (response.accessToken) {
      loginSocial(
        response.accessToken,
        'rest-auth/google/',
        response.profileObj.name,
      );
    }
  };

  const handleChange = event => {
    event.persist();
    authentication.failure = false;

    const {name, value} = event.target;
    let newValue = value;

    if (name === 'username' && loginType === 'phone') {
      if (value.startsWith('+38') && value.length <= 4) {
        newValue = '+380';
      } else {
        newValue = value.replace(/[^0-9+]/g, '');

        if (!newValue.startsWith('+380')) {
          newValue = '+380' + newValue.replace(/^\+\d*/, '');
        }

        if (newValue.length > 13) {
          newValue = newValue.slice(0, 13);
        }

        if (newValue.length > 4) {
          const cleanValue = newValue.replace(/^\+380/, '');
          newValue = '+380 ' + formatPhoneNumber(cleanValue);
        }
      }
    }

    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: newValue,
      },
      touched: {
        ...prevState.touched,
        [name]: true,
      },
    }));
  };

  const newLink =
    authentication.error &&
    authentication.error.response &&
    authentication.error.response.data.non_field_errors &&
    authentication.error.response.data.non_field_errors.length > 0 &&
    authentication.error.response.data.non_field_errors[0].indexOf('E-mail') ===
      0;

  return (
    <div className="user-main__wrapper">
      <div className="user-main__inner">
        <SignInLogo />
        <div className="user-main__right">
          <div className="user-main__right-form user-form user-form-login">
            <FormHeader />
            <div className="mb-0 mt-0">
              <form onSubmit={handleSignIn}>
                <RadioGroup
                  row
                  aria-label="login-type"
                  name="login-type"
                  value={loginType}
                  onChange={handleLoginTypeChange}
                  className={classes.radioGroup}>
                  <FormControlLabel
                    value="email"
                    control={<Radio className={classes.radio} />}
                    label={t('common:login_email')}
                    className={classes.radioLabel}
                  />
                  <FormControlLabel
                    value="phone"
                    control={<Radio className={classes.radio} />}
                    label={t('common:login_phone')}
                    className={classes.radioLabel}
                  />
                </RadioGroup>
                <FormField
                  name="username"
                  handleChange={handleChange}
                  type="text"
                  title={
                    loginType === 'phone'
                      ? t('common:enter_phone')
                      : t('common:enter_email')
                  }
                  formState={formState}
                  hasErrorText={
                    hasError('username')
                      ? loginType === 'phone'
                        ? t('common:enter_phone3')
                        : t('common:enter_email2')
                      : authentication.error &&
                        authentication.error.response &&
                        authentication.error.response.data.user_not_found
                      ? authentication.error.response.data.user_not_found
                      : null
                  }
                  authentication={authentication}
                  signInUsername={true}
                />
                <FormField
                  name="password"
                  handleChange={handleChange}
                  type="password"
                  title={t('common:enter_password')}
                  formState={formState}
                  hasErrorText={
                    hasError('password') &&
                    formState.values.password?.length < 8
                      ? t('common:Password must be at least 8 characters')
                      : authentication.error &&
                        authentication.error.response &&
                        authentication.error.response.data.password_incorrect
                      ? authentication.error.response.data.password_incorrect
                      : null
                  }
                  authentication={authentication}
                />
                <Link to="/forgot-pw" className="user-form__link mt-7 mb-4">
                  {t('common:Forgot your password?')}
                </Link>
                <div className="user-form__captcha mb-4">
                  <ReCAPTCHA
                    sitekey={'6LcqltkUAAAAAJ5dGwJgNtTUz91abygZNj8TtIGF'}
                    onChange={handleCaptcha}
                    style={{maxWidth: 200}}
                  />
                </div>
                <button
                  className={`${
                    disabledBtn ? 'btn-disabled' : 'btn'
                  } btn-big btn--w100 mb-6`}>
                  {t('common:come_in')}
                </button>
                <div className="user-form__footer">
                  <GoogleLogin
                    clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
                    buttonText=""
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    // onLogoutSuccess={this.logout}
                    render={renderProps => (
                      <button
                        onClick={renderProps.onClick}
                        className="user-form__footer-button">
                        {t('common:Registration through')}
                        <span className="user-form__footer-google">
                          <SignInSvg />
                        </span>
                      </button>
                    )}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {verificationError && (
        <Alert
          severity="error"
          className={clsx(classes.alert)}
          onClose={() => setVerificationError(false)}>
          <AlertTitle>
            <strong>{t('common:error')}</strong>
          </AlertTitle>
          {t('common:E-mail is not confirmed')}.
          <br />
          <button
            onClick={handleResendMail}
            className={clsx(classes.alert_btn)}>
            {t('common:SEND')}
          </button>
        </Alert>
      )}
    </div>
  );
};

function mapState(state) {
  const {authentication} = state;
  const {loggedIn} = state.authentication;
  return {loggedIn, authentication};
}

const actionCreators = {
  login: userActions.login,
  resendEmailConfirmation: userActions.resendEmailConfirmation,
  logout: userActions.logout,
  loginSocial: userActions.loginSocial,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.authentication.error = null;
  }

  render() {
    return <SignIn {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
