import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {cartActions} from '../../../_actions/cart.actions';
import {withRouter} from 'react-router-dom';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles(theme => ({
    list: {
        // width: '100%',
        // marginTop: 0,
        // border: '1px solid #c0c0c0',
        // listStyle: 'none',
        // padding: 0,
        // backgroundColor: '#fff',
    },
    listItem: {
        // display: 'flex',
        // alignItems: 'center',
        // padding: '0 8px',
        // height: 32,
        [theme.breakpoints.up('lg')]: {
            // height: 48,
        },
        '& .MuiTypography-body2': {
            // color: '#636363',
        },
        '&:not(:last-child)': {
            // borderBottom: '1px solid #c0c0c0',
        },
    },
}));

function AnalogNumbers(props) {
    React.useEffect(() => {
    }, []);
    const classes = useStyles();
    const {
        productAnalogNumbers,
        // getAnalogNumbers
    } = props;
    if (!productAnalogNumbers.data) return <React.Fragment/>;


    return (
        <TableContainer className="analog_code_table">
            <Table size="small">
                <TableBody>
                    {productAnalogNumbers.data && productAnalogNumbers.data.map((item, index) =>
                        <TableRow key={index} className={classes.listItem}>
                            <TableCell>
                                <Typography >{item.brand} {item.article}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function mapState(state) {
    const {productAnalogNumbers, getAnalogNumbers} = state;
    return {productAnalogNumbers, getAnalogNumbers};
}

const actionCreators = {
    getAnalogNumbers: cartActions.getAnalogNumbers,
};

class Wrapper extends React.Component {
    componentDidMount() {
        this.props.getAnalogNumbers(
            this.props.match.params.article,
        );
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.article !== prevProps.match.params.article) {
            this.props.getAnalogNumbers(
                this.props.match.params.article,
            );
        }
    }

    render() {
        return <AnalogNumbers {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(withRouter(Wrapper));


