import {alertActions} from './';
import {promotionService} from '../_services/promotion.service';
import {errorMessage} from '../_constants/errorMessage.constants';
import {promotionConstants} from '../_constants/promotion.constants';
// import {searchService} from "../_services";
// import {userConstants} from "../_constants";

export const promotionActions = {
  searchRandomPromoProduct,
  promotionListItems,
  getPromotionsByGroupId,
};

function searchRandomPromoProduct() {
  return dispatch => {
    dispatch(request());

    promotionService.searchRandomPromoProduct().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: promotionConstants.RANDOM_PROMO_PRODUCT_REQUEST};
  }

  function success(data) {
    return {type: promotionConstants.RANDOM_PROMO_PRODUCT_SUCCESS, data};
  }

  function failure(error) {
    return {type: promotionConstants.RANDOM_PROMO_PRODUCT_FAILURE, error};
  }
}

function promotionListItems() {
  return dispatch => {
    dispatch(request());

    promotionService.promotionListItems().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: promotionConstants.PROMOTION_ITEMS_REQUEST};
  }

  function success(data) {
    return {type: promotionConstants.PROMOTION_ITEMS_SUCCESS, data};
  }

  function failure(error) {
    return {type: promotionConstants.PROMOTION_ITEMS_FAILURE, error};
  }
}

function getPromotionsByGroupId(id) {
  return dispatch => {
    dispatch(request());

    promotionService.getPromotionsByGroupId(id).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: promotionConstants.PROMOTION_ITEMS_REQUEST};
  }

  function success(data) {
    return {type: promotionConstants.PROMOTION_ITEMS_SUCCESS, data};
  }

  function failure(error) {
    return {type: promotionConstants.PROMOTION_ITEMS_FAILURE, error};
  }
}
