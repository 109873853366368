import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import useAnimate from '../../hooks/useAnimate';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import mui
import { makeStyles } from '@material-ui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@material-ui/core';
// import redux
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import components
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import DashboardBanner from '../../_components/DashboardBanner';
import DashboardSearch from '../../_components/DashboardSearch';
import DownloadAndPrint from './components/DownLoadAndPrint';
import TableOfGoods from './components/TableOfGoods';
import PaginationComponent from '../ListOfManagers/components/PaginationComponent';
import Preloader from '../../_components/Preloader';
import DashboardAnimate from '../../_components/DashboardAnimate';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1246,
    margin: '0 auto',
    padding: '0 15px',
    overflowX: 'hidden',
  },

  body: {
    padding: '50px 0 30px 0',
    [theme.breakpoints.down(768)]: {
      padding: '30px 0 15px 0',
    },
  },

  title: {
    color: 'rgb(28, 42, 83)',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  card: {
    padding: '28px 32px 36px 28px',
    [theme.breakpoints.down(540)]: {
      padding: 16,
    },
  },

  back: {
    paddingBottom: '34px',
  },

  content: {
    paddingTop: '24px',
    paddingBottom: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    [theme.breakpoints.down(960)]: {
      gap: 15,
    },
  },

  content__of__search: {
    [theme.breakpoints.down(628)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 12,
    },
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const PER_PAGE = 10;

function StoreGoods(props) {
  const {
    userSelectedData,
    warehouseUser,
    getWarehousemanUser,
    warehouseUserGoods,
    getWarehousemanUserGoods,
  } = props;
  useAnimate()
  const classes = useStyles();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const debounceGoods = useDebounce(handleGoods, 700);
  const [searchValue, setSearchValue] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  function handleGoods(warehouseId, filter, perPage, page) {
    if (!warehouseId) return;
    getWarehousemanUserGoods(warehouseId, filter, perPage, page);
  };

  useEffect(() => {
    getWarehousemanUser(userSelectedData?.customer_id);
  }, []);

  useEffect(() => {
    if (warehouseUser?.error || warehouseUser?.loading) return;

    const warehouseFirstId = warehouseUser?.result?.[0]?.id;
    const warehouseParam = searchParams.get('warehouse');
    const pageParam = searchParams.get('page');
    const searchParam = searchParams.get('search');

    const currentSearch = searchParam || "";
    setSearchValue(currentSearch);

    const currentPage = +pageParam || 1;
    setCurrentPage(currentPage);

    const currentWarehouse = +warehouseParam || warehouseFirstId;
    const findWarehouse = warehouseUser?.result?.find(item => item?.id === currentWarehouse);
    const checkingWarehouse = findWarehouse ? currentWarehouse : warehouseFirstId;
    setTabValue(checkingWarehouse);
    handleGoods(checkingWarehouse, currentSearch, PER_PAGE, currentPage);
  }, [warehouseUser?.loading]);

  const addParamToUrl = (key, value) => {
    searchParams.set(key, value);
    history.push({
      search: searchParams.toString(),
    });
  };

  const deleteParamFromUrl = (key) => {
    searchParams.delete(key);
    history.push({
      search: searchParams.toString(),
    });
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    handleGoods(newValue, "", PER_PAGE, 1);
    setSearchValue("");
    setCurrentPage(1);
    addParamToUrl("warehouse", newValue);
    deleteParamFromUrl("page");
    deleteParamFromUrl("search");
  };

  const handleChangeInput = (e) => {
    const { value } = e.target;
    debounceGoods(tabValue, value, PER_PAGE, 1);
    setSearchValue(value);
    setCurrentPage(1);
    if (value) addParamToUrl("search", value)
    else deleteParamFromUrl("search");
    deleteParamFromUrl("page");
  }

  const handleChangePage = (value) => {
    handleGoods(tabValue, searchValue, PER_PAGE, value);
    setCurrentPage(value);
    addParamToUrl("page", value);
  };

  return (
    <main className="dashboard">
      <DashboardBanner />
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.back}>
            <DashboardButtonBack />
          </div>
          <DashboardAnimate style={{ transform: "scale(0.97)" }}>
            <div className={`dashboard-card ${classes.card}`}>
              {warehouseUser?.loading ? (
                <div className="dashboard-card-empty">
                  <Preloader />
                </div>
              ) : warehouseUser?.error ? (
                <div className="dashboard-card-empty">
                  {t("common:unknown_error_label")}
                </div>
              ) : warehouseUser?.is_no_access ? (
                <div className="dashboard-card-empty">
                  {t("common:storekeeper_access_label")}
                </div>
              ) : null}
              <Box
                className={classes.content__of__search}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '0 0 32px 0',
                }}>
                <h1 className={classes.title}>{t('common:goods_on_store')}</h1>
                <DashboardSearch value={searchValue} onChange={handleChangeInput} />
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "100%", gap: "16px" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example">
                  {warehouseUser?.result?.map(item => (
                    <Tab
                      disabled={warehouseUserGoods?.loading}
                      value={item?.id}
                      label={item?.name}
                      {...a11yProps(item.id)}
                    />
                  ))}
                </Tabs>
                <DownloadAndPrint warehouse={warehouseUser?.result?.find((item) => item?.id === tabValue)} />
                <Box sx={{ position: "relative" }}>
                  {warehouseUserGoods?.loading ? (
                    <div className="dashboard-card-empty">
                      <Preloader />
                    </div>
                  ) : warehouseUserGoods?.error ? (
                    <div className="dashboard-card-empty">
                      {t("common:unknown_error_label")}
                    </div>
                  ) : null}
                  <TableOfGoods
                    data={warehouseUserGoods?.results}
                    perPage={PER_PAGE}
                    currentPage={currentPage}
                  />
                </Box>
                {warehouseUserGoods?.count && Math.ceil(warehouseUserGoods?.count / PER_PAGE) > 1 && (
                  <PaginationComponent
                    disabled={warehouseUserGoods?.loading}
                    pages={Math.ceil(warehouseUserGoods?.count / PER_PAGE)}
                    currentPage={currentPage}
                    onClick={handleChangePage}
                  />
                )}
              </Box>
            </div>
          </DashboardAnimate>
        </div>
      </div>
    </main>
  );
}

function mapState(state) {
  const {
    userSelectedData,
    warehouseUser,
    warehouseUserGoods,
  } = state;
  return {
    userSelectedData,
    warehouseUser,
    warehouseUserGoods,
  };
}

const actionCreators = {
  getWarehousemanUser:
    userActions.getWarehousemanUser,
  getWarehousemanUserGoods:
    userActions.getWarehousemanUserGoods,
};

export default connect(mapState, actionCreators)(StoreGoods);
