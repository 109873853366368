import {errorMessage, userConstants} from '../_constants';
import {alertActions} from './';
import {deliveryService} from "../_services/delivery.service";

export const deliveryActions = {
    getListTypeDelivery,
    getListDeliveryService,
    getCityByDeliveryType,
    getAddressByCity,
    getScheduler,
    getSchedulerTime,
    getSchedulerDateTime,
    getTermsByFreeShipping,
};

function getListTypeDelivery(id_warehouse) {
    return dispatch => {
        dispatch(request());

        deliveryService.getListTypeDelivery(id_warehouse).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_TYPE_DELIVERY_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_TYPE_DELIVERY_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_TYPE_DELIVERY_FAILURE, error};
    }
}

function getListDeliveryService() {
    return dispatch => {
        dispatch(request());

        deliveryService.getListDeliveryService().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_DELIVERY_SERVICES_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_DELIVERY_SERVICES_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_DELIVERY_SERVICES_FAILURE, error};
    }
}

function getCityByDeliveryType(deliveryType, id_warehouse) {
    if (deliveryType === '') {
        return dispatch => {
            dispatch(success({data: []}));
        };
    } else {
        return dispatch => {
            dispatch(request());

            deliveryService.getCityByDeliveryType(deliveryType, id_warehouse).then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            );
        };
    };

    function request() {
        return {type: userConstants.GET_CITY_BY_DELIVERY_TYPE_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_CITY_BY_DELIVERY_TYPE_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_CITY_BY_DELIVERY_TYPE_FAILURE, error};
    }
}

function getAddressByCity(deliveryType, cityId) {
    if (deliveryType === '' || cityId === '') {
        return dispatch => {
            dispatch(success({data: []}));
        };
    } else {
        return dispatch => {
            dispatch(request());

            deliveryService.getAddressByCity(deliveryType, cityId).then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            );
        };
    };

    function request() {
        return {type: userConstants.GET_ADDRESS_BY_CITY_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_ADDRESS_BY_CITY_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_ADDRESS_BY_CITY_FAILURE, error};
    }
}


function getScheduler(warehouseId, deliveryType, cityId) {
    if (deliveryType === '' || cityId === '') {
        return dispatch => {
            dispatch(success({data: []}));
        };
    } else {
        return dispatch => {
            dispatch(request());

            deliveryService.getScheduler(warehouseId, deliveryType, cityId).then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            );
        }
    };

    function request() {
        return {type: userConstants.GET_SCHEDULER_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_SCHEDULER_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_SCHEDULER_FAILURE, error};
    }
}

function getSchedulerTime(warehouseId, deliveryType, cityId, date) {
    if (deliveryType === '' || cityId === '' || date === '') {
        return dispatch => {
            dispatch(success({data: []}));
        };
    } else {
        return dispatch => {
            dispatch(request());

            deliveryService.getSchedulerTime(warehouseId, deliveryType, cityId, date).then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            );
        };
    }

    function request() {
        return {type: userConstants.GET_SCHEDULER_TIME_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_SCHEDULER_TIME_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_SCHEDULER_TIME_FAILURE, error};
    }
}

function getSchedulerDateTime(warehouseId, deliveryType, cityId, code3pl, orderLength, orderHeight, orderWidth, allWeightNP, totalVeightOrder) {
    if (deliveryType === '' || cityId === '') {
        return dispatch => {
            dispatch(success({data: []}));
        };
    } else {
        return dispatch => {
            dispatch(request());

            deliveryService.getSchedulerDateTime(warehouseId, deliveryType, cityId, code3pl, orderLength, orderHeight, orderWidth, allWeightNP, totalVeightOrder).then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            );
        }
    };

    function request() {
        return {type: userConstants.GET_SCHEDULER_DATE_TIME_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_SCHEDULER_DATE_TIME_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_SCHEDULER_DATE_TIME_FAILURE, error};
    }
}

function getTermsByFreeShipping(deliveryTerms) {
    return dispatch => {
        dispatch(request());

        deliveryService.getTermsByFreeShipping(deliveryTerms).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.TERMS_BY_FREE_SHIPPING_REQUEST};
    }

    function success(data) {
        return {type: userConstants.TERMS_BY_FREE_SHIPPING_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.TERMS_BY_FREE_SHIPPING_FAILURE, error};
    }
}
