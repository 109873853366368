import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    block: {
        display: 'flex', 
        gap: '10px', 
        alignItems: 'center', 
        color: 'rgb(28, 42, 83)',
    },
    title: {
        fontSize: '16px',
        fontWeight: 600,
        color: 'rgb(28, 42, 83)',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px!important',
        },
    }
}))

function Title(props) {
    const { content, style, variant } = props;
    const classes = useStyles();
    return (
        <div className={classes.block}>
            <span style={{ width: '16px', height: '16px', minWidth: '16px', minHeight: '16px', borderRadius: '50%', background: '#1f1f1f' }}></span>
            <Typography
                className={classes.title}
                variant={variant}
                style={style}
            >
                {content}
            </Typography>

        </div>
    );
}

export default Title;