import React from 'react';
import {useTranslation} from 'react-i18next';
import Timer from 'react-compound-timer';
import {ProductTooltip} from '../../../../_components/Product/ProductTooltip';
import noImage from '../../../../images/product.png';
import moment from 'moment';
import clsx from 'clsx';
import connect from 'react-redux/es/connect/connect';
import {Link} from 'react-router-dom';
import PriceToStr from '../../../../_components/PriceToStr';

const CarouselItem = ({row, setDialogData, sell_currency_id, currencies}) => {
  const {t} = useTranslation();
  let toDay = moment().valueOf();
  if (!row) return;

  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);

  const addToCart = () => {
    setDialogData({row, indexRows: '', type: 'promoProducts'});
  };

  return (
    <div
      style={{border: '2px solid #315efb', borderRadius: 5}}
      className={clsx(
        'product__inline swiper-slide',
        row?.balance?.every(i => i.quantity === '0') ? 'empty d-none' : '',
      )}>
      <div className="product__inline-inner">
        <div className="product__inline-img">
          {row.badge_name && (
            <div style={{width: '100%'}} className="product__inline-discount">
              {row.badge_name}
            </div>
          )}
          <Link to={`/product/${row.brand}/${row.article}`}>
            <img
              className={row.badge_name ? 'mt-2' : ''}
              src={row.images.thumb ? row.images.thumb : noImage}
              alt="product"
            />
          </Link>
        </div>
        <div
          className={clsx(
            'product__inline-text',
            row.badge_name ? 'mt-5' : '',
          )}>
          <Link to={`/product/${row.brand}/${row.article}`}>
            <h3 className="product__inline-title">{row.brand}</h3>
            <div className="product__inline-code">{row.article}</div>
            <p className="product__inline-desc">
              {/* {row.description.length < 60
              ? row.description
              : row.description.slice(0, 59) + '...'} */}
              {/* {row.promotion_name ?? row.promotion_name} */}
              {row.name ?? row.name}
            </p>
            {row?.promotion_date_to && (
              <div className="product__discount">
                <div
                  className="product__discount-title"
                  style={{textAlign: 'center'}}>
                  {t('common:It_remained_until')}:
                </div>
                <ul className="product__discount-list">
                  <Timer
                    initialTime={
                      moment(row.promotion_date_to).valueOf() - toDay
                    }
                    direction="backward">
                    <li className="product__discount-item">
                      <Timer.Days /> <span>{t('common:days')}</span>
                    </li>
                    <li className="product__discount-item">
                      <Timer.Hours /> <span>{t('common:hours')}</span>
                    </li>
                    <li className="product__discount-item">
                      <Timer.Minutes /> <span>{t('common:minutes')}</span>
                    </li>
                    <li className="product__discount-item">
                      <Timer.Seconds /> <span>{t('common:seconds')}</span>
                    </li>
                  </Timer>
                </ul>
              </div>
            )}
          </Link>
        </div>
      </div>
      <div className="product__inline-footer">
        <div className="price">
          {/* {row.price} {sell_currency_id === 2 ? t('common:uah') : '$'} */}
          {row.discounts_by_quantity_sum_of_order &&
          row.discounts_by_quantity_sum_of_order.best_discount !== undefined &&
          row.discounts_by_quantity_sum_of_order.best_discount
            .discount_conditions_document__promotion_type !== 'NO_CONDITION' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '-2px',
              }}>
              <PriceToStr
                stAll={{marginBottom: -5}}
                stSumma={{
                  fontStyle: 'normal',
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#546e7a',
                  textDecoration: 'line-through',
                }}
                data={row.price}
                beforeText={''}
                text={currencyMain?.simbol}
                grn={t('common:uah')}
              />
              <PriceToStr
                stSumma={{
                  fontStyle: 'normal',
                  fontSize: '16px',
                  color: 'red',
                  fontWeight: '700',
                }}
                data={
                  row.discounts_by_quantity_sum_of_order.best_discount
                    .price_with_discount
                }
                beforeText={''}
                text={currencyMain?.simbol}
                grn={t('common:uah')}
              />
            </div>
          ) : (
            <PriceToStr
              stAll={{marginBottom: -5}}
              stSumma={{
                fontStyle: 'normal',
                fontSize: 16,
                fontWeight: '700',
              }}
              data={row.price}
              beforeText={''}
              text={currencyMain?.simbol}
              grn={t('common:uah')}
            />
          )}
        </div>
        <ProductTooltip
          title={
            <ul className="single-product-list">
              {row?.balance?.map(item => (
                <li
                  key={item.name}
                  className={item.quantity <= 0 ? 'disabled' : ''}>
                  <span className="title">{item.name}</span>
                  <span className="count">{item.quantity} шт</span>
                </li>
              ))}
            </ul>
          }>
          <button onClick={addToCart} className="btn">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.304453 0.654591C0.49957 0.454903 0.778008 0.344864 1.05645 0.348731C1.87699 0.348731 2.7225 0.519591 3.41332 0.980841C3.95227 1.33697 4.36184 1.871 4.59633 2.47076C4.72289 2.77803 4.79637 3.10322 4.86 3.42877C5.30719 5.68791 5.75227 7.94776 6.21633 10.2037C6.29121 10.5493 6.47402 10.8844 6.77109 11.0865C7.11879 11.3375 7.55789 11.4237 7.98012 11.4237C9.72738 11.4258 11.475 11.4212 13.2223 11.4258C13.5197 11.0809 13.7602 10.6928 13.9971 10.3053C14.3768 9.68939 14.7104 9.04709 15.0525 8.41006C15.4093 7.75932 15.7127 7.08115 16.0555 6.42303C16.2605 6.03631 16.7295 5.81904 17.1577 5.90729C17.6295 5.9899 17.9975 6.43252 18 6.911V6.97252C17.9845 7.13108 17.9395 7.28576 17.8703 7.42885C17.216 8.79854 16.5414 10.1619 15.7419 11.4535C15.4009 11.99 15.0441 12.5237 14.5948 12.9772C14.3205 13.2497 13.9771 13.5067 13.5714 13.4975C11.707 13.5035 9.84234 13.4993 7.97801 13.4996C7.14656 13.5014 6.30141 13.2873 5.6148 12.8074C4.88426 12.3092 4.37379 11.5161 4.18992 10.6537C3.70934 8.33693 3.25512 6.01486 2.79422 3.69385C2.73727 3.39854 2.64937 3.09338 2.43914 2.86803C2.22469 2.63072 1.90898 2.51787 1.60031 2.46865C1.26105 2.4008 0.891211 2.47674 0.574453 2.31045C0.237656 2.14697 0.0158203 1.79049 0 1.41854V1.34049C0.0189844 1.08561 0.120234 0.834591 0.304453 0.654591Z"
                fill="#1F1F1F"
              />
              <path
                d="M6.94234 14.2255C7.36633 14.1379 7.81563 14.2339 8.18266 14.4582C8.54617 14.6867 8.82426 15.0527 8.93324 15.4696C9.15649 16.1991 8.79613 17.0464 8.13449 17.4159C7.74109 17.6448 7.25137 17.7228 6.81297 17.5868C6.39004 17.4753 6.01809 17.1905 5.79273 16.8168C5.54453 16.4059 5.46227 15.8852 5.61836 15.4264C5.78992 14.8193 6.32676 14.3464 6.94234 14.2255Z"
                fill="#1F1F1F"
              />
              <path
                d="M13.165 14.227C13.5724 14.1415 14.0073 14.2224 14.3655 14.4312C15.0402 14.8088 15.3998 15.6807 15.1509 16.4193C14.9523 17.1358 14.2404 17.6586 13.4996 17.6529C13.0155 17.6512 12.5332 17.436 12.2129 17.0721C11.8934 16.7164 11.7292 16.2238 11.7798 15.7471C11.8304 15.2996 12.0554 14.8703 12.4091 14.5884C12.6239 14.4034 12.8911 14.2913 13.165 14.227Z"
                fill="#1F1F1F"
              />
              <rect
                x="7.875"
                y="5.625"
                width="5.625"
                height="2.25"
                rx="1.125"
                fill="#1F1F1F"
              />
            </svg>
          </button>
        </ProductTooltip>
      </div>
    </div>
  );
};

const mapState = state => {
  const {customer_id, sell_currency_id} = state.userSelectedData;
  const {currencies} = state.frontendData;
  return {sell_currency_id, currencies};
};

export default connect(mapState, {})(CarouselItem);
