import PropTypes from 'prop-types'
import React from "react";
import Slider from 'react-slick';
import sliderLeftArrow from '../../../images/left-arrow.svg';
import sliderRightArrow from '../../../images/right-arrow.svg';
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import PtoductsItemColumns from "../../ProductList/components/PtoductsNewTableColumns/PtoductsItemColumns";
import {dataProcessing} from "../../../_helpers";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({

    analogs_list: {
        '& .slick-arrow.slick-prev': {
            backgroundImage: `url(${sliderLeftArrow})`,
        },
        '& .slick-arrow.slick-next': {
            backgroundImage: `url(${sliderRightArrow})`,
        },

    },

    gridContainerVr: {
        // display: 'block',
        // justifyContent: 'space-between',
        // border: '1px solid #eeeeee',
        // boxShadow: '0 1px 2px #eeeeee',
        // padding: 10,
        // borderRadius: 5,
        // backgroundColor: 'white',
        //
        // margin: 5,
        // width: 'auto',
        // '&:hover': {
        //     boxShadow: '0 2px 5px #333333',
        // }
    },

}));


const ProductPageAnalogListBob = (props) => {
    const {
        cartAll,
        settings,
        data,
        setDialogData,
        currencyMainContr,
        currencyMain,
        setPhotosGallery,
        handleUuidInfo,
        setAnalogList,
        listMyFavorite,
        promotionsByProductsList,
    } = props;
    const classes = useStyles();

    const {results} = data;
    const {warehouses} = data;

    let resultsMod = dataProcessing(
        currencyMain,
        results,
        cartAll,
        listMyFavorite,
        '1',
        ['OWN'],
        promotionsByProductsList
    ).results;

    let countInResult = resultsMod.length;
    if (countInResult === 0) setAnalogList(false);
    return (
        <div className={clsx(classes.analogs_list, "analogs_list")}>
            <Slider className="slide" {...settings}>
                {results.map((row, index) =>
                    // row.count > 0 && row.countInStoks > 0 &&
                    row.count > 0 &&
                    <PtoductsItemColumns key={index}
                                         xs={12}
                                         sm={12}
                                         md={12}
                                         itsSlider={'analog_item'}
                                         widthXS={12}
                                         typePage={'cardVr'}
                                         row={row}
                                         index={index}
                                         warehouses={warehouses}
                        //currencyMainSite={currencyMainSite}
                                         currencyMainContr={currencyMainContr}
                                         currencyMain={currencyMain}
                                         type={'searchByArticleOwn'}
                                         setDialogData={setDialogData}
                                         setPhotosGallery={setPhotosGallery}
                                         handleUuidInfo={handleUuidInfo}
                        //showProductCart={showProductCart}
                    />
                )}
            </Slider>
        </div>
    )


};

ProductPageAnalogListBob.propTypes = {
    cartAll: PropTypes.any,
    currencyMain: PropTypes.any,
    currencyMainContr: PropTypes.any,
    data: PropTypes.any,
    handleUuidInfo: PropTypes.any,
    setAnalogList: PropTypes.any,
    setDialogData: PropTypes.any,
    setPhotosGallery: PropTypes.any,
    settings: PropTypes.object
};

function mapState(state) {
    const {
        listMyFavorite,
        promotionsByProductsList,
    } = state;

    return {
        listMyFavorite,
        promotionsByProductsList,
    };
}

export default connect(mapState)(ProductPageAnalogListBob);
// export default ProductPageAnalogListBob;
