import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import mui
import { Stack } from '@mui/material';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import download
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// import for print
import { useReactToPrint } from 'react-to-print';
// import fetch
import axInstTest from '../../../_helpers/axios-instance-test';
// import components
import PrintGoods from "./PrintGoods";

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',

    [theme.breakpoints.down(485)]: {
      margin: '0px !important',
      padding: '0px !important',
    },
  },

  content: {
    paddingLeft: 10,
    [theme.breakpoints.down(485)]: {
      flexDirection: 'column !important',
      alignItems: 'flex-start !important',
      gap: 10,
    },
  },

  print: {
    position: 'absolute',
    display: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    width: '70vw',
    height: '70vh',
    zIndex: 999,
  },
}));

function DownloadAndPrint(props) {
  const { warehouse } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [printData, setPrintData] = useState(null);

  // logic print
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getData = async () => {
    if (!warehouse?.id) return;
    try {
      const response = await axInstTest.post("/api/get-items-warehouses-for-warehouseman/", { warehouse_id: warehouse?.id });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const onPrint = async () => {
    if (!warehouse?.id) return;
    setDisabled(true);
    const data = await getData();
    setPrintData(data || {});
    handlePrint();
    setDisabled(false);
  };

  // logic download
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const columnProduct = [
    { key: 'id', header: 'Номер' },
    { key: 'article', header: 'Артикул' },
    { key: 'brand', header: 'Бренд' },
    { key: 'name', header: 'Назва' },
    { key: 'quantity', header: 'Кількість (шт)' },
  ];

  const formatData = (data, columnProduct) => {
    return data.map(item => {
      const formattedItem = {};
      columnProduct.forEach(column => {
        formattedItem[column.header] = item[column.key];
      });
      return formattedItem;
    });
  };

  const exportToCSV = (apiData, fileName) => {
    const formattedData = formatData(apiData, columnProduct);
    // create excel file
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const onDownload = async () => {
    if (!warehouse?.id) return;
    setDisabled(true);
    const data = await getData();
    exportToCSV(data?.products, `${warehouse?.name} (${moment()?.format("DD.MM.YYYY")})`);
    setDisabled(false);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={4}
        alignItems="center"
        className={classes.content}>
        <Button disabled={disabled} className={classes.button} startIcon={<DownloadIcon />} onClick={onDownload}>
          {t("common:download_file_label")}
        </Button>
        <Button disabled={disabled} className={classes.button} startIcon={<PrintIcon />} onClick={onPrint}>
          {t("common:print_label")}
        </Button>
      </Stack>
      <div className={classes.print}>
        <PrintGoods ref={componentRef} data={printData?.products} warehouse={warehouse} />
      </div>
    </>
  );
}

export default DownloadAndPrint;

const DownloadIcon = () => {
  return (
    <svg
      width="16.000000"
      height="16.000000"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnslink="http://www.w3.org/1999/xlink">
      <defs />
      <path
        id="Vector"
        d="M8 12L3 7L4.4 5.55L7 8.14L7 0L9 0L9 8.14L11.59 5.55L13 7L8 12ZM0 16L0 11L2 11L2 14L14 14L14 11L16 11L16 16L0 16Z"
        fill="#2F80ED"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const PrintIcon = () => {
  return (
    <svg
      width="20.000000"
      height="20.000000"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs />
      <path
        id="Vector"
        d="M5 8C4.8 8 4.6 8.05 4.44 8.16C4.27 8.27 4.15 8.43 4.07 8.61C4 8.8 3.98 9 4.01 9.19C4.05 9.38 4.15 9.56 4.29 9.7C4.43 9.84 4.61 9.94 4.8 9.98C4.99 10.01 5.2 9.99 5.38 9.92C5.56 9.84 5.72 9.71 5.83 9.55C5.94 9.39 6 9.19 6 9C6 8.73 5.89 8.48 5.7 8.29C5.51 8.1 5.26 8 5 8ZM17 4L16 4L16 1C16 0.73 15.89 0.48 15.7 0.29C15.51 0.1 15.26 0 15 0L5 0C4.73 0 4.48 0.1 4.29 0.29C4.1 0.48 4 0.73 4 1L4 4L3 4C2.2 4 1.44 4.31 0.87 4.87C0.31 5.44 0 6.2 0 7L0 13C0 13.79 0.31 14.55 0.87 15.12C1.44 15.68 2.2 16 3 16L4 16L4 19C4 19.26 4.1 19.51 4.29 19.7C4.48 19.89 4.73 20 5 20L15 20C15.26 20 15.51 19.89 15.7 19.7C15.89 19.51 16 19.26 16 19L16 16L17 16C17.79 16 18.55 15.68 19.12 15.12C19.68 14.55 20 13.79 20 13L20 7C20 6.2 19.68 5.44 19.12 4.87C18.55 4.31 17.79 4 17 4ZM6 2L14 2L14 4L6 4L6 2ZM14 18L6 18L6 14L14 14L14 18ZM18 13C18 13.26 17.89 13.51 17.7 13.7C17.51 13.89 17.26 14 17 14L16 14L16 13C16 12.73 15.89 12.48 15.7 12.29C15.51 12.1 15.26 12 15 12L5 12C4.73 12 4.48 12.1 4.29 12.29C4.1 12.48 4 12.73 4 13L4 14L3 14C2.73 14 2.48 13.89 2.29 13.7C2.1 13.51 2 13.26 2 13L2 7C2 6.73 2.1 6.48 2.29 6.29C2.48 6.1 2.73 6 3 6L17 6C17.26 6 17.51 6.1 17.7 6.29C17.89 6.48 18 6.73 18 7L18 13Z"
        fill="#2F80ED"
        fill-opacity="1.000000"
        fill-rule="nonzero"
      />
    </svg>
  );
};
