import React, {
    useEffect
    // useEffect, useState
} from 'react';
import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';


import {useTranslation} from 'react-i18next';

import axInst from "../../../_helpers/axios-instance";
import clsx from 'clsx';

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from "@material-ui/core/IconButton";



const MOBILE_SIZE = 768;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2, 0),
    '& h4': {
      fontWeight: '500',
      fontSize: '24px',
      margin: ' 0',
      [theme.breakpoints.down(MOBILE_SIZE)]: {
        fontSize:'16px',
        fontWeight:'600',
        // margin: '13px 0'
      }
    },
      "& svg": {
        width: '20px',
        zIndex: 0,
      },
  },

    topHeader : {
        height: '30px',
        // background: '#00b7d2',
        background: '#556878a8',
        borderTop: '#c0c0c0 1px solid',
        borderBottom: '#c0c0c0 1px solid',
        borderRight: '#c0c0c0 1px solid',
        color: '#fff',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '700',
        fontSize: '14px'
    },

    subItemParam: {
        borderBottom: '#c0c0c0 1px solid',
        borderRight: '#c0c0c0 1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        padding:'5px',
        fontSize: '12px'
    },

    subItemParamText: {
        justifyContent: 'start !important',
    },

    subItemParamPrice: {
        justifyContent: 'end !important',
    },

    cellHeader: {
        fontSize: '14px',
        // fontWeight: '700',
        textAlign: 'center',
    },

    middleHeader: {
        height: '40px',
        // borderRight:"#dedede 1px solid",
        borderBottom:"#dedede 1px solid",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

  tableBodyItemRow: {
      padding: "5px",
      borderRight:"#dedede 1px solid",
      borderTop:"#dedede 1px solid",
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
  },

  cellRightBorder:{
      height: '40px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight:"#dedede 1px solid",
  },
  cell: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#505050',
  },
  priceCell:{
    '& span':{
      //background: 'red',
      display:'inline-block',
      width:'100%',
      paddingRight:"30%",
      [theme.breakpoints.down(MOBILE_SIZE)]: {
        paddingRight:"0",
      },
      textAlign:'right'
    }
  },
  cellBold: {
    fontWeight: '700',
    fontSize: '14px',
  },
  sectionDivider: {
    paddingTop: '10px',
  },
  positive: {color: '#56AF3B'},
  i: {
    color: '#56AF3B',
    display: 'inline-block',
    marginLeft: '10px',
    border: '1px solid #56AF3B',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '11px',
    height: '11px',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '9px',
    lineHeight: '11px',
  },
  tooltip: {
    tooltip: {
      background: 'red',
    },
  },
  // continue here : mobile view
  mobileSummary: {
    borderTop: '#c0c0c0 2px solid',
    fontSize: '14px',
    paddingTop: '5px'
  },
  mobileFirstCol: {
    padding: '5px 0',
    fontWeight: '500',
    whiteSpace: 'nowrap'
  },
  mobileSecondCol: {
    padding: '5px 0',
    textAlign: 'right',
  },

  selectOrganization: {
    margin: '0 0 0 15px',
    fontSize: '15px',
    minWidth: '210px',
    [theme.breakpoints.down(MOBILE_SIZE)]: {
      minWidth: '160px',
    },
    '&:before': {
      borderColor: 'rgba(255, 255, 255 0)!important',
      border: 'none',
      opacity: 0
    },
    '&:after': {
      borderColor: 'rgba(255, 255, 255 0)!important',
      border: 'none',
      opacity: 0
    },
    // '&:hover': {
    //   borderColor: 'inherit!important',
    //   border: 'none',
    // },

    border: '1px solid #bebdbd',
    borderRadius: '4px',
    "& select": {
      textIndent: '5px',
    },
  },

    dateFromInput: {
        marginRight: 20,
        '& input': {
            textAlign: 'center',
            color: '#008CB4'
        },
    },

    dateUntilInput: {
        '& input': {
            textAlign: 'center',
            color: '#008CB4'
        },
    },

    data_filter_container: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
    },

    controlsContainer: {
        margin: '0 0 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    detailRow: {
        transition:'background .2s',

        "&:hover": {
            transition:'background .2s',
            background: '#a6dadd !important'
        }
    },
}));

// const LightTooltip = withStyles((theme: Theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//     maxWidth: '310px',
//   },
// }))(Tooltip);

function ReconciliationDetailsBody(props) {
  const classes = useStyles();
  const {t} = useTranslation();


    const [open, setOpen] = React.useState(false);
    const [documentId, setDocumentId] = React.useState(false);
    const [documentItems, setDocumentItems] = React.useState();

    const {
      elem, index, getCurrencySymbol,  filterDocumentOrganization, selectTypesOfDocuments
    } = props;

    useEffect(() => {
        // this function will be called twice without "return" when tab changes
        if(index === 0) {
            setOpen(true);
            reconciliationDetail(elem.document_id, elem.document_type)
        } else{
            if(filterDocumentOrganization && filterDocumentOrganization.length > 0 && elem.document_id === filterDocumentOrganization[0].document_id) {
                setOpen(true);
                reconciliationDetail(elem.document_id, elem.document_type)
            }
        }

        // eslint-disable-next-line
    }, [index, elem, selectTypesOfDocuments, filterDocumentOrganization]);

    const reconciliationDetail = (document_id, document_type, event) => {
        if(documentId === document_id) {
            setDocumentId(false);
            setOpen(false);
        } else {
            // event.stopPropagation();
            axInst.post('/api/get-accounting-items/',
                JSON.stringify({
                    document_id : document_id,
                    document_type: document_type,

                })
            ).then(res => {
                setDocumentItems(res.data.result.data)
                setOpen(true);
                setDocumentId(document_id)
            });
        }
    };


  return (
      <>
          <Grid className={classes.detailRow} style={{
              background
                  :elem.document_type_id === 4 ?"#55c03e26"
                  :elem.document_type_id === 5 ? '#55c03e26'
                  :elem.document_type_id === 6 ? '#f78b7d26'
                  :elem.document_type_id === 7 ? '#f1f08133'
                  :elem.document_type_id === 8 ? '#f1f08133'
                  :elem.document_type_id === 9 ? '#f1f08133':'none'}}>
              <Grid container lg={12} className={classes.tableBodyRow} style={{borderLeft: '#dedede 1px solid', borderBottom: open ? "#dedede 1px solid" : "none"}} onClick={(event) => reconciliationDetail(elem.document_id, elem.document_type, event)}>
                  <Grid item lg={8} container>
                      <Grid lg={1} container>
                          <Grid item lg={12} className={classes.tableBodyItemRow}>
                              {elem.is_subitems === true ?
                                  <IconButton aria-label="expand row" size="small" >
                                      {open
                                          ? <KeyboardArrowUpIcon style={{color: "#008CB4"}}/>
                                          : <KeyboardArrowDownIcon style={{color: "#008CB4"}}/>
                                      }
                                  </IconButton>
                              :null
                              }

                              {index+1}
                          </Grid>
                      </Grid>
                      <Grid item lg={11} container className={clsx(classes.tableBodyItemRow, classes.subItemParamText)}>
                          {elem.name}
                      </Grid>
                  </Grid>
                  <Grid item lg={4} container>
                      <Grid item lg={4} className={clsx(classes.tableBodyItemRow, classes.subItemParamPrice)}>
                          {elem.exercise}
                      </Grid>
                      <Grid item lg={4} className={clsx(classes.tableBodyItemRow, classes.subItemParamPrice)}>
                          {elem.payment}
                      </Grid>
                      <Grid item lg={4} className={clsx(classes.tableBodyItemRow, classes.subItemParamPrice)}>
                          {elem.refund_amount}
                      </Grid>
                  </Grid>
              </Grid>
              {elem.is_subitems  === true ?
                  <Collapse in={documentId === elem.document_id} timeout="auto" unmountOnExit>
                      { elem.document_type_id === 2 ||
                        elem.document_type_id === 3 ||
                        elem.document_type_id === 6 ?
                          <Box margin={1}>
                              <Grid container lg={12} className={classes.tableHeadRow} style={{borderLeft: '#dedede 1px solid'}}>
                                  <Grid item container lg={2}>
                                      <Grid item lg={3} className={classes.topHeader}>
                                          №
                                      </Grid>
                                      <Grid item lg={9} className={classes.topHeader}>
                                          {t("common:article")}
                                      </Grid>
                                  </Grid>
                                  <Grid item lg={10} container>
                                      <Grid item lg={7} className={classes.topHeader}>
                                          {t("common:nomenclature")}
                                      </Grid>
                                      <Grid item container lg={5}>
                                          <Grid item lg={4} className={classes.topHeader}>
                                              {t("common:pieces")}
                                          </Grid>
                                          <Grid item lg={4} className={classes.topHeader}>
                                              {t("common:price")}
                                          </Grid>
                                          <Grid item lg={4} className={classes.topHeader}>
                                              {t("common:sum")}
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>
                              {documentItems && documentItems.map((item, indexEl) => (
                                    <Grid key={indexEl} container lg={12} className={classes.tableHeadRow} style={{borderLeft: '#dedede 1px solid'}}>
                                          <Grid item container lg={2}>
                                              <Grid item lg={3} className={classes.subItemParam}>
                                                  {indexEl+1}
                                              </Grid>
                                              <Grid item lg={9} className={clsx(classes.subItemParam, classes.subItemParamText)}>
                                                  {item.article}
                                              </Grid>
                                          </Grid>
                                          <Grid item lg={10} container>
                                              <Grid item lg={7} className={clsx(classes.subItemParam, classes.subItemParamText)}>
                                                  {item.name}
                                              </Grid>
                                              <Grid item container lg={5}>
                                                  <Grid item lg={4} className={classes.subItemParam}>
                                                      {item.quantity}
                                                  </Grid>
                                                  <Grid item lg={4} className={clsx(classes.subItemParam, classes.subItemParamPrice)}>
                                                      {item.price}
                                                  </Grid>
                                                  <Grid item lg={4} className={clsx(classes.subItemParam, classes.subItemParamPrice)}>
                                                      {item.sum}
                                                  </Grid>
                                              </Grid>
                                          </Grid>
                                    </Grid>
                              ))}
                          </Box>
                          :
                          <Box margin={1}>
                              <Grid container lg={12} className={classes.tableHeadRow} style={{borderLeft: '#dedede 1px solid'}}>
                                  <Grid item lg={1} className={classes.topHeader}>
                                      №
                                  </Grid>
                                  <Grid item lg={9} className={classes.topHeader}>
                                      {t("common:settlement_object")}
                                  </Grid>
                                  <Grid item lg={2} className={classes.topHeader}>
                                      {t("common:sum")} ({getCurrencySymbol()})
                                  </Grid>
                              </Grid>
                              {documentItems && documentItems.map((item, indexEl) => (
                                  <Grid key={indexEl} container lg={12} className={classes.tableHeadRow} style={{borderLeft: '#dedede 1px solid'}}>
                                      <Grid item lg={1} className={classes.subItemParam}>
                                          {indexEl+1}
                                      </Grid>
                                      <Grid item lg={9} className={clsx(classes.subItemParam, classes.subItemParamText)}>
                                          {item.name}
                                      </Grid>
                                      <Grid item lg={2} className={clsx(classes.subItemParam, classes.subItemParamPrice)}>
                                          {item.sum}
                                      </Grid>
                                  </Grid>
                              ))}
                          </Box>
                      }
                  </Collapse>
              : null
              }
          </Grid>
    </>
  );
}

function mapState(state) {
  const {userSelectedData, authentication, contragentData} = state;
  const {customer_id: customerId} = userSelectedData;
  const {loggedIn} = authentication;
  const {main_currency} = state.frontendData;
  const {
    financeCalendar,
    financeCalendarByOrganization
  } = state;
  return {
    customerId,
    loggedIn,
    contragentData,
    main_currency,
    financeCalendar,
    financeCalendarByOrganization
  };
}

const actionCreators = {
  // getPaymentCalendarByOrganization: userActions.getPaymentCalendarByOrganization
};

export default connect(mapState, actionCreators)(withRouter(ReconciliationDetailsBody));
