export const productConstants = {
  GET_BOUND_PRODUCTS_REQUEST: 'GET_BOUND_PRODUCTS_REQUEST',
  GET_BOUND_PRODUCTS_SUCCESS: 'GET_BOUND_PRODUCTS_SUCCESS',
  GET_BOUND_PRODUCTS_ERROR: 'GET_BOUND_PRODUCTS_SUCCESS',

  GET_ANALOG_PRODUCTS_REQUEST: 'GET_ANALOG_PRODUCTS_REQUEST',
  GET_ANALOG_PRODUCTS_SUCCESS: 'GET_ANALOG_PRODUCTS_SUCCESS',
  GET_ANALOG_PRODUCTS_ERROR: 'GET_ANALOG_PRODUCTS_SUCCESS',

  GET_BONUS_PRODUCTS_REQUEST: 'GET_BONUS_PRODUCTS_REQUEST',
  GET_BONUS_PRODUCTS_SUCCESS: 'GET_BONUS_PRODUCTS_SUCCESS',
  GET_BONUS_PRODUCTS_ERROR: 'GET_BONUS_PRODUCTS_ERROR',
};
