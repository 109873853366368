import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Skeleton} from '@material-ui/lab';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  sliderSkeleton: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#DBDDE0',
    minHeight: 300,
    [theme.breakpoints.down(760)]: {
      minHeight: 250,
      alignItems: 'flex-end',
    },
  },
  skeletonContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.down(760)]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  skeletonCircle: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    [theme.breakpoints.down(760)]: {
      width: 30,
      height: 30,
    },
  },
  skeletonTitle: {
    display: 'block',
    [theme.breakpoints.down(760)]: {
      display: 'none',
    },
  },
  skeletonBtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down(760)]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
}));

const TopSliderSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.sliderSkeleton}>
      <div className={classes.skeletonContent}>
        <div className={classes.skeletonTitle}>
        <Typography variant="h3" className={classes.skeletonTitle}>
          <Skeleton width={300} />
        </Typography>
        <Typography variant="h3" className={classes.skeletonTitle}>
          <Skeleton width={500} />
        </Typography>
        <Typography variant="h3" className={classes.skeletonTitle}>
          <Skeleton width={400} />
        </Typography>
        </div>
        <div className={classes.skeletonBtn}>
          <Skeleton variant="circle" className={classes.skeletonCircle} />
          <Skeleton
            variant="text"
            width="80%"
            className={classes.skeletonTitle}
          />
          <Skeleton variant="circle" className={classes.skeletonCircle} />
        </div>
      </div>
    </div>
  );
};

export default TopSliderSkeleton;
