import React, { useState } from 'react'
// import material ui
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { PieChart } from '@mui/x-charts/PieChart';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import LinkTo from '../../../_components/LinkTo';
import PriceToStr from '../../../_components/PriceToStr';

const useStyles = makeStyles(theme => ({
    item: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
    },
    fullscreen: {
        display: "flex",
        alignItems: "center",
        gap: 15,
        justifyContent: "space-around",
        paddingTop: 18,
        [theme.breakpoints.down(768)]: {
            flexDirection: "column",
        },
    },
    chart: {
        position: "relative",
        width: 230,
        margin: "0 auto",
        paddingTop: 22,
    },
    chart_fullscreen: {
        position: "relative",
        width: 312,
        [theme.breakpoints.down(1024)]: {
            width: 230
        },
    },
    tooltip: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#1C2A53",
        fontSize: 24,
        fontWeight: 500
    },
    details: {
        paddingTop: 46,
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap"
    },
    details_fullscreen: {
        width: 596,
        [theme.breakpoints.down(1024)]: {
            width: 400,
        },
        [theme.breakpoints.down(768)]: {
            width: "100%",
        },
    },
    details__item: {
        width: "100%",
        boxSizing: "border-box",
        textAlign: "center",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: 10,
        "&:not(:last-child) ": {
            paddingBottom: 32,
        },
    },
    details__item_fullscreen: {
        width: "100%",
        boxSizing: "border-box",
        textAlign: "center",
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: 10,
        "&:not(:last-child) ": {
            paddingBottom: 32,
        },
        [theme.breakpoints.down(479)]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
    },
    details__item_title: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        "& h4 ": {
            minWidth: 120,
            maxWidth: 120,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textAlign: "left",
            [theme.breakpoints.down(560)]: {
                flexDirection: "column"
            },
            [theme.breakpoints.down(479)]: {
                minWidth: 80,
                maxWidth: 80,
            },
        },
    },
    details__item_price: {
        color: "#1C2A53",
        fontSize: 14,
        fontWeight: 500,
        whiteSpace: "nowrap",
    },
}));

const colors = [
    // dark 
    '#1F1F1F',
    // yellow
    '#FFBE00',
    // green
    '#01571f',
    '#279F51',
    '#66D53F',
    '#88B04B',
    // blue
    '#1C2A53',
    '#2F80ED',
    '#00C3F8',
    // gray
    '#5E5E5E',
    '#8E95A9',
    // red
    '#880900',
    '#FF392B',
    '#FF6F61',
    '#af706b',
    '#F7CAC9',
];

const PieChartOfPurchasedProducts = (props) => {
    const { data, currency, fullscreen, notAllManagerDataCondition } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [hover, setHover] = useState(false);
    const [percentageValue, setPercentageValue] = useState();

    const isTablet = useMediaQuery(theme.breakpoints.down(1024), {
        defaultMatches: true,
    });

    const isMobile = useMediaQuery(theme.breakpoints.down(768), {
        defaultMatches: true,
    });

    const isSmallMobile = useMediaQuery(theme.breakpoints.down(479), {
        defaultMatches: true,
    });

    const tooltip = (value) => {
        setPercentageValue(value.toFixed(1) + "%");
        return ""
    }

    const mergedData = data.map((items, index) => ({
        ...items,
        color: colors[index % colors.length]
    }));

    const productLabel = (qty) => {
        if (qty == 1) {
            return t('common:commodity_label')
        } else {
            return t('common:of_goods')
        }
    }

    const details = (item, index, fullscreen) => {
        return (
            <div key={index} className={fullscreen ? classes.details__item_fullscreen : classes.details__item}>
                <div className={classes.details__item_title}>
                    <div style={{
                        backgroundColor: item?.color,
                        width: 8,
                        height: 8,
                        borderRadius: "50%"
                    }}></div>
                    <h4 className="dashboard-text">{item?.name}</h4>
                </div>
                {fullscreen && !isSmallMobile && (
                    <div className={classes.details__item_price}>
                        {item.qty} {productLabel(item.qty)}
                    </div>
                )}
                <div className={classes.details__item_price}>
                    {currency}{" "}
                    <PriceToStr
                        data={item?.sum}
                        toFixed={0}
                        beforeText={''}
                    />
                </div>
                <div className="dashboard-text">
                    {item?.percent}%
                </div>
            </div>
        )
    }

    if (fullscreen) {
        return (
            <div className={`dashboard-card ${classes.item}`}>
                {notAllManagerDataCondition === true ? <div className="dashboard-card-empty">{t('common:fill_user_details_label')}</div> : data.length === 0 ? (
                    <div className="dashboard-card-empty">{t('common:category_buy_goods_label')}</div>
                ) : null}
                <div className={classes.title_link} style={isMobile ? {
                    flexDirection: "column",
                    alignItems: "flex-start",
                } : {}}>
                    <h2 className="dashboard-title">{t('common:purchased_by_category')}</h2>
                    <span className="dashboard-text">{t('common:last_month_label')}</span>
                </div>
                <div className={classes.fullscreen}>
                    <div className={!isMobile ? classes.chart_fullscreen : classes.chart}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}>
                        <PieChart
                            series={[
                                {
                                    data: data.length === 0 ? [{ id: 1, value: 100, name: "name" }] : data.map((item, index) => ({
                                        id: index,
                                        value: item?.percent,
                                        name: item?.name
                                    })),
                                    innerRadius: !isTablet ? 90 : 60,
                                    outerRadius: !isTablet ? 156 : 110,
                                    highlightScope: { faded: "global", highlighted: "item" },
                                    faded: { innerRadius: !isTablet ? 80 : 50, additionalRadius: -15 },
                                    valueFormatter: hover ? (element) => `${element.name} ${tooltip(element.value)}` : null,
                                    cx: !isTablet ? 151 : 110,
                                    cy: !isTablet ? 151 : 110,
                                },
                            ]}
                            colors={data.length === 0 ? ["#1C2A53"] : colors.map((item) => item)}
                            sx={{ width: '100%' }}
                            height={!isTablet ? 312 : 230}
                        />
                        <div className={classes.tooltip}>
                            {hover ? percentageValue : "100%"}
                        </div>
                    </div>
                    <div className={classes.details_fullscreen}>
                        {mergedData.map((item, index) => details(item, index, fullscreen))}
                        {data.length === 0 ? details({ name: "name", qty: 100, percent: 100, }, 1, fullscreen) : null}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`dashboard-card ${classes.item}`}>
            {notAllManagerDataCondition === true ? <div className="dashboard-card-empty">{t('common:fill_user_details_label')}</div> : data.length === 0 ? (
                <div className="dashboard-card-empty">{t('common:category_buy_goods_label')}</div>
            ) : null}
            <div className={classes.title_link}>
                <h2 className="dashboard-title">{t('common:purchased_by_category')}</h2>
                {/* <LinkTo title={t('common:read_mode_label')} adaptive={true} path={"/"} /> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "100%" }}>
                <div className={classes.chart}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <PieChart
                        series={[
                            {
                                data: data.length === 0 ? [{ id: 1, value: 100, name: "name" }] : data.map((item, index) => ({
                                    id: index,
                                    value: item?.percent,
                                    name: item?.name
                                })),
                                innerRadius: 60,
                                outerRadius: 110,
                                highlightScope: { faded: "global", highlighted: "item" },
                                faded: { innerRadius: 50, additionalRadius: -15 },
                                valueFormatter: hover ? (element) => `${element.name} ${tooltip(element.value)}` : null,
                                cx: 110,
                                cy: 110,
                            },
                        ]}
                        colors={data.length === 0 ? ["#1C2A53"] : colors.map((item) => item)}
                        sx={{ width: '100%' }}
                        height={230}
                    />
                    <div className={classes.tooltip}>
                        {hover ? percentageValue : "100%"}
                    </div>
                </div>
                <div className={classes.details}>
                    {mergedData.map((item, index) => details(item, index, fullscreen))}
                    {data.length === 0 ? details({ name: "name", qty: 100, percent: 100, }, 1, fullscreen) : null}
                </div>
            </div>
        </div>
    )
}

export default PieChartOfPurchasedProducts