import React from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
// import i18n
import { useTranslation } from 'react-i18next';
// import icons
import WithdrawalIcon from '../../icons/Withdrawal';
import CoatOfArmsIcon from '../../icons/CoatOfArms';
// import img
import logo from "../../images/restore life center.png"

const useStyles = makeStyles(theme => ({
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "center",
        cursor: "pointer",
        border: "0.6px solid #E3E3E3",
        height: "100%",
        padding: "32px 23px",
        transition: "0.3s",
        [theme.breakpoints.down(575)]: {
            padding: 23
        },
        "&:hover": {
            border: "0.6px solid #2F80ED",
        },
    },
    gapBetweenElements: {
        display: "flex",
        flexDirection: "column",
    },
    buy: {
        display: "flex",
        alignItems: "center",
        gap: 12,
        fontSize: 14,
        fontWeight: 500,
        color: "#2F80ED",
        padding: "11px 16px",
        borderRadius: "20px",
        border: "1px solid #2F80ED"
    },

    uptitle: {
        fontSize: 13,
        fontWeight: 700,
        color: "#F0BA2E",
    },

    link: {
        fontSize: 13,
        color: "#2F80ED",
        textDecoration: "underline",
    },
}));

const WithdrawFundsCard = (props) => {
    const { type, balance, setPopupData } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const onClick = (icon, iconType, title, description, color) => {
        setPopupData({
            type: type,
            icon: {
                type: iconType,
                icon: icon,
            },
            title: title,
            description: description,
            color: color,
        })
    }

    if (type === "card") {
        return (
            <article className={`dashboard-card ${classes.card}`} style={{ gap: 36 }} onClick={() => {
                onClick(
                    <WithdrawalIcon width={28} height={24} />,
                    "svg",
                    t('common:withdraw_funds_title2'),
                    t('common:withdraw_funds_description2'),
                    "#FFBE00",
                )
            }}>
                <div className={classes.gapBetweenElements} style={{ gap: 32 }}>
                    <h6 className={classes.uptitle}>1/2 {t('common:withdraw_funds_uptitle2')}</h6>
                    <h4 className="dashboard-title" style={{ fontSize: 14, fontWeight: 700 }}>{t('common:withdraw_funds_title2')}</h4>
                    <div className="dashboard-text" style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
                        <h5 style={{
                            fontSize: 13,
                            fontWeight: 400,
                            textAlign: "left",
                            maxWidth: 120
                        }}>{t('common:withdraw_funds_label2')}</h5>
                        <div style={{
                            textAlign: "right"
                        }}>
                            <p style={{
                                fontWeight: 700,
                                color: "#2F80ED"
                            }}>{balance / 2} {t('common:uah')}</p>
                            <p style={{
                                fontSize: 10
                            }}>({balance} {t('common:uah')} / 50%)</p>
                        </div>
                    </div>
                </div>
                <WithdrawalIcon width={142} height={142} />
                <div className={classes.buy}>
                    <span>{t('common:leave_a_request_label')} </span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33337 5.99998H10.6667M10.6667 5.99998L6.00004 1.33331M10.6667 5.99998L6.00004 10.6666" stroke="#2F80ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </article>
        )
    }

    return (
        <article className={`dashboard-card ${classes.card}`} style={{ gap: 41 }} onClick={() => {
            onClick(
                logo,
                "image",
                t('common:withdraw_funds_title1'),
                t('common:withdraw_funds_description1'),
                "#2F80ED",
            )
        }}>
            <div className={classes.gapBetweenElements} style={{ gap: 8 }}>
                <h6 className={classes.uptitle}>2х {t('common:withdraw_funds_label1')}</h6>
                <h4 className="dashboard-title" style={{ fontSize: 14, fontWeight: 700 }}>{t('common:withdraw_funds_title1')} Restore Life</h4>
                <a className={classes.link} target="_blank" href="https://www.instagram.com/restore_life_rivne/">{t('common:to_learn_more')}</a>
            </div>
            <img style={{
                maxWidth: 116,
            }} src={logo} alt="Restore Life" />
            <div className={classes.buy}>
                <span>{t('common:leave_a_request_label')} </span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33337 5.99998H10.6667M10.6667 5.99998L6.00004 1.33331M10.6667 5.99998L6.00004 10.6666" stroke="#2F80ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </article>
    )
}

export default WithdrawFundsCard