import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import CategoryItemAccordion from './CategoryItemAccordion';

const frArrowIcon = (
  <svg
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="6" height="6" rx="3" fill="#FFBE00" />
  </svg>
);

export const CategoryItem = props => {
  const {subItems} = props;
  const history = useHistory();
  const {pathname} = useLocation();

  return subItems.map((item, index) => (
    <React.Fragment key={index}>
      <CategoryItemAccordion name={item?.name} url={item.url_repr}>
        <ul className="category-item__child">
          {item.sub_items.map(elem => (
            <li
              className={pathname.includes(elem.url_repr) ? 'active' : ''}
              onClick={() =>
                history.push(
                  `/product-groups/${item.parent__url_repr}/${item.url_repr}/${elem.url_repr}`,
                )
              }
              key={elem.id}>
              {frArrowIcon}
              {elem.name}
            </li>
          ))}
        </ul>
      </CategoryItemAccordion>
      {/* <li
        key={item.id}
        className={clsx('category-item', activeItem === index && 'active')}>
        <div
          onClick={() => handleShowDetails(activeItem === index ? null : index)}
          className="category-item__header">
          {item?.name}
        </div>
        <ul className="category-item__child">
          {item.sub_items.map(elem => (
            <li key={elem.id}>
              {frArrowIcon}
              {elem.name}
            </li>
          ))}
        </ul>
      </li> */}
    </React.Fragment>
  ));
};
