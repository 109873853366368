import React from 'react';

const SignInSvg = () => {
  return (
    <svg
      width="74"
      height="46"
      viewBox="0 0 74 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3335 13.666L48.6668 27.666L72.0002 13.666"
        stroke="#315EFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.666H25.3333"
        stroke="#315EFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 23H27.6667"
        stroke="#315EFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 32.334H25.3333"
        stroke="#315EFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3335 13.6667V11.3333C25.3335 6.2 29.5335 2 34.6668 2H62.6668C67.8002 2 72.0002 6.2 72.0002 11.3333V34.6667C72.0002 39.8 67.8002 44 62.6668 44H34.6668C29.5335 44 25.3335 39.8 25.3335 34.6667V32.3333"
        stroke="#315EFB"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignInSvg;
