import React from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
// import i18n
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    button: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        fontSize: 14,
        fontWeight: 600,
        color: "#555F7E",
        cursor: "pointer",
        backgroundColor: "transparent",
        border: 0,
        transition: "0.3s",

        "&:hover": {
            color: "#C8CAD8",

            "& svg": {
                fill: "#C8CAD8",
            },
        },

        "& svg": {
            fill: "#555F7E",
            transition: "0.3s",
        }
    },
}));

const DashboardButtonBack = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onClick = () => {
        window.history.back();
    }

    return (
        <button className={classes.button} onClick={onClick}>
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0606 0.453828C11.3418 0.73512 11.4998 1.11658 11.4998 1.51433C11.4998 1.91207 11.3418 2.29354 11.0606 2.57483L3.63557 9.99983L11.0606 17.4248C11.3338 17.7077 11.485 18.0866 11.4816 18.4799C11.4782 18.8732 11.3204 19.2494 11.0423 19.5276C10.7642 19.8057 10.388 19.9634 9.99467 19.9668C9.60138 19.9703 9.22248 19.8191 8.93957 19.5458L0.454072 11.0603C0.172866 10.779 0.0148926 10.3976 0.0148926 9.99983C0.0148926 9.60208 0.172866 9.22062 0.454072 8.93933L8.93957 0.453828C9.22086 0.172622 9.60233 0.0146484 10.0001 0.0146484C10.3978 0.0146484 10.7793 0.172622 11.0606 0.453828Z" />
            </svg>
            <span>{t('common:back_link')}</span>
        </button>
    )
}

export default DashboardButtonBack