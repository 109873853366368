import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  search: {
    maxWidth: '340px',
    width: '100%',
    position: 'relative',

    [theme.breakpoints.down(628)]: {
      maxWidth: '240px',
    },
  },

  input: {
    maxWidth: '340px',
    border: '1px solid rgb(200, 202, 216)',
    borderRadius: '50px',
    background: 'rgb(255, 255, 255)',
    outline: 'none',
    height: '40px',
    padding: '12px 25px',
    width: '100%',
    boxSizing: 'border-box',
    color: 'rgb(0, 0, 0)',

    '&:focus': {
      border: '1px solid rgb(47, 128, 237)',

      '&::placeholder': {
        color: 'transparent',
      },
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
}));

const DashboardSearch = (props) => {
  const { ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <input
        {...rest}
        className={classes.input}
        placeholder={t('common:result_search')}
      />
      <Icon />
    </div>
  );
};

export default DashboardSearch;

const Icon = () => {
  return (
    <svg
      style={{
        position: 'absolute',
        right: '20px',
        top: '50%',
        transform: 'translate(0, -50%)',
      }}
      width="14.914062"
      height="15.603516"
      viewBox="0 0 14.9141 15.6035"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs />
      <path
        id="Vector"
        d="M5.93 11.06C3.09 11.06 0.8 8.76 0.8 5.93C0.8 3.09 3.09 0.79 5.93 0.79C8.76 0.79 11.06 3.09 11.06 5.93C11.06 8.76 8.76 11.06 5.93 11.06Z"
        stroke="#00C3F8"
        stroke-opacity="1.000000"
        stroke-width="1.600000"
        stroke-linejoin="round"
      />
      <path
        id="Vector"
        d="M9.41 9.7L14.11 14.8"
        stroke="#00C3F8"
        stroke-opacity="1.000000"
        stroke-width="1.600000"
        stroke-linejoin="round"
        stroke-linecap="round"
      />
    </svg>
  );
};