import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        border: '1px solid rgb(255, 217, 151)',
        borderRadius: '14px',
        boxShadow: '0px 8px 32px 0px rgba(51, 38, 174, 0.08)',
        background: 'rgb(255, 255, 255)',
        padding: '16px',

        [theme.breakpoints.down('sm')]: {
            padding: '8px',
          },
    },
    title: {
        color: 'rgb(28, 42, 83)',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        margin: '0 0 8px 0',

        [theme.breakpoints.down(600)]: {
            fontSize: '12px',
          },
      },

      description: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        color: 'rgb(0, 0, 0)',

        [theme.breakpoints.down(600)]: {
            fontSize: '12px',
        },
      }
}));

const ProgramCard = (props) => {
    const { title, description } = props;
    const classes = useStyles();

    return (
        <div className={classes.card}>
            <h5 className={classes.title}>
                {title}
            </h5>
            <p className={classes.description}>
                {description}
            </p>
        </div>
    );
}

export default ProgramCard;