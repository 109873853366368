import React from 'react';
import {useSwiper} from 'swiper/react';

export const NextBtn = ({isDeactive = false, arrowType}) => {
  const swiper = useSwiper();

  if (arrowType === 'home-slider') {
    return (
      <div
        style={{pointerEvents: !isDeactive ? 'auto' : 'none'}}
        className="home-slider-next"
        onClick={() => swiper.slideNext()}>
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9999 8.45082L5.76499 12.9974L0.999924 8.45082M5.81111 13L5.81111 0.999999L5.81111 13Z"
            stroke="#1F1F1F"
            strokeOpacity={isDeactive ? '0.5' : '1'}
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  return (
    <div
      style={{pointerEvents: !isDeactive ? 'auto' : 'none'}}
      className="next"
      onClick={() => swiper.slideNext()}>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.36066 1L10.9979 5.18795L7.36066 9M11 5.15105H1.4H11Z"
          stroke="#414D5F"
          strokeOpacity={isDeactive ? '0.5' : '1'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
