import React from "react";
import Title from './Title';
import creditCard from '../images/two credit card.png';
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '0 0 64px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px 0'
        },
    },
    box: {
        display: 'flex',
        gap: '16px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '16px'
        },
    }
}))

function ReturnPolicy() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <section className={classes.section}>
            <div style={{ margin: '0 0 16px 0' }}>
                <Title
                    content={t("common:return_policy")}
                    variant="h3"
                />
            </div>
            <p style={{ color: 'rgb(85, 95, 126)' }}>
                {t("common:return_proper_quality")}
            </p>
            <div className={classes.box}>
                <img
                    src={creditCard}
                    alt="Cardboard box"
                    style={{ height: '270px' }}
                />
                <div>
                    <div style={{ margin: '0 0 16px 0' }}>
                        <Title
                            content={t("common:method_compensation")}
                            variant="h4"
                            style={{ margin: '0' }}
                        />
                    </div>
                    <p style={{ color: 'rgb(85, 95, 126)' }}>
                        {t("common:refund_money")}
                    </p>
                </div>
            </div>
        </section>
    );
}

export default ReturnPolicy;