import React from 'react';
import useAnimate from '../../../hooks/useAnimate';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import DashboardButtonBack from '../../../_components/DashboardButtonBack';
import DashboardAnimate from '../../../_components/DashboardAnimate';
import { Box } from '@mui/material';

import ReturnNecessaries from './components/ReturnNecessaries';
import ReturnProcess from './components/ReturnProcess';
import ReturnNecessaryConditions from './components/ReturnNecessaryConditions';
import ReturnConditions from './components/ReturnConditions';
import ReturnPolicy from './components/ReturnPolicy';
import Contacts from './components/Contacts';
import ValidityPeriod from './components/ValidityPeriod';
import Support from './components/Support';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1246,
    margin: "0 auto",
    padding: "0 15px",
    overflowX: "hidden",
  },

  body: {
    padding: "50px 0 30px 0",
    [theme.breakpoints.down(768)]: {
      padding: "30px 0 15px 0"
    },
  },

  back: {
    paddingBottom: "30px",
  },

  title: {
    fontІize: '24px',
    fontWeight: 500,
    lineHeight: '36px',
    color: 'rgb(31, 31, 31)'
  },

  button: {
    color: 'rgb(28, 42, 83)',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px'
  },
  card: {
    padding: '28px 32px 36px 28px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    }
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 34px 0',
    [theme.breakpoints.down('475')]: {
      flexDirection: 'column',
      gap: '8px'
    }
  }
}));

const ReturnTerms = props => {
  useAnimate();
  const { t } = useTranslation();
  const classes = useStyles();

  const phones = ['+38 067 360-12-00', '+38 063 112-74-25', '+38 050 086-05-85'];

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <div className={classes.back}><DashboardButtonBack /></div>
        <Box className={classes.box}>
          <h1 className={`dashboard-title ${classes.title}`}>
            {t("common:return_terms")}
          </h1>
          <button className={`btn ${classes.btn}`}>
            {t("common:order_from_basket2")}
          </button>
        </Box>
        <DashboardAnimate style={{ transform: "scale(0.99)" }}>
          <div className={`dashboard-card ${classes.card}`}>
            <ReturnNecessaryConditions />
            <ReturnNecessaries phones={phones} />
            <ReturnProcess phones={phones} />
            <ReturnConditions />
            <ReturnPolicy />
            <Contacts phones={phones} />
            <ValidityPeriod />
            <Support />
          </div>
        </DashboardAnimate>
      </div>
    </div>
  );
};

export default ReturnTerms;




// import React from 'react';
// import {connect} from 'react-redux';
// import {useTranslation} from 'react-i18next';
// import {static_pages_Actions} from '../../../_actions/staticPages.actions';
// import returnImage from '../../../images/return1.svg';
// import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';
// import MainDialog from '../../../_components/ReturnProduct/MainDialog';
// import {useState} from 'react';
// import {Link, useHistory} from 'react-router-dom';

// const ReturnTerms = props => {
//   const {staticPage, authentication} = props;
//   const {html, name, image} = staticPage;
//   const {t} = useTranslation();
//   const history = useHistory();


//   const [openReturnDialog, setOpenReturnDialog] = useState(false);

//   const createMarkup = () => {
//     return {__html: html};
//   };
//   return (
//     <>
//       <BreadCrumbs items={[{title: name, path: '/'}]} />
//       <div className="products-block pt-1">
//         <div className="wrapper-inner">
//           <div className="products-block__header mb-4">
//             <h2 className="title">{name}</h2>
//             {authentication.loggedIn && (
//               <button
//                 onClick={() => history.push('profile/invoice')}
//                 className="btn">
//                 {t('common:issue_a_refund')}
//               </button>
//             )}
//             <MainDialog
//               setOpenReturnDialog={setOpenReturnDialog}
//               openReturnDialog={openReturnDialog}
//             />
//           </div>
//           <div
//             className="formated-text-wrapper"
//             dangerouslySetInnerHTML={createMarkup()}
//           />
//           <div className="row mb-4 mt-4">
//             <div className="col-md-9 col-12"></div>
//             <div className="col-md-3 col-12">
//               <div className="image-block">
//                 <img
//                   className="about-us__img"
//                   src={image ? image[0] : ''}
//                   alt="delivery"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// function mapState(state) {
//   const {staticPage, authentication} = state;

//   return {staticPage, authentication};
// }

// const actionCreators = {
//   getStaticPage: static_pages_Actions.getStaticPages,
// };

// class Wrapper extends React.Component {
//   componentDidMount() {
//     this.props.getStaticPage('return_terms');
//   }

//   render() {
//     return <ReturnTerms {...this.props} />;
//   }
// }

// export default connect(mapState, actionCreators)(Wrapper);
