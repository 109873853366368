import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {static_pages_Actions} from '../../../_actions';
import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';
import {history} from '../../../_helpers';

const StaticPage = props => {
  const [markup, setMarkup] = useState({__html: <></>});
  const {staticPage, match} = props;
  const {html, name, image, images, title} = staticPage;
  const {t} = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!staticPage.loading && staticPage.html === '') {
      history.push('/');
      props.resetStaticPage()
    }
    setMarkup({__html: html});
  }, [staticPage]);

  useEffect(() => {
    props.getStaticPage(match?.params.static_page);
  }, [match]);
  return (
    <>
      {staticPage.html && (
        <>
          <BreadCrumbs
            items={[
              {
                title: t(`common:${match?.params.static_page}`),
                path: match?.params.static_page,
              },
            ]}
          />
          <div className="wrapper-inner" style={{marginBottom: '100px'}}>
            <div className="products-block__header mb-4">
              <h2 className="title">{t(`common:${match?.params.static_page}`)}</h2>
            </div>
            <div dangerouslySetInnerHTML={markup} />
          </div>
        </>
      )}
    </>
  );
};

function mapState(state) {
  const {staticPage} = state;

  return {staticPage};
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
  resetStaticPage: static_pages_Actions.resetStaticPage
};

// class Wrapper extends React.Component {
//   componentDidMount() {
//     this.props.getStaticPage(this.props.match?.params.static_page);
//   }

//   render() {
//     return <StaticPage {...this.props} />;
//   }
// }

export default connect(mapState, actionCreators)(StaticPage);
