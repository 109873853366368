import React, {useTransition} from 'react';
import Product from '../_components/Product/Product';
import Stocks from '../_components/Stocks/Stocks';
import {Banners} from './components/Banners';
import {connect} from 'react-redux';
import {catalogActions, userActions} from '../_actions';
import {brandsActions} from '../_actions/brands.actions';
import './home.scss';
import {useEffect} from 'react';
import axInst from '../_helpers/axios-instance';
import {useState} from 'react';

function HomePage(props) {
  const {
    catalogsGroup,
    brands,
    // getPersonalManager,
    // personalManager,
    userSelectedData,
    customer_id,
  } = props;
  // const {t} = useTransition();
  const catalogs = catalogsGroup.data;
  const [frontendData, setFrontendData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [mainProductGroups, setMainProductGroups] = useState([]);
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    axInst.post('/api/get-frontend-data/', {}).then(res => {
      setFrontendData(res.data);
      axInst
        .post('/api/get-customer-data/', {
          customer: res.data.data.customer_default.customer_id,
        })
        .then(response => {
          setCustomerData(response.data);
        });
    });
  }, []);

  useEffect(() => {
    axInst
      .post('/api/get-main-product-groups/')
      .then(res => setMainProductGroups(res.data.result));
  }, []);
  
  return (
    <div className="home">
      <div style={{width: '100%'}}>
        <div className="categoryGroupBlock">
          <div style={{width: '100%'}}>
            <Banners
              catalogs={
                mainProductGroups?.length > 0
                  ? mainProductGroups
                  : catalogs?.result
              }
            />
            <Stocks />
            {customerData &&
              frontendData &&
              brands?.brands?.length > 0 &&
              brands?.brands?.map(item => (
                <React.Fragment key={item.id}>
                  <Product
                    productBrandInfo={item}
                    frontendData={frontendData}
                    customerData={customerData}
                    promotions={promotions}
                  />
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

class Wrapper extends React.Component {
  componentDidMount() {
    // this.props.getCatalogTree()
    this.props.getAllBrands();
  }

  componentDidUpdate(prevProps) {}

  render() {
    return <HomePage {...this.props} />;
  }
}

function mapState(state) {
  const {
    frontendData,
    // catalogTree,
    userSelectedData,
    // personalManager,
    brands,
  } = state;
  const {authentication} = state;
  const catalogsGroup = state.catalogTree;
  const {user, key: userKey} = authentication;
  const {customer_id, sell_currency_id} = state.userSelectedData;
  return {
    user,
    userKey,
    frontendData,
    catalogsGroup,
    // personalManager,
    // catalogTree,
    userSelectedData,
    brands,
    customer_id,
    sell_currency_id,
  };
}

const actionCreators = {
  // getPersonalManager: userActions.getPersonalManager,
  getCatalogTree: catalogActions.getCatalogTree,
  getAllBrands: brandsActions.getAllBrands,
};

const connectedHomePage = connect(mapState, actionCreators)(Wrapper);

export {connectedHomePage as HomePage};
