import {makeStyles} from '@material-ui/core';
import React from 'react';
import {Swiper} from 'swiper/react';

const useStyles = makeStyles(() => ({
  root: {
    '& .swiper-wrapper': {
      height: 370,
    },
  },
}));

export const ProductList = props => {
  const {children, setSliderIndex, setSlider, breakpoints} = props;
  const classes = useStyles();

  if (!setSliderIndex) return;

  return (
    <Swiper
      onSlideChange={e => setSliderIndex(e.activeIndex)}
      onSwiper={e => setSlider(e)}
      breakpoints={breakpoints}
      spaceBetween={20}
      className={classes.root}
      style={{display: 'flex', flexDirection: 'column-reverse'}}>
      {children && children}
    </Swiper>
  );
};
