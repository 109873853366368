import React from 'react';
import {useTranslation} from 'react-i18next';
import {Router, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import PhoneVerificationProvider from '../_components/PhoneVerification/PhoneVerificationProvider';

import {history} from '../_helpers';
import {alertActions} from '../_actions';
import {ThemeProvider} from '@material-ui/styles';
import theme from '../theme';
import Routes from '../Routes';
import {SnackbarProvider} from 'notistack';
import Alert from './Alert';
import Auth from '../_HOC/Auth';
import Language from '../_HOC/Language';

// import {useServiceWorker} from '../hooks/useServiceWorker';

function ScrollToTop() {
  const {pathname} = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App(props) {
  const {alert, clearAlerts} = props;
  // const {needsUpdate} = useServiceWorker();
  const {t} = useTranslation();

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }

  React.useEffect(() => {
    const unlisten = history.listen((location, action) => {
      clearAlerts();
    });

    return () => {
      unlisten();
    };
  }, [clearAlerts]);

  return (
    <SnackbarProvider maxSnack={3}>
      {/* {needsUpdate && (
        <Alert
          alert={{
            message: `${t('common:update_version')}`,
            variant: 'error',
            autoHideDuration: 999999,
          }}
        />
      )} */}
      {alert && <Alert alert={alert} />}
      <ThemeProvider theme={theme}>
        <PhoneVerificationProvider>
          <Router history={history}>
            <ScrollToTop />
            <Auth />
            <Language />
            <Routes />
          </Router>
        </PhoneVerificationProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

function mapState(state) {
  const {alert} = state;
  return {alert};
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(App);
export {connectedApp as App};
