import { productConstants } from '../_constants';
import { productService } from '../_services/product.service';

export const productsActions = {
  getBoundProductsByGroupId,
  getAnalogProducts,
  getBonusProducts,
};

function getBoundProductsByGroupId(product_group_id) {
  return dispacth => {
    dispacth(request());

    productService.getBoundProductsByGroupId(product_group_id).then(
      data => {
        dispacth(success(data.data));
      },
      error => {
        dispacth(failrule(error));
      },
    );
  };

  function request() {
    return { type: productConstants.GET_BOUND_PRODUCTS_REQUEST };
  }
  function success(data) {
    return { type: productConstants.GET_BOUND_PRODUCTS_SUCCESS, payload: data };
  }
  function failrule(err) {
    return { type: productConstants.GET_BOUND_PRODUCTS_ERROR, payload: err };
  }
}

function getAnalogProducts(analogList) {
  return dispacth => {
    dispacth(request());

    productService.getAnalogProducts(analogList).then(
      res => {
        dispacth(success(res.data.results));
      },
      err => {
        dispacth(failrule(err));
      },
    );
  };
  function request() {
    return { type: productConstants.GET_ANALOG_PRODUCTS_REQUEST };
  }
  function success(data) {
    return { type: productConstants.GET_ANALOG_PRODUCTS_SUCCESS, payload: data };
  }
  function failrule(err) {
    return { type: productConstants.GET_ANALOG_PRODUCTS_ERROR, payload: err };
  }
}

function getBonusProducts() {
  return dispatch => {
    dispatch(request());
    productService.getBonusProducts().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: productConstants.GET_BONUS_PRODUCTS_REQUEST };
  }

  function success(data) {
    return { type: productConstants.GET_BONUS_PRODUCTS_SUCCESS, data };
  }

  function failure(error) {
    return {
      type: productConstants.GET_BONUS_PRODUCTS_ERROR,
      error,
    };
  }
}