import clsx from 'clsx';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import {customerActions} from '../../_actions';
import {ProductTooltip} from './ProductTooltip';
import productImage from '../../images/product.png';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';
import PriceToStr from '../PriceToStr';
import IconDiscount from '../../images/icon_discount.svg';
import moment from 'moment';
import Timer from 'react-compound-timer/build';
import {Tooltip, styled} from '@material-ui/core';
import axInst from '../../_helpers/axios-instance';
import axInstTest from '../../_helpers/axios-instance-test';

const ProductTooltipS = styled(ProductTooltip)(() => ({
  width: 400,
}));

const ProductItem = props => {
  const {
    row,
    addToWishlist,
    removeFromWishlist,
    userSelectedData,
    setDialogData,
    unLike,
    sell_currency_id,
    promoLabel,
    currencies,
    showPromotionTimer,
  } = props;
  const {wish_list} = userSelectedData;
  const {t} = useTranslation();
  const toDay = moment().valueOf();
  const history = useHistory();

  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    setEmpty(row.balance.every(i => i.quantity_int === 0));
  }, []);

  const handleLike = (event, data) => {
    event.preventDefault();
    if (wish_list.find(i => i.article === data.article)) {
      removeFromWishlist(data);
      axInst.post("/api/delete-wish-list-product/", JSON.stringify({customer_id: userSelectedData.customer_id, article: row.article}))
    } else {
      addToWishlist(data);
      axInst.post("/api/add-wish-list-product/", JSON.stringify({customer_id: userSelectedData.customer_id, article: row.article}))
    }
  };
  const handleAddToCart = event => {
    if (empty) return;
    event.preventDefault();
    setDialogData({row, indexRows: '', type: 'promoProducts'});
  };
  const handleGoToReviews = (e, path) => {
    e.preventDefault();
    history.push(path);
  };

  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);

  const getFirstItem = str => str.split('')[0];

  return (
    <>
      <Link
        to={`/product/${row.brand}/${row.article_clean}`}
        className="product__img">
        {row.badge_name && (
          <div
            style={{width: '100%', backgroundColor: `#${row.badge_rgb_code}`}}
            className="product__inline-discount">
            {row.badge_name}
          </div>
        )}
        <img src={row?.images?.thumb ?? productImage} alt="promotion" />
        {promoLabel && (
          <span
            style={{
              background:
                promoLabel[0].badge_rgb_code?.split('')[0] === '#'
                  ? promoLabel[0].badge_rgb_code
                  : '#' + promoLabel[0].badge_rgb_code,
              color: 'white',
              whiteSpace: 'nowrap',
            }}
            className="info">
            {promoLabel[0].badge_name}
          </span>
        )}
        {row?.isDiscount ? (
          <span
            style={row.badge_name ? {top: 27, right: 0} : {}}
            className="percent">
            – {row?.isDiscount}%
          </span>
        ) : (
          <></>
        )}
      </Link>
      <Link
        to={`/product/${row.brand}/${row.article_clean}`}
        className="product__inner">
        {!unLike ? (
          row.all_warehouses_is_empty ? 
          <Tooltip title={t('common:add_to_favourite_tooltip')}>
          <div
            className={clsx(
              'like',
              wish_list?.find(i => i.article === row.article_clean)
                ? 'active'
                : '',
            )}
            onClick={event =>
              handleLike(event, {article: row.article_clean, brand: row.brand})
            }>
            <svg
              className="like-default"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.04853 4.15142C0.750873 2.53645 1.85092 1.1375 3.56571 1.00537C5.07558 0.890012 5.82404 2.66858 5.97503 3.00835C5.9767 3.01405 5.98024 3.01906 5.98511 3.02263C5.98997 3.0262 5.9959 3.02813 6.00199 3.02813C6.00809 3.02813 6.01401 3.0262 6.01888 3.02263C6.02374 3.01906 6.02728 3.01405 6.02895 3.00835C6.72997 1.1396 8.00689 1.0599 8.45553 1.01585C9.66343 0.890013 11.2833 1.79817 10.9576 3.91442C10.4917 6.85073 5.97072 10.1667 5.97072 10.1667C5.97072 10.1667 1.63738 7.30796 1.04853 4.15142Z"
                stroke="#315EFB"
                strokeWidth="1.6"
              />
            </svg>
          </div>
          </Tooltip> :
          <div
            className={clsx(
              'like',
              wish_list?.find(i => i.article === row.article_clean)
                ? 'active'
                : '',
            )}
            onClick={event =>
              handleLike(event, {article: row.article_clean, brand: row.brand})
            }>
            <svg
              className="like-default"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.04853 4.15142C0.750873 2.53645 1.85092 1.1375 3.56571 1.00537C5.07558 0.890012 5.82404 2.66858 5.97503 3.00835C5.9767 3.01405 5.98024 3.01906 5.98511 3.02263C5.98997 3.0262 5.9959 3.02813 6.00199 3.02813C6.00809 3.02813 6.01401 3.0262 6.01888 3.02263C6.02374 3.01906 6.02728 3.01405 6.02895 3.00835C6.72997 1.1396 8.00689 1.0599 8.45553 1.01585C9.66343 0.890013 11.2833 1.79817 10.9576 3.91442C10.4917 6.85073 5.97072 10.1667 5.97072 10.1667C5.97072 10.1667 1.63738 7.30796 1.04853 4.15142Z"
                stroke="#315EFB"
                strokeWidth="1.6"
              />
            </svg>
          </div>
        ) : (
          <div
            className={'like'}
            onClick={event =>
              handleLike(event, {
                article: row.article_clean,
                brand: row.brand,
              })
            }>
            <svg
              className="like-default"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5639 2.78062H9.15244V2.17775C9.15244 1.15287 8.36871 0.369141 7.34382 0.369141H4.93234C3.90746 0.369141 3.12373 1.15287 3.12373 2.17775V2.78062H0.712246C0.350523 2.78062 0.109375 3.02177 0.109375 3.38349C0.109375 3.74522 0.350523 3.98637 0.712246 3.98637H1.31512V11.8237C1.31512 12.8486 2.09885 13.6323 3.12373 13.6323H9.15244C10.1773 13.6323 10.9611 12.8486 10.9611 11.8237V3.98637H11.5639C11.9256 3.98637 12.1668 3.74522 12.1668 3.38349C12.1668 3.02177 11.9256 2.78062 11.5639 2.78062ZM4.32812 2.17807C4.32812 1.81634 4.56927 1.5752 4.931 1.5752H7.34248C7.7042 1.5752 7.94535 1.81634 7.94535 2.17807V2.78094H4.32812V2.17807ZM9.15502 12.4255C9.51674 12.4255 9.75789 12.1844 9.75789 11.8227V3.98535H2.52344V11.8227C2.52344 12.1844 2.76459 12.4255 3.12631 12.4255H9.15502Z"
                fill="#315EFB"
              />
            </svg>
          </div>
        )}

        <div className="product-top">
          <div className="product__title">{row?.brand}</div>
          <div
            className="d-flex justify-content-between"
            style={{cursor: 'pointer'}}>
            <span className="product-code">{row?.article}</span>
            <span className="product-min-part">{row?.weight} кг</span>
          </div>
          <p className="description">{row?.name}</p>
          {/* Нужно будет убрать условие <<row?.reviews_amount>> для того что бы показывало 0 отзывов 0 звезд, а пока просто скрыли */}
          {row?.average_rating ? (
            <span
              onClick={e =>
                handleGoToReviews(
                  e,
                  `/product/${row.brand}/${row.article_clean}?tab=reviews#top`,
                )
              }
              className="stars">
              <div className="js-rating">
                <StarRatings
                  rating={row?.average_rating || 0}
                  starDimension="15px"
                  starSpacing="1px"
                  starRatedColor="#FFBE00"
                />
              </div>
              {row?.reviews_amount || 0} {t('common:reviews')}
            </span>
          ) : (
            <div style={{height: '17px'}}></div>
          )}
        </div>
        <div className="product-end">
          {showPromotionTimer &&
            (new Date(row?.promotion_date_to).getDate() -
              new Date().getDate() ===
            1 ? (
              <div className="promotion__footer">
                {t('common:It_remained_until')}:{' '}
                <span>
                  {new Date(row?.promotion_date_to).getDate() -
                    new Date().getDate()}
                  {t('common:days')}
                </span>
              </div>
            ) : (
              <Timer
                initialTime={moment(row.promotion_date_to).valueOf() - toDay}
                direction="backward">
                <div className="timers">
                  <div className="timer">
                    <Timer.Days />{' '}
                    <span>{getFirstItem(t('common:days'))}.</span>
                  </div>
                  <div className="timer">
                    <Timer.Hours />{' '}
                    <span>{getFirstItem(t('common:hours'))}.</span>
                  </div>
                  <div className="timer">
                    <Timer.Minutes />{' '}
                    <span>{getFirstItem(t('common:minutes'))}.</span>
                  </div>
                  <div className="timer">
                    <Timer.Seconds />{' '}
                    <span>{getFirstItem(t('common:seconds'))}.</span>
                  </div>
                </div>
              </Timer>
            ))}
          <div className="line"></div>
          <div className="product-footer">
            <div className="price">
              {currencyMain && currencyMain.simbol && (
                <div style={{display: 'inline-grid', textAlign: 'right'}}>
                  {row.isDiscount !== 0 && (
                    <PriceToStr
                      stAll={{marginBottom: -10}}
                      stSumma={{
                        fontStyle: 'normal',
                        fontSize: 12,
                        fontWeight: 'normal',
                        color: '#546e7a',
                        textDecoration: 'line-through',
                      }}
                      data={
                        row.offers
                          ? row.offers[0].priceCurrent
                          : row.regularPrice
                      }
                      beforeText={''}
                      text={currencyMain.simbol}
                      grn={t('common:uah')}
                    />
                  )}
                  <PriceToStr
                    stSumma={{
                      fontStyle: 'normal',
                      color: row.isDiscount !== 0 && 'red',
                    }}
                    // data={row.offers ? row.offers[0].price * currencyMainContr.koef : row.price * currencyMainContr.koef}
                    data={
                      row.offers ? row.offers[0].priceCurrent : row.priceCurrent
                    }
                    beforeText={''}
                    text={currencyMain.simbol}
                    grn={t('common:uah')}
                  />
                </div>
              )}
              {row.discounts_by_quantity_sum_of_order &&
              row.discounts_by_quantity_sum_of_order.best_discount !==
                undefined &&
              row.discounts_by_quantity_sum_of_order.best_discount
                .discount_conditions_document__promotion_type !==
                'NO_CONDITION' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {/* <img
                      style={{width: '20px', margin: '0 5px 0 0'}}
                      src={IconDiscount}
                      alt={'IconDiscount'}
                    /> */}
                  </div>
                  <PriceToStr
                    stSumma={{
                      fontStyle: 'normal',
                      fontSize: '12px',
                      color: '#FF5252',
                      fontWeight: '700',
                    }}
                    data={
                      row.discounts_by_quantity_sum_of_order.best_discount
                        .price_with_discount
                    }
                    stBefore={{
                      fontWeight: '500',
                      fontSize: 12,
                      margin: '0 5px 0 0',
                      fontStyle: 'normal',
                    }}
                    beforeText={t('common:best_price')}
                  />
                </div>
              ) : null}
            </div>
            <ProductTooltipS
              title={
                <ul style={{minWidth: 225}} className="single-product-list">
                  {row?.balance?.map(item => (
                    <li
                      key={item.name}
                      className={item.quantity <= 0 ? 'disabled' : ''}>
                      <span className="title">{item.name}</span>
                      <span className="count">
                        {item.quantity} {t('common:pieces')}
                      </span>
                    </li>
                  ))}
                </ul>
              }>
              <button onClick={event => handleAddToCart(event)} className="btn">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.304453 0.654591C0.49957 0.454903 0.778008 0.344864 1.05645 0.348731C1.87699 0.348731 2.7225 0.519591 3.41332 0.980841C3.95227 1.33697 4.36184 1.871 4.59633 2.47076C4.72289 2.77803 4.79637 3.10322 4.86 3.42877C5.30719 5.68791 5.75227 7.94776 6.21633 10.2037C6.29121 10.5493 6.47402 10.8844 6.77109 11.0865C7.11879 11.3375 7.55789 11.4237 7.98012 11.4237C9.72738 11.4258 11.475 11.4212 13.2223 11.4258C13.5197 11.0809 13.7602 10.6928 13.9971 10.3053C14.3768 9.68939 14.7104 9.04709 15.0525 8.41006C15.4093 7.75932 15.7127 7.08115 16.0555 6.42303C16.2605 6.03631 16.7295 5.81904 17.1577 5.90729C17.6295 5.9899 17.9975 6.43252 18 6.911V6.97252C17.9845 7.13108 17.9395 7.28576 17.8703 7.42885C17.216 8.79854 16.5414 10.1619 15.7419 11.4535C15.4009 11.99 15.0441 12.5237 14.5948 12.9772C14.3205 13.2497 13.9771 13.5067 13.5714 13.4975C11.707 13.5035 9.84234 13.4993 7.97801 13.4996C7.14656 13.5014 6.30141 13.2873 5.6148 12.8074C4.88426 12.3092 4.37379 11.5161 4.18992 10.6537C3.70934 8.33693 3.25512 6.01486 2.79422 3.69385C2.73727 3.39854 2.64937 3.09338 2.43914 2.86803C2.22469 2.63072 1.90898 2.51787 1.60031 2.46865C1.26105 2.4008 0.891211 2.47674 0.574453 2.31045C0.237656 2.14697 0.0158203 1.79049 0 1.41854V1.34049C0.0189844 1.08561 0.120234 0.834591 0.304453 0.654591Z"
                    fill="#1F1F1F"
                  />
                  <path
                    d="M6.94234 14.2255C7.36633 14.1379 7.81563 14.2339 8.18266 14.4582C8.54617 14.6867 8.82426 15.0527 8.93324 15.4696C9.15649 16.1991 8.79613 17.0464 8.13449 17.4159C7.74109 17.6448 7.25137 17.7228 6.81297 17.5868C6.39004 17.4753 6.01809 17.1905 5.79273 16.8168C5.54453 16.4059 5.46227 15.8852 5.61836 15.4264C5.78992 14.8193 6.32676 14.3464 6.94234 14.2255Z"
                    fill="#1F1F1F"
                  />
                  <path
                    d="M13.165 14.227C13.5724 14.1415 14.0073 14.2224 14.3655 14.4312C15.0402 14.8088 15.3998 15.6807 15.1509 16.4193C14.9523 17.1358 14.2404 17.6586 13.4996 17.6529C13.0155 17.6512 12.5332 17.436 12.2129 17.0721C11.8934 16.7164 11.7292 16.2238 11.7798 15.7471C11.8304 15.2996 12.0554 14.8703 12.4091 14.5884C12.6239 14.4034 12.8911 14.2913 13.165 14.227Z"
                    fill="#1F1F1F"
                  />
                  <rect
                    x="7.875"
                    y="5.625"
                    width="5.625"
                    height="2.25"
                    rx="1.125"
                    fill="#1F1F1F"
                  />
                </svg>
              </button>
            </ProductTooltipS>
          </div>
        </div>
      </Link>
    </>
  );
};

const mapState = state => {
  const {userSelectedData} = state;
  const {currencies} = state.frontendData;
  const {customer_id, sell_currency_id} = state.userSelectedData;
  return {userSelectedData, sell_currency_id, currencies};
};
const actionCreators = {
  addToWishlist: customerActions.addToWishlist,
  removeFromWishlist: customerActions.removeFromWishlist,
};

const connectedProductItem = connect(mapState, actionCreators)(ProductItem);
export {connectedProductItem as ProductItem};
