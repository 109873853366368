import {userConstants} from '../../_constants';

const initSate = {
    //historySearchData: []
};

export function historySearch(state = initSate, action) {
    if (action.type === userConstants.ADD_STR_SEARCH) {
        let arr = [];
        if (Array.isArray(state.historySearchData))
            arr = state.historySearchData;

        if (action.data) {
            //if(arr.indexOf(action.data) >= 0) arr.splice(arr.indexOf(action.data), 1);
            if (action.data.url !== undefined) {
                let ind = arr.findIndex(x => x.url === action.data.url);
                if (ind >= 0) arr.splice(ind, 1);
            }
            arr.unshift(action.data);
            arr = arr.slice(0, 40);
        }
        return {
            historySearchData: arr,
        };
    } else {
        return state;
    }
}
