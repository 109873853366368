import React from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
import { LineChart } from '@mui/x-charts/LineChart';
// import components
import StatisticsCard from '../../Dashboard/components/StatisticsCard';
// import i18n
import { useTranslation } from 'react-i18next';
// import moment
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    item: {
        overflow: "hidden",
        position: "relative",
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },

    cards: {
        width: "100%",
        display: "grid",
        gap: 32,
        gridTemplateColumns: "repeat(2, 1fr)",
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
        [theme.breakpoints.down(620)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    },
}));
const NumberOfOrders = (props) => {
    const { data, currency, notAllManagerDataCondition } = props;
    const { customer_orders, manager_orders } = data;
    const classes = useStyles();
    const { t } = useTranslation();

    const maxSellValue = Math.max(...customer_orders?.orders?.map((item) => item.sum_sell), ...manager_orders?.orders?.map((item) => item.sum_sell));
    const max = Math.ceil(maxSellValue * 1.1);

    return (
        <div className={`dashboard-card ${classes.item}`}>
            {notAllManagerDataCondition === true ? <div className="dashboard-card-empty">{t('common:fill_user_details_label')}</div> : data?.customer_orders?.orders_qty < 3 && data?.manager_orders?.orders_qty < 3 ? (
                <div className="dashboard-card-empty">{t('common:cannot_show_chart_label')}</div>
            ) : null}
            <div className={classes.cards}>
                <StatisticsCard
                    data={{ main_numbers: customer_orders?.orders_qty, percent: customer_orders?.orders_percent }}
                    title={`${t('common:quantity_orders_label')} (${currency?.current_currency})`}
                    chart={true}
                    color={"#1F1F1F"}
                    style={{ padding: 16 }}
                />
                <StatisticsCard
                    data={{ main_numbers: manager_orders?.orders_qty, percent: manager_orders?.orders_percent }}
                    title={`${t('common:quantity_orders_user_label')} (${currency?.current_currency})`}
                    chart={true}
                    color={"#279F51"}
                    style={{ padding: 16 }}
                />
            </div>
            <div className={classes.chart}>
                <LineChart
                    series={[
                        {
                            data: customer_orders?.orders?.map((item) => item.sum_sell),
                            color: '#1F1F1F',
                            showMark: false,
                            valueFormatter: (el) => `${el ? `${currency?.current_symbol} ${el}` : t("common:not_orders_label")}`,
                        },
                        {
                            data: manager_orders?.orders?.map((item) => item.sum_sell),
                            color: '#279F51',
                            showMark: false,
                            valueFormatter: (el) => `${el ? `${currency?.current_symbol} ${el}` : t("common:not_orders_label")}`,
                        },
                    ]}
                    xAxis={[
                        {
                            data: customer_orders?.orders?.map((_, index) => index + 1),
                            valueFormatter: (el) => `${moment(customer_orders?.orders[el - 1]?.data)?.format("DD.MM.YYYY")}`,
                        },
                        {
                            data: manager_orders?.orders?.map((_, index) => index + 1),
                            valueFormatter: (el) => `${moment(manager_orders?.orders[el - 1]?.data)?.format("DD.MM.YYYY")}`,
                        }
                    ]}
                    yAxis={[
                        {
                            valueFormatter: (el) => ` ${el}`,
                            max: max,
                        }
                    ]}
                    sx={{ width: '100%' }}
                    height={295}
                />
            </div>
        </div>
    )
}

export default NumberOfOrders