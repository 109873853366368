import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LastContent = props => {
  const { showLastContent, handleClose, firstOrder, selectedInvoiceItem, isExist } = props;
  const { t } = useTranslation();
  return (
    <div className={clsx('main-content', showLastContent ? 'hidden' : '')}>
      <div className="no__products mt-0">
        <div className="no__products-wrapper">
          {isExist === true ? (
            <div>
              <svg width="165" height="167" viewBox="0 0 165 167" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.125" cy="13.125" r="11.125" stroke="#FFBE00" strokeWidth="4" />
                <circle cx="161.5" cy="33.5" r="2.75" stroke="#FFBE00" strokeWidth="1.5" />
                <circle cx="12.5" cy="44.5" r="2.5" stroke="#FFBE00" strokeWidth="2" />
                <circle cx="125.5" cy="163.5" r="2.5" stroke="#FFBE00" strokeWidth="2" />
                <circle cx="42.5" cy="11.5" r="2.5" stroke="#FFBE00" strokeWidth="2" />
                <path d="M87.5026 160.415C127.773 160.415 160.419 127.769 160.419 87.4987C160.419 47.2279 127.773 14.582 87.5026 14.582C47.2318 14.582 14.5859 47.2279 14.5859 87.4987C14.5859 127.769 47.2318 160.415 87.5026 160.415Z" fill="#FFBE00" stroke="#FFBE00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M85.8665 116.026C103.531 115.506 117.429 100.765 116.909 83.1009C116.389 65.4368 101.648 51.5387 83.9837 52.0586C66.3196 52.5785 52.4215 67.3196 52.9414 84.9837C53.4613 102.648 68.2024 116.546 85.8665 116.026Z" stroke="white" strokeWidth="5" strokeLinejoin="round" />
                <path d="M107.32 106.906L122.079 121.997L107.32 106.906Z" fill="white" />
                <path d="M107.32 106.906L122.079 121.997" stroke="white" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
                <rect x="78.9453" y="71.2893" width="27.6869" height="8.97475" rx="4.48737" transform="rotate(45 78.9453 71.2893)" fill="white" stroke="#FFBE00" strokeWidth="3.5" />
                <rect x="72.5999" y="90.8652" width="27.6869" height="8.97475" rx="4.48737" transform="rotate(-45 72.5999 90.8652)" fill="white" stroke="#FFBE00" strokeWidth="3.5" />
                <circle cx="153" cy="160" r="6" stroke="#FFBE00" strokeWidth="2" />
              </svg>
              <h2 className="no__products-title">
                {t('common:is_exist_label')}
              </h2>
            </div>
          ) : (
            <div>
              <svg
                width="165"
                height="167"
                viewBox="0 0 165 167"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="13.125"
                  cy="13.125"
                  r="11.125"
                  stroke="#FFBE00"
                  strokeWidth="4"
                />
                <circle
                  cx="161.5"
                  cy="33.5"
                  r="2.75"
                  stroke="#FFBE00"
                  strokeWidth="1.5"
                />
                <circle
                  cx="12.5"
                  cy="44.5"
                  r="2.5"
                  stroke="#FFBE00"
                  strokeWidth="2"
                />
                <circle
                  cx="125.5"
                  cy="163.5"
                  r="2.5"
                  stroke="#FFBE00"
                  strokeWidth="2"
                />
                <circle
                  cx="42.5"
                  cy="11.5"
                  r="2.5"
                  stroke="#FFBE00"
                  strokeWidth="2"
                />
                <path
                  d="M87.5026 160.415C127.773 160.415 160.419 127.769 160.419 87.4987C160.419 47.2279 127.773 14.582 87.5026 14.582C47.2318 14.582 14.5859 47.2279 14.5859 87.4987C14.5859 127.769 47.2318 160.415 87.5026 160.415Z"
                  fill="#FFBE00"
                  stroke="#FFBE00"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M94.6853 102.389L104.312 92.7627H71.6953M81.322 71.6094L71.6953 81.236H104.312"
                  stroke="white"
                  strokeWidth="3.5"
                  stroke-miterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M88.0026 118.665C105.492 118.665 119.669 104.488 119.669 86.9987C119.669 69.5092 105.492 55.332 88.0026 55.332C70.5131 55.332 56.3359 69.5092 56.3359 86.9987C56.3359 104.488 70.5131 118.665 88.0026 118.665Z"
                  stroke="white"
                  strokeWidth="3.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="153" cy="160" r="6" stroke="#FFBE00" strokeWidth="2" />
              </svg>
              <h2 className="no__products-title">
                {t('common:return_request1') +
                  ' №' +
                  selectedInvoiceItem?.order_number +
                  ' ' +
                  t('common:for') +
                  selectedInvoiceItem?.date?.split(' ')[0] +
                  ' ' +
                  t('common:return_request2')}
              </h2>
              <p className="mt-4">{t('common:wait_for_contact_from_the_manage')}</p>
            </div>
          )}
        </div>
      </div>
      <button onClick={handleClose} className="btn px-15 py-3 button">
        {t('common:done')}
      </button>
    </div>
  );
};
