import React from 'react'
// import mui
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    section: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 32,

        "& a": {
            color: 'rgb(47, 128, 237)',
            textDecoration: 'underline',
        },
        
        [theme.breakpoints.down(476)]: {
            gap: 16,
        },
    },

    card: {
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 32,
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(992)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
            gap: 16,
        },
    },
}));

const InstructionCardWrapper = ({ children, title, id }) => {
    const classes = useStyles();

    return (
        <section className={classes.section} id={id || ""}>
            {title && (
                <Typography variant='h2' className="dashboard__title" style={{ fontSize: "22px" }}>
                    {title}
                </Typography>
            )}
            <article className={`dashboard-card ${classes.card}`}>
                {children}
            </article>
        </section >
    )
}

export default InstructionCardWrapper