import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';
import {connect} from 'react-redux';
import {promotionActions} from '../../../_actions/promotion.actions';
import {Link} from 'react-router-dom';

const BannerCarousel = ({promotionItems}) => {
  const {results, loading} = promotionItems;

  if (loading) return <>Loading...</>;

  return (
    <div className="product__inline__slider-banner swiper">
      <Swiper
        modules={[Pagination]}
        pagination={{clickable: true}}
        autoplay={{
          delay: 8000
        }}
        spaceBetween={10}>
        {results?.map(i => (
          <SwiperSlide key={i.id}>
            <Link to={`/promotion/${i.url}/${i.id}`}>
              <div className="product__inline swiper-slide">
                <img src={i.vertical_banner[0]} alt="product" />
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.promotionListItems();
  }

  render() {
    return <BannerCarousel {...this.props} />;
  }
}
const actionCreators = {
  promotionListItems: promotionActions.promotionListItems,
};

const mapState = state => {
  const {promotionItems} = state;

  return {promotionItems};
};

export default connect(mapState, actionCreators)(Wrapper);
