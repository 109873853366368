import React, { useEffect, useState } from 'react'
// import img
import image from "../images/product.png"
// import material ui
import { makeStyles } from '@material-ui/styles';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
} from '@material-ui/core';
import clsx from 'clsx';
// import language
import { useTranslation } from 'react-i18next';
// import redux
import { connect } from 'react-redux';
import { wishlistActions } from '../_actions';
// import fetch
import axInst from '../_helpers/axios-instance';

const useStyles = makeStyles(theme => ({
    divider: {
        margin: '8px 0 24px 0',
    },
    stMainHeader: {
        color: '#008CB4',
        fontSize: 18,
        fontWeight: 'bold',
    },
    stMessage: {
        color: '#606060',
        fontSize: 14,
    },
    signOutButton: {
        margin: '16px 0 4px 0',
        padding: '4px 8px',
    },
    modalCloseBtn: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        padding: 5,
        '& div': {
            width: 22,
            height: 22,
        },
    },

    title: {
        display: "flex",
        flexDirection: "column",
        gap: "9px",

        textAlign: "center",

        "&>h4": {
            fontSize: "16px",
            fontWeight: "700",
            color: "#279F51",
        },

        "&>p": {
            fontSize: "14px",
            fontWeight: "600",
            color: "#555F7E",
        },
    },

    body: {
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        [theme.breakpoints.down(960)]: {
            gap: "16px",
        },

        "&>button": {
            width: "fit-content",
            margin: "0 auto",
            padding: "8px 16px",
            borderRadius: "20px",
            border: "1px solid #2F80ED",
            cursor: "pointer",
            backgroundColor: "transparent",

            fontSize: "14px",
            fontWeight: "600",
            color: "#2F80ED",
        },
    },

    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",

        [theme.breakpoints.down(767)]: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "5px",
        },

        "&:not(:last-child)": {
            paddingBottom: "16px",
            marginBottom: "16px",
            borderBottom: "1px solid #E9EAF3",
        },

        "&>img": {
            width: "84px",
            height: "53px",
            objectFit: "contain",
            objectPosition: "center center",
        },

        "&>div": {
            display: "flex",
            flexDirection: "column",
            gap: "5px",

            "&>h4": {
                fontSize: "14px",
                fontWeight: "700",
                color: "#1C2A53",
                whiteSpace: "nowrap",
            },

            "&>p": {
                fontSize: "13px",
                fontWeight: "400",
                color: "#8E95A9",
                whiteSpace: "nowrap",
            },
        },

        "&>h1": {
            fontSize: "14px",
            fontWeight: "600",
            color: "#1C2A53",
        },

        "&>p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "#555F7E",
        },
    },
}));

const Row = ({ data, currency }) => {
    const { article, name, brand, price, product_photos } = data;
    const { large_photos } = product_photos;
    const classes = useStyles();

    return (
        <article className={classes.card}>
            <img src={large_photos?.[0] || image} alt="test" />
            <div>
                <h4>{brand}</h4>
                <p>{article}</p>
            </div>
            <h1>{name}</h1>
            <p>{currency} {price.toFixed(2)}</p>
        </article>
    )
};

const WishlistMessage = (props) => {
    const { wishlistFlagMessage, getWishlistFlagMessage, customer_id, financeCalendarByOrganization } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const { loading, data } = wishlistFlagMessage;
    const [currentMessageData, setCurrentMessageData] = useState([]);

    const handleCloseMessage = (currentMessageData) => {
        currentMessageData.forEach((item) => {
            axInst.post("/api/seen-wish-list-message/", JSON.stringify({
                customer_id: customer_id,
                product_id: item.wish_list_product__product__id,
            }))
        });
        setCurrentMessageData([]);
    };

    useEffect(() => getWishlistFlagMessage(), []);

    useEffect(() => {
        if (customer_id !== 4890 && loading === false && data.length !== 0) {
            const unseenMessages = data.filter(item => !item.is_seen_message && item.show_message);
            setCurrentMessageData(prev => [...prev, ...unseenMessages]);
        }
    }, [wishlistFlagMessage]);

    return null;

    // return (
    //     <Dialog
    //         className="dialog-wrapper"
    //         maxWidth="md"
    //         fullWidth={true}
    //         open={currentMessageData.length !== 0 ? true : false}>
    //         <DialogContent style={{
    //             flex: "1 1 auto",
    //             overflowY: "auto",
    //             padding: "16px",
    //         }}>
    //             <Box sx={{ maxWidth: "769px", width: "100%" }}>
    //                 <IconButton
    //                     className={clsx('button-close', classes.modalCloseBtn)}
    //                     style={{ float: 'right', marginTop: -5 }}
    //                     onClick={handleCloseMessage}
    //                     size={'small'}>
    //                     <Box>
    //                         <svg
    //                             width="16"
    //                             height="16"
    //                             viewBox="0 0 16 16"
    //                             fill="none"
    //                             xmlns="http://www.w3.org/2000/svg">
    //                             <path
    //                                 d="M15 1L1.15497 15M1 1L14.845 15L1 1Z"
    //                                 stroke="#414D5F"
    //                                 strokeOpacity="0.5"
    //                                 strokeWidth="2"
    //                                 strokeLinecap="round"
    //                                 strokeLinejoin="round"
    //                             />
    //                         </svg>
    //                     </Box>
    //                 </IconButton>
    //                 <Box className="modal-body" style={{ overflowY: "auto" }}>
    //                     {/* <Typography className="modal-title mb-4">
    //                         {t('common:warn_message2_countdown_title')}
    //                     </Typography>
    //                     <Typography className="modal-description mb-4">
    //                         {currentMessageData.length === 1 ? t('common:wishlist_message1') : t('common:wishlist_message2')}
    //                         <span style={{ fontWeight: 600 }}>{currentMessageData.map((item, index) => index !== currentMessageData.length - 1 ? ` ${item.wish_list_product__product__article},` : ` ${item.wish_list_product__product__article}`)}</span>
    //                         {' '}{currentMessageData.length === 1 ? t('common:wishlist_message3') : t('common:wishlist_message4')}
    //                     </Typography> */}
    //                     {/* <button onClick={() => {
    //                         handleCloseMessage(currentMessageData)
    //                     }} className="btn btn-big">
    //                         {t('common:warn_message2_countdown_confirm_btn')}
    //                     </button> */}

    //                     <div className={classes.body}>
    //                         <div className={classes.title}>
    //                             <h4>{t("common:wishlist_msg_text1")}</h4>
    //                             <p>{t("common:wishlist_msg_text2")}</p>
    //                         </div>
    //                         <div>
    //                             {currentMessageData?.map((item, index) => (
    //                                 <Row
    //                                     key={`wishlist-product-${index}`}
    //                                     data={item}
    //                                     currency={financeCalendarByOrganization?.result?.current_symbol || "$"}
    //                                 />
    //                             ))}
    //                         </div>
    //                         <button onClick={() => {
    //                             handleCloseMessage(currentMessageData)
    //                         }}>
    //                             {t("common:wishlist_msg_btn")}
    //                         </button>
    //                     </div>
    //                 </Box>
    //             </Box>
    //         </DialogContent>
    //     </Dialog>
    // )
}

function mapState(state) {
    const {
        wishlistFlagMessage,
        userSelectedData,
        financeCalendarByOrganization,
    } = state;
    const { customer_id } = userSelectedData;
    return {
        wishlistFlagMessage,
        customer_id,
        financeCalendarByOrganization,
    };
}

const actionCreators = {
    getWishlistFlagMessage:
        wishlistActions.getWishlistFlagMessage,
};

export default connect(mapState, actionCreators)(WishlistMessage);