import {userConstants} from '../_constants';

const initSate = {
  customer_id: undefined,
  customer_name: '',
  customer_code: '',
  customer_cart_id: 0,
  // customer_cart_name: 'Default cart',
  sell_currency_id: undefined,
  customer_cart_ids: [0],
  wish_list: [],
  wish_list_items: {},
  // 'tree_expand': [603],
  // 'selected_id': 615,
  // 'js_version': 'a040be638ac742658f238fa064762e4c',
};

export function userSelectedData(state = {...initSate}, action) {
  switch (action.type) {
    case userConstants.FRONTEND_DATA_SUCCESS:
      const {data} = action.data;
      const {customer_default: customer, customer_guest: guest} = data;

      if (
        // Если доступен дользователь отличный от гостя, берем его
        state.customer_id === guest.customer_id &&
        state.customer_id !== customer.customer_id
      ) {
        return {
          ...state,
          customer_id: customer.customer_id,
          customer_name: customer.customer_name,
          customer_code: customer.customer_code,
        };
      }

      return {
        ...state,
        customer_id: state.customer_id
          ? state.customer_id
          : customer.customer_id,
        customer_name: state.customer_name
          ? state.customer_name
          : customer.customer_name,
        customer_code: state.customer_code
          ? state.customer_code
          : customer.customer_code,

        sell_currency_id: state.sell_currency_id
          ? state.sell_currency_id
          : data.main_currency.id,
      };
    case userConstants.SET_CUSTOMER_ID:
      const {
        customer_id,
        customer_name,
        customer_code,
        customer_discount_group,
      } = action.data;
      return {
        ...state,
        customer_id,
        customer_name,
        customer_code,
        customer_discount_group,
        customer_cart_id: initSate.customer_cart_id,
        customer_cart_ids: initSate.customer_cart_ids,
      };
    case userConstants.SET_CURRENCY_ID:
      return {
        ...state,
        sell_currency_id: action.currencyId,
      };

    case userConstants.SET_CUSTOMER_CART_ID:
      return {
        ...state,
        customer_cart_id: action.cartId,
        // временно пока не добавим переключатель
        customer_cart_ids: [action.cartId],
      };

    case userConstants.SET_SELECTED_CARTS:
      return {
        ...state,
        customer_cart_ids: [...action.data],
      };
    case userConstants.GET_WISHLIST_ITEMS_SUCCESS: {
      return {
        ...state,
        wish_list_items: action.data,
      };
    }

    case userConstants.ADD_TO_WISHLIST:
      return {
        ...state,
        wish_list: [
          ...state.wish_list,
          {article: action.data.article, brand: action.data.brand},
        ],
      };
    case userConstants.REMOVE_FROM_WISHLIST:
      let newWishlistItems = [];
      if (state.wish_list_items?.results?.length > 0) {
        newWishlistItems = state.wish_list_items.results.filter(
          i => i.article_clean !== action.data.article,
        );
      }
      const newWishList = state.wish_list.filter(
        i => i.article !== action.data?.article,
      );
      return {
        ...state,
        wish_list: newWishList,
        wish_list_items: {...state.wish_list_items, results: newWishlistItems},
      };
    default:
      return state;
  }
}
