import React from 'react'; // useState
import {connect} from 'react-redux';

// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

import {withRouter} from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles';

import {useTranslation} from 'react-i18next';

import clsx from 'clsx';
import {Grid} from '@material-ui/core';

// const MOBILE_SIZE = 768;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  table: {
    '& td': {
      textAlign: 'center',
    },
  },

  tableHeadRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'sticky',
    top: 64,
    zIndex: 9,
    background: '#FFBE00',
  },

  cellHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '600',
    padding: '4px',
    borderBottom: '#e8e8e8 solid 5px',
    textAlign: 'center',
    borderRight: '#dedede 1px solid',
    lineHeight: 1.3,
    background: '#00b7d2',
    // margin: '0 0 10px',
    background: '#FFBE00',
  },
  cellRightBorder: {
    borderRight: '#dedede 1px solid',
  },
  cell: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#505050',
    border: '#dedede 1px solid',
  },

  cellBold: {
    fontWeight: '700',
    fontSize: '14px',
  },
  sectionDivider: {
    paddingTop: '10px',
  },
}));

const ScheduleDeliveriesHead = props => {
  // const {
  //     // customerId,
  //     // userBalance,
  //     // loggedIn,
  //     // contragentData,
  //     // main_currency,
  // } = props;

  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <>
      <Grid container className={classes.tableHeadRow}>
        <Grid container item xs={8}>
          <Grid item alignItems="center" xs={2} className={classes.cellHeader}>
            <span>{t('common:type_of_delivery')}</span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>
              {t('common:city')}
              <br />
              {t('common:city_of_dispatch')}
            </span>
          </Grid>
          <Grid item xs={2} className={clsx(classes.cellHeader)}>
            <span>
              {t('common:city')}
              <br />
              {t('common:priznach')}
            </span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:region')}</span>
          </Grid>
          <Grid item xs={2} className={clsx(classes.cellHeader)}>
            <span>{t('common:order_time')}</span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:shipment_time')}</span>
          </Grid>
        </Grid>
        <Grid container item xs={4}>
          {/*<Grid item xs={1} className={classes.cellHeader}>*/}
          {/*    /!*{t('common:last_day')}*!/*/}
          {/*</Grid>*/}
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:Mon')}</span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:Tue')}</span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:Wed')}</span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:Thu')}</span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:Fri')}</span>
          </Grid>
          <Grid item xs={2} className={classes.cellHeader}>
            <span>{t('common:Sat')}</span>
          </Grid>
          {/*<Grid item xs={4} className={classes.cellHeader}>*/}
          {/*    {t('common:delivery_date')}*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
    </>
  );
};

function mapState(state) {
  const {userBalance, userSelectedData, authentication, contragentData} = state;
  const {customer_id: customerId} = userSelectedData;
  const {loggedIn} = authentication;
  const {main_currency} = state.frontendData;

  return {
    customerId,
    userBalance,
    loggedIn,
    contragentData,
    main_currency,
  };
}

export default connect(mapState)(withRouter(ScheduleDeliveriesHead));
