import React, { useEffect, useState, useContext, createContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import Preloader from '../../../_components/Preloader';
import MainDialog from '../../../_components/ReturnProduct/MainDialog';
import axInst from '../../../_helpers/axios-instance';
import axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import EmptyPage from '../../../images/EmptyPage.png';
// import OrdersMobile from './OrdersMobile';
// import OrderModal from './OrderModal';

import { cartActions } from '../../../_actions/cart.actions';
import clsx from 'clsx';

import ukLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { store } from '../../../_helpers';
import SalesInvoiceEnhancedTableBody from './SalesInvoiceEnhancedTableBody';

export const ModalContext = createContext(null);

const localeMap = {
  en: enLocale,
  uk: ukLocale,
  ru: ruLocale,
};

/* ===================================================== */

function desc(a, b, invoiceBy) {
  if (b[invoiceBy] < a[invoiceBy]) {
    return -1;
  }
  if (b[invoiceBy] > a[invoiceBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  let stabilizedThis = [];
  if (array !== undefined && array.length > 0) {
    stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const invoices = cmp(a[0], b[0]);
      if (invoices !== 0) return invoices;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
}

function getSorting(invoices, invoiceBy) {
  return invoices === 'desc'
    ? (a, b) => desc(a, b, invoiceBy)
    : (a, b) => -desc(a, b, invoiceBy);
}

function EnhancedTableHead(props) {
  const { classes, invoices, invoiceBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headCells = [
    {
      id: 'arrows',
      align: 'center',
      disablePadding: false,
      label: '',
    },
    {
      id: 'date',
      align: 'center',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelNumberDate'),
    },
    {
      id: 'deliveryFrom',
      align: 'center',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelDeliveryFrom'),
    },
    {
      id: 'remark',
      align: 'center',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelDelivery'),
    },
    {
      id: 'deliveryStatus',
      align: 'center',
      disablePadding: false,
      label: t('common:orderStatus'),
    },
    {
      id: 'total',
      align: 'right',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelTotal'),
    },
    {
      id: 'download',
      align: 'center',
      disablePadding: false,
      label: '',
    },
  ];
  return (
    <>
      <TableHead>
        <TableRow className={classes.tableHeadRow}>
          {headCells.map(headCell => (
            <TableCell
              style={{ width: headCell.id === 'date' ? '220px' : '' }}
              className={classes.tableHeadCells}
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={invoiceBy === headCell.id ? invoices : false}>
              {headCell.id === 'date' ? (
                <TableSortLabel
                  active={invoiceBy === headCell.id}
                  direction={invoices}
                  onClick={createSortHandler(headCell.id)}
                  className={classes.tableHeadTitle}>
                  {headCell.label}
                  {invoiceBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {invoices === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <div className={classes.tableHeadTitle}>{headCell.label}</div>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  invoices: PropTypes.oneOf(['asc', 'desc']).isRequired,
  invoiceBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    height: '100%',
  },
  contentBodyHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    height: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    height: '100%',
  },
  table: {
    minWidth: 750,
    height: '100%',
  },
  tableWrapper: {
    height: '100%',
    maxHeight: 550,
    overflowX: 'auto',
  },
  tableHeadTitle: {
    clear: 'both',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  visuallyHidden: {
    align: 'center',
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeadRow: {
    backgroundColor: '#e5e5e5',
  },
  tableHeadCells: {
    fontSize: 14,
    fontWeight: 700,
  },
  tableCells: {
    fontSize: 14,
  },
  tableRowText: {
    '& a': {
      margin: 0,
      fontSize: 14,
      // color: '#2f76b5',
      textDecoration: 'none',
    },
    '& b': {
      margin: 0,
      fontSize: 14,
      whiteSpace: 'nowrap',
    },
    '& p': {
      margin: 0,
      fontSize: 14,
    },
  },
  tableRowCount: {
    color: '#a0a0a0',
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  modalButton: {
    marginLeft: theme.spacing(1),
  },
  dateFromInput: {
    marginRight: 20,
    '& input': {
      textAlign: 'center',
    },
  },
  dateUntilInput: {
    '& input': {
      textAlign: 'center',
    },
  },
  dateFilter: {
    display: 'flex',
    alignItems: 'baseline',
    '& p': {
      margin: '0 14px 0 0',
    },
  },
}));

/* ================================================================================== */

const Returns = props => {
  const { getInvoices, invoice } = props;
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState({});
  const [invoiceOrderItems, setInvoiceOrderItems] = useState([]);
  const classes = useStyles();
  const [returnOrders, setReturnOrders] = useState([]);
  const history = useHistory();

  const [invoices, setInvoices] = useState('asc');
  const [invoiceBy, setInvoiceBy] = useState('calories');
  const [isMobileList, showMobileList] = useState(false);
  // const [userOrderList, setUserOrderList] = useState([]);
  // const [modalOrderData, setModalOrderData] = useState({});
  const [preloader, setPreloader] = useState(true);

  const [modifyData, setModifyData] = useState([]);

  const [filterFromDate, setFromDate] = useState(moment().subtract(10, 'day'));
  const [filterUntilDate, setUntilDate] = useState(moment());
  const state = store.getState();
  const { userSelectedData, invoicesListParams } = state;
  const Token = '36c9b92726c91e3c5cb95d89793be15f1da839ad';
  const config = {
    headers: {
      Authorization: `Token ${Token}`,
      'Content-Type': 'application/json',
    },
  };
  // const [modalIsOpen, setModal] = useState(false);

  const state2 = store.getState();

  const { languageReducer } = state2;
  let lang = 'uk';
  if (languageReducer.ln_ch !== undefined) lang = languageReducer.ln_ch; //'UA';

  useEffect(() => {
    const handleResize = () => {
      const currentScreenWidth = window.innerWidth;
      if (currentScreenWidth <= 780) {
        showMobileList(true);
      } else {
        showMobileList(false);
      }
    };

    // first render set
    handleResize();

    window.addEventListener('resize', handleResize);

    // clean up
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInvoices(
      moment(filterFromDate).format('DD.MM.YYYY'),
      moment(filterUntilDate).format('DD.MM.YYYY'),
    );
  }, [filterFromDate, filterUntilDate, getInvoices]);
  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const from = queryParams.get('from');
  //   const to = queryParams.get('to');

  //   if (from && to) {
  //     setFromDate(from);
  //     setUntilDate(to)
  //   }
  // }, []);
  useEffect(() => {
  }, []);
  useEffect(
    () => {
      axInst
        .post('/api/moneyback-list/', {
          data_send_dict: {
            customer_id: +userSelectedData.customer_id,
            filter_by_date_order: [
              moment(filterFromDate).format('DD.MM.YYYY'),
              moment(filterUntilDate).format('DD.MM.YYYY'),
            ],
          },
        })
        .then(data => {
          setReturnOrders(data.data.result);
        });
    },
    // eslint-disable-next-line
    [invoice],
  );

  const setupUserInvoices = list => {
    let modifyData = [];
    let sortedData = [];

    if (list.invoices && list.invoices.length > 0) {
      list.invoices.map((invoice, id) => {
        modifyData[id] = {
          status_message: invoice.delivery_type,
          price_sell: invoice.sum.toFixed(2),
        };

        sortedData[id] = {
          status: invoice.delivery_status,
          number: invoice.number,
          date: invoice.date,
          deliveryFrom: invoice.storage,
          deliveryTo: t('common:orderDelivery'),
          // deliveryTo: order.storage__name_repr,
          total: invoice.sum, //.toFixed(2),
          // currency_simbol: invoice.currency_simbol,
          currency:
            invoice.currency_simbol === '₴'
              ? JSON.parse(localStorage.getItem('state')).languageReducer
                .lan === 'uk'
                ? 'UAH'
                : 'грн'
              : invoice.currency_simbol,
          // count: invoice.quantity,
          // price_sell: invoice.price_sell.toFixed(2),
        };

        return true;
      });
    }

    modifyData.sort((a, b) => (a.number - b.number) * -1);

    // setUserOrderList(sortedData);
    setModifyData(invoice.invoices);
  };

  const [locale] = useState(lang);

  // const toggleModal = currentOrder => {
  //   if (!modalIsOpen) {
  //     setModalOrderData({...currentOrder});
  //   }
  //   setModal(!modalIsOpen);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = invoiceBy === property && invoices === 'asc';

    setInvoices(isAsc ? 'desc' : 'asc');
    setInvoiceBy(property);
  };

  const handleSetFromDate = value => {
    setFromDate(value);
  };

  const handleSetUntilDate = value => {
    setUntilDate(value);
  };

  /*const isSelected = name => selected.indexOf(name) !== -1;*/

  /* =============================================================================== */
  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);

    const [goods, setGoods] = useState([]);
    const getReturnGoods = id => {
      axInst
        .post('/api/moneyback-items/', {
          id: id,
        })
        .then(data => {
          setGoods(data.data.result);
        });
    };
    return (
      <TableBody style={{ width: '100%' }}>
        <>
          <TableRow
            style={{ background: 'white' }}
            onClick={event => {
              event.stopPropagation();
              setOpen(!open);
              if (goods.length == 0) getReturnGoods(row.id);
            }}>
            <TableCell>
              <IconButton aria-label="expand row" size="small">
                {open ? (
                  <KeyboardArrowUpIcon style={{ color: '#008CB4' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ color: '#008CB4' }} />
                )}
              </IconButton>
            </TableCell>
            <TableCell>
              <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                ЗП №{row.id} на основі ВН №{row.zakaz_n.slice(-4)} вiд{' '}
                {row.created.slice(0, 10)}
              </p>
            </TableCell>
            <TableCell>
              <p style={{ textAlign: 'center' }}>{row.warehouse}</p>
            </TableCell>
            <TableCell>
              <p style={{ textAlign: 'center' }}>{row.return_method}</p>
            </TableCell>
            <TableCell>
              <p style={{ textAlign: 'center' }}>{row.status}</p>
            </TableCell>
            <TableCell>
              <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {userSelectedData?.customer_discount_group.includes('Гривня')
                  ? ''
                  : '$ '}{' '}
                {row.price_sum}
                {userSelectedData?.customer_discount_group.includes('Гривня')
                  ? ' грн'
                  : ''}{' '}
              </p>
            </TableCell>
          </TableRow>
          <TableRow style={{ background: '#e5e5e5' }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                      {goods.map((good, index) => (
                        <TableRow
                          className={'table_order_inner_row'}
                          key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant={'body2'}
                              className={'article_brand'}>
                              <RouterLink>
                                {good.order_item__brand}{' '}
                                {good.order_item__article}
                              </RouterLink>
                              <p>{good.order_item__name}</p>
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <p style={{ fontWeight: 'bold' }}>
                              {userSelectedData?.customer_discount_group.includes(
                                'Гривня',
                              )
                                ? ''
                                : '$ '}
                              {good.price_sum}
                              {userSelectedData?.customer_discount_group.includes(
                                'Гривня',
                              )
                                ? ' грн'
                                : ''}
                            </p>
                            <p>
                              {good.quantity_return} шт. x {good.price_sum}
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      </TableBody>
    );
  };
  const { t } = useTranslation();
  return (
    <div
      className={clsx(classes.root, 'order_container')}
      style={{ height: '100%' }}>
      <Grid className={classes.grid} container style={{ height: '100%' }}>
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
          style={{ height: '100%' }}>
          <div className={classes.content} style={{ height: '100%' }}>
            <div className={classes.contentBody} style={{ height: '100%' }}>
              <Grid
                container
                className={classes.contentBodyHead}
                style={{ marginBottom: '20px', height: '100%' }}>
                <Grid item xs={12} md={3} style={{ display: 'flex' }}>
                  <Typography className={classes.title} variant="h3">
                    {t('common:return')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  className={clsx('data_filter_container')}
                  style={{ height: '100%' }}>
                  <Typography>{t('common:orderDateFilterTitle')}</Typography>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[locale]}>
                    <DatePicker
                      className={classes.dateFromInput}
                      disableToolbar
                      autoOk
                      format="dd.MM.yyyy"
                      maxDate={filterUntilDate}
                      variant="inline"
                      value={filterFromDate}
                      onChange={value => {
                        handleSetFromDate(value);
                        // history.push(
                        //   `/profile/invoice?from=${value}&&to=${filterUntilDate}`,
                        // );
                      }}
                    />
                    <DatePicker
                      className={classes.dateUntilInput}
                      disableToolbar
                      autoOk
                      format="dd.MM.yyyy"
                      minDate={filterFromDate}
                      variant="inline"
                      value={filterUntilDate}
                      onChange={value => {
                        handleSetUntilDate(value);
                        // history.push(
                        //   `/profile/invoice?from=${filterFromDate}&&to=${value}`,
                        // );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {/* <Button variant="contained">{t('common:filters')}</Button> */}
              </Grid>

              {/* ================================================================ */}

              <Paper className={clsx(classes.paper, 'order_table_paper')}>
                <Table
                  className={clsx(classes.table, 'order_table')}
                  aria-labelledby="tableTitle"
                  style={{
                    background: 'white',
                    height: '100%',
                    position: 'relative',
                  }}
                  size={'medium'}
                  aria-label="enhanced table"
                  stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    invoices={invoices}
                    invoiceBy={invoiceBy}
                    onRequestSort={handleRequestSort}
                  />
                  {returnOrders.length != 0 ? (
                    returnOrders.map(row => <Row row={row} />)
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        left: '40%',
                        top: '220px',
                      }}>
                      <img src={EmptyPage} />
                      <p style={{ fontWeight: 'bold', marginLeft: '30px' }}>
                        {t('common:notFound')}
                      </p>
                    </div>
                  )}
                  {/*{emptyRows > 0 && (
                                                    <TableRow style={{height: 53 * emptyRows}}>
                                                        <TableCell colSpan={6}/>
                                                    </TableRow>
                                                )}*/}
                </Table>
              </Paper>
              {/* =================================================================== */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

function mapState(state) {
  const { invoice } = state;
  return { invoice };
}

const actionCreators = {
  getInvoices: cartActions.getInvoices,
};

export default connect(mapState, actionCreators)(withRouter(Returns));
