import React from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {catalogActions} from '../../_actions';
import {splitPath} from '../../_helpers';
import ProductList from '../ProductList';
import {ProductListSkeleton} from '../ProductList/components/ProductListSkeleton/ProductListSkeleton';

function mapState(state) {
  const {catalogPage, catalogByGroup} = state;
  return {
    catalogPage,
    catalogByGroup,
  };
}

const actionCreators = {
  getCatalogByGroup: catalogActions.getCatalogByGroup,
  getPageProductGroup: catalogActions.getPageProductGroup,
};

const DEFAULT_PER_VIEW = 9;

class Wrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      perViewValue: this.getInitialPerViewValue(),
      loading: true,
      currentPage: this.getPageFromUrl(props.location.search) || 1,
      currentPath: props.location.pathname,
      lastRequestId: null,
    };
  }

  getInitialPerViewValue() {
    try {
      const storedValue = localStorage.getItem('perViewValue');
      if (!storedValue) return DEFAULT_PER_VIEW;

      const parsedValue = parseInt(storedValue, 10);
      return !isNaN(parsedValue) && parsedValue > 0
        ? parsedValue
        : DEFAULT_PER_VIEW;
    } catch (error) {
      console.warn('Error reading from localStorage:', error);
      return DEFAULT_PER_VIEW;
    }
  }

  getPageFromUrl(searchString) {
    const searchParams = new URLSearchParams(searchString);
    return parseInt(searchParams.get('page'), 10) || 1;
  }

  componentDidMount() {
    this.ensurePageParam();
    this.loadData();
  }

  componentWillUnmount() {
    if (this.checkPageParInterval) {
      clearInterval(this.checkPageParInterval);
      this.checkPageParInterval = null;
    }

    if (this.checkPageParTimeout) {
      clearTimeout(this.checkPageParTimeout);
      this.checkPageParTimeout = null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {location} = this.props;
    const pathChanged = location.pathname !== prevProps.location.pathname;
    const searchChanged = location.search !== prevProps.location.search;
    const pageIdChanged =
      this.props.catalogPage?.page_par?.id !==
      prevProps.catalogPage?.page_par?.id;

    if (pathChanged) {
      const newPage = 1;
      const newPath = location.pathname;

      this.setState(
        {
          loading: true,
          currentPage: newPage,
          currentPath: newPath,
        },
        () => {
          this.ensurePageParam(newPage);
          this.loadData();
        },
      );
    } else if (searchChanged) {
      const newPage = this.getPageFromUrl(location.search);

      this.setState(
        {
          loading: true,
          currentPage: newPage,
        },
        () => {
          if (this.props.catalogPage?.page_par?.id) {
            this.loadCatalogData(this.props.catalogPage.page_par.id);
          }
        },
      );
    } else if (pageIdChanged && this.props.catalogPage?.page_par?.id) {
      this.loadCatalogData(this.props.catalogPage.page_par.id);
    } else if (
      this.state.perViewValue !== prevState.perViewValue &&
      this.props.catalogPage?.page_par?.id
    ) {
      this.setState(
        {
          loading: true,
          currentPage: 1,
        },
        () => {
          this.ensurePageParam(1);
          this.loadCatalogData(this.props.catalogPage.page_par.id);
        },
      );
    }

    if (
      prevProps.catalogByGroup?.loading &&
      !this.props.catalogByGroup?.loading
    ) {
      this.setState({loading: false});
    }
  }

  ensurePageParam = (page = null) => {
    const {history, location} = this.props;
    const searchParams = new URLSearchParams(location.search);
    const currentPage = page !== null ? page : this.state.currentPage || 1;

    if (
      !searchParams.has('page') ||
      parseInt(searchParams.get('page'), 10) !== currentPage
    ) {
      searchParams.set('page', currentPage);

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  };

  loadData = () => {
    const requestId = Date.now();
    this.setState({lastRequestId: requestId, loading: true});

    const path = splitPath(this.props.location.pathname);
    this.props.getPageProductGroup(path);

    const savedRequestId = requestId;

    if (this.checkPageParInterval) {
      clearInterval(this.checkPageParInterval);
    }

    this.checkPageParInterval = setInterval(() => {
      if (this.state.lastRequestId !== savedRequestId) {
        clearInterval(this.checkPageParInterval);
        this.checkPageParInterval = null;
        return;
      }

      const {catalogPage} = this.props;
      if (catalogPage?.page_par?.id && !catalogPage.loading) {
        clearInterval(this.checkPageParInterval);
        this.checkPageParInterval = null;
        this.loadCatalogData(catalogPage.page_par.id);
      }
    }, 50);

    if (this.checkPageParTimeout) {
      clearTimeout(this.checkPageParTimeout);
    }

    this.checkPageParTimeout = setTimeout(() => {
      if (this.checkPageParInterval) {
        clearInterval(this.checkPageParInterval);
        this.checkPageParInterval = null;
      }
    }, 5000);
  };

  loadCatalogData = pageId => {
    const requestId = Date.now();
    this.setState({lastRequestId: requestId, loading: true});

    const {perViewValue} = this.state;
    const {getCatalogByGroup, location} = this.props;

    getCatalogByGroup(
      'setData',
      pageId,
      location.pathname,
      [],
      this.state.currentPage,
      perViewValue,
    );
  };

  handlePerViewChange = value => {
    try {
      const numericValue = parseInt(value, 10);

      if (isNaN(numericValue) || numericValue <= 0) {
        console.warn('Invalid perViewValue:', value);
        this.setState({perViewValue: DEFAULT_PER_VIEW});
      } else {
        this.setState({perViewValue: numericValue});
        localStorage.setItem('perViewValue', numericValue.toString());
      }
    } catch (error) {
      console.warn('Error updating perViewValue:', error);
      this.setState({perViewValue: DEFAULT_PER_VIEW});
    }
  };

  render() {
    const {perViewValue, currentPage, loading} = this.state;
    const {catalogPage, location} = this.props;
    const pageId = catalogPage?.page_par?.id;

    const requestForCatalogByGroup = {
      setData: 'setData',
      id: pageId || null,
      oriPath: location.pathname,
      brands: [],
      page_number: currentPage,
      rows_per_page: perViewValue,
    };

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={catalogPage?.page_par?.meta_desc || ''}
          />
          <meta
            name="keywords"
            content={catalogPage?.page_par?.meta_keywords || ''}
          />
          <title>{catalogPage?.page_par?.meta_title || 'Каталог'}</title>
        </Helmet>
        {/*{loading ? (*/}
        {/*  <ProductListSkeleton />*/}
        {/*) : (*/}
          <ProductList
            {...this.props}
            requestForCatalogByGroup={requestForCatalogByGroup}
            onPerViewChange={this.handlePerViewChange}
          />
        {/*)}*/}
      </>
    );
  }
}

export default connect(mapState, actionCreators)(Wrapper);
