import PropTypes from 'prop-types';
import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  cls1: {
    fill: 'none',
  },

  cls2: {
    clipPath: 'url(#clip-path)',
  },
  cls3: {
    fill: '#ffbe00',
  },
  cls4: {
    fill: '#dedede',
  },
  cls5: {
    fill: '#ffbe00',
    fontSize: '32px',
    // fontFamily:'MyriadPro-Regular', 'Myriad Pro',
  },
  cls6: {
    fill: '#ffbe00',
    // fontFamily:'MyriadPro-Regular', 'Myriad Pro',
    fontSize: '17.87px',
  },
  // cls7: {
  //     fill: "#d9d9d9",
  // },
  // cls8: {
  //     fill: "#628cb4",
  // },
  // cls9: {
  //     fill: "#008cb4",
  //     fontSize: 11.44,
  //     // fontFamily: 'MyriadPro-Regular, Myriad Pro',
  // },
  // cls10: {
  //     fill: "none",
  //     stroke: "#008cb4",
  //     strokeLinecap: "round",
  //     strokeMiterlimit: 10,
  //     strokeWidth: "0.8",
  // },
  // cls11: {
  //     fill: "#008cb4",
  //     // fontFamily: 'MyriadPro-Regular, Myriad Pro',
  //     fontSize: 11.44,
  // }
}));

export default function Bob(props) {
  const classes = useStyles();
  const {weight} = props;
  // const {t} = useTranslation();

  return (
    <SvgIcon {...props} viewBox={'0 0 166.01 168.79'}>
      <defs>
        <clipPath id="clip-path">
          <rect
            className={classes.cls1}
            x="3.01"
            y="3.06"
            width="159.99"
            height="162.67"
          />
        </clipPath>
      </defs>
      <g className="cls-2">
        <path
          className={classes.cls3}
          d="M83,53.9a25.42,25.42,0,1,1,25.42-25.42A25.44,25.44,0,0,1,83,53.9m0-37.28A11.86,11.86,0,1,0,94.87,28.48,11.87,11.87,0,0,0,83,16.62"
        />
        <path
          className={classes.cls4}
          d="M162.8,151.75,144.94,53.47a11.85,11.85,0,0,0-11.67-9.74H32.74a11.86,11.86,0,0,0-11.67,9.74L3.21,151.75a11.85,11.85,0,0,0,11.67,14H151.14a11.84,11.84,0,0,0,11.66-14"
        />
      </g>
      <text className={classes.cls5} transform="translate(35.6 115.13)">
        {weight}
        {/*{t('common:kg')}*/}
      </text>
    </SvgIcon>
  );
}

Bob.propTypes = {
  weight: PropTypes.any,
};
