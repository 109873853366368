import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {Topbar} from './components';
import {userActions} from '../../_actions';
import {connect} from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import SelectLanguage from '../../_components/SelectLanguage';
import {Link as RouterLink} from 'react-router-dom';

const Minimal = props => {
    const {children, frontendData} = props;
    const header = document.getElementById('header');
    const {t} = useTranslation()
    const [headerHeight, setHeaderHeight] = useState(
        header !== null ? header.offsetHeight : 0,
    );
    const [contentHeight, setContentHeight] = useState(
        header != null
            ? window.innerHeight - (headerHeight)
            : 0,
    );

    useEffect(() => {
        if (header != null) {
            setHeaderHeight(header.offsetHeight);
            setContentHeight(
                window.innerHeight - (headerHeight),
            );
        }
    }, [header, headerHeight]);

  return (
    <div className="wrapper mt-0">
      <main className={clsx('user-main')}>{children}</main>
      <div className="user-footer">
        <div className="wrapper-inner">
          <div className="user-footer__inner">
            <RouterLink
              className="user-footer__agreement"
              to={'/user_agreement'}>
              {t('common:user_agreement')}
            </RouterLink>
            <SelectLanguage minimalMain />
          </div>
        </div>
      </div>
    </div>
  );
};

Minimal.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

class MinimalWrapper extends React.Component {
    componentDidMount() {
        this.props.getFrontendData();
    }

    render() {
        return <Minimal {...this.props} />;
    }
}

function mapState(state) {
    const {frontendData} = state;
    return {frontendData};
}

const actionCreators = {
    getFrontendData: userActions.getFrontendData,
};

const connectedMainWrapper = connect(mapState, actionCreators)(MinimalWrapper);

export default connectedMainWrapper;
