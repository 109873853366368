import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Cansel from '../icons/Cancel';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  divider: {
    //margin: theme.spacing(1, 0),
    margin: '8px 0 24px 0',
  },
  stMain: {
    // width: 340,
    // maxWidth: 340
  },
  stMainHeader: {
    color: '#1F1F1F',
    fontSize: 18,
    fontWeight: 'bold',
  },
  stMessage: {
    color: '#1F1F1F',
    fontSize: 14,
    fontWeight: 500,
  },
  stMessageLink: {
    color: "#000",
    textDecoration: "underline",
  },
  stWarning: { color: '#C43939', fontSize: 14, fontWeight: 'bold' },
}));

let timerId = 0;
const Message = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { waitTime, title, message, messageWarning, orderMessage = '' } = props;

  const [messageSet, setMessageSet] = useState(message);
  const [messageSetWarning, setMessageSetWarning] = useState(messageWarning);

  const handleCloseMessage = props => {
    clearTimeout(timerId);
    setMessageSet('');
    setMessageSetWarning('');
  };

  timerId = setTimeout(function () {
    handleCloseMessage();
  }, waitTime);

  return (
    <Dialog
      onClose={handleCloseMessage}
      maxWidth="xs"
      fullWidth={true}
      open={messageSet !== ''}>
      <div className={clsx(classes.stMain, 'add_to_cart_dialog')}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseMessage}>
          <span className={classes.stMainHeader}>{title}</span>
          <IconButton
            style={{ float: 'right', marginTop: -5 }}
            onClick={handleCloseMessage}
            className="close"
            size={'small'}>
            <Cansel />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: '100%', textAlign: 'left' }}>
            <Divider className={classes.divider} />
            <span className={classes.stMessage}>{messageSet}</span>
            {messageSetWarning ? (
              <div className={classes.stWarning}>{messageSetWarning}</div>
            ) : null}
            {orderMessage && orderMessage.show_message && (
              <div className={classes.stMessage} style={{paddingTop: 10}}>{orderMessage.message} "<Link className={classes.stMessageLink} to="/profile/invoice">{t('common:expenditure_overhead')}</Link>"</div>
            )}
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </div>
    </Dialog>
  );
};

Message.propTypes = {
  waitTime: PropTypes.number.isRequired,
};

function mapState(state) {
  const { userSelectedData } = state;
  return { userSelectedData };
}

const actionCreators = {};

class Wrapper extends React.Component {
  componentDidMount() {
    //this.props.getCart();
  }
  componentDidUpdate(prevProps) {
  }
  render() {
    return <Message {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
