import React from 'react';
import useAnimate from '../../hooks/useAnimate';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import DashboardBanner from '../../_components/DashboardBanner';
import BonusCard from './components/BonusCard';
import creditCard from './images/creditCard.svg';
import gift from './images/gift.svg';
import restoreLife from './images/restoreLife.svg';
import ParticipateProgram from './components/ParticipateProgram';
import FooterBonus from './components/FooterBonus';
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import DashboardAnimate from '../../_components/DashboardAnimate';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px"
    },
    body: {
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0"
        },
    },
    content: {
        paddingTop: "24px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: 'column',
        alignItems: "flex-start",
        gap: 32,
    },
    back: {
        paddingBottom: "50px",
        [theme.breakpoints.down(768)]: {
            paddingBottom: "30px",
        },
    },
    card: {
        padding: '32px 32px 64px 32px',

        [theme.breakpoints.down('sm')]: {
            padding: '16px'
        },
    },
    title: {
        margin: '0 0 16px 0',

        '& h4': {
            color: 'rgb(28, 42, 83)'
        }
    },
    p400: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        color: 'rgb(28, 42, 83)',

        '& span': {
            color: 'rgb(255, 190, 0)'
        }
    },
    h2: {
        color: 'rgb(28, 42, 83)',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '36px',
        textAlign: 'center'
    },
    bonusCards: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0 54px 0',
        gap: '20px',

        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    h6: {
        color: 'rgb(28, 42, 83)',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        margin: '0 0 8px 0'
    }
}));

const Bonus = () => {
    useAnimate();
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <main className="dashboard">
            <DashboardBanner />
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <h1 className="dashboard__title">{t("common:bonus")}</h1>
                    <div className={classes.content}>
                        <DashboardAnimate style={{ transform: "scale(0.97)" }}>
                            <div className={`dashboard-card ${classes.card}`}>
                                <div className={`about-us-page__card-history_title ${classes.title}`}>
                                    <span></span>
                                    <h4>{t("common:bonus_program")}</h4>
                                </div>
                                <p className={classes.p400} style={{ margin: '0 0 54px 0' }}>
                                    {t("common:participation_in_program")} <span>{t("common:percent_of_sum")}</span>!
                                </p>
                                <h2 className={classes.h2} style={{ margin: '0 0 8px 0' }}>
                                    {t("common:how_to_use_bonus")}
                                </h2>
                                <p className={classes.p400} style={{ margin: '0 0 32px 0', fontSize: '13px', textAlign: 'center' }}>
                                    {t("common:several_ways_to_use_bonus")}
                                </p>
                                <div className={classes.bonusCards}>
                                    <BonusCard
                                        imgSrc={creditCard}
                                        imgAlt="Daniparts"
                                        title={t("common:withdraw_funds_to_card")}
                                        description={t("common:withdraw_funds_description")}
                                    />
                                    <BonusCard
                                        imgSrc={gift}
                                        imgAlt="Daniparts"
                                        title={t("common:buy_gifts")}
                                        description={t("common:choose_gifts")}
                                    />
                                    <BonusCard
                                        imgSrc={restoreLife}
                                        imgAlt="Daniparts"
                                        title={t("common:support_relife")}
                                        description={t("common:you_can_donate_bonuses")}
                                    />
                                </div>
                                <h2 className={classes.h2} style={{ textAlign: 'left', margin: '0 0 32px 0' }}>
                                    {t("common:how_to_become_participant")}
                                </h2>
                                <ParticipateProgram />
                                <div className={`about-us-page__card-history_title ${classes.title}`}>
                                    <span></span>
                                    <h4>{t("common:advantages_work_with_daniparts")}</h4>
                                </div>
                                <p className={classes.p400}>
                                    {t("common:work_with_daniparts_profitable")}
                                </p>
                                <h6 className={classes.h6}>{t("common:join_bonus_program")}</h6>
                            </div>
                        </DashboardAnimate>
                        <DashboardAnimate style={{ transform: "scale(0.90)" }}>
                            <FooterBonus />
                        </DashboardAnimate>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Bonus;