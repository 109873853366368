import React from 'react'; // useState
import {CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Range from './Range';
import {searchActions} from '../../../../_actions/search.actions';
// import {catalogActions} from '../../../../_actions';
import CurrencyById from '../../../../_components/CurrencyById';
import BrandList from './BrandList';
import Grid from '@material-ui/core/Grid';
import Availability from './Availability';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useTranslation} from 'react-i18next';
import CatalogFilters from './CatalogFilters';
import {history} from '../../../../_helpers';
import {Toggle} from './Toggle';
import {authentication} from '../../../../_reducers/authentication.reducer';
import {Skeleton} from '@material-ui/lab';

const Filters = props => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const {
    brands,
    price_min,
    price_max,
    setPriceRange,
    selectedBrands,
    setBrandList,
    setProduct_group_id,
    search,
    sell_currency_id,
    setSearchAvailability,
    searchAvailability,
    searchRealAvailability,
    searchStatus,
    searchParams,
    currencyMainContr,
    catalogTree,
    price_min_current,
    price_max_current,
    product_group_tree,
    brandsLayout,
  } = props;

  const renderCurrency = () => <CurrencyById id={sell_currency_id} />;

  const toggleCheckbox = (e, id, url) => {
    searchParams.product_group_id = id;
    setProduct_group_id(id);
    search();
    if (window.location.pathname.split('/')[1] === 'product-groups') {
      history.push(url);
    }
  };

  const {loading: searchStatusLoading} = searchStatus;
  const {t} = useTranslation();
  function resetFilters() {
    if (window.location.pathname === '/products') {
      setBrandList('');
      search();
    } else {
      searchParams.searchAvailability = '';
      history.push(catalogTree.data.menu_block[0].url);
      setBrandList('');
      setSearchAvailability('', false);
      search();
    }
  }
  if (searchStatus.loading) {
    return (
      <div className="row">
        <div className="col-12 filter d-lg-block d-none">
          <div className="filter-header">
            <h3 className="filter-title">
              <Skeleton width={100} height={20} variant="rect" />
            </h3>
            <div>
              <Skeleton width={100} height={20} variant="rect" />
            </div>
          </div>
          <div
            className="filter-block"
            style={{backgroundColor: '#DBDDE0', borderRadius: 4}}>
            <div className="form__field mb-3">
              <label className="checkbox gap-10">
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={'80%'} height={20} />
              </label>
            </div>
            <div className="form__field mb-3">
              <label className="checkbox gap-10">
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={'95%'} height={20} />
              </label>
            </div>
            <div className="form__field mb-3">
              <label className="checkbox gap-10">
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={'70%'} height={20} />
              </label>
            </div>

            <Skeleton width={'55%'} height={25} variant="rect" />
          </div>
          <div
            className="filter-block"
            style={{backgroundColor: '#DBDDE0', borderRadius: 4}}>
            <h3 className="filter-title mb-4">
              <Skeleton variant="rect" width={50} height={15} />
            </h3>
            <form className="filter__search mb-4">
              <Skeleton
                className="filter__search-input"
                height={40}
                variant="rect"
              />
            </form>
            <div className="filter-category">
              <div className="filter-category__item mb-3">
                <div className="filter-category__header">
                  <div className="form__field" style={{width: '100%'}}>
                    <label className="checkbox gap-10">
                      <Skeleton variant="rect" width={20} height={20} />
                      <Skeleton variant="rect" width={'40%'} height={20} />
                    </label>
                  </div>
                </div>
              </div>
              <div className="filter-category__item mb-3">
                <div className="filter-category__header">
                  <div className="form__field" style={{width: '100%'}}>
                    <label className="checkbox gap-10">
                      <Skeleton variant="rect" width={20} height={20} />
                      <Skeleton variant="rect" width={'60%'} height={20} />
                    </label>
                  </div>
                </div>
              </div>
              <div className="filter-category__item active mb-4">
                <div className="filter-category__header">
                  <div className="form__field" style={{width: '100%'}}>
                    <label className="checkbox gap-10">
                      <Skeleton variant="rect" width={20} height={20} />
                      <Skeleton variant="rect" width={'80%'} height={20} />
                    </label>
                  </div>
                </div>
                <div className="filter-category__body">
                  <div className="form__field mb-2">
                    <label className="checkbox gap-10">
                      <Skeleton variant="rect" width={20} height={20} />
                      <Skeleton variant="rect" width={'30%'} height={15} />
                    </label>
                  </div>
                  <div className="form__field mb-2">
                    <label className="checkbox gap-10">
                      <Skeleton variant="rect" width={20} height={20} />
                      <Skeleton variant="rect" width={'70%'} height={15} />
                    </label>
                  </div>
                  <div className="form__field mb-2">
                    <label className="checkbox gap-10">
                      <Skeleton variant="rect" width={20} height={20} />
                      <Skeleton variant="rect" width={'60%'} height={15} />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <Skeleton width={'55%'} height={25} variant="rect" />
          </div>
          <div
            className="filter-block"
            style={{backgroundColor: '#DBDDE0', borderRadius: 4}}>
            <h3 className="filter-title mb-4">
              <Skeleton variant="rect" width={50} height={15} />
            </h3>
            <div className="form__field">
              <label className="checkbox gap-10">
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={'80%'} height={20} />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="filter-header">
        <h3 className="filter-title">{t('common:filters')}</h3>
        <button
          style={{letterSpacing: '0px'}}
          onClick={resetFilters}
          className="btn btn-filter">
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6.93358 7L1 1ZM7 1L1.06642 7L7 1Z" fill="#414D5F" />
            <path
              d="M7 1L1.06642 7M1 1L6.93358 7L1 1Z"
              stroke="#414D5F"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {t('common:clean')}
        </button>
      </div>
      {!brandsLayout &&
      searchStatus.loading === false &&
      searchStatus.catalog === true &&
      catalogTree.data &&
      catalogTree.data.menu_block ? (
        <div className="filter-block">
          {/* <Toggle
            toggleCheckbox={toggleCheckbox}
            data={catalogTree.data.menu_block}
            onFilter={true}
            authentication={authentication}
          /> */}

          {searchParams &&
            searchParams.product_group_id > 0 &&
            product_group_tree === undefined && (
              <CatalogFilters
                key={'CatalogFilters'}
                disabled={searchStatusLoading}
                catalogTree={catalogTree}
                searchParams={searchParams}
                searchStatus={searchStatus}
                setProduct_group_id={setProduct_group_id}
                search={isDesktop ? search : () => {}}
              />
            )}
        </div>
      ) : null}
      {!brandsLayout && brands && brands.length > 0 && (
        <div className="filter-block">
          <BrandList
            key={'BrandList'}
            brands={brands}
            product_groups_on_filter={product_group_tree}
            setBrandList={setBrandList}
            selectedBrands={selectedBrands}
            search={isDesktop ? search : () => {}}
            disabled={searchStatusLoading}
            widthoutSearchInput={brandsLayout}
          />
        </div>
      )}
      {searchRealAvailability.length > 0 && (
        <div className="filter-block">
          <Availability
            key={'Availability'}
            disabled={searchStatusLoading}
            searchRealAvailability={searchRealAvailability}
            setSearchAvailability={setSearchAvailability}
            searchAvailability={searchAvailability}
            search={isDesktop ? search : () => {}}
          />
        </div>
      )}
      {price_max && price_max > 0 ? (
        <div className="filter-block">
          <Range
            key={'Range'}
            disabled={searchStatusLoading}
            title={t('common:price')}
            price_min_current={price_min_current}
            price_max_current={price_max_current}
            currencyMainContr={currencyMainContr}
            min={
              price_min && currencyMainContr.koef
                ? Math.floor(price_min * currencyMainContr.koef)
                : 0
            }
            max={
              price_max && currencyMainContr.koef
                ? Math.ceil(price_max * currencyMainContr.koef)
                : 0
            }
            unit={renderCurrency()}
            setValue={setPriceRange}
            onChangeCommitted={isDesktop ? search : () => {}}
            k="PriceRange"
          />
        </div>
      ) : null}
    </>
  );
};

function mapState(state) {
  const {
    price_min: price_min_current,
    price_max: price_max_current,
    brands_clean_list: selectedBrands,
    searchAvailability,
  } = state.searchParams;
  const {
    brands,
    price_min,
    price_max,
    delivery_max,
    delivery_min,
    product_group_tree,
    finded_rows_count: resultsCount,
  } = state.panelData;

  const {sell_currency_id} = state.userSelectedData;
  const {searchStatus, searchParams, catalogTree} = state;

  return {
    brands,
    price_min,
    price_max,
    delivery_max,
    delivery_min,
    selectedBrands,
    resultsCount,
    sell_currency_id,
    searchAvailability,
    searchStatus,
    searchParams,
    catalogTree,
    price_min_current,
    price_max_current,
    product_group_tree,
  };
}

const actionCreators = {
  setPriceRange: searchActions.setPriceRange,
  setDeliveryRange: searchActions.setDeliveryRange,
  setProduct_group_id: searchActions.setProduct_group_id,
  setBrandList: searchActions.setBrandList,
  search: searchActions.search,
  setSearchAvailability: searchActions.setSearchAvailability,
};

export default connect(mapState, actionCreators)(Filters);
