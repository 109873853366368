import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function BasketDelete(props) {
  return (
    <SvgIcon {...props} width="28" height="28" viewBox="0 0 28 28">
      <g id="Page-1" stroke="none" fill="none" fillRule="evenodd">
        <g fill="#929292">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5639 9.78062H17.1524V9.17775C17.1524 8.15287 16.3687 7.36914 15.3438 7.36914H12.9323C11.9075 7.36914 11.1237 8.15287 11.1237 9.17775V9.78062H8.71225C8.35052 9.78062 8.10938 10.0218 8.10938 10.3835C8.10938 10.7452 8.35052 10.9864 8.71225 10.9864H9.31512V18.8237C9.31512 19.8486 10.0988 20.6323 11.1237 20.6323H17.1524C18.1773 20.6323 18.9611 19.8486 18.9611 18.8237V10.9864H19.5639C19.9256 10.9864 20.1668 10.7452 20.1668 10.3835C20.1668 10.0218 19.9256 9.78062 19.5639 9.78062ZM12.3281 9.17807C12.3281 8.81634 12.5693 8.5752 12.931 8.5752H15.3425C15.7042 8.5752 15.9453 8.81634 15.9453 9.17807V9.78094H12.3281V9.17807ZM17.155 19.4255C17.5167 19.4255 17.7579 19.1844 17.7579 18.8227V10.9854H10.5234V18.8227C10.5234 19.1844 10.7646 19.4255 11.1263 19.4255H17.155Z"
            fill="#315EFB"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
