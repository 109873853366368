import React from 'react';
import EmptyPage from '../../../images/EmptyPage.png';
// import material ui
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
// import i18n
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1246,
    margin: '0 auto',
    padding: '0 15px',
    overflowX: 'hidden',
  },

  body: {
    padding: '50px 0 30px 0',
    [theme.breakpoints.down(768)]: {
      padding: '30px 0 15px 0',
    },
  },

  title: {
    color: 'rgb(28, 42, 83)',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  card: {
    padding: '28px 32px 36px 28px',
  },

  back: {
    paddingBottom: '34px',
  },

  content: {
    paddingTop: '24px',
    paddingBottom: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    [theme.breakpoints.down(960)]: {
      gap: 15,
    },
  },

  table: {
    paddingTop: 16,
    width: '100%',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: 3,
      height: 3,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#ccc',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8E95A9',
      borderRadius: 10,
    },

    [theme.breakpoints.down(767)]: {
      paddingTop: 0,
    },
  },
}));

const Row = props => {
  const { data } = props;
  const { index, article, name, quantity } = data;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableBody>
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell sx={{ minWidth: 150, whiteSpace: "nowrap" }}>{article}</TableCell>
        <TableCell sx={{ minWidth: 500 }} style={{ color: 'rgb(28, 42, 83)' }}>
          {name}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{quantity} шт.</TableCell>
      </TableRow>
    </TableBody>
  );
};

function TableOfGoods(props) {
  const { data, currentPage, perPage } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const startIndex = (currentPage - 1) * perPage + 1;

  return (
    <div className={classes.table}>
      <Table className="dashboard-table" aria-label="full width table">
        <TableHead>
          <TableRow>
            <TableCell>{t("common:orderTableHeaderLabelNumber")}</TableCell>
            <TableCell>{t("common:article")}</TableCell>
            <TableCell>{t("common:title_label")}</TableCell>
            <TableCell>{t("common:quantity_label")}</TableCell>
          </TableRow>
        </TableHead>
        {data && data?.length > 0 ? (
          data?.map((item, index) => <Row data={{ ...item, index: startIndex + index }} key={`row-${index}`} />)
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={12} sx={{ textAlign: 'center' }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingTop: '30px',
                    paddingBottom: '30px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}>
                  <img src={EmptyPage} />
                  <p className="no__products-title">{t("common:not_find_products")}</p>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </div>
  );
}

export default TableOfGoods;
