import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '32px',

        [theme.breakpoints.down('sm')]: {
            padding: '16px'
        }
    },
    block: {
        display: 'flex',
        gap: '32px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    title: {
        color: 'rgb(85, 95, 126)',
        padding: '0 0 8px 0',
        borderBottom: '1px solid rgb(255, 190, 0);',
        margin: '0 0 8px 0',

        '& h4': {
            fontSize: '14px',
            fontWeight: 700,
        }
    },
    p: {
        color: 'rgb(85, 95, 126)',
    },
    discount: {
        fontSize: '16px',
        fontWeight: 700,
        color: 'rgb(255, 190, 0)'
    }
}));

const WishlistOffers = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={`dashboard-card ${classes.card}`}>
            <div className={classes.block}>
                <svg width="34.952148" height="34.971680" viewBox="0 0 34.9521 34.9717" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs />
                    <path id="Vector" d="M16.38 0.01L15.53 0.1C11.43 0.51 7.31 2.62 4.51 5.74C1.43 9.19 -0.14 13.47 0 18.03C0.26 25.91 5.67 32.58 13.35 34.48C15.61 35.02 17.96 35.11 20.26 34.75C22.55 34.38 24.75 33.56 26.73 32.33C30.62 29.91 33.4 26.06 34.48 21.6C34.86 20.07 34.95 19.29 34.95 17.48C34.95 15.66 34.86 14.88 34.48 13.35C32.75 6.27 26.92 1.07 19.67 0.12C19.05 0.04 16.79 -0.04 16.38 0.01ZM19.35 3.4C21.81 3.72 24.14 4.68 26.11 6.18C26.83 6.71 28.25 8.13 28.79 8.85C30.01 10.49 30.92 12.42 31.33 14.27C31.8 16.38 31.8 18.57 31.33 20.68C30.43 24.75 27.56 28.37 23.76 30.23C22.39 30.9 20.92 31.35 19.41 31.57C18.48 31.71 16.49 31.71 15.56 31.57C11.3 30.93 7.7 28.6 5.44 25.03C4.88 24.1 4.42 23.13 4.05 22.11C3.53 20.62 3.27 19.05 3.3 17.48C3.3 15.2 3.74 13.27 4.71 11.26C5.45 9.74 6.21 8.68 7.45 7.44C8.55 6.3 9.84 5.38 11.27 4.7C13.78 3.48 16.59 3.02 19.35 3.4ZM16.84 9.36C16.35 9.52 16.07 9.8 15.94 10.27C15.86 10.54 15.85 11.15 15.86 14.89C15.88 19.2 15.88 19.2 16.01 19.44C16.17 19.74 16.44 19.98 16.76 20.11C17.05 20.21 17.91 20.21 18.2 20.11C18.53 19.98 18.79 19.74 18.96 19.44C19.09 19.2 19.09 19.2 19.11 14.89C19.12 10.18 19.12 10.17 18.79 9.78C18.52 9.45 18.23 9.35 17.57 9.33C17.33 9.32 17.08 9.33 16.84 9.36ZM17.02 23.01C16.58 23.17 16.21 23.48 16 23.89C15.84 24.28 15.82 24.72 15.94 25.12C16.06 25.44 16.27 25.71 16.54 25.91C16.82 26.11 17.15 26.22 17.48 26.23C18.15 26.23 18.82 25.75 19.03 25.12C19.15 24.72 19.13 24.28 18.97 23.89C18.79 23.57 18.53 23.31 18.21 23.12C17.96 22.99 17.27 22.93 17.02 23.01Z" fill="#FFBE00" fill-opacity="1.000000" fill-rule="evenodd" />
                </svg>
                <article className="about-us-page__card-history">
                    <div className={classes.title}>
                        <h4>{t("common:looking_for_a_parts")}</h4>
                    </div>
                    <p className={classes.p}>
                        {t("common:no_problem_add_it")}
                    </p>
                </article>
                <article className="about-us-page__card-history">
                    <div className={classes.title}>
                        <h4>{t("common:we_offer_you_more_benefits")}</h4>
                    </div>
                    <p className={classes.p}>
                        {t("common:offer_in_wishlist")} <span className={classes.discount}>{t("common:with_discount_wishlist")} 3%</span>.
                    </p>
                </article>
            </div>
        </div>
    );
}

export default WishlistOffers;