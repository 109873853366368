import React from 'react'
// import mui
import { Skeleton } from '@material-ui/lab';

const DashboardSkeleton = (props) => {
    const { height } = props;
    return (
        <Skeleton
            width="100%"
            height={height || "250px"}
            variant="rectangular"
            animation="wave"
            style={{
                borderRadius: "12px",
            }}
        />
    )
}

export default DashboardSkeleton