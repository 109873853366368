// import {errorMessage, userConstants} from '../_constants';
import {userConstants} from '../_constants';
import {userService} from '../_services';
import {alertActions} from './';
import {history} from '../_helpers';
// import {errorMessage} from '../_constants/errorMessage.constants';
import {progressActions} from './progress.actions';
import config from './../_services/config';

export const userActions = {
  login,
  logout,
  register,
  // getCustomerData,
  getUserBalance,
  getContragentData,
  getFrontendData,
  getFullPaymentCalendar,
  getPaymentCalendarByOrganization,
  getPersonalManager,
  getManagerById,
  getPersonalManagers,
  setCurrencyId,
  loginSocial,
  getMailConfirm,
  createCustomer,
  openOrdersList,
  forgotPW,
  confirmPW,
  sendEmailConfirmation,
  resendEmailConfirmation,
  getStatisticsOrder,
  getBonusBalance,
  getTurnoverStatistics,
  getPromoCodes,
  getStatisticsOrderByOrganisation,
  getMostFrequentlyOrderedItems,
  getChartOrderPrices,
  getMoneyBackSummaryList,
  getIntegrationByType,
  getWarehouseman,
  getStatisticsOrderWarehouseman,
  getWarehousemanUser,
  getWarehousemanUserGoods,
  getOrderCreateInfo,
};

function openOrdersList(value) {
  return dispatch => {
    dispatch(setVal(value));
  };

  function setVal(value) {
    return {type: userConstants.OPEN_ORDERS_LIST, value};
  }
}

function setCurrencyId(currencyId) {
  return dispatch => {
    dispatch(setId(currencyId));
  };

  function setId(currencyId) {
    return {type: userConstants.SET_CURRENCY_ID, currencyId};
  }
}

function login(username, password, captcha, phoneLogin) {
  if (phoneLogin) {
    const phone = username.replace(/\s/g, '');
    return dispatch => {
      dispatch(request({ phone }));

      userService.login(phone, password, captcha, phoneLogin).then(
        data => {
          dispatch(success(data));
          history.push('/');
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  } else {
    return dispatch => {
      dispatch(request({ username }));

      userService.login(username, password, captcha, phoneLogin).then(
        data => {
          dispatch(success(data));
          history.push('/');
          dispatch(getFrontendData());
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function sendEmailConfirmation(email) {
  return dispatch => {
    dispatch(request());

    userService.sendEmailConfirmation(email).then(
      data => {
        dispatch(success(data));
        history.push('/sign-ta');
        dispatch(getFrontendData()); // ??????????????????????
      },

      error => {
        // dispatch(failure(error.toString()));
        dispatch(failure(error));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.REGISTER_REQUEST};
  }

  function success(data) {
    return {type: userConstants.SEND_EMAIL_COMFIRMATION, data};
  }

  function failure(error) {
    return {type: userConstants.REGISTER_FAILURE, error};
  }
}

function resendEmailConfirmation(email) {
  return dispatch => {
    dispatch(request());

    userService.sendEmailConfirmation(email).then(
      data => {
        dispatch(success(data));
        history.push('/sign-te');
        dispatch(getFrontendData()); // ??????????????????????
      },

      error => {
        // dispatch(failure(error.toString()));
        dispatch(failure(error));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.REGISTER_REQUEST};
  }

  function success(data) {
    return {type: userConstants.SEND_EMAIL_COMFIRMATION, data};
  }

  function failure(error) {
    return {type: userConstants.REGISTER_FAILURE, error};
  }
}

function loginSocial(access_token, url, username) {
  return dispatch => {
    dispatch(request({username}));

    userService.loginSocial(access_token, url).then(
      user => {
        dispatch(alertActions.info(`Welcome ${user.username}!`));
        dispatch(success({...user, username}));
        history.push('/');
        dispatch(getFrontendData());
      },
      () => {
        dispatch(failure({username}, {access_token}));
      },
    );
  };

  function request(user) {
    return {type: userConstants.LOGIN_REQUEST, user};
  }

  function success(data) {
    return {type: userConstants.LOGIN_SUCCESS, data};
  }

  function failure(user, password) {
    return {type: userConstants.LOGIN_FAILURE, user, password};
  }
}

function getFrontendData() {
  return dispatch => {
    dispatch(request());
    dispatch(progressActions.add('getFrontendData'));

    userService
      .getFrontendData()
      .then(
        data => {
          // dispatch(setCustomer(data));
          dispatch(success(data));
          // dispatch(progressActions.done('getFrontendData'))
          if (!data.data) {
            history.push('/sign-out');
          } else {
            if (data.data.user_perms.expect_manager_response && data.data.user_perms.expect_manager_response === false) {
              dispatch({type: userConstants.LOGOUT});
              dispatch({type: userConstants.DESTROY_SESSION});
              history.push('/sign-in');
              dispatch(expect_manager_response(data.data.user));
            }
            // якщо email не підтвердженно (вже вхід тільки з підтвердженним)
            // else if (data.data.user_perms.need_to_create_customer) {
            //   dispatch(expect_manager_response(data.data.user));
            //   history.push('/accounts/confirm-email');
            // }
          }
        },
        error => dispatch(failure(error.toString())),
      )
      .then(() => {
        dispatch(progressActions.done('getFrontendData'));
      });
  };

  function expect_manager_response(user) {
    return {type: userConstants.EXPECT_MANAGER_RESPONSE, user};
  }

  function request() {
    return {type: userConstants.FRONTEND_DATA_REQUEST};
  }

  function success(data) {
    return {type: userConstants.FRONTEND_DATA_SUCCESS, data};
  }

  // function setCustomer(data) {
  //   return {type: userConstants.SET_CUSTOMER_ID, data: {...data.data.customer_default}};
  // }

  function failure(error) {
    return {type: userConstants.FRONTEND_DATA_FAILURE, error};
  }
}

function getFullPaymentCalendar(customerId) {
  return dispatch => {
    dispatch(request());
    userService.getFullPaymentCalendar(customerId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return {type: userConstants.FULL_PAYMENT_CALENDAR_REQUEST};
  }

  function success(data) {
    return {type: userConstants.FULL_PAYMENT_CALENDAR_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.FULL_PAYMENT_CALENDAR_FAILURE, error};
  }
}

function getPaymentCalendarByOrganization(customerId) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getPaymentCalendarByOrganization(customerId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_REQUEST};
  }

  function success(data) {
    return {type: userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_FAILURE,
      error,
    };
  }
}

function getPersonalManager(customerId) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getPersonalManager(customerId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.PERSONAL_MANAGER_REQUEST};
  }

  function success(data) {
    return {type: userConstants.PERSONAL_MANAGER_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.PERSONAL_MANAGER_FAILURE,
      error,
    };
  }
}

function getManagerById(managerId) {
  if (managerId) {
    return dispatch => {
      dispatch(request());
      userService.getManagerById(managerId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.PERSONAL_MANAGER_BY_ID_REQUEST};
  }

  function success(data) {
    return {type: userConstants.PERSONAL_MANAGER_BY_ID_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.PERSONAL_MANAGER_BY_ID_FAILURE,
      error,
    };
  }
}

function getPersonalManagers(customerId) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getPersonalManagers(customerId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.PERSONAL_MANAGERS_REQUEST};
  }

  function success(data) {
    return {type: userConstants.PERSONAL_MANAGERS_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.PERSONAL_MANAGERS_FAILURE,
      error,
    };
  }
}

function getStatisticsOrder(customerId, dateFrom, dateUntil, manager) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService
        .getStatisticsOrder(customerId, dateFrom, dateUntil, manager)
        .then(
          data => {
            dispatch(success(data));
          },
          error => {
            dispatch(failure(error));
          },
        );
    };
  }

  function request() {
    return {type: userConstants.STATISTICS_ORDER_REQUEST};
  }

  function success(data) {
    return {type: userConstants.STATISTICS_ORDER_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.STATISTICS_ORDER_FAILURE,
      error,
    };
  }
}

function getStatisticsOrderByOrganisation(customerId, dateFrom, dateUntil) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService
        .getStatisticsOrderByOrganisation(customerId, dateFrom, dateUntil)
        .then(
          data => {
            dispatch(success(data));
          },
          error => {
            dispatch(failure(error));
          },
        );
    };
  }

  function request() {
    return {type: userConstants.STATISTICS_ORDER_ORGANISATION_REQUEST};
  }

  function success(data) {
    return {type: userConstants.STATISTICS_ORDER_ORGANISATION_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.STATISTICS_ORDER_ORGANISATION_FAILURE,
      error,
    };
  }
}

function getChartOrderPrices(customerId, dateFrom, dateUntil) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getChartOrderPrices(customerId, dateFrom, dateUntil).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.CHART_ORDER_PRICE_REQUEST};
  }

  function success(data) {
    return {type: userConstants.CHART_ORDER_PRICE_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.CHART_ORDER_PRICE_FAILURE,
      error,
    };
  }
}

function getMostFrequentlyOrderedItems(customerId, dateFrom, dateUntil) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService
        .getMostFrequentlyOrderedItems(customerId, dateFrom, dateUntil)
        .then(
          data => {
            dispatch(success(data));
          },
          error => {
            dispatch(failure(error));
          },
        );
    };
  }

  function request() {
    return {type: userConstants.MORE_FREQUENTLY_ORDERED_ITEMS_REQUEST};
  }

  function success(data) {
    return {type: userConstants.MORE_FREQUENTLY_ORDERED_ITEMS_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.MORE_FREQUENTLY_ORDERED_ITEMS_FAILURE,
      error,
    };
  }
}

function getIntegrationByType(type, customer_code) {
  if (customer_code && type) {
    return dispatch => {
      dispatch(request());
      userService.getIntegrationByType(type, customer_code).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.INTEGRATION_BY_TYPE_REQUEST};
  }

  function success(data) {
    return {type: userConstants.INTEGRATION_BY_TYPE_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.INTEGRATION_BY_TYPE_FAILURE,
      error,
    };
  }
}

function getBonusBalance(customerId, dateFrom, dateUntil) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getBonusBalance(customerId, dateFrom, dateUntil).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.BONUS_BALANCE_REQUEST};
  }

  function success(data) {
    return {type: userConstants.BONUS_BALANCE_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.BONUS_BALANCE_FAILURE,
      error,
    };
  }
}

function getTurnoverStatistics(customerId, dateFrom, dateUntil) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getTurnoverStatistics(customerId, dateFrom, dateUntil).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.TURNOVER_STATISTICS_REQUEST};
  }

  function success(data) {
    return {type: userConstants.TURNOVER_STATISTICS_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.TURNOVER_STATISTICS_FAILURE,
      error,
    };
  }
}

function getStatisticsOrderWarehouseman(
  customerId,
  filter_start_date,
  filter_end_date,
) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService
        .getStatisticsOrderWarehouseman(
          customerId,
          filter_start_date,
          filter_end_date,
        )
        .then(
          data => {
            dispatch(success(data));
          },
          error => {
            dispatch(failure(error));
          },
        );
    };
  }

  function request() {
    return {type: userConstants.WAREHOUSEMAN_STATISTICS_REQUEST};
  }

  function success(data) {
    return {type: userConstants.WAREHOUSEMAN_STATISTICS_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.WAREHOUSEMAN_STATISTICS_FAILURE,
      error,
    };
  }
}

function getWarehousemanUser(customerId) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getWarehouseUser(customerId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.WAREHOUSEMAN_USER_REQUEST};
  }

  function success(data) {
    return {type: userConstants.WAREHOUSEMAN_USER_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.WAREHOUSEMAN_USER_FAILURE,
      error,
    };
  }
}

function getWarehousemanUserGoods(warehouseId, filter, perPage, page) {
  if (warehouseId) {
    return dispatch => {
      dispatch(request());
      userService
        .getWarehouseUserGoods(warehouseId, filter, perPage, page)
        .then(
          data => {
            dispatch(success(data));
          },
          error => {
            dispatch(failure(error));
          },
        );
    };
  }

  function request() {
    return {type: userConstants.WAREHOUSEMAN_USER_GOODS_REQUEST};
  }

  function success(data) {
    return {type: userConstants.WAREHOUSEMAN_USER_GOODS_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.WAREHOUSEMAN_USER_GOODS_FAILURE,
      error,
    };
  }
}

function getPromoCodes(customerId) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getPromoCodes(customerId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.PROMO_CODES_REQUEST};
  }

  function success(data) {
    return {type: userConstants.PROMO_CODES_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.PROMO_CODES_FAILURE,
      error,
    };
  }
}

function getMoneyBackSummaryList(customerId) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getMoneyBackSummaryList(customerId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.MONEY_BACK_LIST_REQUEST};
  }

  function success(data) {
    return {type: userConstants.MONEY_BACK_LIST_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.MONEY_BACK_LIST_FAILURE,
      error,
    };
  }
}

function getWarehouseman(customerId, filterDate) {
  if (customerId) {
    return dispatch => {
      dispatch(request());
      userService.getWarehouseman(customerId, filterDate).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.WAREHOUSEMAN_REQUEST};
  }

  function success(data) {
    return {type: userConstants.WAREHOUSEMAN_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.WAREHOUSEMAN_FAILURE,
      error,
    };
  }
}

function getUserBalance(customerId) {
  return dispatch => {
    dispatch(request());
    // dispatch(progressActions.add('getUserBalance'));

    userService
      .getUserBalance(customerId)
      .then(
        data => {
          // dispatch(setCustomer(data));
          dispatch(success(data));
          // dispatch(progressActions.done('getFrontendData'))
        },
        error => dispatch(failure(error.toString())),
      )
      .then(() => {
        // dispatch(progressActions.done('getUserBalance'))
      });
  };

  function request() {
    return {type: userConstants.USER_BALANCE_REQUEST};
  }

  function success(data) {
    return {type: userConstants.USER_BALANCE_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.USER_BALANCE_FAILURE, error};
  }
}

function getContragentData(customerId, main_currency) {
  if (customerId === '') {
    return dispatch => {
      dispatch(
        success({
          customer_data: {
            unregUser: true,
            credit_limit_currency_id:
              main_currency && main_currency.id
                ? main_currency.id
                : parseInt(config.currencySiteMainId),
          },
        }),
      );
    };
  } else {
    return dispatch => {
      dispatch(request());
      // dispatch(progressActions.add('getUserBalance'));

      userService
        .getContragentData(customerId)
        .then(
          data => {
            // dispatch(setCustomer(data));
            dispatch(success(data));
            // dispatch(progressActions.done('getFrontendData'))
          },
          error => dispatch(failure(error.toString())),
        )
        .then(() => {
          // dispatch(progressActions.done('getUserBalance'))
        });
    };
  }

  function request() {
    return {type: userConstants.CONTRAGENT_DATA_REQUEST};
  }

  function success(data) {
    return {type: userConstants.CONTRAGENT_DATA_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.CONTRAGENT_DATA_FAILURE, error};
  }
}

/*
function getCustomerData() {
  return dispatch => {
    dispatch(request());

    userService.getCustomerData().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: userConstants.PROFILE_REQUEST};
  }

  function success(data) {
    return {type: userConstants.PROFILE_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GETALL_FAILURE, error};
  }
}
*/

function logout() {
  userService.logout();
  return dispatch => {
    dispatch({type: userConstants.LOGOUT});
    dispatch({type: userConstants.DESTROY_SESSION});
    dispatch(getFrontendData());
  };
}

function register(modernizedTel, email, password, captcha) {
  return dispatch => {
    dispatch(request());

    userService.register(modernizedTel, email, password, captcha).then(
      data => {
        dispatch(success(data));
        history.push('/sign-ta');
        dispatch(getFrontendData()); // ??????????????????????
      },

      error => {
        // dispatch(failure(error.toString()));
        dispatch(failure(error));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.REGISTER_REQUEST};
  }

  function success(data) {
    return {type: userConstants.REGISTER_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.REGISTER_FAILURE, error};
  }
}

function forgotPW(email, captcha) {
  return dispatch => {
    dispatch(request());

    userService.forgotPW(email, captcha).then(
      data => {
        dispatch(success(data));
        history.push('/sign-ta');
        // dispatch(getFrontendData()); ????????
      },

      error => {
        // dispatch(failure(error.toString()));
        dispatch(failure(error));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.FORGOT_PW_REQUEST};
  }

  function success(data) {
    return {type: userConstants.FORGOT_PW_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.FORGOT_PW_FAILURE, error};
  }
}

function confirmPW(pw, match) {
  return dispatch => {
    dispatch(request());

    userService.confirmPW(pw, match).then(
      data => {
        dispatch(success(data));
        history.push('/sign-ta');
        // dispatch(getFrontendData()); ????????
      },

      error => {
        // dispatch(failure(error.toString()));
        dispatch(failure(error));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.CONFIRM_PW_REQUEST};
  }

  function success(data) {
    return {type: userConstants.CONFIRM_PW_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.CONFIRM_PW_FAILURE, error};
  }
}

function getMailConfirm(key) {
  return dispatch => {
    // dispatch(request({key}));
    dispatch(request());

    userService.getMailConfirm(key).then(
      data => {
        dispatch(success(data));
      },
      error => {
        // dispatch(failure(error.toString()));
        dispatch(failure(error));
        // dispatch(alertActions.error("The url is unavailable"));
      },
    );
  };

  function request() {
    return {type: userConstants.GET_MAIL_CONFIRM_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_MAIL_CONFIRM_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GET_MAIL_CONFIRM_FAILURE, error};
  }
}

function createCustomer(customerRaw) {
  let customer = customerRaw;
  customer.already_work = customerRaw.already_work === 'y';
  return dispatch => {
    // dispatch(request());

    userService.createCustomer(customer).then(
      data => {
        dispatch(success(data, customer.already_work));
        history.push('/');
        dispatch(getFrontendData());
      },
      error => {
        // dispatch(failure(error.toString()));
        dispatch(failure(error));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  /*
      function request() {
        return {type: userConstants.CREATE_CUSTOMER_REQUEST};
      }
    */

  function success(data, already_work) {
    return {type: userConstants.CREATE_CUSTOMER_SUCCESS, data, already_work};
  }

  function failure(error) {
    return {type: userConstants.CREATE_CUSTOMER_FAILURE, error};
  }
}

function getOrderCreateInfo(customerId, orderId) {
  if ((customerId, orderId)) {
    return dispatch => {
      dispatch(request());
      userService.getOrderCreateInfo(customerId, orderId).then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        },
      );
    };
  }

  function request() {
    return {type: userConstants.GET_ORDER_CREATE_INFO_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_ORDER_CREATE_INFO_SUCCESS, data};
  }

  function failure(error) {
    return {
      type: userConstants.GET_ORDER_CREATE_INFO_FAILURE,
      error,
    };
  }
}
