import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Divider, IconButton} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import NavigateNext from '@material-ui/icons/NavigateNext';
// import {Link as RouterLink, NavLink} from "react-router-dom";
import {Link as RouterLink} from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
//import Lens from "@material-ui/icons/Lens"; // -->RadioButtonChecked
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
//import DeleteBasket from "@material-ui/icons/DeleteForever";
import Edit from '@material-ui/icons/Edit';
import MainBasket from '@material-ui/icons/Stars';
//import Save from "@material-ui/icons/Save";

import {connect} from 'react-redux';
import {cartActions} from '../../../../_actions';
import PriceToStr from '../../../../_components/PriceToStr';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import BasketDelete from '../../../../icons/BasketDelete';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 5,
    border: '1px solid white',
    backgroundColor: '#f7f7f7', //'#b0bec5',
    height: '100%',
    color: '#829299', //'white',
    padding: '15px 5px 5px 5px',
    /*display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),*/
  },

  divider: {
    margin: theme.spacing(1, 1),
  },

  gridBlock: {
    //padding: 0,
    justifyContent: 'space-between', //'flex-end', //'space-around',//'space-between',// 'center',
    display: 'flex',
    alignItems: 'center',
    //align: 'center',
    //backgroundColor: 'white',
  },

  mainCard: {
    fontWeight: 'bold',
    marginTop: -10,
    paddingTop: 6,
    color: '#556878', //'#829299',
    // backgroundColor: '#f9c533', //'white',
    borderRadius: 3,
    boxShadow: '0 2px 3px gray',
  },

  inputCard: {
    background: 'none',
    border: '1px solid',
    borderRadius: 2,
    height: 20,
    color: '#2a4256', //'#829299',//'inherit',
    padding: '0 2px',
  },

  otherCard: {
    color: '#2a4256', //'#829299',//'white',
  },

  buttonNewCart: {
    // color: "white",
    // backgroundColor: "#556878",//"#215c79",//"#808e95",
    textAlign: 'center',
    padding: 5,
    '& .btn': {
      width: '100%',
    },
  },
}));

let editNameBasket = 0;
let editBasket = -1;
let this_props;

const SidebarBasket = props => {
  const {
    handlePopoverClose,
    cart,
    addCart,
    //currencies,
    //currentCurrencyId,
    className,
    mainCartId,
    setMainCartId,
    setSelectedCarts,
    updateCustomerCart,
    addCustomerCart,
    deleteCustomerCart,
    authentication,
    getCart,
  } = props;

  const {list_customer_cart} = cart;
  const {customer_cart_id} = addCart;

  if (customer_cart_id !== undefined && editBasket === -10)
    editBasket = customer_cart_id;

  const classes = useStyles();

  //const [editBasket, setEditBasket] = React.useState(-1);
  const [flag, setFlag] = React.useState(1);
  const [nameBasket, setNameBasket] = React.useState('');

  const changeMainBasket = id => event => {
    setMainCartId(id);
    handlePopoverClose();
    getCart();
  };

  const showSelectBasket = id => event => {
    let carts = [];
    carts[0] = id;
    setSelectedCarts(carts);
    handlePopoverClose();
    getCart();
  };
  const editBasketSet = (id, name) => event => {
    //editBasket === id ? setEditBasket(-1) : setEditBasket(id);
    if (editBasket === id) {
      editBasket = -1;
      setNameBasket(nameBasket);
    } else {
      editBasket = id;
      setNameBasket(name);
    }
    /*editBasket === id ? editBasket = -1 : editBasket = id;
        setNameBasket(name);*/
    setFlag(flag + 1);
  };
  const onKeyUp = (id, name) => e => {
    if (e.keyCode === 13) {
      editBasket === id ? (editBasket = -1) : (editBasket = id);
      setFlag(flag + 1);
      updateCustomerCart(id, nameBasket);
    }
  };
  const changeNameBasket = id => event => {
    editNameBasket += 1;
    setNameBasket(event.target.value);
    //updateCustomerCart(id, nameBasket);
  };
  /*const saveNameBasket = (id) => event => {
        updateCustomerCart(id, nameBasket);
    };*/
  const deleteBasket = id => event => {
    if (mainCartId === id) setMainCartId(0);
    if (editBasket === id) {
      editBasket = -1;
    }
    deleteCustomerCart(id);
  };
  const addNewBasket = () => event => {
    //setEditBasket(-1);
    let name_date = t('common:cart') + ' ' + moment().format('HH:mm DD.MM.YY');
    editBasket = -10;
    setNameBasket(name_date);
    addCustomerCart(name_date);
  };

  const {t} = useTranslation();
  return (
    <div className={clsx(classes.root, className)} id={`${'basketWindow'}`}>
      {list_customer_cart &&
        list_customer_cart.map((item, index) => (
          <div
            key={index}
            className={
              item.id === mainCartId ? classes.mainCard : classes.otherCard
            }>
            <Grid container item xs={12} align="left">
              <Grid
                item
                xs={9}
                align="left"
                style={{display: 'flex', alignItems: 'center'}}>
                {item.id > 0 ? (
                  <Edit
                    style={{marginLeft: 10, marginRight: 4, cursor: 'pointer'}}
                    onClick={editBasketSet(item.id, item.name)}
                  />
                ) : (
                  <MainBasket style={{marginLeft: 12, marginRight: 4}} />
                )}
                {editBasket === item.id ? (
                  <input
                    type="text"
                    className={classes.inputCard}
                    id={`${'inputCart'}${item.id}`}
                    data_id_cart={item.id}
                    onChange={changeNameBasket(item.id)}
                    onKeyUp={onKeyUp(item.id, item.name)}
                    value={nameBasket}
                  />
                ) : (
                  <span>{item.name}</span>
                )}
              </Grid>
              {editBasket === item.id && (
                <Grid
                  item
                  xs={3}
                  align="right"
                  /*style={{display: "flex", alignItems: "center", justifyContent: 'space-between',}}*/
                >
                  {/*{nameBasket !== item.name
                                ? <Save onClick={saveNameBasket(item.id)} style={{cursor: "pointer"}}/>
                                : <span/>
                            }*/}
                  <BasketDelete
                    onClick={deleteBasket(item.id)}
                    style={{cursor: 'pointer', marginRight: 10}}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} className={classes.gridBlock}>
              <Grid item xs={2} align="center">
                <Radio
                  style={{color: item.id === mainCartId && '#556878'}}
                  icon={<RadioButtonUnchecked />}
                  //icon={<Adjust/>}
                  checkedIcon={<RadioButtonChecked />}
                  //color={'secondary'}
                  checked={item.id === mainCartId}
                  onChange={changeMainBasket(item.id)}
                  value={item.id}
                />
              </Grid>
              <RouterLink
                style={{display: 'contents', textDecoration: 'none'}}
                to="/cart"
                onClick={showSelectBasket(item.id)}>
                <Grid
                  item
                  xs={3}
                  align="left"
                  style={{color: '#777777', fontWeight: 'normal'}}>
                  <span>{t('common:goods')}</span>
                </Grid>
                <Grid item xs={1} align="center" style={{color: 'black'}}>
                  <span>{item.quantity}</span>
                </Grid>
                <Grid item xs={4} align="right">
                  <span
                    style={{
                      color: item.id === mainCartId ? '#556878' : 'black',
                    }}>
                    {
                      <PriceToStr
                        data={item.cost.sum}
                        beforeText={''}
                        text={
                          item.cost.currency.simbol
                            ? item.cost.currency.simbol
                            : ''
                        }
                        grn={false}
                      />
                    }
                  </span>
                </Grid>
                <Grid item xs={2} align="center">
                  <IconButton
                    style={{color: item.id === mainCartId && '#556878'}}>
                    <NavigateNext />
                  </IconButton>
                </Grid>
              </RouterLink>
            </Grid>
            <Divider
              className={classes.divider}
              style={{width: item.id === mainCartId && 0}}
            />
          </div>
        ))}
      {authentication.loggedIn !== undefined && (
        <div className={classes.buttonNewCart}>
          <button className="btn" onClick={addNewBasket()}>
            {t('common:add_new_cart')}
          </button>
        </div>
      )}
    </div>
  );
};

function mapState(state) {
  const {
    frontendData,
    userSelectedData,
    cart,
    addCart,
    authentication,
    getCart,
  } = state;
  const {currencies} = frontendData;
  const {
    sell_currency_id: currentCurrencyId,
    customer_cart_id: mainCartId,
    customer_id: customerId,
  } = userSelectedData;
  return {
    currencies,
    currentCurrencyId,
    cart,
    addCart,
    mainCartId,
    customerId,
    authentication,
    getCart,
  };
}

const actionCreators = {
  getCart: cartActions.getCart,
  setMainCartId: cartActions.setMainCartId,
  setSelectedCarts: cartActions.setSelectedCarts,
  updateCustomerCart: cartActions.updateCustomerCart,
  addCustomerCart: cartActions.addCustomerCart,
  deleteCustomerCart: cartActions.deleteCustomerCart,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this_props = this.props;
    let el_basketWindow = document.getElementById('basketWindow');
    el_basketWindow.addEventListener(
      'click',
      this.handleClickOutsideEditCart,
      false,
    );

    /*if (this.props.customerId && this.props.currentCurrencyId) {
            this.props.getCart();
        }*/
  }

  componentWillUnmount() {
    let el_basketWindow = document.getElementById('basketWindow');
    el_basketWindow.removeEventListener(
      'click',
      this.handleClickOutsideEditCart,
      false,
    );
  }

  handleClickOutsideEditCart(e) {
    if (e.target.id.indexOf('inputCart') < 0 && editNameBasket > 0) {
      let el_basketWindow = document.getElementById('basketWindow');
      //let inputs = el_basketWindow.getElementsByTagName('input');
      let inputs = el_basketWindow.querySelectorAll('input[data_id_cart]');
      if (inputs.length > 0) {
        for (let input of inputs) {
          this_props.updateCustomerCart(
            input.attributes.data_id_cart.value,
            input.value,
          );
          editNameBasket = 0;
          editBasket = -1;
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customerId !== prevProps.customerId ||
      this.props.currentCurrencyId !== prevProps.currentCurrencyId
    ) {
      // this.props.resetSearchParams();
      // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
      // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
      this.props.getCart();
    }
  }

  render() {
    return <SidebarBasket {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
