import React from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loader: {

  },
}));

const ThreeDotsLoader = (props) => {
  const { width, height, bg, gap } = props;
  const classes = useStyles();

  return (
    <div className="dots-loader" style={{gap: gap}}>
      <span style={{backgroundColor: bg, width: width, height: height}}></span>
      <span style={{backgroundColor: bg, width: width, height: height}}></span>
      <span style={{backgroundColor: bg, width: width, height: height}}></span>
    </div>
  )
}

export default ThreeDotsLoader