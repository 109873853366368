import React from 'react';
import { withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';
import {SignInLogo} from '../../_components/Authorization/SignInLogo';
import SignTeSvg from './components/SignTeSvg';

const SignTe = () => {
  const {t} = useTranslation();
  
  return (
    <div className="user-main__wrapper">
      <div className="user-main__inner">
        <SignInLogo />
        <div className="user-main__right">
          <div className="user-main__right-form user-form pb-8">
            <h2 className="user-form__title mb-4">
              {t('common:request_thanks')}
            </h2>
            <div className="user-form__flex">
              <div className="user-form__icon">
                <SignTeSvg/>
              </div>
              <p className="user-form__description ">
                {t('common:we_have_sent_you')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (withRouter(SignTe));
