import React from 'react';
import aboutCompany from '../icons/slide2.gif';
// import mui
import { Box, makeStyles } from '@material-ui/core';
// import components
import InstructionCardWrapper from './InstructionCardWrapper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    info: {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        padding: "70px 0",
        [theme.breakpoints.down(992)]: {
            padding: 0,
            order: 2,
        },
    },
    text_wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 27,
        [theme.breakpoints.down(476)]: {
            gap: 16,
        },
    },
}));

const AboutCompany = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <InstructionCardWrapper title={t("common:info_about_company")} id="about">
            <Box className={`dashboard-text ${classes.info}`}>
                <svg style={{ minWidth: 32, minHeight: 32 }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <clipPath id="clip1818_11763">
                            <rect id="Frame" width="19.478260" height="19.478260" transform="translate(6.260742 5.488525)" fill="white" fill-opacity="0" />
                        </clipPath>
                    </defs>
                    <circle id="Ellipse 24" cx="16.000000" cy="15.923340" r="16.000000" fill="#FFBE00" fill-opacity="1.000000" />
                    <rect id="Frame" width="19.478260" height="19.478260" transform="translate(6.260742 5.488525)" fill="#FFFFFF" fill-opacity="0" />
                    <g clip-path="url(#clip1818_11763)">
                        <path id="Vector" d="M8.69 22.53L23.3 22.53M11.13 20.09L11.13 13.6M14.37 20.09L14.37 13.6M17.62 20.09L17.62 13.6M20.86 20.09L20.86 13.6M16.34 7.32C16.21 7.24 16.15 7.2 16.08 7.19C16.03 7.18 15.96 7.18 15.9 7.19C15.84 7.2 15.78 7.24 15.65 7.32L9.5 11.16L22.49 11.16L16.34 7.32Z" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="1.043478" stroke-linejoin="round" />
                    </g>
                </svg>
                <Box className={classes.text_wrapper}>
                    <h2 style={{ color: "#555F7E", fontSize: "14px" }}>{t("common:about_company_instruction_title")}</h2>
                    <p>{t("common:about_company_instruction_par_one")}</p>
                    <p>{t("common:list_of_managers")}</p>
                    <p>{t("common:table_of_orders_too")}</p>
                </Box>
            </Box>
            <img src={aboutCompany} alt="dashboard" style={{
                maxWidth: "100%",
                objectFit: 'contain',
                objectFit: "center",
                margin: "0 auto",
                borderRadius: "14px",
                filter: "drop-shadow(0px 12px 32px 0px rgba(51, 38, 174, 0.08))",
            }} />
        </InstructionCardWrapper>
    )
}

export default AboutCompany;