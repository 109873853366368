import React from "react";


function Phones(props) {
    const {phones} = props;
    return (
        <ul style={{padding: '0', display: 'flex', flexDirection: 'column', gap: '9px'}}>
            {phones.map(phone => (
                <li key={phone} style={{display: 'flex', gap: '9px', alignItems: 'center'}}>
                    <svg width="10.000000" height="10.000000" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <defs />
                        <path id="Vector" d="M2.01 4.33C2.81 5.9 4.09 7.18 5.67 7.98L6.89 6.76C7.05 6.61 7.26 6.56 7.46 6.62C8.08 6.83 8.75 6.94 9.44 6.94C9.75 6.94 10 7.19 10 7.5L10 9.44C10 9.75 9.75 10 9.44 10C4.22 10 0 5.77 0 0.55C0 0.24 0.25 0 0.55 0L2.5 0C2.8 0 3.05 0.24 3.05 0.55C3.05 1.24 3.16 1.91 3.37 2.53C3.43 2.73 3.38 2.95 3.23 3.1L2.01 4.33Z" fill="#FFA000" fillOpacity="1.000000" fillRule="evenodd" />
                    </svg>
                    <a href={`tel:${phone}`}>
                        {phone}
                    </a>
                </li>
            ))}
        </ul>
    );
}

export default Phones;