import React from 'react';
// import mui
import { Box, makeStyles } from '@material-ui/core';
// import icons
import image1 from "../icons/first.png";
import image2 from "../icons/second.png";
import image3 from "../icons/third.png";
// import components
import InstructionCardWrapper from './InstructionCardWrapper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    info: {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        padding: "70px 0",
        "& svg": {
            minWidth: "18px",
            minHeight: "29px",
        },
        [theme.breakpoints.down(992)]: {
            padding: 0,
            order: 2,
        },
    },
    
    text_wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 32,
        [theme.breakpoints.down(476)]: {
            gap: 16,
        },
    },

    images: {
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",

        [theme.breakpoints.down(992)]: {
            paddingBottom: "50%",
            order: 1,
        },

        "& img": {
            position: "absolute",
            top: "100%",
            opacity: 0,
            transition: "top 1s ease-in-out, opacity 1s ease-in-out",
        },

        "&.scroll-effect img:first-child": {
            top: "75px",
            right: 0,
            zIndex: 3,
            opacity: 1,
            transitionDelay: "0.5s",
        },

        "&.scroll-effect img:nth-child(2)": {
            top: "42px",
            left: "50%",
            zIndex: 2,
            transform: "translate(-50%)",
            transitionDelay: "1s",
            opacity: 1,
        },

        "&.scroll-effect img:last-child": {
            top: 0,
            left: 0,
            zIndex: 1,
            opacity: 1,
            transitionDelay: "1.5s",
        },
    },
}));

const TopCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <InstructionCardWrapper>
            <Box className={`dashboard-text ${classes.info}`}>
                <svg width="18" height="29" viewBox="0 0 18 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6512 20.77H6.76117C5.97117 20.77 5.32117 21.41 5.33117 22.2V26.09C5.33117 26.88 5.97117 27.52 6.76117 27.52H7.00917C7.17776 27.8206 7.42338 28.0708 7.72076 28.245C8.01814 28.4191 8.35654 28.5109 8.70117 28.5109C9.04579 28.5109 9.3842 28.4191 9.68158 28.245C9.97896 28.0708 10.2246 27.8206 10.3932 27.52H10.6512C11.4412 27.52 12.0812 26.88 12.0812 26.09V22.2C12.0812 21.41 11.4412 20.77 10.6512 20.77Z" fill="#9B9B9B" />
                    <path d="M11.1611 21.63C11.4011 21.63 11.5911 21.45 11.6111 21.22C11.6811 20.36 12.0511 18.27 14.0711 16.03C15.0349 15.2711 15.8266 14.3161 16.3938 13.2283C16.9609 12.1405 17.2906 10.9447 17.3611 9.72C17.3811 9.48 17.3911 9.32 17.3911 9.22V9.12C17.3311 4.34 13.4611 0.5 8.69111 0.5C6.40189 0.499877 4.20508 1.40306 2.57796 3.01334C0.950827 4.62362 0.0248199 6.8109 0.00111106 9.1C0.00111106 9.1 -0.00888873 9.34 0.0311113 9.74C0.191111 12.28 1.43111 14.53 3.32111 16.02C5.34111 18.27 5.74111 20.36 5.81111 21.22C5.83111 21.45 6.02111 21.63 6.26111 21.63H11.1611Z" fill="#FCD53F" />
                    <path d="M8.70125 9.2002C10.3212 9.2002 11.6412 10.5102 11.6612 12.1302V12.2102C11.6612 12.2402 11.6612 12.2802 11.6512 12.3402C11.6012 13.1802 11.1912 13.9702 10.5312 14.4902L10.4612 14.5402L10.4012 14.6002C9.30125 15.8202 9.07125 18.9202 9.03125 20.6202H8.38125C8.33125 18.9202 8.09125 15.8202 6.99125 14.6002L6.93125 14.5402L6.86125 14.4902C6.53095 14.229 6.26018 13.9003 6.06704 13.5261C5.8739 13.1519 5.76283 12.7408 5.74125 12.3202C5.74125 12.2802 5.73125 12.2502 5.73125 12.2302V12.1402C5.76125 10.5202 7.09125 9.2002 8.70125 9.2002ZM8.70125 8.2002C7.87836 8.20049 7.076 8.45713 6.40569 8.93445C5.73538 9.41176 5.23041 10.086 4.96097 10.8636C4.69153 11.6411 4.671 12.4832 4.90224 13.273C5.13348 14.0627 5.60499 14.7608 6.25125 15.2702C7.45125 16.6102 7.39125 21.6302 7.39125 21.6302H10.0312C10.0312 21.6302 9.95125 16.6102 11.1612 15.2802C12.0212 14.6002 12.5912 13.5702 12.6612 12.4002C12.6712 12.2902 12.6712 12.2202 12.6712 12.1702V12.1302C12.6604 11.0843 12.2375 10.0849 11.4941 9.34909C10.7508 8.61326 9.74718 8.2004 8.70125 8.2002Z" fill="#FFB02E" />
                    <path d="M12.1673 23.553C12.232 23.5417 12.2939 23.5178 12.3494 23.4826C12.4049 23.4474 12.4529 23.4016 12.4907 23.3478C12.5285 23.294 12.5553 23.2334 12.5696 23.1692C12.584 23.1051 12.5856 23.0388 12.5743 22.974C12.563 22.9093 12.539 22.8474 12.5038 22.7919C12.4686 22.7364 12.4228 22.6884 12.3691 22.6506C12.3153 22.6128 12.2546 22.586 12.1905 22.5716C12.1263 22.5573 12.06 22.5557 11.9953 22.567L5.25527 23.747C5.19052 23.7583 5.12864 23.7822 5.07315 23.8175C5.01766 23.8527 4.96964 23.8985 4.93185 23.9522C4.89406 24.006 4.86722 24.0667 4.85288 24.1308C4.83854 24.1949 4.83697 24.2613 4.84827 24.326C4.85956 24.3908 4.88349 24.4526 4.9187 24.5081C4.95391 24.5636 4.99971 24.6116 5.05347 24.6494C5.10724 24.6872 5.16792 24.7141 5.23206 24.7284C5.29619 24.7427 5.36252 24.7443 5.42727 24.733L12.1673 23.553ZM12.1173 25.703C12.2464 25.6786 12.3607 25.6044 12.4355 25.4963C12.5103 25.3883 12.5396 25.2551 12.517 25.1257C12.4944 24.9962 12.4217 24.8809 12.3147 24.8046C12.2077 24.7283 12.075 24.6972 11.9453 24.718L5.29527 25.888C5.22839 25.8969 5.164 25.9191 5.10596 25.9535C5.04793 25.9879 4.99745 26.0337 4.95756 26.0881C4.91768 26.1425 4.88921 26.2044 4.87388 26.27C4.85854 26.3357 4.85666 26.4038 4.86834 26.4703C4.88002 26.5367 4.90502 26.6001 4.94184 26.6566C4.97866 26.7131 5.02653 26.7616 5.08258 26.7992C5.13863 26.8367 5.20169 26.8625 5.26798 26.875C5.33426 26.8875 5.40239 26.8865 5.46827 26.872L12.1173 25.703Z" fill="#D3D3D3" />
                    <path d="M6.79093 3.94021C5.68093 5.86021 6.24093 8.26021 8.04093 9.29021C9.84093 10.3202 12.1909 9.61021 13.3009 7.69021C14.4109 5.77021 13.8509 3.37021 12.0509 2.34021C10.2509 1.31021 7.90093 2.02021 6.79093 3.94021Z" fill="#FFF478" />
                </svg>
                <Box className={classes.text_wrapper}>
                    <h2 style={{ color: "#555F7E", fontSize: "14px" }}>{t('common:control_statistics_instruction')}.</h2>
                    <p>{t("common:dashboards_benefits")}</p>
                </Box>
            </Box>
            <Box className={`${classes.images} anim-items anim-hide`}>
                <img src={image1} alt="dashboard" style={{ width: "66.67%" }} />
                <img src={image2} alt="dashboard" style={{ width: "66.67%" }} />
                <img src={image3} alt="dashboard" style={{ width: "66.67%" }} />
            </Box>
        </InstructionCardWrapper>
    )
}

export default TopCard;