import React, { useRef } from 'react';
// import i18n
import { useTranslation } from 'react-i18next';
// import mui
import { makeStyles } from '@material-ui/core';
// import components
import PrintButton from '../components/PrintButton';
import PrintOrder from '../../DashboardStorekeeper/components/PrintOrder';
// import for print
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '36px 62px 60px 62px',

    '& div , & h1': {
      paddingBottom: 32,

      [theme.breakpoints.down(960)]: {
        paddingBottom: 15,
      },
    },
  },

  success_title: {
    fontSize: 24,
    color: '#1F1F1F',
    textAlign: 'center',
    fontWeight: 500,
  },

  success_info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    textAlign: 'center',
    alignItems: 'center',
  },

  success_order: {
    display: 'flex',
    fontWeight: 700,
    fontSize: 16,
    color: '#2F80ED',
    alignItems: 'center',
    gap: 5,
  },

  success_text: {
    color: '#1C2A53',
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    gap: 5,

    '& span': {
      color: '#555F7E',
    },
  },

  success_logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: 118,
      height: 118,

      [theme.breakpoints.down('sm')]: {
        width: 80,
        height: 80,
      },
    },
  },

  print: {
    position: 'absolute',
    display: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    width: '70vw',
    height: '70vh',
    zIndex: 999,
  },
}));

const Success = props => {
  const { orderId, data, numberOfBlocks } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  // logic print
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onDownload = (e) => {
    e.stopPropagation();
    handlePrint();
  };

  return (
    <>
      <div className={`dashboard-card ${classes.card}`}>
        <div
          className={classes.content}
          style={{ alignItems: 'center', justifyContent: 'center' }}>
          <div className={classes.success_logo}>
            <svg
              viewBox="0 0 118 118"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="59" cy="59" r="59" fill="#279F51" />
              <path
                d="M50.8749 71.575L38.7999 59.5L34.7749 63.525L50.8749 79.625L85.3749 45.125L81.3499 41.1L50.8749 71.575Z"
                fill="white"
              />
            </svg>
          </div>
          <h1 className={classes.success_title}>
            {t('common:success_issue_a_ttn')}
          </h1>
          <div className={classes.success_info}>
            <h2 className={classes.success_order}>
              {t('common:order')}
              <p className={classes.success_text}>№{orderId}</p>
            </h2>
            <p className={classes.success_text}>
              {t('common:TTN_number')}: {data?.nova_post_document_number || "-"}
            </p>
            <p className={classes.success_text}>
              {t('common:number_of_seats_default')}: <span>{numberOfBlocks || "-"}</span>
            </p>
          </div>
          <PrintButton onClick={onDownload} />
        </div>
      </div>
      {data && (
        <div className={classes.print}>
          <PrintOrder data={data} ref={componentRef} />
        </div>
      )}
    </>
  );
};

export default Success;
