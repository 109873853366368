import {
  Avatar,
  Grid,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import axInst from '../../../../../../_helpers/axios-instance';

import UserCircle from '../../../../../../icons/UserCircle';
import {
  cartActions,
  customerActions,
  userActions,
} from '../../../../../../_actions';
import MyAccountHeader from '../../../../../../_components/MyAccountHeader';
import Preloader from '../../../../../../_components/Preloader';
import PriceToStr from '../../../../../../_components/PriceToStr';
import SelectCompany from '../../../../../../_components/SelectCompany';
import SelectContragent from '../../../../../../_components/SelectContragent';
import UserButtons from './UserButtons';
import { useRef } from 'react';
import SelectLanguage from '../../../../../../_components/SelectLanguage';

const useStyles = makeStyles(theme => ({
  showSelectContragent: {
    cursor: 'pointer',
  },
  userIconProfile: {
    fontSize: '4.5rem',
    [theme.breakpoints.down(420)]: {
      fontSize: '3.5rem',
    },
  },
  userInfoList: {
    display: 'flex',
    flexDirection: 'column',
    '& li': {
      margin: '2px 0 !important',
    },
  },

  copyBtn: {
    display: 'flex',
    backgroundColor: 'transparent',
    borderRadius: '0.25rem',
    outline: 'none',
    cursor: 'pointer',
    padding: '0.25rem 1.0625rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  currency: {
    display: 'flex',
    gap: '15px',
    padding: '10px',
    alignItems: 'center',

    "& .langs__item-link": {

      '& svg': {
        stroke: '#000'
      },

      "&:hover": {
        '& svg': {
          stroke: '#FFBE00'
        },
      }
    },

  },
  price: {
    color: 'inherit'
  }
}));

const frPopupBodyList = (
  <ul>
    <li>
      <div className="icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.92192 1.01968C8.58972 0.947703 9.27926 1.07387 9.87495 1.38622C10.6104 1.76286 11.1989 2.41573 11.5014 3.18546C11.7601 3.83328 11.8118 4.56079 11.6559 5.24048C11.5072 5.88804 11.1663 6.49017 10.6875 6.95021C10.135 7.4886 9.3967 7.83203 8.62896 7.90401C7.89223 7.97865 7.1335 7.80733 6.50096 7.42087C5.8207 7.00997 5.29048 6.35657 5.02962 5.6049C4.773 4.87394 4.77194 4.05586 5.02723 3.32464C5.28995 2.55862 5.83342 1.89434 6.52959 1.48317C6.95349 1.23137 7.43121 1.07015 7.92192 1.01968ZM7.95426 2.22502C7.33445 2.31479 6.76155 2.67921 6.41559 3.20193C6.09508 3.67631 5.96756 4.27579 6.06592 4.83994C6.14121 5.29971 6.36628 5.73239 6.6966 6.06015C7.10593 6.47344 7.68041 6.71727 8.26205 6.72019C8.6894 6.72736 9.11781 6.6081 9.47995 6.38074C9.98418 6.06918 10.3572 5.55045 10.4879 4.97089C10.6045 4.46915 10.5449 3.92704 10.3156 3.46541C10.0984 3.01866 9.72861 2.6484 9.28271 2.43033C8.87444 2.22847 8.40441 2.15702 7.95426 2.22502Z"
            fill="#414D5F"
            stroke="#414D5F"
            strokeWidth="0.4"
          />
          <path
            d="M6.65371 8.22373C7.79208 7.91828 9.01501 7.94086 10.1422 8.28509C11.3148 8.63994 12.38 9.34221 13.1674 10.2822C14.1239 11.4142 14.6676 12.8892 14.6665 14.3729C14.666 14.5819 14.6665 14.7909 14.666 15C10.4145 15.0002 6.163 15.0008 1.91151 15C1.91416 14.6398 1.90196 14.2788 1.93033 13.9195C2.00456 12.9229 2.31606 11.9444 2.8346 11.0905C3.67446 9.68804 5.07421 8.63649 6.65371 8.22373ZM6.09142 9.70052C5.25634 10.0902 4.53181 10.7106 4.01273 11.4724C3.54005 12.1619 3.24101 12.9683 3.14451 13.7986C6.57471 13.7981 10.0046 13.7989 13.4348 13.7983C13.315 12.7463 12.862 11.7354 12.157 10.9465C11.3087 9.98499 10.0905 9.36293 8.81591 9.23624C7.88804 9.14009 6.93525 9.30211 6.09142 9.70052Z"
            fill="#414D5F"
            stroke="#414D5F"
            strokeWidth="0.4"
          />
        </svg>
      </div>
      <div>
        <div className="title">Драганчук Юрій Валерійович</div>
        <ul className="info-list">
          <li>Опт+</li>
          <li>Готiвка</li>
          <li>Гривня</li>
        </ul>
      </div>
    </li>
    <li className="active">
      <div className="icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 15C11.8661 15 15 11.8661 15 8C15 4.1339 11.8661 1 8 1C4.1339 1 1 4.1339 1 8C1 11.8661 4.1339 15 8 15Z"
            stroke="#315EFB"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 8L7 10L11 6"
            stroke="#315EFB"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div>
        <div className="title">ФОП Драганчук Ю.В.</div>
        <ul className="info-list">
          <li>Опт+</li>
          <li>Готiвка</li>
          <li>Гривня</li>
        </ul>
      </div>
    </li>
    <li>
      <div className="icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.92192 1.01968C8.58972 0.947703 9.27926 1.07387 9.87495 1.38622C10.6104 1.76286 11.1989 2.41573 11.5014 3.18546C11.7601 3.83328 11.8118 4.56079 11.6559 5.24048C11.5072 5.88804 11.1663 6.49017 10.6875 6.95021C10.135 7.4886 9.3967 7.83203 8.62896 7.90401C7.89223 7.97865 7.1335 7.80733 6.50096 7.42087C5.8207 7.00997 5.29048 6.35657 5.02962 5.6049C4.773 4.87394 4.77194 4.05586 5.02723 3.32464C5.28995 2.55862 5.83342 1.89434 6.52959 1.48317C6.95349 1.23137 7.43121 1.07015 7.92192 1.01968ZM7.95426 2.22502C7.33445 2.31479 6.76155 2.67921 6.41559 3.20193C6.09508 3.67631 5.96756 4.27579 6.06592 4.83994C6.14121 5.29971 6.36628 5.73239 6.6966 6.06015C7.10593 6.47344 7.68041 6.71727 8.26205 6.72019C8.6894 6.72736 9.11781 6.6081 9.47995 6.38074C9.98418 6.06918 10.3572 5.55045 10.4879 4.97089C10.6045 4.46915 10.5449 3.92704 10.3156 3.46541C10.0984 3.01866 9.72861 2.6484 9.28271 2.43033C8.87444 2.22847 8.40441 2.15702 7.95426 2.22502Z"
            fill="#414D5F"
            stroke="#414D5F"
            strokeWidth="0.4"
          />
          <path
            d="M6.65371 8.22373C7.79208 7.91828 9.01501 7.94086 10.1422 8.28509C11.3148 8.63994 12.38 9.34221 13.1674 10.2822C14.1239 11.4142 14.6676 12.8892 14.6665 14.3729C14.666 14.5819 14.6665 14.7909 14.666 15C10.4145 15.0002 6.163 15.0008 1.91151 15C1.91416 14.6398 1.90196 14.2788 1.93033 13.9195C2.00456 12.9229 2.31606 11.9444 2.8346 11.0905C3.67446 9.68804 5.07421 8.63649 6.65371 8.22373ZM6.09142 9.70052C5.25634 10.0902 4.53181 10.7106 4.01273 11.4724C3.54005 12.1619 3.24101 12.9683 3.14451 13.7986C6.57471 13.7981 10.0046 13.7989 13.4348 13.7983C13.315 12.7463 12.862 11.7354 12.157 10.9465C11.3087 9.98499 10.0905 9.36293 8.81591 9.23624C7.88804 9.14009 6.93525 9.30211 6.09142 9.70052Z"
            fill="#414D5F"
            stroke="#414D5F"
            strokeWidth="0.4"
          />
        </svg>
      </div>
      <div>
        <div className="title">Драганчук Ю.В. ТОВ Партс</div>
        <ul className="info-list">
          <li>Опт+</li>
          <li>Готiвка</li>
          <li>Гривня</li>
        </ul>
      </div>
    </li>
    <li>
      <div className="icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.92192 1.01968C8.58972 0.947703 9.27926 1.07387 9.87495 1.38622C10.6104 1.76286 11.1989 2.41573 11.5014 3.18546C11.7601 3.83328 11.8118 4.56079 11.6559 5.24048C11.5072 5.88804 11.1663 6.49017 10.6875 6.95021C10.135 7.4886 9.3967 7.83203 8.62896 7.90401C7.89223 7.97865 7.1335 7.80733 6.50096 7.42087C5.8207 7.00997 5.29048 6.35657 5.02962 5.6049C4.773 4.87394 4.77194 4.05586 5.02723 3.32464C5.28995 2.55862 5.83342 1.89434 6.52959 1.48317C6.95349 1.23137 7.43121 1.07015 7.92192 1.01968ZM7.95426 2.22502C7.33445 2.31479 6.76155 2.67921 6.41559 3.20193C6.09508 3.67631 5.96756 4.27579 6.06592 4.83994C6.14121 5.29971 6.36628 5.73239 6.6966 6.06015C7.10593 6.47344 7.68041 6.71727 8.26205 6.72019C8.6894 6.72736 9.11781 6.6081 9.47995 6.38074C9.98418 6.06918 10.3572 5.55045 10.4879 4.97089C10.6045 4.46915 10.5449 3.92704 10.3156 3.46541C10.0984 3.01866 9.72861 2.6484 9.28271 2.43033C8.87444 2.22847 8.40441 2.15702 7.95426 2.22502Z"
            fill="#414D5F"
            stroke="#414D5F"
            strokeWidth="0.4"
          />
          <path
            d="M6.65371 8.22373C7.79208 7.91828 9.01501 7.94086 10.1422 8.28509C11.3148 8.63994 12.38 9.34221 13.1674 10.2822C14.1239 11.4142 14.6676 12.8892 14.6665 14.3729C14.666 14.5819 14.6665 14.7909 14.666 15C10.4145 15.0002 6.163 15.0008 1.91151 15C1.91416 14.6398 1.90196 14.2788 1.93033 13.9195C2.00456 12.9229 2.31606 11.9444 2.8346 11.0905C3.67446 9.68804 5.07421 8.63649 6.65371 8.22373ZM6.09142 9.70052C5.25634 10.0902 4.53181 10.7106 4.01273 11.4724C3.54005 12.1619 3.24101 12.9683 3.14451 13.7986C6.57471 13.7981 10.0046 13.7989 13.4348 13.7983C13.315 12.7463 12.862 11.7354 12.157 10.9465C11.3087 9.98499 10.0905 9.36293 8.81591 9.23624C7.88804 9.14009 6.93525 9.30211 6.09142 9.70052Z"
            fill="#414D5F"
            stroke="#414D5F"
            strokeWidth="0.4"
          />
        </svg>
      </div>
      <div>
        <div className="title">Драганчук Ю.В.</div>
        <ul className="info-list">
          <li>Опт+</li>
          <li>Готiвка</li>
          <li>Гривня</li>
        </ul>
      </div>
    </li>
  </ul>
);

const UserInfo = props => {
  const {
    history,
    handlePopoverClose,
    anchorEl,
    abbrName,
    userSelectedData,
    frontendData,
    customer,
    authentication,
    currencies,
    setCustomerData,
    financeCalendar,
    getFullPaymentCalendar,
    getPaymentCalendarByOrganization,
    userBalance,
    getCart,
    contragentData,
    financeCalendarByOrganization,
    getFrontendData,
    getContragentData,
    profilePage,
    sell_currency_id,
    main_currency
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const profileIcons = useMediaQuery(theme.breakpoints.down(660));

  const isDesktop = useMediaQuery(theme.breakpoints.up(1200), {
    defaultMatches: true,
  });

  const isTablet = useMediaQuery(theme.breakpoints.down(960), {
    defaultMatches: true,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down(476), {
    defaultMatches: true,
  });
  const [showInfoBody, setShowInfoBody] = useState(false);
  const headerRef = useRef();
  document.addEventListener('click', e => {
    if (!e.composedPath().includes(headerRef.current)) {
      setShowInfoBody(false);
    }
  });

  const frUserInfo = (
    <div
      onClick={() => setShowInfoBody(!showInfoBody)}
      className="header-user__info-header"
      style={{
        textAlign: 'center',
        padding: '0 0 15px',
      }}>
      <span>
        {!isMobile ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}>
            <span
              className="head"
              style={{
                fontSize: '13px',
                color: '#414D5F',
                fontWeight: '600',
              }}>
              {userSelectedData.customer_name}
            </span>
            {/* <svg
              style={{transform: showInfoBody ? 'rotate(180deg)' : ''}}
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.15625 1L4.29596 4L7.15625 1"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
          </div>
        ) : (
          <span style={{ marginBottom: isMobile ? '0' : '6px' }} className="head">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.92192 1.01968C8.58972 0.947703 9.27926 1.07387 9.87495 1.38622C10.6104 1.76286 11.1989 2.41573 11.5014 3.18546C11.7601 3.83328 11.8118 4.56079 11.6559 5.24048C11.5072 5.88804 11.1663 6.49017 10.6875 6.95021C10.135 7.4886 9.3967 7.83203 8.62896 7.90401C7.89223 7.97865 7.1335 7.80733 6.50096 7.42087C5.8207 7.00997 5.29048 6.35657 5.02962 5.6049C4.773 4.87394 4.77194 4.05586 5.02723 3.32464C5.28995 2.55862 5.83342 1.89434 6.52959 1.48317C6.95349 1.23137 7.43121 1.07015 7.92192 1.01968ZM7.95426 2.22502C7.33445 2.31479 6.76155 2.67921 6.41559 3.20193C6.09508 3.67631 5.96756 4.27579 6.06592 4.83994C6.14121 5.29971 6.36628 5.73239 6.6966 6.06015C7.10593 6.47344 7.68041 6.71727 8.26205 6.72019C8.6894 6.72736 9.11781 6.6081 9.47995 6.38074C9.98418 6.06918 10.3572 5.55045 10.4879 4.97089C10.6045 4.46915 10.5449 3.92704 10.3156 3.46541C10.0984 3.01866 9.72861 2.6484 9.28271 2.43033C8.87444 2.22847 8.40441 2.15702 7.95426 2.22502Z"
                fill="#FFF"
                stroke="#FFF"
                strokeWidth="0.4"
              />
              <path
                d="M6.65371 8.22373C7.79208 7.91828 9.01501 7.94086 10.1422 8.28509C11.3148 8.63994 12.38 9.34221 13.1674 10.2822C14.1239 11.4142 14.6676 12.8892 14.6665 14.3729C14.666 14.5819 14.6665 14.7909 14.666 15C10.4145 15.0002 6.163 15.0008 1.91151 15C1.91416 14.6398 1.90196 14.2788 1.93033 13.9195C2.00456 12.9229 2.31606 11.9444 2.8346 11.0905C3.67446 9.68804 5.07421 8.63649 6.65371 8.22373ZM6.09142 9.70052C5.25634 10.0902 4.53181 10.7106 4.01273 11.4724C3.54005 12.1619 3.24101 12.9683 3.14451 13.7986C6.57471 13.7981 10.0046 13.7989 13.4348 13.7983C13.315 12.7463 12.862 11.7354 12.157 10.9465C11.3087 9.98499 10.0905 9.36293 8.81591 9.23624C7.88804 9.14009 6.93525 9.30211 6.09142 9.70052Z"
                fill="#FFF"
                stroke="#FFF"
                strokeWidth="0.4"
              />
            </svg>
          </span>
        )}
        {!isMobile && (
          <ul
            className={clsx(
              'info-list',
              isTablet && profilePage && classes.userInfoList,
            )}
            style={{ fontSize: '13px', fontWeight: '500' }}>
            {userSelectedData?.customer_discount_group?.split(';').map(i => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        )}
      </span>
    </div>
  );

  const frPopupLogout = (
    <Link to="/sign-out" className="logout">
      {!profileIcons && (
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 14H2.33333C1.97971 14 1.64057 13.8478 1.39052 13.5769C1.14048 13.306 1 12.9386 1 12.5556V2.44444C1 2.06135 1.14048 1.69395 1.39052 1.42307C1.64057 1.15218 1.97971 1 2.33333 1H5"
            stroke="#414D5F"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 7.5H13"
            stroke="#414D5F"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 4L13 7.5L9.5 11"
            stroke="#414D5F"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {t('common:Sign_out')}
    </Link>
  );

  const [showSelectContragent, setShowSelectContragent] = React.useState(false);

  const stPositive = {
    color: '#008000',
    margin: '0 0 10px',
    // whiteSpace:'nowrap',
    overflowWrap: useMediaQuery(theme.breakpoints.down(420))
      ? 'break-word'
      : 'normal',
  };
  const stNegative = {
    color: '#C43939',
    margin: '0 0 10px',
    // whiteSpace:'nowrap',
    overflowWrap: useMediaQuery(theme.breakpoints.down(420))
      ? 'break-word'
      : 'normal',
  };

  const showStrSearch = () => {
    if (customer.results && customer.results.length > 1) {
      if (showSelectContragent) setShowSelectContragent(false);
      else {
        setShowSelectContragent(true);
        document.addEventListener('click', handleClickOutsidAutosug, false);
      }
    }
  };

  const handleClickOutsidAutosug = e => {
    let elt = e.target.closest('#SelectContragent');
    if (elt === null) {
      setShowSelectContragent(false);
      document.removeEventListener('click', handleClickOutsidAutosug, false);
    }
  };

  const getCurrencySymbol = () => {
    if (
      financeCalendarByOrganization.loading === false &&
      financeCalendarByOrganization.result.current_currency !== 'UAH'
    ) {
      return ' ' + financeCalendarByOrganization.result.current_symbol;
    }
  };

  const NewContragent = () => {
    //for MyAccount
    // history.push('/');
    // history.push( window.location.pathname);
    // getFrontendData();
    getContragentData(userSelectedData.customer_id);
    getCart();
    // getFullPaymentCalendar(userSelectedData.customer_id);

    // handlePopoverClose();
    getPaymentCalendarByOrganization(userSelectedData.customer_id);
    if (financeCalendarByOrganization.loading === false) {
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    }

    // return ()
    // window.location.reload();
  };

  let contrData = '';
  if (
    authentication.loggedIn &&
    customer.results &&
    customer.results?.length > 0 &&
    userSelectedData.customer_name &&
    userSelectedData.customer_discount_group === undefined
  ) {
    let tmp = customer.results.find(
      x => x.text === userSelectedData.customer_name,
    );
    if (tmp) contrData = tmp.discount_group + '; ' + tmp.credit_limit_currency;
  }

  useEffect(() => {
    props.getCustomers();
    // getPaymentCalendarByOrganization(userSelectedData.customer_id);
    // getFullPaymentCalendar(userSelectedData.customer_id);
    // getContragentData(userSelectedData.customer_id);
  }, []);

  const [buttonText, setButtonText] = useState({ id: null, text: t('common:copy_btn') });

  // Функція для копіювання тексту і зміни тексту кнопки
  const copyToClipboard = (id, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setButtonText({ id: id, text: t('common:copied_btn') }); // Змінюємо текст кнопки

        // Опційно: повернути початковий текст через деякий час
        setTimeout(() => {
          setButtonText({ id: null, text: t('common:copy_btn') });
        }, 3000); // через 3 секунди повертаємо текст "Копіювати"
      })
      .catch(err => {
        console.error('Помилка при копіюванні: ', err);
      });
  };

  // Функція для отримання URL для замовлення

  const [itemsUrl, setItemsUrl] = useState([]);

  const getItemUrl = (api) => {
    // get data from LS
    const localStorageData = JSON.parse(localStorage.getItem('state'));
    const userData = localStorageData.userSelectedData;
    axInst.post(api,
      JSON.stringify({
        item_code_in_accounting_system: userData.customer_code,
      })
    ).then(res => {
      setItemsUrl((prev) => [...prev, res.data]);
    });
  };

  useEffect(() => {
    if (profilePage) {
      getItemUrl("/api/get-link-to-get-products-XML/");
      getItemUrl("/api/get-link-to-get-products-csv/");
      getItemUrl("/api/get-link-to-get-products-JSON/");
    }
  }, []);

  return (
    <>
      <div
        ref={headerRef}
        className={clsx(
          'header-user__info ',
          profilePage ? 'profile-page' : '',
          showInfoBody ? 'active' : '',
        )}
        style={{ padding: '20px 0 5px 0', margin: '0' }}>
        {profilePage && <UserCircle className={classes.userIconProfile} />}
        {frUserInfo}
        {/* <SelectContragent /> */}
        <div
          className={clsx(
            'header-user__info-body',
            profilePage ? 'profile-page' : '',
          )}>
          <div className="header-user__info-body__inner">
            <>
              <Grid
                style={
                  profilePage && { display: 'flex', flexDirection: 'column' }
                }
                container
                item
                xs={12}
                alignItems="center"
                className="mb-3">
                {!profilePage && (
                  <Grid item xs={2} align="left">
                    {abbrName !== '-' ? (
                      <Avatar className="">{abbrName}</Avatar>
                    ) : (
                      <UserCircle className="" />
                    )}
                  </Grid>
                )}
                <Grid item xs={10} align="right" className="">
                  <RouterLink to="/profile/finance">
                    <Grid container className="">
                      <Grid
                        item
                        xs={12}
                        align="left"
                        style={{
                          margin: '0 0 10px',
                          fontWeight: '600',
                          fontSize: '11px',
                          color: '#1F1F1F',
                        }}>
                        {userBalance.current_balance < 0
                          ? t('common:debt')
                          : t('common:your_balance')}
                      </Grid>
                      {financeCalendarByOrganization.loading === false &&
                        financeCalendarByOrganization.result.organization_data.map(
                          (item, index) => (
                            <React.Fragment key={index}>
                              <Grid
                                item
                                xs={profilePage ? (isDesktop ? 8 : 12) : 8}
                                align="left"
                                style={{
                                  fontWeight: '600',
                                  fontSize: '11px',
                                  color: '#1F1F1F',
                                  margin: '0 0 10px',
                                }}>
                                {item.organization}
                              </Grid>
                              <Grid
                                style={{
                                  margin:
                                    profilePage && isDesktop ? '0' : '10px 0',
                                  fontWeight: '600',
                                  fontSize: '12px',
                                  color: '#1F1F1F',
                                }}
                                item
                                xs={profilePage ? (isDesktop ? 4 : 0) : 4}>
                                <PriceToStr
                                  data={
                                    item.shipment_allowed === false
                                      ? item.full_payment * -1
                                      : item.full_payment * -1
                                  }
                                />
                                {getCurrencySymbol()}
                              </Grid>

                              {item.shipment_allowed === false &&
                                item.overdue_payment !== undefined ? (
                                <>
                                  <Grid
                                    item
                                    xs={profilePage ? (isDesktop ? 8 : 12) : 8}
                                    align="left"
                                    style={{
                                      ...stNegative,
                                      fontWeight: '600',
                                      fontSize: '11px',
                                      color: '#FF5252',
                                      margin: '0 0 10px',
                                    }}>
                                    {t('common:outdated_debt')}:
                                  </Grid>
                                  <Grid
                                    item
                                    xs={profilePage ? (isDesktop ? 4 : 0) : 4}
                                    style={{
                                      ...stNegative,
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'flex-end',
                                      fontWeight: '600',
                                      fontSize: '12px',
                                      color: '#FF5252',
                                      margin: '0 0 10px',
                                    }}>
                                    <b style={{ whiteSpace: 'nowrap' }}>
                                      <PriceToStr data={item.overdue_payment} />
                                      {getCurrencySymbol()}
                                    </b>
                                  </Grid>
                                </>
                              ) : null}

                              {item.shipment_allowed === true &&
                                item.overdue_payment !== undefined ? (
                                <>
                                  <Grid
                                    item
                                    xs={profilePage ? (isDesktop ? 8 : 12) : 8}
                                    align="left"
                                    style={{
                                      ...stPositive,
                                      margin: '0 0 10px',
                                      fontWeight: '600',
                                      fontSize: '11px',
                                    }}>
                                    {t('common:shipment_available_sum_2')}:{' '}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={profilePage ? (isDesktop ? 4 : 0) : 4}
                                    style={{
                                      ...stPositive,
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'flex-end',
                                      margin: '0 0 10px',
                                      fontWeight: '600',
                                      fontSize: '12px',
                                    }}>
                                    <b style={{ whiteSpace: 'nowrap' }}>
                                      <PriceToStr
                                        stPositive={stPositive}
                                        data={
                                          item.credit_limit +
                                          item.full_payment * -1
                                        }
                                      />
                                      {getCurrencySymbol()}
                                    </b>
                                  </Grid>
                                </>
                              ) : null}
                              {/* <Grid
                                item
                                xs={profilePage ? (isDesktop ? 8 : 12) : 8}
                                align="left">
                                <p
                                  style={{
                                    color: 'red',
                                  }}>
                                  Протермінований борг
                                </p>
                              </Grid>
                              <Grid
                                style={{
                                  margin:
                                    profilePage && isDesktop ? '0' : '10px 0',
                                }}
                                item
                                xs={profilePage ? (isDesktop ? 4 : 0) : 4}>
                                <PriceToStr data={745} />
                                {getCurrencySymbol()}
                              </Grid> */}
                            </React.Fragment>
                          ),
                        )}
                      {/*<>*/}
                      {/* <Grid item xs={8}>ТОВ "ДАНІ-ПАРТС</Grid>
                         <Grid item xs={4}><PriceToStr data={userBalance.current_balance} />{getCurrencySymbol()}</Grid> */}

                      {/* {financeCalendar.shipment_allowed === false ? (
                        <>
                          <Grid item xs={8} align="left" style={stNegative}>
                            {t('common:outdated_debt')}:
                          </Grid>
                          <Grid item xs={4} style={stNegative}>
                            <b>
                              <PriceToStr data={financeCalendar.outdatedDebt} />
                              {getCurrencySymbol()}
                            </b>
                          </Grid>
                        </>
                      ) : null} */}

                      {/*    {financeCalendar.shipment_allowed === true ?*/}
                      {/*    <>*/}
                      {/*        <Grid item xs={8} align="left" style={stPositive}>{t('common:shipment_available_sum_2')}: </Grid>*/}
                      {/* <Grid item xs={4} style={stPositive}><b><PriceToStr stPositive={stPositive} data={customerData && userBalance ? customerData.credit_limit_sum + userBalance.current_balance: null} />{getCurrencySymbol()}</b></Grid> */}
                      {/*    </> : null }*/}
                      {/*</>*/}
                    </Grid>
                    {/*</HtmlTooltip>*/}
                  </RouterLink>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                className={classes.gridBl}
                style={{ marginTop: -6 }}>
                <Grid item xs={7} align="left">
                  <p>
                    {authentication.user !== undefined
                      ? authentication.user
                      : ''}
                  </p>
                </Grid>
                <Grid item xs={5} align="right">
                  {/*<SelectCurrency/>*/}
                </Grid>
              </Grid>

              {!profilePage && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    className={classes.gridBl}
                    style={{ marginTop: -4 }}>
                    <Grid
                      item
                      xs={11}
                      align="left"
                      title={t('common:change_contractor')}>
                      <ListItemText
                        onClick={showStrSearch}
                        className={classes.showSelectContragent}
                        classes={{
                          primary: classes.primaryPrice,
                          secondary: classes.secondaryPrice,
                        }}
                        primary={`${userSelectedData.customer_name}`}
                        secondary={
                          userSelectedData.customer_discount_group !== undefined
                            ? userSelectedData.customer_discount_group
                            : contrData
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      align="right"
                      title={t('common:change_contractor')}>
                      {customer.results && customer.results.length > 1 ? (
                        showSelectContragent ? (
                          <ArrowDropUp
                            onClick={showStrSearch}
                            className={classes.showSelectContragent}
                          />
                        ) : (
                          <ArrowDropDown
                            onClick={showStrSearch}
                            className={classes.showSelectContragent}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    id="SelectContragent"
                    className={classes.gridBl}
                    style={{
                      // display: 'block',
                      margin: showSelectContragent ? '10px 0 7px' : '0',
                      // height: showSelectContragent ? 50 : 10,
                    }}>
                    {showSelectContragent && (
                      <SelectContragent
                        NewContragent={NewContragent}
                        showStrSearch={showStrSearch}
                        customer={customer}
                      />
                    )}
                  </Grid>
                </>
              )}
              {/*<div style={{padding: 10, textAlign: "left", marginTop: -12}}>
                    <ListItemText style={{marginBottom: -6}}
                        classes={{
                            primary: classes.primaryPrice,
                            secondary: classes.secondaryPrice
                        }}
                        // primary={`${userSelectedData.customer_name}`}
                        primary={`${''}`}
                        secondary={userSelectedData.customer_discount_group !== undefined ? userSelectedData.customer_discount_group : ''}
                    />
                    <SelectCompany NewContragent={NewContragent}/>
                </div>*/}
            </>
          </div>
          {itemsUrl[0] && itemsUrl[0]?.prom_unloading ? (
            <div
              className="promLink"
              style={{
                width: '100%',
                backgroundColor: '#693B97',
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'white',
                fontSize: 11,
                fontWeight: 600,
              }}>
              {' '}
              {t('common:url_prom')}
              <button
                className={classes.copyBtn}
                onClick={() =>
                  copyToClipboard(
                    1,
                    itemsUrl[0]?.link_xml,
                  )
                }
                style={{
                  color: '#fff',
                  border: '1px solid #fff',
                  fontSize: 7,
                  fontWeight: 600,
                }}>
                {buttonText.id === 1 ? buttonText.text : t('common:copy_btn')}
              </button>
            </div>
          ) : null}
          {itemsUrl[1] && itemsUrl[1]?.csv_unloading ? (
            <div
              className="promLink"
              style={{
                width: '100%',
                backgroundColor: '#FFBE00',
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'black',
                fontSize: 11,
                fontWeight: 600,
              }}>
              {' '}
              {t('common:url_csv')}
              <button
                className={classes.copyBtn}
                onClick={() =>
                  copyToClipboard(
                    2,
                    itemsUrl[1]?.link_csv,
                  )
                }
                style={{
                  color: 'black',
                  border: '1px solid black',
                  fontSize: 7,
                  fontWeight: 600,
                }}>
                {buttonText.id === 2 ? buttonText.text : t('common:copy_btn')}
              </button>
            </div>
          ) : null}
          {itemsUrl[2] && itemsUrl[2]?.json_unloading ? (
            <div
              className="promLink"
              style={{
                width: '100%',
                backgroundColor: '#D8E5FF',
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: '#315EFB',
                fontSize: 11,
                fontWeight: 600,
              }}>
              {' '}
              {t('common:url_integration')}
              <button
                className={classes.copyBtn}
                onClick={() =>
                  copyToClipboard(
                    3,
                    itemsUrl[2]?.link_json,
                  )
                }
                style={{
                  color: '#315EFB',
                  border: '1px solid #315EFB',
                  fontSize: 7,
                  fontWeight: 600,
                }}>
                {buttonText.id === 3 ? buttonText.text : t('common:copy_btn')}
              </button>
            </div>
          ) : null}
          {/* <div
            style={{
              width: '100%',
              backgroundColor: '#D8E5FF',
              padding: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#315EFB',
            }}>
            Посилання на інтеграцію
            <button
              className={classes.copyBtn}
              onClick={() => {
                copyToClipboard('https://prom.ua/');
                getPromUrl();
              }}
              style={{
                color: '#315EFB',
                border: '1px solid #315EFB',
              }}>
              {buttonText}
            </button>
          </div> */}
          <div className={classes.currency}>
            <SelectLanguage 
              strokeColor="black"
            />
            <div className={classes.price}>
              <a href="#">{sell_currency_id === 2 ? 'UAH' : 'USD $'}</a>
              {sell_currency_id !== 2 && <span>{main_currency?.currency_rate}</span>}
            </div>
          </div>
          <div
            className="header-user__info-body__body "
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              color: '#414D5F',
              fontSize: 13,
              fontWeight: 600,
            }}>
            {!profilePage && (
              <div className="header__group-item header-user__group on-popup">
                {profileIcons && <UserButtons />}
              </div>
            )}
            {/* {!profilePage && frPopupBodyList} */}
            {frPopupLogout}
          </div>
        </div>
      </div>
    </>
  );
};

function mapState(state) {
  const {
    customer,
    userSelectedData,
    frontendData,
    authentication,
    financeCalendar,
    userBalance,
    contragentData,
    financeCalendarByOrganization,
    getFrontendData,
    getContragentData,
    sell_currency_id,
    main_currency
  } = state;
  const { currencies } = frontendData;
  return {
    customer,
    userSelectedData,
    authentication,
    currencies,
    financeCalendar,
    userBalance,
    contragentData,
    financeCalendarByOrganization,
    getFrontendData,
    getContragentData,
    sell_currency_id,
    main_currency
  };
}

const actionCreators = {
  getCustomers: customerActions.getCustomers,
  getFullPaymentCalendar: userActions.getFullPaymentCalendar,
  getPaymentCalendarByOrganization:
    userActions.getPaymentCalendarByOrganization,
  getCart: cartActions.getCart,
  getFrontendData: userActions.getFrontendData,
  getContragentData: userActions.getContragentData,
  //setCustomerData: customerActions.setCustomerData,
};

export default connect(mapState, actionCreators)(UserInfo);
