import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  sort_form: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  title: {
    fontStyle: 'normal',
    color: '#414D5F',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 13,

    '& span': {
      fontWeight: 600,
    },
  },
  openPopup: {
    '& ul': {
      padding: 0,

      '& li': {
        padding: '5px 40px 5px 10px',
        cursor: 'pointer',
        transition: '.3s all ease',

        '&:hover': {
          background: '#ededed',
        },
        '&.active': {
          background: '#ededed',
        },
      },
    },
  },
  popup: {
    position: 'absolute',
    background: '#fff',
    width: '100%',
    padding: '5px 0',
    transition: '.3s all ease',
    transform: 'translateY(-20px)',
    visibility: 'hidden',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 6px rgba(0, 0, 0, 0.02)',
    zIndex: 20,
    opacity: 0,
    top: 20,
    borderRadius: 4,

    '&.active': {
      transform: 'translateY(0)',
      visibility: 'visible',
      opacity: 1,
    },

    '& ul': {
      padding: 0,

      '& li': {
        padding: '5px 10px',
        cursor: 'pointer',
        transition: '.3s all ease',

        '&:hover': {
          background: '#ededed',
        },
        '&.active': {
          background: '#ededed',
        },
      },
    },
  },
}));

export const SortForm = ({setSorting, onSideBar, onClose, currentSortItem}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const listRef = useRef();

  const [showPopup, setShowPopup] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const selectActiveItem = index => {
    setActiveItem(index);
    setShowPopup(false);
    setSorting(listItems[index].value);
    onClose();
  };

  const listItems = [
    {value: '1', label: t('common:Sort_by_article')},
    {value: '3', label: t('common:Sort_by_name')},
    {value: '5', label: t('common:Sort_by_price_a_z')},
    {value: '6', label: t('common:Sort_by_price_z_a')},
  ];

  //   window.addEventListener("click", () => {
  //     if (condition) {

  //     }
  //   })

  if (onSideBar) {
    return (
      <div className="py-4">
        <h4 className="ml-2 mb-2">{t('common:Sort_by')}:</h4>

        <div className={classes.openPopup}>
          <ul>
            {listItems.map((item, index) => (
              <li
                key={item.value}
                onClick={() => selectActiveItem(index)}
                className={item.value === currentSortItem ? 'active' : ''}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div ref={listRef} className={classes.sort_form}>
      <div onClick={() => setShowPopup(!showPopup)} className={classes.title}>
        {t('common:Sort_by')}: <span>{listItems[activeItem].label}</span>
      </div>
      <svg
        style={{
          transform: showPopup ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'ease all .3s',
        }}
        width="8"
        height="5"
        viewBox="0 0 8 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L4.13971 4L7 1"
          stroke="#414D5F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div className={clsx(classes.popup, showPopup ? 'active' : '')}>
        <ul>
          {listItems.map((item, index) => (
            <li
              key={item.value}
              onClick={() => selectActiveItem(index)}
              className={activeItem === index ? 'active' : ''}>
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
