import { userConstants } from '../_constants';

export function managerById(state = {}, action) {
  switch (action.type) {
    case userConstants.PERSONAL_MANAGER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.PERSONAL_MANAGER_BY_ID_SUCCESS:
      return {
        ...state,
        data: [{...action.data}],
        loading: false,
      };
    case userConstants.PERSONAL_MANAGER_BY_ID_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}