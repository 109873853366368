import {brandsConstants} from '../_constants';

export function brands(state = {}, action) {
  switch (action.type) {
    case brandsConstants.BRANDS_ALL_REQUEST:
      return {loading: true};
    case brandsConstants.BRANDS_ALL_SUCCESS:
      return {brands: [...action.data.data]};
    case brandsConstants.BRANDS_ALL_ERROR:
      return {error: action};

    default:
      return state;
  }
}
