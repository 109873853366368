import React, { useState } from 'react';
import { Link, Link as RouterLink, NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  // SvgIcon,
  SwipeableDrawer,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import { SidebarSearch } from './components';
// import MenuIcon from '../../../../icons/Menu';
// import SearchIcon from '../../../../icons/Search';
// import DirectoryFilesIcon from '../../../../icons/DirectoryFiles';
import ShoppingCartIcon from '../../../../_components/ShoppingCartIcon';
import { SearchInput } from '../../../../_components';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Progress from '../../../../App/Progress';
// import MyAccountHeader from '../../../../_components/MyAccountHeader';
import MyCartHeader from '../../../../_components/MyCartHeader';
import PriceToStr from '../../../../_components/PriceToStr';
import UserCircle from '../../../../icons/UserCircle';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// import Container from '@material-ui/core/Container';
import { cartActions, userActions } from '../../../../_actions';
// import PlayList from '../../../../icons/PlayList';
import { TopBalanceHide } from '../../../../views/Balance/Balance';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import ToggleButton from '@material-ui/lab/ToggleButton';
import CatalogModal from './components/CatalogModal';
import MenuSideBar from './components/MenuSideBar/MenuSideBar';

import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

import logo_header_work from '../../../../images/logo_header_work.svg';
import { store } from '../../../../_helpers/store';
import UserInfo from './components/UserInfo/UserInfo';
import SelectLanguage from '../../../../_components/SelectLanguage';
import { TopBarMenu } from './components/TopBarMenu/TopBarMenu';
import { useEffect } from 'react';
import UserButtons from './components/UserInfo/UserButtons';
import MainDialog from '../../../../_components/ReturnProduct/MainDialog';
// import {ToggleDelivery} from '../../../../_components/ToggleDelivery';
// import reconciliationIcon from '../../../../images/act_sverki.svg';

// logo_header_work.png

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#1F1F1F',
    boxShadow: 'none',
    fontFamily: 'sans-serif',
    zIndex: 1000,
  },

  ToolbarMain: {
    paddingLeft: 0,
    paddingRight: 0,
    // [theme.breakpoints.up(1280)]: {
    //     paddingLeft: 'calc((100% - 1280px)/2 )',
    //     paddingRight: 'calc((100% - 1280px)/2)',
    // },
  },
  logoWrap: {
    width: '100%',
    [theme.breakpoints.up(600)]: {
      paddingLeft: 20,
      textAlign: 'left',
    },
  },
  logo: {
    marginTop: 6,
    maxHeight: 34,
    maxWidth: 120,
    [theme.breakpoints.up(1280)]: {
      marginTop: 6,
      maxHeight: 34,
      maxWidth: 120,
    },
  },

  largeIcon: {
    fontSize: '25px',
  },
  largestIcon: {
    fontSize: '32px',
  },

  smallIcon: {
    fontSize: '12px',
  },

  gridMain: {
    display: 'flex',
    alignItems: 'center',
  },
  gridBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  gridItem: {
    minwidth: 100,
    display: 'flex',
    alignItems: 'center',
  },

  searchInput: {
    padding: '8px 0px',
    height: 40,
    position: 'relative',
    justifyContent: 'end',
  },

  drawer: {
    width: '100%',
    height: '64px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  smallAloneBalanceWrap: {
    display: 'block',
  },
  // smallBalanceWrap: {
  //     display: 'block',
  // },
  // smallBalance: {
  //     textAlign: 'right',
  // },
  // smallBalance_title: {margin: 0, color: '#fff', fontSize: 12, fontWeight: 700},
  // smallBalance_value: {
  //     margin: 0,
  //     color: '#e61610',
  //     fontSize: 18,
  //     fontWeight: 700,
  // },
  balanceText: {
    width: '100%',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis' /* Многоточие */,
  },
  balanceField: {
    display: 'flex',
  },
  balanceFieldContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 350,
    width: '100%',
    padding: '0 10px',
    margin: '0 auto',
    borderRadius: 4,
    background: 'rgba(255, 255, 255, 0.2)',
  },
  balanceFieldDropdown: {
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      margin: 0,
      fontSize: 14,
    },
  },
  orderLink: {
    color: '#fff',
  },
  basketAmount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      //marginLeft: 6,
    },
  },
  loginBtn: {
    color: '#fff',
    '& .MuiButton-label': {
      display: 'flex',
      gap: '10px',
    },
  },
  accountCircle: {
    display: 'flex',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: '50%',
    fontSize: 12,
    backgroundColor: 'white',
    fontWeight: 'bold',
    color: '#b0bec5',
  },
  IconButton: {},
  redirectLink: {
    color: '#fff',
  },
  headerBalance: {
    display: 'flex',
    flexDirection: 'column',
    '& >div:nth-child(1)': {
      fontSize: '12px',
      textAlign: 'left',
    },
    '& >div:nth-child(2)': {
      fontSize: '14px',
    },
  },

  balance_by_organization: {
    width: 200,
    listStile: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 0 5px',
    },
  },
  language: {
    zIndex: 1,
    position: 'relative'
  }
}));

const Topbar = props => {
  const {
    className,
    onSidebarOpen,
    // onSidebarOpenRight,
    onSidebarSearchOpen,
    frontendData,
    authentication,
    userSelectedData,
    userBalance,
    listCustomerCart,
    location,
    setSelectedCarts,
    openOrdersList,
    customer_data,
    cart,
    deleteItemsFromCart,
    setHeaderHeight,
    setShowSearch,
    getPaymentCalendarByOrganization,
    financeCalendarByOrganization,
    getCart,
    sell_currency_id,
    main_currency,
    ...rest
  } = props;

  const { list } = cart;
  const { all: allCart } = { ...list };
  const { t } = useTranslation();

  const classes = useStyles();

  const [state, setState] = useState({
    top: false,
  });
  const [openReturnDialog, setOpenReturnDialog] = useState(false);

  // const {current_balance} = userBalance;
  const [searchPanelExpanded, setSearchPanelExpanded] = React.useState(false);
  const [inputFocus, setInputFocus] = React.useState(false);
  const [catalogModalState, setCatalogModalState] = useState(false);
  const [anchorElCart, setAnchorElCart] = useState(null);
  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const tab = useMediaQuery(theme.breakpoints.down(780));
  const isCart = useMediaQuery(theme.breakpoints.down(890));
  const profileIcons = useMediaQuery(theme.breakpoints.down(660));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isHandset = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  if (smUp && state.top === true) {
    setState({ top: false });
  }

  const openCatalogModal = () => {
    setCatalogModalState(true);
  };

  const closeCatalogModal = () => {
    setCatalogModalState(false);
  };

  // if (smUp && searchPanelExpanded) {
  //   setSearchPanelExpanded(false);
  //   setShowSearch(false);
  //   setInputFocus(false);
  // }
  const handlePopoverCart = event => {
    setAnchorElCart(event.currentTarget);
  };
  const handlePopoverCloseCart = () => {
    //for MyCart
    setAnchorElCart(null);
  };
  const showSelectBasket = event => {
    let carts = [];
    carts[0] = userSelectedData.customer_cart_id;
    setSelectedCarts(carts);
    getCart();
  };
  React.useEffect(() => {
    setTimeout(function () {
      getCart();
    }, 500);
  }, []);

  const toggleSidebarSearch = (side, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  React.useEffect(() => {
    if (userSelectedData.customer_id) {
      return getPaymentCalendarByOrganization(userSelectedData.customer_id);
    }

    // eslint-disable-next-line
  }, [authentication.loggedIn]);

  /* const CustomRouterLink = forwardRef((props, ref) => (
                 <div
                     ref={ref}
                     /!*style={{flexGrow: 1}}*!/
                 >
                     <RouterLink {...props} />
                 </div>
             ));*/

  /*-------------------------------------------------*/
  /*https://codesandbox.io/s/0ovrx - почитать*/
  const searchInputTopBar = (
    <SwipeableDrawer
      anchor="top"
      className={clsx(
        classes.drawer,
        'search_drawer',
        state.top === true && 'active',
      )}
      // classes={{paper: classes.drawer}}
      onClose={toggleSidebarSearch('top', false)}
      onOpen={toggleSidebarSearch('top', true)}
      open={state.top}>
      <SidebarSearch
        onSidebarOpenFromSearch={onSidebarOpen}
        onToggleSidebarSearch={toggleSidebarSearch}
      />
    </SwipeableDrawer>
  );
  const [openMenuBar, setOpenMenuBar] = useState(false);

  const handleClickOpen = () => {
    setOpenMenuBar(true);
  };

  /*--------------------------------------------*/
  // let currency = null;
  let current_cart = null;
  // if (frontendData.currencies !== undefined) {
  //     currency = frontendData.currencies.find(
  //         x => x.id === userSelectedData.sell_currency_id,
  //     );
  // }
  if (listCustomerCart) {
    for (let i in listCustomerCart) {
      if (listCustomerCart[i].id === userSelectedData.customer_cart_id) {
        current_cart = listCustomerCart[i];
        //Проверка(презерватив) - соответствует валюта в корзине валюте контрагента
        if (
          customer_data &&
          customer_data.credit_limit_currency_iso_code &&
          current_cart.cost.currency.iso_code !== '' &&
          customer_data.credit_limit_currency_iso_code !==
          current_cart.cost.currency.iso_code
        ) {
          if (allCart !== undefined) {
            let mas_id = [];
            allCart.map(row => {
              if (row.customer_cart_id === null) row.customer_cart_id = 0;
              if (row.customer_cart_id === userSelectedData.customer_cart_id)
                mas_id.push(row.id);
              return true;
            });
            if (mas_id.length > 0) {
              //deleteItemsFromCart(mas_id);
            }
          }
        }
        break;
      }
    }
  }

  // let balance = 123450.49;
  // let balanceMes1 = t('common:debt');
  // let balanceMes2 = t('common:shipment_is_forbidden');
  let name = '';
  //let code = '';
  let abbrName = '-';
  if (authentication.loggedIn) {
    if (userSelectedData.customer_name !== undefined) {
      name = userSelectedData.customer_name;
      /*code =
              userSelectedData.customer_code !== null
                ? userSelectedData.customer_code
                : 'Код: -';*/
      let arrName = name.split(' ');
      abbrName =
        arrName[0].substring(0, 1) +
        (arrName[1] !== undefined ? arrName[1].substring(0, 1) : '');
    }
  }

  const languages = [
    ['uk', 'Укр'],
    ['ru', 'RU'],
    ['en', 'Eng'],
  ];
  let language;
  const storeState = store.getState();
  const { languageReducer } = storeState;
  if (languageReducer.ln_ch !== undefined) {
    language = languageReducer.ln_ch;
  } else {
    language = 'uk';
  }
  const [currentLaguage, setCurrentLaguage] = useState(languages[0]);

  const selectLanguage = lang => {
    // i18next.changeLanguage(lang[0])
    i18n.changeLanguage(lang[0]);
    setCurrentLaguage(lang);
    // window.location.reload();
  };

  // const frUserPrice = (
  //   <div className="header-price">
  //     <a href="#">{sell_currency_id === 2 ? 'UAH' : 'USD $'}</a>
  //     {sell_currency_id !== 2 && <span>{main_currency?.currency_rate}</span>}
  //   </div>
  // );

  // перенес в Balance компонент
  // const frBalance = (
  //     <div className={classes.smallBalance}>
  //         <p className={classes.smallBalance_title}>{balanceMes1}</p>
  //         <p className={classes.smallBalance_value}>
  //             {currency !== null &&
  //             <PriceToStr data={balance} beforeText={''} text={currency.simbol} grn={true}/>
  //             }
  //         </p>
  //     </div>
  // );

  /*const frBalance = (
          <Grid container align="center" style={{paddingLeft: 10}}>
              <div style={{display: 'contents', lineHeight: 1.1}}>
          <span className={classes.balanceText} style={{fontSize: 12}}>
            {balanceMes1}
          </span>
                  <span
                      className={classes.balanceText}
                      style={{color: 'crimson', fontSize: 18}}>
            {currency !== null &&
                <PriceToStr data={balance} beforeText={''} text={currency.simbol} grn={true}/>
            }
          </span>
                  <span
                      className={classes.balanceText}
                      style={{color: 'crimson', fontSize: 13}}>
            {balanceMes2}
          </span>
              </div>
          </Grid>
      );*/

  //Димина корзина

  //Все переделать!!!!!!!!!!!!
  //Tablet
  // let b1 = 4; //Menu + logo
  let b1i1 = 3; //Menu
  // let b2i1 = current_balance && current_balance !== 0 ? 7 : 7; //7- frSearchInput
  let b2i3 = 3; //profile + Favorite

  if (isDesktop) {
    // b1 = 3;
    b1i1 = 2;
    // b2i1 = current_balance && current_balance !== 0 ? 5 : 7;//5;
    b2i3 = 2; //profile + Favorite
  } else if (isHandset) {
    // b1 = 6;
    b1i1 = 4;
    // b2i1 = 0;
    b2i3 = 6; //profile + Favorite
  }
  let b1i2 = 12 - b1i1; //logo
  // let b2i2 = 12 - b2i1; //Блок баланса

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',

      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  return (
    <div id="back-to-top-anchor">
      <TopBalanceHide />
      <MainDialog
        openReturnDialog={openReturnDialog}
        setOpenReturnDialog={setOpenReturnDialog}
      />
      <Hidden smUp>{searchInputTopBar}</Hidden>
      <Progress />
      <AppBar
        {...rest}
        className={clsx(classes.root, 'header header-auto')}
        id="header">
        <div className="wrapper-inner">
          <div className="row">
            <div className="col-12 header__column">
              <Box>
                <MenuSideBar
                  openMenuBar={openMenuBar}
                  setOpenMenuBar={setOpenMenuBar}
                />
                <Box className={'header__inner'}>
                  {/* Header Logo */}
                  <Box className="header-logo__block">
                    <Box
                      onClick={handleClickOpen}
                      className="header__menu-btn header__menu-btn1 mr-2 ml-0">
                      <svg
                        width="22"
                        height="12"
                        viewBox="0 0 22 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect width="22" height="2" rx="1" fill="#fff" />
                        <rect
                          y="5"
                          width="22"
                          height="2"
                          rx="1"
                          fill="#fff"
                        />
                        <rect
                          y="10"
                          width="22"
                          height="2"
                          rx="1"
                          fill="#fff"
                        />
                      </svg>
                    </Box>

                    <Box>
                      <RouterLink to="/" className={'header__logo'}>
                        <img
                          alt={'Company logo'}
                          // статический логотип
                          // src={frontendData.logo && frontendData.logo.logo_small}
                          src={logo_header_work}
                        />
                      </RouterLink>
                    </Box>

                    {/*
                    Попросив закоментувати клієнт - Рома 18.02.2025
                    */}
                    {/* <Grid>
                      {location?.pathname !== '/sign-in' &&
                        <button
                          onClick={openCatalogModal}
                          className="btn btn-home"
                          style={{ whiteSpace: 'nowrap' }}>
                          <span className="header__menu-btn">
                            <svg
                              width="22"
                              height="12"
                              viewBox="0 0 22 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect
                                width="22"
                                height="2"
                                rx="1"
                                fill="#1F1F1F"
                              />
                              <rect
                                y="5"
                                width="22"
                                height="2"
                                rx="1"
                                fill="#1F1F1F"
                              />
                              <rect
                                y="10"
                                width="22"
                                height="2"
                                rx="1"
                                fill="#1F1F1F"
                              />
                            </svg>
                          </span>
                          {t('common:catalog_of_goods')}
                        </button>
                      )}
                    </Grid>
                    */}
                  <TopBarMenu onHeader={true} />
                    <CatalogModal
                      closeCatalogModal={closeCatalogModal}
                      catalogModalState={catalogModalState}
                    />
                  </Box>
                  <SearchInput
                    setShowSearch={setShowSearch}
                    setSearchPanelExpanded={setSearchPanelExpanded}
                    searchPanelExpanded={searchPanelExpanded}
                    inputFocus={inputFocus}
                    className={clsx(classes.searchInput)}
                  />

                  {/* Input */}

                  <Box className="header-desktop">
                    <Box
                      className={clsx(
                        'header__search header__search-mobile',
                        searchPanelExpanded ? 'active' : '',
                      )}>
                      <IconButton
                        className="p-2"
                        onClick={() => {
                          setSearchPanelExpanded(!searchPanelExpanded);
                          setInputFocus(!inputFocus);
                        }}>
                        <svg
                          className="open"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.13371 11.2674C8.96898 11.2674 11.2674 8.96898 11.2674 6.13371C11.2674 3.29844 8.96898 1 6.13371 1C3.29844 1 1 3.29844 1 6.13371C1 8.96898 3.29844 11.2674 6.13371 11.2674Z"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinejoin="round"
                            fill="none"
                          />
                          <path
                            d="M9.61377 9.90625L14.3108 15.0003"
                            stroke="white"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <svg
                          className="close"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect
                            width="20.8866"
                            height="1.74055"
                            rx="0.870274"
                            transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.9998 14.7695)"
                            fill="#FFBE00"
                          />
                          <rect
                            x="0.000305176"
                            y="14.7695"
                            width="20.8866"
                            height="1.74055"
                            rx="0.870274"
                            transform="rotate(-45 0.000305176 14.7695)"
                            fill="#FFBE00"
                          />
                        </svg>
                      </IconButton>
                    </Box>
                    <Box className="header__group">
                      <Box className="header__group-item" style={{ position: 'relative', zIndex: 1 }}>
                        <Box className={`select__language-mobile ${classes.language}`}>
                          {!authentication.loggedIn && (
                            <SelectLanguage />
                          )}

                        </Box>
                      </Box>
                      <Box className="header__group-item">
                        <Link
                          to={'/wishlist'}
                          style={{ position: 'relative' }}
                          className="btn btn-transparent favourite__icon btn-icon btn-icon-left p-2">
                          {/* <Button style={{color: '#fff'}}> */}
                          <span className="svg-group svg-group-like none">
                            <svg
                              width="18"
                              height="16"
                              viewBox="0 0 18 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1.07412 5.81308C0.619515 3.34657 2.29959 1.21 4.91853 1.0082C7.22452 0.832019 8.36763 3.54838 8.59823 4.0673C8.60078 4.076 8.60619 4.08366 8.61362 4.08911C8.62105 4.09456 8.6301 4.09751 8.63941 4.09751C8.64871 4.09751 8.65777 4.09456 8.6652 4.08911C8.67263 4.08366 8.67803 4.076 8.68058 4.0673C9.75122 1.21321 11.7014 1.09148 12.3866 1.02421C14.2314 0.832019 16.7054 2.21903 16.208 5.45111C15.4964 9.93566 8.59164 15 8.59164 15C8.59164 15 1.97346 10.634 1.07412 5.81308Z"
                                stroke="white"
                                strokeWidth="1.6"
                                fill="none"
                              />
                            </svg>
                          </span>
                          {userSelectedData.wish_list?.length > 0 && (
                            <span
                              className="favourite__icon-count"
                              style={{ left: 0 }}>
                              {userSelectedData.wish_list.length}
                            </span>
                          )}
                          {/* </Button> */}
                        </Link>
                      </Box>
                      <Box className="header__group-item">
                        {authentication.loggedIn && (
                          // <ArrowDropDown
                          //   onClick={handlePopoverCart}
                          //   className={classes.largestIcon}
                          //   style={{
                          //     verticalAlign: 'middle',
                          //     cursor: 'pointer',
                          //   }}
                          // />
                          <svg
                            onClick={handlePopoverCart}
                            className={classes.largestIcon}
                            style={{
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                            }}
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L6.23285 6L11 1"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <Link
                          className="btn btn-transparent btn-icon btn-icon-left"
                          to="/cart">
                          <Button style={{ color: '#fff' }} className="cartBtn">
                            <span className="svg-group">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M0.270625 0.581533C0.444063 0.404033 0.691562 0.30622 0.939062 0.309658C1.66844 0.309658 2.42 0.461533 3.03406 0.871533C3.51312 1.1881 3.87719 1.66278 4.08563 2.19591C4.19813 2.46903 4.26344 2.7581 4.32 3.04747C4.7175 5.0556 5.11313 7.06435 5.52563 9.06966C5.59219 9.37685 5.75469 9.67466 6.01875 9.85435C6.32781 10.0775 6.71813 10.154 7.09344 10.154C8.64656 10.1559 10.2 10.1518 11.7531 10.1559C12.0175 9.84935 12.2313 9.50435 12.4419 9.15997C12.7794 8.61247 13.0759 8.04153 13.38 7.47528C13.6972 6.89685 13.9669 6.29403 14.2716 5.70903C14.4538 5.36528 14.8706 5.17216 15.2513 5.2506C15.6706 5.32403 15.9978 5.71747 16 6.14278V6.19747C15.9863 6.33841 15.9462 6.47591 15.8847 6.6031C15.3031 7.8206 14.7034 9.03247 13.9928 10.1806C13.6897 10.6575 13.3725 11.1318 12.9731 11.535C12.7294 11.7772 12.4241 12.0056 12.0634 11.9975C10.4062 12.0028 8.74875 11.999 7.09156 11.9993C6.3525 12.0009 5.60125 11.8106 4.99094 11.384C4.34156 10.9412 3.88781 10.2362 3.72438 9.46966C3.29719 7.41028 2.89344 5.34622 2.48375 3.2831C2.43313 3.0206 2.355 2.74935 2.16812 2.54903C1.9775 2.3381 1.69688 2.23778 1.4225 2.19403C1.12094 2.13372 0.792188 2.20122 0.510625 2.05341C0.21125 1.9081 0.0140625 1.59122 0 1.2606V1.19122C0.016875 0.964658 0.106875 0.741533 0.270625 0.581533Z"
                                  fill="white"
                                />
                                <path
                                  d="M6.17093 12.6448C6.54781 12.5669 6.94718 12.6523 7.27343 12.8516C7.59656 13.0548 7.84374 13.3801 7.94062 13.7507C8.13906 14.3991 7.81874 15.1523 7.23062 15.4807C6.88093 15.6841 6.44562 15.7535 6.05593 15.6326C5.67999 15.5335 5.34937 15.2804 5.14906 14.9482C4.92843 14.5829 4.85531 14.1201 4.99406 13.7123C5.14656 13.1726 5.62374 12.7523 6.17093 12.6448Z"
                                  fill="white"
                                />
                                <path
                                  d="M11.7022 12.6461C12.0644 12.5701 12.451 12.642 12.7694 12.8276C13.3691 13.1633 13.6888 13.9383 13.4676 14.5948C13.291 15.2317 12.6582 15.6964 11.9997 15.6914C11.5694 15.6898 11.1407 15.4986 10.856 15.1751C10.5719 14.8589 10.426 14.4211 10.471 13.9973C10.516 13.5995 10.716 13.2179 11.0304 12.9673C11.2213 12.8029 11.4588 12.7033 11.7022 12.6461Z"
                                  fill="white"
                                />
                                <rect
                                  x="7"
                                  y="5"
                                  width="5"
                                  height="2"
                                  rx="1"
                                  fill="white"
                                />
                              </svg>
                              {current_cart?.quantity ? (
                                <span
                                  className="favourite__icon-count"
                                  style={{ left: -12, top: -10 }}>
                                  {current_cart.quantity}
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                            {!isCart && (
                              <Box className="header__word-mobile ml-2">
                                {current_cart &&
                                  current_cart.cost.sum_with_discounts !== 0 ? (
                                  <PriceToStr
                                    data={
                                      current_cart.cost.sum_with_discounts
                                    }
                                    beforeText={''}
                                    text={current_cart.cost.currency.simbol}
                                    putTextAfter={true}
                                    grn={t('common:uah')}
                                  />
                                ) : (
                                  t('common:empty')
                                )}
                              </Box>
                            )}
                          </Button>
                        </Link>
                      </Box>
                      {!authentication.loggedIn && (
                        <Box className="header__group-item">
                          <Link
                            to="/sign-in"
                            className="btn btn-transparent btn-icon btn-icon-left">
                            <Button className={classes.loginBtn}>
                              <span
                                className="svg-group"
                                style={{ marginTop: '3px' }}>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M7.92189 1.01968C8.58969 0.947703 9.27923 1.07387 9.87492 1.38622C10.6103 1.76286 11.1989 2.41573 11.5013 3.18546C11.7601 3.83328 11.8118 4.56079 11.6559 5.24048C11.5072 5.88804 11.1663 6.49017 10.6875 6.95021C10.135 7.4886 9.39667 7.83203 8.62893 7.90401C7.8922 7.97865 7.13347 7.80733 6.50093 7.42087C5.82066 7.00997 5.29045 6.35657 5.02959 5.6049C4.77297 4.87394 4.77191 4.05586 5.0272 3.32464C5.28992 2.55862 5.83339 1.89434 6.52956 1.48317C6.95346 1.23137 7.43118 1.07015 7.92189 1.01968ZM7.95423 2.22502C7.33442 2.31479 6.76152 2.67921 6.41556 3.20193C6.09505 3.67631 5.96753 4.27579 6.06589 4.83994C6.14118 5.29971 6.36625 5.73239 6.69657 6.06015C7.1059 6.47344 7.68038 6.71727 8.26202 6.72019C8.68937 6.72736 9.11778 6.6081 9.47992 6.38074C9.98415 6.06918 10.3571 5.55045 10.4878 4.97089C10.6045 4.46915 10.5448 3.92704 10.3155 3.46541C10.0984 3.01866 9.72858 2.6484 9.28268 2.43033C8.87441 2.22847 8.40438 2.15702 7.95423 2.22502Z"
                                    fill="white"
                                    stroke="white"
                                    strokeWidth="0.4"
                                  />
                                  <path
                                    d="M6.65369 8.22373C7.79205 7.91828 9.01498 7.94086 10.1422 8.28509C11.3148 8.63994 12.38 9.34221 13.1673 10.2822C14.1238 11.4142 14.6676 12.8892 14.6665 14.3729C14.666 14.5819 14.6665 14.7909 14.666 15C10.4145 15.0002 6.16298 15.0008 1.91148 15C1.91413 14.6398 1.90194 14.2788 1.93031 13.9195C2.00454 12.9229 2.31603 11.9444 2.83458 11.0905C3.67443 9.68804 5.07419 8.63649 6.65369 8.22373ZM6.0914 9.70052C5.25632 10.0902 4.53179 10.7106 4.01271 11.4724C3.54003 12.1619 3.24099 12.9683 3.14449 13.7986C6.57469 13.7981 10.0046 13.7989 13.4348 13.7983C13.315 12.7463 12.8619 11.7354 12.157 10.9465C11.3087 9.98499 10.0905 9.36293 8.81589 9.23624C7.88802 9.14009 6.93523 9.30211 6.0914 9.70052Z"
                                    fill="white"
                                    stroke="white"
                                    strokeWidth="0.4"
                                  />
                                </svg>
                              </span>
                              <Box className="header__word-mobile">
                                {t('common:come_in')}
                              </Box>
                            </Button>
                          </Link>
                        </Box>
                      )}
                      {/* <ToggleDelivery authentication={authentication} /> */}
                      {/* не забыть убрать воскл знак */}
                      {authentication.loggedIn && (
                        <div className="header__group-item header-user__group" style={{ position: 'relative', zIndex: 2 }}>
                          {!profileIcons && (
                            <UserButtons
                              setOpenReturnDialog={setOpenReturnDialog}
                            />
                          )}
                          <UserInfo />
                          <div style={{ paddingLeft: "17px" }}>
                            <Link className="dashboard-header-btn" to="/dashboard">
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.58333 4.25V0.75H11.25V4.25H6.58333ZM0.75 6.58333V0.75H5.41667V6.58333H0.75ZM6.58333 11.25V5.41667H11.25V11.25H6.58333ZM0.75 11.25V7.75H5.41667V11.25H0.75Z" />
                              </svg>
                              <span>{t("common:dashboard_label")}</span>
                            </Link>
                          </div>
                        </div>
                      )}
                    </Box>
                  </Box>

                  {/* {tab && authentication.loggedIn && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Box
                        className={clsx(
                          'header__search header__search-mobile',
                          searchPanelExpanded ? 'active' : '',
                        )}>
                        <IconButton
                          className="p-2"
                          onClick={() => {
                            setSearchPanelExpanded(!searchPanelExpanded);
                            setInputFocus(!inputFocus);
                          }}>
                          <svg
                            className="open"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6.13371 11.2674C8.96898 11.2674 11.2674 8.96898 11.2674 6.13371C11.2674 3.29844 8.96898 1 6.13371 1C3.29844 1 1 3.29844 1 6.13371C1 8.96898 3.29844 11.2674 6.13371 11.2674Z"
                              stroke="white"
                              strokeWidth="1.6"
                              strokeLinejoin="round"
                              fill="none"
                            />
                            <path
                              d="M9.61377 9.90625L14.3108 15.0003"
                              stroke="white"
                              strokeWidth="1.6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButton>
                      </Box>
                    </div>
                  )} */}
                </Box>

                {/* <div className={classes.flexGrow} /> */}
              </Box>

              <MyCartHeader
                anchorEl={anchorElCart}
                handlePopoverClose={handlePopoverCloseCart}
              />
              {/* <Hidden smUp> */}
              {/* <Grid container>
                <Grid xs={12} item>
                  <ExpansionPanel
                    TransitionProps={{
                      onEnter: (node, isAppearing) => {
                        setShowSearch(true);
                        setInputFocus(true);
                      },
                      onExit: node => {
                        setShowSearch(false);
                        setInputFocus(false);
                      },
                    }}
                    className={'search_mobile_panel'}
                    square
                    expanded={searchPanelExpanded}>
                    <ExpansionPanelSummary
                      hidden={true}
                      aria-controls="panel1d-content"
                      id="panel1d-header"></ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      

                      <div className={clsx('header-mobile__search active')}>
                        <form className="header__search-form">
                          {searchPanelExpanded && (
                            <SearchInput
                              setShowSearch={setShowSearch}
                              setSearchPanelExpanded={setSearchPanelExpanded}
                              inputFocus={inputFocus}
                              className={clsx(classes.searchInput)}
                            />
                          )}
                          <button className="header__search-btn">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.13371 11.2674C8.96898 11.2674 11.2674 8.96898 11.2674 6.13371C11.2674 3.29844 8.96898 1 6.13371 1C3.29844 1 1 3.29844 1 6.13371C1 8.96898 3.29844 11.2674 6.13371 11.2674Z"
                                strokeWidth="1.6"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.61383 9.90625L14.3108 15.0003"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </form>
                      </div> 
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              </Grid> */}
              {/* </Hidden> */}
              {/* <Toolbar className={clsx(classes.ToolbarMain, "toolbar_main")}>

                            </Toolbar> */}
            </div>
          </div>
        </div>

        {/* <Hidden mdDown>
                   <div className="header_bottom">
                       <Container maxWidth="xl">{authentication.loggedIn && (
                           <HorizontalMenu frontendData={frontendData}/>
                       )}</Container>
                   </div>
                </Hidden> */}
      </AppBar>
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

function mapState(state) {
  const {
    userSelectedData,
    listCustomerCart,
    userBalance,
    cart,
    financeCalendarByOrganization,
  } = state;
  const { customer_id, sell_currency_id } = userSelectedData;
  const { customer_data } = state.contragentData;
  const { main_currency } = state.frontendData;
  return {
    userSelectedData,
    listCustomerCart,
    userBalance,
    customer_data,
    cart,
    financeCalendarByOrganization,
    customer_id,
    sell_currency_id,
    main_currency,
  };
}

const actionCreators = {
  setSelectedCarts: cartActions.setSelectedCarts,
  openOrdersList: userActions.openOrdersList,
  deleteItemsFromCart: cartActions.deleteItemsFromCart,
  getPaymentCalendarByOrganization:
    userActions.getPaymentCalendarByOrganization,
  getCart: cartActions.getCart,
  getListCustomerCart: cartActions.getListCustomerCart,
};

class Wrapper extends React.Component {
  componentDidMount() {
    const d = this.getCartCount();
    if (this.props.customer_id && !d) {
      this.props.getListCustomerCart();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customer_id &&
      this.props.customer_id !== prevProps.customer_id
    ) {
      // this.props.resetSearchParams();
      // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
      // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
      this.props.getListCustomerCart();
    }
  }

  getCartCount = () => {
    const { listCustomerCart, customer_cart_id } = this.props;

    for (let [key, value] of Object.entries(listCustomerCart)) {
      if (value.id === customer_cart_id) return listCustomerCart[key].quantity;
    }

    return (
      listCustomerCart && listCustomerCart[0] && listCustomerCart[0].quantity
    );
  };

  render() {
    return <Topbar {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
/*export default Topbar;*/
