import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {withStyles, makeStyles} from '@material-ui/styles';
import {useMediaQuery, useTheme} from '@material-ui/core';
import {
  Grid,
  Button,
  TextField, Select,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import {cartActions, customerActions} from '../../../../_actions';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {deliveryActions} from '../../../../_actions/delivery.actions';
import FormOrderSelect from './FormOrderSelect';
import FormOrderSelectAsync from './FormOrderSelectAsync';
import InputElement from 'react-input-mask';
import PriceToStr from '../../../../_components/PriceToStr';
import Divider from '@material-ui/core/Divider';
import Template from './Template';
import Template2 from './Template2';
import moment from 'moment';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import axInst from '../../../../_helpers/axios-instance';
// import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Cansel from '../../../../icons/Cancel';
import DialogContent from '@material-ui/core/DialogContent';
// import {Link} from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import {Link} from 'react-router-dom';
import dropshipping_banner from '../../../../images/dropshipping.jpg';

/*
/api/delivery/<customer_id>/settings_list/
/api/delivery/<delivery_id>/cities/
/api/delivery/<delivery_service_city_id>/departments/
*/


const schema = {
  //Обязательні всегда
  // templateDelivery: {
  //     presence: {allowEmpty: false, message: 'is required'},
  // },
  templateReceiver: {
    //presence: {allowEmpty: false, message: 'is required'},
  },
  
  contragent: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  deliveryType: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  
  returnDelivery: {},
  //comment: {},
  ttnDelivery: {},
  
};

const schemaPayment = {
  //Обязательні всегда
  payment: {
    presence: {allowEmpty: false, message: 'is required'},
  },
};
const schemaEdrpou = {
  //Обязательні всегда
  edrpou: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      minimum: 8,
      maximum: 10,
    },
  },
};
const schemaTemplateReceiver = {
  //Обязательні всегда
  templateReceiver: {
    presence: {allowEmpty: false, message: 'is required'},
  },
};
const schemaPoint_x = {
  supliers: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  phone: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  fierstName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
  lastName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
  fatherName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 2, maximum: 64},
  },
};

const schemaFIO = {
  supliers: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  /*email: {
        presence: {allowEmpty: false, message: 'is required'},
        email: true,
        length: {
            minimum: 6,
            maximum: 64,
        },
    },*/
  phone: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  fierstName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
  lastName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
  fatherName: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 2, maximum: 64},
  },
};
const schemaCompany = {
  supliers: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  /*email: {
        presence: {allowEmpty: false, message: 'is required'},
        email: true,
        length: {
            minimum: 6,
            maximum: 64,
        },
    },*/
  phone: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  company: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 2, maximum: 64},
  },
};

const schemaReturnDeliverySum = {
  returnDeliverySum: {
    presence: {allowEmpty: false, message: 'is required'},
  },
};
const schemaTtnPhone = {
  ttnPhone: {
    presence: {allowEmpty: false, message: 'is required'},
  },
};


const schema1 = {
  //не всегда
  deliveryService: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  deliveryCity: {
    presence: {allowEmpty: false, message: 'is required'},
  },
};
const schemaDepartment = {
  //не всегда
  deliveryDepartment: {
    presence: {allowEmpty: false, message: 'is required'},
  },
};
const schemaAddress = {
  //не всегда
  addressDeliveryText: {
    presence: {allowEmpty: false, message: 'is required'},
    //phone: true,
    length: {
      minimum: 2,
      maximum: 64,
    },
  },
};
const schema2 = {
  cityByDeliveryType: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  addressByCity: {
    presence: {allowEmpty: false, message: 'is required'},
  },
  
  deliveryDateTime: {
    presence: {allowEmpty: false, message: 'is required'},
  },
};

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  root: {
    backgroundColor: 'none' /*theme.palette.background.default,*/,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  content: {
    /*width: '80%',*/
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    width: '100%',
    //position: 'fixed',
  },
  
  contentBody: {
    width: '100%',
    flexGrow: 1,
    display: 'inline-flex',
    //alignItems: 'center',
    /*justifyContent: 'center',*/
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
    //minWidth: 120,
    paddingBottom: 125 /*700,*/,
    flexBasis: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  
  textField: {
    // marginTop: 15,//theme.spacing(1),
  },
  SignUpButton: {
    margin: theme.spacing(2, 0),
    fontSize: '16px!important',
  },
  
  divider: {
    margin: '5px 0',//theme.spacing(1, 1),
    // marginTop: 15,
    background: '#dddddd',
  },
  
  header: {
    textAlign: 'left',
    marginBottom: 0,
    marginTop: 10,
    paddingTop: 10,
    borderTop: '1px solid black',
  },
  header2: {
    textAlign: 'left',
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
  },
  
  warningMessage: {
    display: 'block',
    color: '#c43939',
    fontWeight: '400',
    margin: '10px',
    lineHeight: 1.25,
    fontSize: '16px',
  },
  
  phoneBlock: {
    display: 'flex',
  },
  
  operatorCodesSelect: {
    marginTop: '8px',
    width: '120px',
    height: '40px',
  },
  
  delivery_shipment: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    paddingLeft: '10px',
    height: '40px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
  
  financeLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  
  //--------------------
  blStrData: {
    // display: "flex",
    // flexWrap: 'wrap',
    // justifyContent: 'space-between',//'flex-start','center',//'space-between',
    // textAlign: 'center'
  },
  
  disabledSelect: {
    pointerEvents: 'none',
    opacity: 0.6,
  },
  
  balanceRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 0 7px',
  },
  
  financeContainer: {
    width: '100%',
    background: '#F4F4F4',
    padding: '10px 10px 3px 10px',
    margin: '0 0 10px',
    color: '#606060',
    
    [theme.breakpoints.down('md')]: {
      background: '#fff',
      padding: '0px',
    },
    
    [theme.breakpoints.down(767)]: {
      background: 'transparent',
    },
    
    '& b': {
      color: '#000',
    },
    
  },
  
  leftContainerOrder: {
    width: '57%',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    
    [theme.breakpoints.down(767)]: {
      overflow: 'unset',
    },
  },
  
  rightContainerOrder: {
    padding: '0 0 0 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '43%',
    
    [theme.breakpoints.down('md')]: {
      padding: '0',
      width: '100%',
    },
  },
  
  rightOrderBlock: {
    width: '100%',
    background: '#F4F4F4',
    padding: '1px 10px 10px 10px',
    
    [theme.breakpoints.down('md')]: {
      background: '#fff',
      padding: '0 0 10px',
    },
    
    [theme.breakpoints.down(767)]: {
      background: 'transparent',
    },
    
    '& h3': {
      margin: '10px 0 5px',
      color: '#444',
    },
  },
  
  yourOrderRow: {
    // display:'flex',
    // justifyContent: 'space-between',
    padding: '10px 0 3px',
    width: '100%',
    // background:'#eeeeee',
    borderRadius: '5px',
  },
  
  deliveryContainer: {
    width: '100%',
    padding: '10px 0 3px',
    // background:'#eeeeee',
    borderRadius: '5px',
    
  },
  
  orderInfoStyle: {
    padding: '0 0 7px',
    color: '#606060',
    // "& b": {
    //   color: '#444'
    // }
  },
  
  financeRight: {
    textAlign: 'right',
    '& b': {
      color: '#444',
    },
  },
  
  modalLogoBlock: {
    position: 'absolute',
    // opacity: '0.07',
    top: '90px',
    // transform: 'translate(0%, -50%)',
    pointerEvents: 'none',
    width: '100%',
    padding: '10px 0 0 0',
    // maxHeight: '373px',
    // overflow: 'hidden',
    
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    
    '& img': {
      display: 'block',
      objectFit: 'contain',
      margin: '0 auto',
    },
  },
  warningMessageContent: {
    padding: '0 24px',
  },
  
  stMainHeaderHelp: {
    color: '#315EFB',
    fontSize: 18,
    fontWeight: 'bold',
    
    
    '& .close': {
      overflow: 'hidden',
      borderRadius: '50%',
    },
    
    
    '& .MuiSvgIcon-root': {
      backgroundColor: '#ccc',
      color: '#fff',
    },
  },
  '& .close': {
    overflow: 'hidden',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
  },
  
  gridContainerHr: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    /*border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        borderRadius: 5,*/
    padding: 0,
    width: '100%',
    marginBottom: 8,
    //margin: 5,
  },
  stMainHeader: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  
  warehouseHelpContent: {
    color: '#606060 !important',
    display: 'block',
    fontWeight: '400',
    // margin: '10px',
    padding: '0 24px 20px',
    lineHeight: 1.25,
    fontSize: '16px',
    '& p': {
      margin: 0,
    },
    
  },
  
  helpModalBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    color: '#315EFB',
    border: '1px solid #315EFB',
    borderRadius: '50%',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    opacity: 1,
    margin: '0 0 0 5px',
  },
  
}));

let currentIdWarehouse = -1;

const FormOrder = props => {
    // const StyledSelect = withStyles(theme => ({
    //     root: {
    //         textAlign: 'start',
    //     },
    // }))(Select);
    
    const [isDelivery, setIsDelivery] = React.useState(false);
    const GreenCheckbox = withStyles({
      root: {
        textAlign: 'start',
        color: 'gray',
        '& +span': {
          fontWeight: '400 !important',
          whiteSpace: 'nowrap',
        },
        '&$checked': {
          color: 'gray',
        },
      },
      checked: {},
    })(props => <Checkbox color='default' {...props} />);
    
    const {
      //history,
      authentication,
      onClose,
      id_warehouse,
      arrayIdForOrder,
      setRememberCartOderData,
      rememberCartOderData,
      // listTypePayment,
      listDepartments,
      listTypeDelivery,
      citiesByDeliveryType,
      addressesByCity,
      deliverySchedulerDateTime,
      // deliveryScheduler,
      // deliverySchedulerTime,
      listDeliveryServices,
      listTemplateDelivery,
      listTemplateReceiver,
      //customer,
      customer_id,
      createOrderAct,
      totalCost,
      allWeight,
      currencyMainContrSimbol,
      customer_data,
      user,
      // orderQuantity,
      // orderPacking_ratio,
      // orderVolume,
      allWeightNP,
      totalVeightOrder,
      orderLength,
      orderHeight,
      orderWidth,
      cartOderData,
      getTermsByFreeShipping,
      userSelectedData,
      financeCalendarByOrganization,
      // contragentData,
      // userBalance,
      customer_name,
      name_warehouse,
      warehouse_dropshipping_enable,
      termsByFreeShipping,
      // setOrderFinanceData,
      massive_param,
      cityId,
    } = props;
    
    // const {dataPayment} = listTypePayment;
    rememberCartOderData.values.orderLength = orderLength;
    rememberCartOderData.values.orderHeight = orderHeight;
    rememberCartOderData.values.orderWidth = orderWidth;
    rememberCartOderData.values.allWeightNP = allWeightNP;
    rememberCartOderData.values.totalVeightOrder = totalVeightOrder;
    
    const classes = useStyles();
    
    const [showTemplateDelivery, setShowTemplateDelivery] = useState(false);
    const [showTemplateReceiver, setShowTemplateReceiver] = useState(false);
    const [fierstDeliveryDepartment, setFierstDeliveryDepartment] = useState(0);
    const [showWeightWarning, setShowWeightWarning] = useState(false);
    const [showBusWarning, setShowBusWarning] = useState(false);
    const [showBusWarningPrice, setShowBusWarningPrice] = useState(false);
    const [mobileOperatorCodes, setMobileOperatorCodes] = useState([]);
    const [showDropshippingBanner, setShowDropshippingBanner] = useState(true);
    const [showPrice, setShowPrice] = useState(false);
    const [handleCloseModal, setHandleCloseModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');
    
    const [warningModal, setWarningModal] = useState(false);
    // const [selectOperatorCodes, setSelectOperatorCodes] = useState('');
    // const [selectOperatorCodesTTN, setSelectOperatorCodesTTN] = useState('');
    // const [onlyPhone, setOnlyPhone] = useState('');
    
    const theme = useTheme();
    const is767 = useMediaQuery(theme.breakpoints.down(767), {
      defaultMatches: true,
    });
    
    const {t} = useTranslation();
    
    // const StyledSelect = withStyles(theme => ({
    //     root: {
    //         textAlign: 'start',
    //     },
    // }))(Select);
    
    // const stNegative = {color: '#C43939'};
    // const customerData = contragentData.customer_data;
    const shipment_available_sum = financeCalendarByOrganization?.result?.credit_limit + financeCalendarByOrganization?.result?.full_payment * -1;
    
    let dataSupliers = {
      data: [
        {code: 'c', name: t('common:company')},
        {code: 'p', name: t('common:individual_person')},
      ],
    };
    
    const [formState, setFormState] = useState({
      isValid: false,
      values: rememberCartOderData.values,
      touched: {},
      errors: {},
    });
    
    // let onlyPhone = formState.values.phone.split(') ');
    
    useEffect(() => {
      // let city_destination;
      let delivery_code_3pl_param;
      // if (citiesByDeliveryType && citiesByDeliveryType.data) {
      //     city_destination = citiesByDeliveryType.data.find(x => x.id === formState.values.cityByDeliveryType);
      // } else city_destination = null;
      
      let delivery_code_3pl_for_date = '';
      if (deliverySchedulerDateTime.data) {
        delivery_code_3pl_param = deliverySchedulerDateTime.data.find(x => x.date === formState.values.deliveryDateTime);
        if (delivery_code_3pl_param) delivery_code_3pl_for_date = delivery_code_3pl_param.delivery_code_3pl;
      }
      
      let params = formState.values;
      if (deliverySchedulerDateTime.loading === false &&
        (
          (formState.values.deliveryType === 'delivery_service' && deliverySchedulerDateTime.data.length > 0) ||
          (formState.values.deliveryDateTime !== '')
        ) && (params.addressByCity !== '' || params.deliveryCity !== '')) {
        let deliveryTerms = {
          delivery_type: params.deliveryType,
          warehouse: id_warehouse,
          city_destination: addressesByCity && addressesByCity.loading === false ? addressesByCity.data[0].city : params.deliveryType === 'delivery_service' ? cityId : '',
          // city_destination: city_destination ? city_destination.internal_id: params.deliveryType=== "delivery_service" ?params.deliveryCity:'',
          date_delivery: params.deliveryDateTime !== '' ? moment(params.deliveryDateTime).format('DD.MM.YYYY') : moment(deliverySchedulerDateTime.data[0].date).format('DD.MM.YYYY'),
          time_delivery: params.deliveryDateTime !== '' ? moment(params.deliveryDateTime).format('HH:mm') : moment(deliverySchedulerDateTime.data[0].date).format('HH:mm'),
          delivery_code_3pl: params.deliveryType === 'scheduled_delivery' || params.deliveryType === 'delivery_in_the_city' || params.deliveryType === 'bus' ? delivery_code_3pl_for_date : params.deliveryType === 'delivery_service' ? deliverySchedulerDateTime.data[0].delivery_code_3pl : '',
          customer_id: params.contragent,
          price_sell: totalCost,
          currency_cell: userSelectedData.sell_currency_id,
        };
        getTermsByFreeShipping(deliveryTerms);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverySchedulerDateTime, formState.values.deliveryDateTime, formState.values.addressByCity, formState.values.deliveryCity]);
    
    useEffect(() => {
      
      if (cartOderData.id === undefined) {
        clearField();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartOderData]);
    
    
    const actHandleChange = (name, type, value, checked) => {
      if (formState.values[name] === value) return false;
      setRememberCartOderData(rememberCartOderData => ({
        ...rememberCartOderData,
        values: {
          ...rememberCartOderData.values,
          [name]:
            type === 'checkbox' ? checked : value,
        },
      }));
      
      setFormState(formState => ({
        ...formState,
        
        values: {
          ...formState.values,
          [name]:
            type === 'checkbox' ? checked : value,
        },
        touched: {
          ...formState.touched,
          [name]: true,
        },
      }));
      
      let res;
      
      // if (value === 'delivery_service') {
      //     setIsDelivery(true)
      // }
      
      switch (name) {
        case 'templateDelivery':
          setIsDelivery(true);
          res = listTemplateDelivery.data.find(x => x.id === value);
          if (res) {
            
            if (res.delivery_recipient_edrpou && res.delivery_recipient_edrpou.length >= 8) actHandleChange('edrpouTemplate', '', true, '');
            else actHandleChange('edrpouTemplate', '', false, '');
            actHandleChange('deliveryType', '', res.typeDelivery ? res.typeDelivery : '', '');
            actHandleChange('deliveryTypeName', '', res.typeDeliveryName ? res.typeDeliveryName : '', '');
            
            actHandleChange('addressDelivery', 'checkbox', res.delivery_to_address ? res.delivery_to_address : '', res.delivery_to_address);
            actHandleChange('addressDeliveryText', '', res.address ? res.address : '', '');
            
            // actHandleChange('comment', '', res.remark ? res.remark : '', '');
            if (res.typeDelivery === 'delivery_service') {
              actHandleChange('cityByDeliveryType', '', '', '');
              actHandleChange('addressByCity', '', '', '');
              
              actHandleChange('deliveryService', '', res.delivery_service ? res.delivery_service : '', '');
              actHandleChange('deliveryServiceName', '', res.delivery_service_name ? res.delivery_service_name : '', '');
              actHandleChange('deliveryCity', '', res.city_id ? res.city_id : '', '');
              actHandleChange('deliveryCityName', '', res.city ? res.city : '', '');
              actHandleChange('deliveryDepartment', '', res.delivery_department ? res.delivery_department : '', '');
              actHandleChange('deliveryDepartmentName', '', res.department ? res.department : '', '');
              actHandleChange('delivery_department_max_weight', '', res.delivery_department_max_weight ? res.delivery_department_max_weight : 0, '');
              actHandleChange('id_from_delivery_service_system', '', res.delivery_department_code ? res.delivery_department_code : '', '');
              actHandleChange('min_amount', '', res.min_amount ? res.min_amount : '');
              actHandleChange('min_amount_free_shipping', '', res.min_amount_free_shipping ? res.min_amount_free_shipping : '', '');
              
            } else {
              // setIsDelivery(false)
              actHandleChange('cityByDeliveryType', '', res.delivery_city ? res.delivery_city : '', '');
              actHandleChange('addressByCity', '', res.address_scheduled ? res.address_scheduled : '', '');
              
              actHandleChange('deliveryService', '', '', '');
              actHandleChange('deliveryServiceName', '', '', '');
              actHandleChange('deliveryCity', '', '', '');
              actHandleChange('deliveryCityName', '', '', '');
              actHandleChange('deliveryDepartment', '', '', '');
              actHandleChange('deliveryDepartmentName', '', '', '');
              actHandleChange('is_enable', '', '', '');
              actHandleChange('delivery_code_3pl', '', '', '');
              
              // actHandleChange('price', '', '', '');
              // actHandleChange('price_massive', '', '', '');
            }
            
            // actHandleChange('delivery_code_3pl', '', '', '');
            // actHandleChange('lat', '', '', '');
            // actHandleChange('lon', '', '', '');
            actHandleChange('deliveryDateTime', '', '', '');
            actHandleChange('is_enable', '', '', '');
            
            actHandleChange('edrpou', '', res.delivery_recipient_edrpou ? res.delivery_recipient_edrpou : '', '');
            
            // actHandleChange('payment', '', res.delivery_recipient_edrpou && res.delivery_recipient_edrpou !== '' ? 'cashless' : '', '');
            actHandleChange('phone', '', res.delivery_recipient_phone ? res.delivery_recipient_phone : '', '');
            actHandleChange('operatorCodes', '', res.operatorCodes ? res.operatorCodes : '', '');
            actHandleChange('operatorCodesTTN', '', res.operatorCodesTTN ? res.operatorCodesTTN : '', '');
            actHandleChange('ttnPhone', '', res.delivery_recipient_phone ? res.delivery_recipient_phone.split(') ')[1] : '', '');
            // actHandleChange('ttnPhone', '', res.delivery_recipient_phone ? res.delivery_recipient_phone : '', '');
            // actHandleChange('phoneNoCode', '', res.delivery_recipient_phone ?  res.delivery_recipient_phone: '', '');
            actHandleChange('operatorCodesTTN', '', res.delivery_recipient_phone ? res.delivery_recipient_phone.split(') ')[0] + ')' : '', '');
            // actHandleChange('phoneNoCode', '', res.phoneNoCode ? res.phoneNoCode : '', '');
            // actHandleChange('templateReceiver', '', res.delivery_recipient, '');
            if (authentication.loggedIn) {
              actHandleChange('templateReceiver', '', value === -1 ? 0 : '', '');
            }
            
            if (res.delivery_recipient_recipient_type === 'p') {//Физична особа или
              actHandleChange('payment', '', 'cash', '');
              actHandleChange('supliers', '', 'p', '');
              actHandleChange('fatherName', '', res.delivery_recipient_middle_name ? res.delivery_recipient_middle_name : '', '');
              actHandleChange('fierstName', '', res.delivery_recipient_first_name ? res.delivery_recipient_first_name : '', '');
              actHandleChange('lastName', '', res.delivery_recipient_last_name ? res.delivery_recipient_last_name : '', '');
            } else if (res.delivery_recipient_recipient_type === 'c') {//Предпріятіє
              actHandleChange('payment', '', 'cashless', '');
              actHandleChange('supliers', '', 'c', '');
              actHandleChange('company', '', res.delivery_recipient_company_name ? res.delivery_recipient_company_name : '', '');
            } else {
              actHandleChange('payment', '', 'cash', '');
              actHandleChange('supliers', '', '', '');
              actHandleChange('fatherName', '', '', '');
              actHandleChange('fierstName', '', '', '');
              actHandleChange('lastName', '', '', '');
              actHandleChange('company', '', '', '');
            }
          } else {
            actHandleChange('edrpouTemplate', '', false, '');
            actHandleChange('deliveryType', '', '', '');
            actHandleChange('deliveryTypeWeight', '', '', '');
            actHandleChange('deliveryMinPrice', '', '', '');
            // actHandleChange('price', '', '', '');
            // actHandleChange('price_massive', '', '', '');
          }
          break;
        case 'templateReceiver':
          // setShowTemplateReceiver(value > 0 ? false : true);
          
          res = listTemplateReceiver.data.find(x => x.id === value);
          if (res) {
            if (res.edrpou && res.edrpou.length >= 8) actHandleChange('edrpouTemplate', '', true, '');
            else actHandleChange('edrpouTemplate', '', false, '');
            if (res.recipient_type === 'p') {//Физична особа
              actHandleChange('payment', '', 'cash', '');
              actHandleChange('supliers', '', 'p', '');
              actHandleChange('fatherName', '', res.middle_name ? res.middle_name : '', '');
              actHandleChange('fierstName', '', res.first_name ? res.first_name : '', '');
              actHandleChange('lastName', '', res.last_name ? res.last_name : '', '');
            } else if (res.recipient_type === 'c') {//Предпріятіє
              actHandleChange('payment', '', 'cashless', '');
              actHandleChange('supliers', '', 'c', '');
              actHandleChange('company', '', res.company_name ? res.company_name : '', '');
            } else {
              actHandleChange('payment', 'cash', '', '');
              // actHandleChange('supliers', '', '', '');
              actHandleChange('supliers', '', formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'bus' ? 'p' : '', '');
              actHandleChange('fatherName', '', '', '');
              actHandleChange('fierstName', '', '', '');
              actHandleChange('lastName', '', '', '');
              actHandleChange('company', '', '', '');
            }
            actHandleChange('edrpou', '', res.edrpou ? res.edrpou : '', '');
            
            actHandleChange('phone', '', res.phone ? res.phone : '', '');
            actHandleChange('ttnPhone', '', res.delivery_recipient_phone ? res.delivery_recipient_phone.split(') ')[1] : '', '');
            // actHandleChange('ttnPhone', '', res.phone ?  res.phone : '', '');
            // actHandleChange('phoneNoCode', '', res.phoneNoCode ?  res.phoneNoCode : '', '');
            actHandleChange('operatorCodes', '', res.operatorCodes ? res.operatorCodes : '', '');
            actHandleChange('operatorCodesTTN', '', res.operatorCodesTTN ? res.operatorCodesTTN : '', '');
          } else {
            actHandleChange('edrpouTemplate', '', false, '');
            // actHandleChange('deliveryType', '', '', '');
          }
          break;
        
        case 'supliers':
          actHandleChange('payment', '', value === 'c' ? 'cashless' : 'cash', '');
          break;
        
        case 'deliveryType':
          if (value === 'point_x' || value === 'bus') {
            if (formState.values.templateDelivery === -1) {
              actHandleChange('templateReceiver', '', 0, '');
              actHandleChange('supliers', '', 'p', '');
              actHandleChange('payment', '', 'cash', '');
            } else {
              actHandleChange('templateReceiver', '', '', '');
              actHandleChange('supliers', '', 'p', '');
              // actHandleChange('payment', '', 'cash', '');
            }
          } else {
            if (deliverySchedulerDateTime && deliverySchedulerDateTime.loading === false) {
              res = deliverySchedulerDateTime.data.find(x => x.date === value);
            }
            
            if (value === 'delivery_service') {
              // actHandleChange('deliveryDateTime', '', res[0], '');
            }
            
            
            if (res) actHandleChange('deliveryMinPrice', '', res.min_amount, '');
            if (res) actHandleChange('min_amount_free_shipping', '', res.min_amount_free_shipping, '');
            
          }
          
          actHandleChange('deliveryService', '', '', '');
          actHandleChange('dropshipping', 'checkbox', '', false); //dropshipping ТОЛЬКО  для НОВОЙ ПОЧТЫ
          
          actHandleChange('cityByDeliveryType', '', '', '');
          actHandleChange('addressByCity', '', '', '');
          actHandleChange('deliveryDateTime', '', '', '');
          actHandleChange('delivery_code_3pl', '', '', '');
          actHandleChange('min_amount', '', '', '');
          actHandleChange('id_from_delivery_service_system', '', '', '');
          actHandleChange('lat', '', '', '');
          actHandleChange('lon', '', '', '');
          actHandleChange('is_enable', '', '', '');
          // actHandleChange('price', '', '', '');
          // actHandleChange('price_massive', '', '', '');
          if (listTypeDelivery.loading === false) {
            res = listTypeDelivery.data.find(x => x.code === value);
            if (res) {
              actHandleChange('deliveryTypeWeight', '', res.max_weight, '');
              // actHandleChange('min_amount', '', res.min_amount, '');
              actHandleChange('price', '', res.price, '');
              actHandleChange('price_massive', '', res.price_massive, '');
              actHandleChange('min_amount_free_shipping', '', res.min_amount_free_shipping, '');
              actHandleChange('max_weight_in_schedule', '', res.max_weight, '');
            } else {
              actHandleChange('deliveryTypeWeight', '', '', '');
              actHandleChange('deliveryMinPrice', '', '', '');
              actHandleChange('min_amount', '', '', '');
              actHandleChange('price', '', '', '');
              actHandleChange('price_massive', '', '', '');
              actHandleChange('min_amount_free_shipping', '', '', '');
              actHandleChange('max_weight_in_schedule', '', '', '');
            }
          }
          
          
          break;
        
        case 'deliveryService':
          actHandleChange('dropshipping', 'checkbox', '', false); //dropshipping ТОЛЬКО  для НОВОЙ ПОЧТЫ
          actHandleChange('deliveryCity', '', '', '');
          actHandleChange('deliveryCityName', '', '', '');
          actHandleChange('deliveryDepartment', '', '', '');
          actHandleChange('deliveryDepartmentName', '', '', '');
          // actHandleChange('price', '', '', '');
          // actHandleChange('price_massive', '', '', '');
          break;
        case 'deliveryCity':
          actHandleChange('deliveryDepartment', '', '', '');
          actHandleChange('deliveryDepartmentName', '', '', '');
          actHandleChange('max_weight', '', '', '');
          actHandleChange('is_enable', '', '', '');
          break;
        case 'deliveryDepartment':
          if (listDepartments.hasOwnProperty('data') && listDepartments.data.find(x => x.id === value)) {
            res = listDepartments.data.find(x => x.id === value);
            actHandleChange('max_weight_NP', '', res && res.max_weight ? res.max_weight : 0, '');
            actHandleChange('id_from_delivery_service_system', '', res && res.delivery_department_code ? res.delivery_department_code : '', '');
            actHandleChange('is_enable', '', res && res.is_enable ? res.is_enable : false, '');
          }
          
          break;
        case 'addressDelivery': //чекбокс
          break;
        
        case 'cityByDeliveryType': //Выбрали город
          if (citiesByDeliveryType && citiesByDeliveryType.data) {
            res = citiesByDeliveryType.data.find(x => x.id === value);
          } else res = null;
          
          actHandleChange('cityByDeliveryTypeName', '', res && res.name ? res.name : '', '');
          actHandleChange('addressByCity', '', '', '');
          actHandleChange('deliveryDateTime', '', '', '');
          actHandleChange('is_enable', '', '', '');
          break;
        case 'addressByCity': //Выбрали адрес
          if (addressesByCity && addressesByCity.data) {
            res = addressesByCity.data.find(x => x.id === value);
          } else res = null;
          actHandleChange('addressByCityName', '', res && res.address ? res.address : '', '');
          actHandleChange('delivery_code_3pl', '', res && res.delivery_code_3pl ? res.delivery_code_3pl : '', '');
          
          actHandleChange('lat', '', res && res.lat ? res.lat : '', '');
          actHandleChange('lon', '', res && res.lon ? res.lon : '', '');
          actHandleChange('id_from_delivery_service_system', '', res && res.delivery_department_code ? res.delivery_department_code : '', '');
          actHandleChange('deliveryDateTime', '', '', '');
          actHandleChange('deliveryMinPrice', '', '', '');
          // actHandleChange('is_enable', '', res && res.is_enable ? res.is_enable : false, '');
          break;
        case 'deliveryDateTime':
          res = deliverySchedulerDateTime.data.find(x => x.date === value);
          if (res) actHandleChange('deliveryMinPrice', '', res.min_amount, '');
          if (res) actHandleChange('is_enable', '', res.is_enable, '');
          if (res) actHandleChange('min_amount', '', res.min_amount, '');
          if (res) actHandleChange('price', '', res.price, '');
          if (res) actHandleChange('price_massive', '', res.price_massive, '');
          if (res) actHandleChange('min_amount_free_shipping', '', res.min_amount_free_shipping, '');
          if (res) actHandleChange('max_weight_in_schedule', '', res.max_weight, '');
          break;
        default:
          break;
      }
      
      
    };
    
    const clearField = () => {
      if (authentication.loggedIn) {
        actHandleChange('templateDelivery', '', '', '');
      }
      
      actHandleChange('deliveryType', '', '', '');
      actHandleChange('deliveryTypeName', '', '', '');
      actHandleChange('deliveryTypeWeight', '', '', '');
      actHandleChange('deliveryMinPrice', '', '', '');
      actHandleChange('min_amount', '', '', '');
      
      actHandleChange('cityByDeliveryType', '', '', '');
      actHandleChange('addressByCity', '', '', '');
      
      actHandleChange('deliveryService', '', '', '');
      actHandleChange('deliveryServiceName', '', '', '');
      actHandleChange('deliveryCity', '', '', '');
      actHandleChange('deliveryCityName', '', '', '');
      actHandleChange('deliveryDepartment', '', '', '');
      actHandleChange('deliveryDepartmentName', '', '', '');
      
      actHandleChange('delivery_code_3pl', '', '', '');
      actHandleChange('min_amount', '', '', '');
      actHandleChange('id_from_delivery_service_system', '', '', '');
      actHandleChange('lat', '', '', '');
      actHandleChange('lon', '', '', '');
      actHandleChange('deliveryDateTime', '', '', '');
      actHandleChange('returnDelivery', 'checkbox', '', false);
      actHandleChange('returnDeliverySum', '', '', '');
      actHandleChange('ttnDelivery', 'checkbox', '', false);
      actHandleChange('ttnPhone', '', '', '');
      actHandleChange('phoneNoCode', '', '', '');
      actHandleChange('phone', '', '', '');
      actHandleChange('operatorCodes', '', '', '');
      actHandleChange('operatorCodesTTN', '', '', '');
      actHandleChange('payment', '', '', '');
      actHandleChange('edrpou', '', '', '');
      
      actHandleChange('addressDelivery', 'checkbox', '', false);
      actHandleChange('addressDeliveryText', '', '', '');
      
      actHandleChange('dropshipping', 'checkbox', '', false);
      actHandleChange('is_enable', '', true, '');
      actHandleChange('company', '', '', '');
      actHandleChange('supliers', '', '', '');
      actHandleChange('templateDeliveryName', '', '', '');
      actHandleChange('price', '', '', '');
      actHandleChange('price_massive', '', '', '');
      actHandleChange('min_amount_free_shipping', '', '', '');
      actHandleChange('max_weight_in_schedule', '', '', '');
      actHandleChange('min_amount', '', '', '');
      actHandleChange('templateReceiver', '', '', '');
      actHandleChange('templateReceiverName', '', '', '');
      //actHandleChange('comment', '', '', '');
      
    };
    
    const handleSignUp = async (event) => {
      event.preventDefault();
      
      if (formState.values.supliers === 'c') formState.values.payment = 'cashless';
      else formState.values.payment = 'cash';
      
      let res;
      /*let supliersName = '';
        res = dataSupliers.data.find(x => x.code === formState.values.supliers);
        if (res) supliersName = res.name;*/
      
      let typeDeliveryID = 0;
      let typeDeliveryName = '';
      res = listTypeDelivery.data.find(x => x.code === formState.values.deliveryType);
      if (res) {
        typeDeliveryName = res.name;
        typeDeliveryID = res.id;
      }
      
      let deliveryServiceName = '';
      let deliveryServiceInsideNumber = '';
      res = listDeliveryServices.data.find(x => x.id === formState.values.deliveryService);
      if (res) {
        deliveryServiceName = res.name;
        deliveryServiceInsideNumber = res.inside_number;
      }
      
      let cityByDeliveryTypeName = '';
      let cityByDeliveryTypeInternalId = '';
      if (citiesByDeliveryType.data) {
        res = citiesByDeliveryType.data.find(x => x.id === formState.values.cityByDeliveryType);
        if (res) {
          cityByDeliveryTypeName = res.name;
          cityByDeliveryTypeInternalId = res.internal_id;
        }
      }
      
      let addressByCityName = '';
      let addressByCityInternalId = '';
      if (addressesByCity.data) {
        res = addressesByCity.data.find(x => x.id === formState.values.addressByCity);
        if (res) {
          addressByCityName = res.address;
          addressByCityInternalId = res.internal_id;
        }
      }
      
      let delivery_department_id = formState.values.addressDelivery ? fierstDeliveryDepartment : formState.values.deliveryDepartment;
      let id_from_delivery_service_system = '';
      // let max_weight = 0;
      if (listDepartments.data) {
        res = listDepartments.data.find(x => x.id === delivery_department_id);
        if (res) {
          id_from_delivery_service_system = res.id_from_delivery_service_system;
          // max_weight = res.max_weight;
        }
      }
      
      let delivery_code_3pl_for_date = '';
      if (deliverySchedulerDateTime.data) {
        res = deliverySchedulerDateTime.data.find(x => x.date === formState.values.deliveryDateTime);
        if (res) delivery_code_3pl_for_date = res.delivery_code_3pl;
      }
      //-----------------------------------------
      let other_order_options = {//Дополнительно будет предаваться шстрокой JSON
        'one_time_delivery': formState.values.templateDelivery === -1 ? 'true' : 'false',
        'delivery_type': formState.values.deliveryType,
        'delivery_type_name': typeDeliveryName,
        
        'city_scheduled_delivery': '',
        'id_city_scheduled_delivery': '',
        'city_scheduled_delivery_name': '',
        'address_scheduled_delivery': '',
        'address_scheduled_delivery_name': '',
        'date_scheduled_delivery': '',
        'time_scheduled_delivery': '',
        
        
        'delivery_service': '',
        'delivery_service_name': '',
        'delivery_service_code': '',
        'delivery_city_name': '',
        'delivery_department_code': '',
        'id_from_delivery_service_system': '',
        'delivery_to_address': 'false',//если TRUE то адрес вводится вручную (только КС)
        'address': '',//если delivery_to_address = TRUE (только КС)
        'delivery_recipient_edrpou': '',
        
        'recipient_last_name_scheduled_delivery': '',
        'recipient_first_name_scheduled_delivery': '',
        'recipient_middle_name_scheduled_delivery': '',
        'recipient_phone': '',
        'recipient_email': '',
        'recipient_type': '',//- тип "p" ФОП "c" компания (компания или ФОП)
        'company_name': '',// - название компании
        
        'user_email': user.email,
        'return_delivery': formState.values.returnDelivery && formState.values.deliveryService !== 12 ? formState.values.returnDeliverySum : '',
        // "dropshipping": formState.values.dropshipping,
        'dropshipping': formState.values.returnDeliverySum > 0 ? true : false,
        'payment': formState.values.payment,
        'code_3PL': delivery_code_3pl_for_date,//formState.values.delivery_code_3pl,
        'warehouse': id_warehouse,
      };
      if (formState.values.deliveryType === 'delivery_service') {
        other_order_options = {
          ...other_order_options,
          'delivery_service': formState.values.deliveryService,
          'delivery_service_name': deliveryServiceName,
          'delivery_service_code': deliveryServiceInsideNumber,
          'delivery_city_name': formState.values.deliveryCityName,
          'delivery_department_code': delivery_department_id,
          'id_from_delivery_service_system': id_from_delivery_service_system && id_from_delivery_service_system !== '' ? id_from_delivery_service_system : formState.values.id_from_delivery_service_system,
          'delivery_to_address': formState.values.addressDelivery ? 'true' : 'false',//если TRUE то адрес вводится вручную (только КС)
          'address': formState.values.addressDeliveryText,//если delivery_to_address = TRUE (только КС)
          'delivery_recipient_edrpou': formState.values.payment === 'cashless' ? formState.values.edrpou : '',
          'date_scheduled_delivery': formState.values.deliveryDateTime !== '' ? moment(formState.values.deliveryDateTime).format('DD.MM.YYYY') : moment(deliverySchedulerDateTime.data[0].date).format('DD.MM.YYYY'),
          'time_scheduled_delivery': formState.values.deliveryDateTime !== '' ? moment(formState.values.deliveryDateTime).format('HH:mm') : moment(deliverySchedulerDateTime.data[0].date).format('HH:mm'),
          'ttn_phone': formState.values.ttnDelivery ? formState.values.operatorCodesTTN + ' ' + formState.values.ttnPhone : '',
          'id_city_scheduled_delivery': addressesByCity && addressesByCity.loading === false ? addressesByCity.data[0].city : formState.values.deliveryType === 'delivery_service' ? cityId : '',
        };
      } else {
        other_order_options = {
          ...other_order_options,
          'city_scheduled_delivery': cityByDeliveryTypeInternalId,
          'id_city_scheduled_delivery': addressesByCity && addressesByCity.loading === false ? addressesByCity.data[0].city : formState.values.deliveryType === 'delivery_service' ? cityId : '',
          'city_scheduled_delivery_name': cityByDeliveryTypeName,
          'address_scheduled_delivery': addressByCityInternalId,
          'address_scheduled_delivery_name': addressByCityName,
          'date_scheduled_delivery': moment(formState.values.deliveryDateTime).format('DD.MM.YYYY'),
          'time_scheduled_delivery': moment(formState.values.deliveryDateTime).format('HH:mm'),
        };
      }
      if (formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'delivery_service' || formState.values.deliveryType === 'bus') {
        other_order_options = {
          ...other_order_options,
          'recipient_last_name_scheduled_delivery': formState.values.lastName,
          'recipient_first_name_scheduled_delivery': formState.values.fierstName,
          'recipient_middle_name_scheduled_delivery': formState.values.fatherName,
          'recipient_phone': formState.values.operatorCodes + ' ' + formState.values.phone,
          // "phone_no_code":formState.values.phone,
          // "operator_code":formState.values.operatorCodes,
          'ttn_phone': formState.values.ttnDelivery ? formState.values.operatorCodesTTN + ' ' + formState.values.ttnPhone : '',
          'recipient_email': formState.values.email,
          
          'recipient_type': formState.values.supliers,//- тип "p" ФОП "c" компания (компания или ФОП)
          'company_name': formState.values.supliers === 'c' ? formState.values.company : '',// - название компании
          
        };
      }
      
      //-----------------------------------------
      let comment = '';
      if (formState.values.deliveryType === 'delivery_service' || formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'bus') {
        if (formState.values.supliers === 'c')
          comment += formState.values.company + '; ';
        else
          comment += formState.values.lastName + ' ' + formState.values.fierstName + '; ';
      }
      
      if (formState.values.deliveryType === 'delivery_service') {
        comment += formState.values.deliveryCityName + '; ' + deliveryServiceName + ';';
      } else {
        comment += cityByDeliveryTypeName + '; ' + typeDeliveryName + ';';
      }
      
      comment += ' #';
      
      if (formState.values.deliveryType === 'delivery_service') {
        if (formState.values.addressDelivery)
          comment += 'Доставка на адрес: ' + formState.values.addressDeliveryText + ';';
        else
          comment += formState.values.deliveryDepartmentName + ';';
        
        comment += formState.values.operatorCodes + ' ' + formState.values.phone + ';';
        if (formState.values.payment === 'cashless') comment += t('common:edrpou2') + ' : ' + formState.values.edrpou + '; ';
      } else {
        comment += addressByCityName + ';';
        comment += formState.values.operatorCodes + ' ' + formState.values.phone !== '' ? formState.values.operatorCodes + formState.values.phone + '; ' : '';
      }
      
      let commentForTemplate = comment;
      
      if (formState.values.deliveryType === 'delivery_service') {/*comment += ' / payment: ' + formState.values.payment;*/
      } else {
        comment += ' / Дата(час): ' + moment(formState.values.deliveryDateTime).format('DD.MM.YYYY HH:mm') + '; ';
      }
      
      
      if (formState.values.deliveryService === 12) {
        comment +=
          // (formState.values.returnDelivery ? ' / ' + t('common:return_delivery') + ': ' : '') +
          (formState.values.dropshipping ? ' / ' + t('common:dropshipping') : '') +
          (formState.values.comment !== '' ? ' / ' + t('common:commentary') + ': ' + formState.values.comment : '');
      } else {
        comment +=
          (formState.values.returnDelivery ? ' / ' + t('common:return_delivery') + ': ' + formState.values.returnDeliverySum : '') +
          (formState.values.dropshipping ? ' / ' + t('common:dropshipping') : '') +
          (formState.values.comment !== '' ? ' / ' + t('common:commentary') + ': ' + formState.values.comment : '');
      }
      
      
      // add username to comment (remark)
      comment += `(${t('common:user')} ${user.username}); `;
      
      //-----------------------------------------
      let tmp = formState;
      tmp.values.weight = allWeight;
      tmp.values.other_order_options = JSON.stringify(other_order_options);
      // if (authentication.loggedIn && (tmp.values.templateDelivery === 0 || tmp.values.templateDelivery === -1)) {
      if (authentication.loggedIn && tmp.values.templateDelivery === 0) {
        tmp.values.delivery_data = {
          // "id_warehouse": formState.values.deliveryType === "delivery_service" ? '' : String(id_warehouse), //Для КС склад не принципиален
          'id_warehouse':
            formState.values.deliveryType === 'delivery_service'
              ? null
              : id_warehouse, //Для КС склад не принципиален
          'name': commentForTemplate,
          'customer': customer_id,
          'delivery_department': formState.values.deliveryType === 'delivery_service' //Только для КС
            ? delivery_department_id
            : null,
          // "max_weight": formState.values.deliveryType === "delivery_service" //Только для КС
          //     ? max_weight
          //     : null,
          
          'remark': '', //formState.values.comment,
          //-------------
          'delivery_type': typeDeliveryID, //- тип доставки (плановая, точка Х, .....)
          
          'delivery_city': formState.values.cityByDeliveryType !== '' ? formState.values.cityByDeliveryType : null,//(id) (Город) (не КС)
          'address_scheduled': formState.values.addressByCity,//(id) (адрес в городе) (не КС)
          
          'delivery_to_address': formState.values.addressDelivery,//если TRUE то адрес вводится вручную (только КС)
          'address': formState.values.addressDeliveryText,//если delivery_to_address = TRUE (только КС)
        };
        if (formState.values.deliveryType === 'delivery_service' || formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'bus') {
          if (formState.values.templateReceiver > 0) {
            // tmp.values.delivery_data.delivery_recipient = formState.values.templateReceiver; //id шаблона покупателя (если есть)
            tmp.values.delivery_data.recipient_id = formState.values.templateReceiver; //id шаблона покупателя (если есть)
          } else {
            tmp.values.delivery_data = {
              ...tmp.values.delivery_data,
              'first_name': formState.values.supliers === 'p' ? formState.values.fierstName : '',
              'last_name': formState.values.supliers === 'p' ? formState.values.lastName : '',
              'middle_name': formState.values.supliers === 'p' ? formState.values.fatherName : '',
              
              'recipient_type': formState.values.supliers,//- тип "p" ФОП "c" компания (компания или ФОП)
              'company_name': formState.values.supliers === 'c' ? formState.values.company : '',// - название компании
              
              'phone': formState.values.operatorCodes + ' ' + formState.values.phone,
              // "operator_codes": formState.values.operatorCodes,
              'operator_codes_TTN': formState.values.operatorCodesTTN,
              // "phone_no_code": formState.values.phoneNoCode,
              'ttn_phone': formState.values.operatorCodesTTN + ' ' + formState.values.ttnPhone,
              'email': formState.values.email,
              
              'edrpou': formState.values.deliveryType === 'delivery_service' && formState.values.payment === 'cashless' ? formState.values.edrpou : '',
            };
          }
        }
      } else {
        tmp.values.delivery_data = {};
      }
      
      // store data for modal window with order message
      // setOrderFinanceData({test:1});
      
      try {
        const response = await createOrderAct(arrayIdForOrder, tmp.values, comment);
        
        window.dataLayer = window.dataLayer || [];
        
        window.dataLayer.push({
          event: 'purchase',
          currency: 'UAH',
          value: response.value,
          transaction_id: response.order.id,
          customerData: response.customerData,
          items: response.order_items,
        });
        
        /*history.push('/');*/
      } catch (error) {
        console.error(error);
      }
      clearField();
      onClose();
    };
    if (authentication.loggedIn === undefined) {
      formState.values.templateReceiver = -1;
    }
    
    const hasError = field => formState.errors[field];
    //=> formState.touched[field] && formState.errors[field] ? true : false; //Только после изменения
    
    useEffect(() => {//Пришли типы доставок
      if (listTypeDelivery.result && listTypeDelivery.data && listTypeDelivery.data.length === 1) {
        
        setRememberCartOderData(rememberCartOderData => ({
          ...rememberCartOderData, values: {
            ...rememberCartOderData.values,
            deliveryType: listTypeDelivery.data[0].code,
            deliveryTypeName: listTypeDelivery.data[0].name,
            deliveryTypeWeight: listTypeDelivery.data[0].max_weight,
          },
        }));
        setFormState(formState => ({
          ...formState, values: {
            ...formState.values,
            deliveryType: listTypeDelivery.data[0].code,
            deliveryTypeName: listTypeDelivery.data[0].name,
            deliveryTypeWeight: listTypeDelivery.data[0].max_weight,
          },
          touched: {
            ...formState.touched,
            deliveryType: true,
            deliveryTypeName: true,
            deliveryTypeWeight: true,
          },
        }));
      }
    }, [listTypeDelivery, setRememberCartOderData]);
    
    useEffect(() => {//Пришли новые города
      if (listDepartments.results && listDepartments.data && listDepartments.data.length > 0) {
        setFierstDeliveryDepartment(listDepartments.data[0].id);
      }
    }, [listDepartments, setFierstDeliveryDepartment]);
    
    useEffect(() => {//Пришли новые города
      
      let res = null;
      if (citiesByDeliveryType.results) {
        if (citiesByDeliveryType.data) {
          if (citiesByDeliveryType.data.length === 1) {
            res = {
              cityByDeliveryType: citiesByDeliveryType.data[0].id,
              cityByDeliveryTypeName: citiesByDeliveryType.data[0].name,
            };
          } else if (formState.values.cityByDeliveryType !== '') {
            let res2 = citiesByDeliveryType.data.find(x => x.id === formState.values.cityByDeliveryType);
            if (res2) {
              res = {
                cityByDeliveryType: res2.id,
                cityByDeliveryTypeName: res2.name,
              };
            }
          }
        }
      }
      if (citiesByDeliveryType.error) res = {cityByDeliveryType: '', cityByDeliveryTypeName: ''};
      
      if (res !== null) {
        setRememberCartOderData(rememberCartOderData => ({
          ...rememberCartOderData, values: {
            ...rememberCartOderData.values,
            cityByDeliveryType: res.cityByDeliveryType,
            cityByDeliveryTypeName: res.cityByDeliveryTypeName,
            // addressByCity: '',
            // addressByCityName: '',
            delivery_code_3pl: '',
            lat: '',
            lon: '',
            deliveryDateTime: '',
          },
        }));
        setFormState(formState => ({
          ...formState, values: {
            ...formState.values,
            cityByDeliveryType: res.cityByDeliveryType,
            cityByDeliveryTypeName: res.cityByDeliveryTypeName,
            // addressByCity: '',
            // addressByCityName: '',
            delivery_code_3pl: '',
            lat: '',
            lon: '',
            deliveryDateTime: '',
          },
          touched: {
            ...formState.touched,
            cityByDeliveryType: true,
            cityByDeliveryTypeName: true,
            // addressByCity: true,
            // addressByCityName: true,
            deliveryDateTime: true,
          },
        }));
      }
      // eslint-disable-next-line
    }, [citiesByDeliveryType, setRememberCartOderData]);
    
    useEffect(() => {//Пришли новые адреса
      
      let res = null;
      if (addressesByCity.results) {
        if (addressesByCity.data) {
          //Убираем повторы
          addressesByCity.data = addressesByCity.data.reduce((acc, n) => {
            let obj = acc.find(m => m.id === n.id);
            if (!obj) {
              acc.push(obj = n);
            }
            // if (n.delivery_code_3pl !== '') obj.delivery_code_3pl += ',' + n.delivery_code_3pl;
            if (obj.delivery_code_3pl !== '0') {
              if (n.delivery_code_3pl === '') obj.delivery_code_3pl = '0';
              else if (n.delivery_code_3pl !== '') obj.delivery_code_3pl += ',' + n.delivery_code_3pl;
            }
            return acc;
          }, []);
          
          if (addressesByCity.data.length === 1) {
            res = {
              addressByCity: addressesByCity.data[0].id,
              addressByCityName: addressesByCity.data[0].address,
              delivery_code_3pl: addressesByCity.data[0].delivery_code_3pl,
              is_enable: addressesByCity.data[0].is_enable,
              lat: (formState.values.deliveryType === 'scheduled_delivery' || formState.values.deliveryType === 'delivery_in_the_city')
              && addressesByCity.data[0].lat2
                ? addressesByCity.data[0].lat2
                : addressesByCity.data[0].lat
              ,
              lon: (formState.values.deliveryType === 'scheduled_delivery' || formState.values.deliveryType === 'delivery_in_the_city')
              && addressesByCity.data[0].lon2
                ? addressesByCity.data[0].lon2
                : addressesByCity.data[0].lon,
            };
          } else if (formState.values.addressByCity !== '') {
            let res2 = addressesByCity.data.find(x => x.id === formState.values.addressByCity);
            if (res2) {
              
              res = {
                addressByCity: res2.id,
                addressByCityName: res2.address,
                delivery_code_3pl: res2.delivery_code_3pl,
                is_enable: res2.is_enable,
                lat: (formState.values.deliveryType === 'scheduled_delivery' || formState.values.deliveryType === 'delivery_in_the_city')
                && res2.lat2
                  ? res2.lat2
                  : res2.lat
                ,
                lon: (formState.values.deliveryType === 'scheduled_delivery' || formState.values.deliveryType === 'delivery_in_the_city')
                && res2.lon2
                  ? res2.lon2
                  : res2.lon
                ,
              };
            }
          }
        }
      }
      if (addressesByCity.error) res = {
        addressByCity: '',
        addressByCityName: '',
        lat: '',
        lon: '',
        delivery_code_3pl: '',
        is_enable: '',
      };
      
      if (res !== null) {
        setRememberCartOderData(rememberCartOderData => ({
          ...rememberCartOderData, values: {
            ...rememberCartOderData.values,
            addressByCity: res.addressByCity,
            addressByCityName: res.addressByCityName,
            delivery_code_3pl: res.delivery_code_3pl,
            // is_enable: res.is_enable,
            lat: res.lat,
            lon: res.lon,
            deliveryDateTime: '',
          },
        }));
        setFormState(formState => ({
          ...formState, values: {
            ...formState.values,
            addressByCity: res.addressByCity,
            addressByCityName: res.addressByCityName,
            delivery_code_3pl: res.delivery_code_3pl,
            // is_enable: res.is_enable,
            lat: res.lat,
            lon: res.lon,
            deliveryDateTime: '',
          },
          touched: {
            ...formState.touched,
            addressByCity: true,
            addressByCityName: true,
            deliveryDateTime: true,
          },
        }));
      }
      // eslint-disable-next-line
    }, [addressesByCity, setRememberCartOderData]);
    
    useEffect(() => {//Пришли новые даты
      let res = null;
      let enable = null;
      if (formState.values.templateDelivery !== '' && formState.values.templateDelivery > 0 && (listDepartments && listDepartments.length <= 1)) {
        
        if (deliverySchedulerDateTime.error) res = '';
        else if (deliverySchedulerDateTime.data && deliverySchedulerDateTime.results) {
          deliverySchedulerDateTime.data.sort((a, b) => {//По возрастанию
            if (a.date > b.date) return 1;
            else return -1;
          });
          
          if (deliverySchedulerDateTime.data.length === 1) {
            res = deliverySchedulerDateTime.data[0].date;
            enable = deliverySchedulerDateTime.data[0].is_enable;
          } else res = '';
        }
        
        if (res !== null) {
          
          setRememberCartOderData(rememberCartOderData => ({
            ...rememberCartOderData, values: {
              ...rememberCartOderData.values,
              deliveryDateTime: res,
              is_enable: enable,
            },
          }));
          
          setFormState(formState => ({
            ...formState, values: {
              ...formState.values,
              deliveryDateTime: res,
              is_enable: enable,
            },
            touched: {
              ...formState.touched,
              deliveryDateTime: true,
              is_enable: true,
            },
          }));
          
        }
      } else {
        if (deliverySchedulerDateTime.data && deliverySchedulerDateTime.results) {
          deliverySchedulerDateTime.data.sort((a, b) => {//По возрастанию
            if (a.date > b.date) return 1;
            else return -1;
          });
          // deliverySchedulerDateTime.data.sort();
          if (formState.values.deliveryType === 'delivery_service') {
            formState.values.deliveryMinPrice = deliverySchedulerDateTime.data.length > 0 && deliverySchedulerDateTime.data[0].min_amount;
          }
          if (deliverySchedulerDateTime.data.length === 1) {
            res = deliverySchedulerDateTime.data[0].date;
            enable = deliverySchedulerDateTime.data[0].is_enable;
          } else res = '';
        }
      }
      // eslint-disable-next-line
    }, [deliverySchedulerDateTime, setRememberCartOderData, formState.values.templateDelivery, listDepartments]);
    
    useEffect(() => {//Пришли новые отделения курьерской службы
      
      let res = null;
      if (listDepartments.results) {
        
        if (listDepartments.data) {
          if (listDepartments.data.length === 1 && listDepartments.data[0].id_from_delivery_service_system !== '' && formState.values.deliveryCity !== '') {
            res = {
              deliveryDepartment: listDepartments.data[0].id,
              deliveryDepartmentName: listDepartments.data[0].name,
              max_weight: listDepartments.data[0].max_weight,
              is_enable: listDepartments.data[0].is_enable,
            };
          }
        }
      }
      if (addressesByCity.error) res = {
        deliveryDepartment: '',
        deliveryDepartmentName: '',
      };
      
      if (res !== null) {
        setRememberCartOderData(rememberCartOderData => ({
          ...rememberCartOderData, values: {
            ...rememberCartOderData.values,
            deliveryDepartment: res.deliveryDepartment,
            deliveryDepartmentName: res.deliveryDepartmentName,
            max_weight: res.max_weight,
            is_enable: res.is_enable,
          },
        }));
        setFormState(formState => ({
          ...formState, values: {
            ...formState.values,
            deliveryDepartment: res.deliveryDepartment,
            deliveryDepartmentName: res.deliveryDepartmentName,
            max_weight: res.max_weight,
            is_enable: res.is_enable,
          },
          touched: {
            ...formState.touched,
            deliveryDepartment: true,
            deliveryDepartmentName: true,
            is_enable: true,
          },
        }));
      }
      // eslint-disable-next-line
    }, [listDepartments, setRememberCartOderData]);
    
    
    useEffect(() => {
      let schemaAll;
      if (formState.values.deliveryType === 'delivery_service') {
        if (formState.values.addressDelivery) schemaAll = (Object.assign({}, schema, schema1, schemaAddress));
        else schemaAll = (Object.assign({}, schema, schema1, schemaDepartment));
        
        if (formState.values.supliers === 'c') schemaAll = (Object.assign({}, schemaAll, schemaCompany, schemaPayment));
        else schemaAll = (Object.assign({}, schemaAll, schemaFIO));
        
        if (formState.values.templateDelivery === 0 || formState.values.templateDelivery === -1) schemaAll = (Object.assign({}, schemaAll, schemaTemplateReceiver));
        if (formState.values.payment === 'cashless') schemaAll = (Object.assign({}, schemaAll, schemaEdrpou));
      } else if (formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'bus') {
        schemaAll = (Object.assign({}, schema, schema2, schemaPoint_x));
      } else {
        schemaAll = (Object.assign({}, schema, schema2));
      }
      if (formState.values.returnDelivery) schemaAll = (Object.assign({}, schemaAll, schemaReturnDeliverySum));
      if (formState.values.ttnDelivery) schemaAll = (Object.assign({}, schemaAll, schemaTtnPhone));
      
      
      let errors = validate(formState.values, schemaAll);
      
      if ((formState.values.deliveryType === 'delivery_service')
        // && (formState.values.templateDelivery === 0 || formState.values.templateDelivery === -1)
        // && (formState.values.templateReceiver === '')
      ) {
        
        if (formState.values.phone.replace(/[^\d]/ig, '').length < 7) {
          if (!errors) errors = {};
          if (!errors.phone) errors.phone = [];
          errors.phone.push('Phone error');
        }
        
        if ((formState.values.templateReceiver === '') && (formState.values.templateDelivery === 0 || formState.values.templateDelivery === -1) && formState.values.operatorCodes.length < 5) {
          
          if (!errors) errors = {};
          if (!errors.selectOperatorCodes) errors.selectOperatorCodes = [];
          errors.selectOperatorCodes.push('Operator Code error');
        }
      }
      
      if ((formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'delivery_service' || formState.values.deliveryType === 'bus')
        && (formState.values.templateReceiver === 0 || formState.values.templateReceiver === -1)
        && (formState.values.templateDelivery === 0 || formState.values.templateDelivery === -1)
      ) {
        
        if (formState.values.phone.replace(/[^\d]/ig, '').length < 7) {
          if (!errors) errors = {};
          if (!errors.phone) errors.phone = [];
          errors.phone.push('Phone error');
        }
        if ((formState.values.operatorCodes === '' || formState.values.operatorCodes === undefined) ||
          (formState.values.operatorCodes.replace(/[^\d]/ig, '').length < 5)) {
          // debugger
          if (!errors) errors = {};
          if (!errors.selectOperatorCodes) errors.selectOperatorCodes = [];
          errors.selectOperatorCodes.push('Operator Code error');
        }
      }
      
      if ((formState.values.deliveryType === 'delivery_service') && formState.values.ttnDelivery === true) {
        
        if (formState.values.operatorCodesTTN.length < 5) {
          if (!errors) errors = {};
          if (!errors.operatorCodesTTN) errors.operatorCodesTTN = [];
          errors.operatorCodesTTN.push('Operator Code error');
        }
        
        if (formState.values.ttnPhone.replace(/[^\d]/ig, '').length < 7) {
          if (!errors) errors = {};
          if (!errors.ttnPhone) errors.ttnPhone = [];
          errors.ttnPhone.push('ttnPhone error');
        }
      }
      
      if (formState.values.returnDelivery && (formState.values.returnDeliverySum.split('')[0] === '0'
        || parseInt(formState.values.returnDeliverySum) < 10)) {
        if (!errors) errors = {};
        if (!errors.returnDeliverySum) errors.returnDeliverySum = [];
        errors.returnDeliverySum.push('DeliverySum error');
      }
      
      // errors = myvalidate(formState.values, errors);
      
      setFormState(formState => ({
        ...formState,
        isValid: !errors, //errors ? false : true,
        errors: errors || {},
      }));
      
      // eslint-disable-next-line
    }, [formState.values,
      // selectOperatorCodes,
      // selectOperatorCodesTTN
    ]);
    
    
    // eslint-disable-next-line
    const specialCharsRegexp = /[a-z+\d`~!№@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
    const handleChange = event => {
      event.persist();
      
      // if (event.target.value === "delivery_service") {
      //     setIsDelivery(true)
      // } else{
      //     setIsDelivery(false)
      // }
      if (event.target.name === 'returnDeliverySum') event.target.value = event.target.value.replace(/[^\d]/ig, '');
      else if (event.target.name === 'edrpou') event.target.value = event.target.value.replace(/[^\d]/ig, '');
      else if (event.target.name === 'fierstName'
        || event.target.name === 'lastName'
        || event.target.name === 'fatherName') event.target.value = event.target.value.replace(specialCharsRegexp, '');
      else if (event.target.name === 'company') event.target.value = event.target.value.replace(specialCharsRegexp, '');
      actHandleChange(event.target.name, event.target.type, event.target.value, event.target.checked);
    };
    
    const clickTemplateDelivery = event => {
      // event.persist();
      setShowDropshippingBanner(!showDropshippingBanner);
      setShowTemplateDelivery(!showTemplateDelivery);
      //добавить новый тип доставки никогда не скрываем
      /*if(formState.values.templateDelivery > 0) setShowTemplateDelivery(!showTemplateDelivery);
        else setShowTemplateDelivery(true);*/
    };
    const clickTemplateReceiver = event => {
      // event.persist();
      setShowTemplateReceiver(!showTemplateReceiver);
      //добавить нового отримувача никогда не скрываем
      /*if(formState.values.templateReceiver > 0) setShowTemplateReceiver(!showTemplateReceiver);
        else setShowTemplateReceiver(true);*/
    };
    
    const MyFormOrderSelect = props => <FormOrderSelect
      formState={formState}
      handleChange={(event) => handleChange(event)}
      {...props}
    />;
    
    const isDeliveryServiceSelected = () =>
      formState.values
      && formState.values.deliveryType
      && formState.values.deliveryType === 'delivery_service';
    
    if (id_warehouse !== undefined && currentIdWarehouse !== id_warehouse) {
      actHandleChange('templateDelivery', '', authentication.loggedIn ? '' : 0, '');
      clearField();
      currentIdWarehouse = id_warehouse;
    }
    
    // let days = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
    let days = [
      t('common:Sunday'),
      t('common:Monday'),
      t('common:Tuesday'),
      t('common:Wednesday'),
      t('common:Thursday'),
      t('common:Friday'),
      t('common:Saturday'),
    ];
    
    useEffect(() => {
      axInst.post('/shopping-cart/get-mobile-operator-codes/', {}).then(res => {
        setMobileOperatorCodes(res.data.result);
      });
    }, []);
    
    
    /*---------------проверки модальное окно предупреждений--------------------*/
    
    useEffect(() => {
      setWarningModal(false);
      formState.values.deliveryMinPrice = '';
      formState.values.max_weight_in_schedule = null;
      // clearField();
      // eslint-disable-next-line
    }, [formState.values.deliveryType, formState.values.addressByCity, formState.values.cityByDeliveryType]);
    
    useEffect(() => {//Ловим изменение шаблона max_weight
      if (formState.values.deliveryType === 'bus' ||
        formState.values.deliveryType === 'point_x' ||
        formState.values.deliveryType === 'delivery_in_the_city' ||
        formState.values.deliveryType === 'point_office') {
        
        if ((formState.values.max_weight_in_schedule !== null && formState.values.max_weight_in_schedule > 0) && formState.values.max_weight_in_schedule < totalVeightOrder) {
          
          setShowBusWarning(true);
          setWarningModal(true);
        } else {
          setShowBusWarning(false);
          setWarningModal(false);
          
        }
        
        if (formState.values.deliveryMinPrice > totalCost) {
          setShowBusWarningPrice(true);
          setWarningModal(true);
        } else {
          setShowBusWarningPrice(false);
          setWarningModal(false);
        }
      } else {
        setShowBusWarning(false);
        setShowBusWarningPrice(false);
        // setWarningModal(false)
      }
      
      setShowWeightWarning(false);
      let max_weight_NP = formState.values.max_weight_NP;
      // let is_enable =  formState.values.is_enable;
      // let maxWeightTemplateDelivery = rememberCartOderData.values.delivery_department_max_weight;
      let max_weight = rememberCartOderData.values.max_weight;
      
      
      if (rememberCartOderData.values.deliveryService === 9 && rememberCartOderData.values.deliveryDepartment !== '') {
        
        if (rememberCartOderData.values.templateDelivery === -1 || rememberCartOderData.values.templateDelivery === 0) {
          if (totalVeightOrder > max_weight_NP && max_weight === '' && max_weight_NP !== 0) {
            setShowWeightWarning(true);
          } else if (max_weight !== '' && totalVeightOrder > max_weight && max_weight !== 0) {
            setShowWeightWarning(true);
          } else if (max_weight === '' && totalVeightOrder > max_weight_NP && max_weight_NP === 0) {
            setShowWeightWarning(false);
          } else if (max_weight !== '' && max_weight !== 0 && totalVeightOrder > max_weight) {
            setShowWeightWarning(true);
          } else {
            setShowWeightWarning(false);
          }
        } else if (rememberCartOderData.values.templateDelivery > 0) {
          if (totalVeightOrder > formState.values.delivery_department_max_weight && formState.values.delivery_department_max_weight !== 0) {
            setShowWeightWarning(true);
            setWarningModal(true);
          } else {
            setShowWeightWarning(false);
            setWarningModal(false);
          }
        }
      }
    }, [rememberCartOderData, totalVeightOrder, formState, totalCost, formState.values.cityByDeliveryType]);
    
    useEffect(() => {
      if (formState.values.is_enable !== undefined && formState.values.is_enable !== '' && formState.values.is_enable !== null) {
        if (formState.values.is_enable === true) {
          
          setShowWeightWarning(false);
        } else {
          setWarningModal(true);
          setShowWeightWarning(true);
          
        }
      }
      if ((formState.values.deliveryType === 'delivery_service' &&
          deliverySchedulerDateTime.loading === false &&
          deliverySchedulerDateTime.data.length > 0
          && formState.values.deliveryDepartment !== '') ||
        (formState.values.deliveryType !== 'delivery_service' && formState.values.deliveryDateTime !== '')) {
        setShowPrice(true);
      } else {
        setShowPrice(false);
      }
      
      // eslint-disable-next-line
    }, [formState, deliverySchedulerDateTime]);
    
    useEffect(() => {
      
      if (formState.values.deliveryType === 'scheduled_delivery') {
        if ((formState.values.max_weight_in_schedule !== null && formState.values.max_weight_in_schedule > 0) && totalVeightOrder > formState.values.max_weight_in_schedule) {
          setShowWeightWarning(true);
          setWarningModal(true);
        } else if (formState.values.is_enable === false) {
          setWarningModal(true);
          // setShowWeightWarning(false);
        } else if (formState.values.deliveryMinPrice !== '' && formState.values.deliveryMinPrice > totalCost) {
          setWarningModal(true);
        } else {
          setWarningModal(false);
        }
      }
      
      if (
        (formState.values.deliveryType !== 'delivery_service' && formState.values.deliveryMinPrice !== '' && formState.values.deliveryMinPrice > totalCost)
        ||
        // (deliverySchedulerDateTime && deliverySchedulerDateTime.loading === false && deliverySchedulerDateTime.data[0].min_amount > totalCost)
        // (formState.values.max_weight_in_schedule!==null && formState.values.max_weight_in_schedule !== 0 && formState.values.max_weight_in_schedule< totalVeightOrder) ||
        // showWeightWarning ||
        showBusWarning ||
        (deliverySchedulerDateTime.data && deliverySchedulerDateTime.data.length === 0 && formState.values.deliveryType !== '')
      ) {
        
        setWarningModal(true);
      }
      
      // eslint-disable-next-line
    }, [
      formState.values.deliveryType,
      formState.values.deliveryMinPrice,
      // formState.values.deliveryTypeWeight,
      formState.values.max_weight_in_schedule,
      totalCost,
      totalVeightOrder,
      showWeightWarning,
      deliverySchedulerDateTime,
      formState.values.is_enable,
      showBusWarning,
    ]);
    
    
    const warningClose = () => {
      // setShowWeightWarning(false);
      setWarningModal(false);
      
      // window.scrollTo( {
      //     top: 10,
      //     behavior: "smooth"
      // });
      
    };
    
    /*-------------------------------------------------*/
    
    
    const getCurrencySymbol = () => {
      if (financeCalendarByOrganization.loading === false && financeCalendarByOrganization.result.current_currency !== 'UAH') {
        
        return ' ' + financeCalendarByOrganization.result.current_symbol;
      }
    };
    
    const renderHelpModal = (type) => {
      setHandleCloseModal(true);
      setTypeModal(type);
    };
    
    let find_massive_param = massive_param.filter(x => (x === 1));
    
    return (
      
      <form onSubmit={handleSignUp} style={{width: '100%'}}>
        <Grid container
          // style={{display: "contents"}}
        >
          <Grid item className={classes.leftContainerOrder}>
            {/*<h4 className={classes.header2}>Доставка</h4>*/}
            <Grid container>
              <Grid container item xs={!is767 ? 7 : 12} align='left'>
                            <span
                              style={{
                                fontSize: 14,
                                width: '100%',
                                paddingBottom: is767 ? '8px' : '',
                              }}>
                                {t('common:customer')} : <b style={{color: '#606060'}}>{customer_name}</b>
                            </span>
              </Grid>
              <Grid container item xs={!is767 ? 5 : 12} align={!is767 ? 'right' : 'left'}>
                            <span style={{
                              fontSize: 14,
                              width: '100%',
                              paddingBottom: is767 ? '8px' : '',
                            }}>
                                {t('common:warehouse')} : <b style={{color: '#606060'}}>{name_warehouse}</b>
                            </span>
                {/*<span style={{fontSize: 14, width: '100%',  marginTop: -8}}><PriceToStr
                            data={totalCost}
                            // stAll={{marginLeft: 10}}
                            beforeText={''}
                            text={currencyMainContrSimbol}
                            grn={t('common:uah')}/>
                        </span>*/}
              </Grid>
            </Grid>
            <Hidden smUp>
              <div>
                {authentication.loggedIn ?
                  /* finance block */
                  <>
                    
                    <div className={classes.financeContainer}>
                      <Grid container>
                        
                        <div className={classes.balanceRow}>
                          <Grid item xs={8} className={classes.financeLeft}>
                            {financeCalendarByOrganization.result && financeCalendarByOrganization.result.full_payment * -1 < 0 ? t('common:debt') : t('common:your_balance')}:
                          </Grid>
                          <Grid item xs={4} className={classes.financeRight}>
                            <b>
                              <PriceToStr
                                data={financeCalendarByOrganization.result && financeCalendarByOrganization.result.full_payment * -1}
                                text={currencyMainContrSimbol}
                                grn={t('common:uah')}
                              />
                            </b>
                            <b>{getCurrencySymbol()}</b>
                          </Grid>
                        </div>
                        
                        {financeCalendarByOrganization.result && financeCalendarByOrganization.result.overdue_payment > 0 ?
                          <div className={classes.balanceRow}>
                            <Grid item xs={8} className={classes.financeLeft}
                                  style={{color: '#C43939'}}>{t('common:outdated_debt')}:</Grid>
                            <Grid item xs={4} className={classes.financeRight}>
                              <b style={{color: '#C43939'}}>
                                <PriceToStr
                                  data={financeCalendarByOrganization.result && financeCalendarByOrganization.result.overdue_payment}
                                  text={currencyMainContrSimbol}
                                  grn={t('common:uah')}
                                />
                              </b>
                              <b style={{color: '#C43939'}}>{getCurrencySymbol()}</b>
                            </Grid>
                          </div> : null}
                        
                        {financeCalendarByOrganization.result && financeCalendarByOrganization.result.shipment_allowed ?
                          <div className={classes.balanceRow}>
                            <Grid item xs={8}
                                  className={classes.financeLeft}>{t('common:shipment_available_sum_2')}: </Grid>
                            <Grid item xs={4} className={classes.financeRight}>
                              <b>
                                <PriceToStr
                                  data={shipment_available_sum}
                                  text={currencyMainContrSimbol}
                                  grn={t('common:uah')}
                                />
                              </b>
                              <b>{getCurrencySymbol()}</b>
                            </Grid>
                          </div> : null}
                        {financeCalendarByOrganization.result && financeCalendarByOrganization.result.recommendedToPay > 0 ?
                          <div className={classes.balanceRow}>
                            <Grid item xs={8}
                                  className={classes.financeLeft}><b>{t('common:recommended_to_pay_2')}:</b></Grid>
                            <Grid item xs={4} className={classes.financeRight}>
                              <b>
                                <PriceToStr
                                  data={financeCalendarByOrganization.result.recommendedToPay}
                                />
                              </b>
                              <b>{getCurrencySymbol()}</b>
                            </Grid>
                          </div>
                          : null}
                      
                      </Grid>
                    </div>
                  </>
                  : null}
                {/*<Divider className={classes.divider}/>*/}
              </div>
            </Hidden>
            {authentication.loggedIn &&
              <>
                <Template
                  title={t('common:delivery_template')}
                  formState={formState}
                  typeTemplate={'templateDelivery'}
                  clickTemplate={clickTemplateDelivery}
                  showTemplate={showTemplateDelivery}
                  
                  id='selectTemplateDelivery'
                  name='selectTemplateDelivery'
                  descriptName='templateDeliveryName'
                  valueName='templateDelivery'
                  titleSel='Введіть шаблон доставки'
                  
                  authentication={authentication}
                  actHandleChange={actHandleChange}
                  listTemplate={listTemplateDelivery.data ? listTemplateDelivery.data : []}
                />
                
                {formState.values.templateDelivery !== '' && formState.values.templateDelivery > 0 && formState.values.deliveryCity !== '' && formState.values.deliveryDepartment !== '' &&
                  <div className={classes.delivery_shipment}>
                    <span style={{display: 'flex', padding: '0 5px 0 0'}}> {t('common:delivery_shipment')}:</span>
                    {deliverySchedulerDateTime !== undefined && deliverySchedulerDateTime.data !== undefined && deliverySchedulerDateTime.data.length > 0 ? (
                      !/\d\d:\d\d:\d\d/.test(deliverySchedulerDateTime.data[0].date.comment) ?
                        moment(deliverySchedulerDateTime.data[0].date).format('DD.MM.YYYY HH:mm') + ' ' + days[new Date(deliverySchedulerDateTime.data[0].date).getDay()] : ''
                    
                    ) : ''}</div>
                }
              
              </>
            }
            
            {(formState.values.templateDelivery === 0 || formState.values.templateDelivery === -1 || authentication.loggedIn === undefined) &&
              <div className={'select_delivery_type_container'}
                // style={{display: "contents"}}
              >
                <MyFormOrderSelect
                  valueName='deliveryType'
                  title={t('common:choose_the_type_delivery')}
                  list={listTypeDelivery}
                  item={(value) => (
                    <MenuItem key={value.code} value={value.code}>{value.name}</MenuItem>)}
                />
                {isDeliveryServiceSelected() &&
                  <div
                    className={'select_delivery_service_container'}
                    // style={{display: "contents"}}
                  >
                    <MyFormOrderSelect
                      valueName='deliveryService'
                      title={t('common:choose_delivery_service')}
                      list={listDeliveryServices}
                      item={(value) => (
                        (value.id === 12 || value.id === 10 || value.id === 13)
                        && id_warehouse === 134 //ЗАГЛУШКА - Для склада КИЕВ (134) недоступен САТ (deliveryService: 12), Автолюкс (deliveryService: 9) Курьерской службы(delivery_type: 4)
                          ? ''
                          : <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                      )}
                    />
                    {formState.values.deliveryService !== '' &&
                      <div
                        // style={{display: "contents"}}\
                        className={'city_and_department_container'}
                      >
                        <Grid container className={clsx(classes.blStrData, 'blStrData')}>
                          <Grid className={'input_grid'} item xs={12} lg={12}>
                            <FormOrderSelectAsync
                              custom_class={'select_delivery_city'}
                              defValue={formState.values.deliveryCityName}
                              formState={formState}
                              actHandleChange={actHandleChange}
                              descriptName='deliveryCityName'
                              valueName='deliveryCity'
                              title={t('common:select_delivery_city')}
                              method={'post'}
                              
                              getList={(d) => (d.data.results.map(v => ({
                                id: v.id,
                                name: v.text,
                              })))}
                              url={`/api/delivery/${formState.values.deliveryService}/cities_search/?this_is_delivery_service_id=true`}
                            />
                          </Grid>
                        </Grid>
                        {formState.values.addressDelivery &&
                          <TextField
                            size='small'
                            className={clsx(classes.textField, 'textField')}
                            error={!!hasError('addressDeliveryText')}
                            fullWidth
                            /*helperText={
                                                    hasError('addressDeliveryText') ? formState.errors.addressDeliveryText : null
                                                    }*/
                            label={t('common:select_delivery_address')}
                            name='addressDeliveryText'
                            onChange={handleChange}
                            type='text'
                            value={formState.values.addressDeliveryText || ''}
                            variant='outlined'
                          />
                        }
                        {formState.values.deliveryCity !== '' &&
                          (<FormOrderSelectAsync
                            custom_class={'select_delivery_department_name'}
                            defValue={formState.values.deliveryDepartmentName}
                            formState={formState}
                            actHandleChange={actHandleChange}
                            descriptName='deliveryDepartmentName'
                            valueName='deliveryDepartment'
                            title={t('common:select_delivery office')}
                            method={'get'}
                            getList={(d) => (d.data.data)}
                            url={`/api/delivery/${formState.values.deliveryCity}/departments/?length=${orderLength}&height=${orderHeight}&width=${orderWidth}&weight=${allWeightNP > totalVeightOrder ? allWeightNP : totalVeightOrder}`}
                          />)
                        }
                        {formState.values.deliveryCity !== '' && formState.values.deliveryDepartment !== '' &&
                          <div className={classes.delivery_shipment}>
                            <span style={{display: 'flex', padding: '0 5px 0 0'}}> {t('common:delivery_shipment')}:</span>
                            {deliverySchedulerDateTime.data ? (
                              !/\d\d:\d\d:\d\d/.test(deliverySchedulerDateTime.data[0].date.comment) ?
                                moment(deliverySchedulerDateTime.data[0].date).format('DD.MM.YYYY HH:mm') + ' ' + days[new Date(deliverySchedulerDateTime.data[0].date).getDay()] : ''
                            
                            ) : ''}</div>
                        }
                      
                      
                      </div>
                    }
                  </div>
                }
                
                {!isDeliveryServiceSelected() && formState.values.deliveryType !== '' &&
                  <div style={{display: 'contents'}}>
                    <MyFormOrderSelect
                      valueName='cityByDeliveryType'
                      title={t('common:select_delivery_city')}
                      list={citiesByDeliveryType}
                      item={(value) => (
                        <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>)}
                    />
                    <MyFormOrderSelect
                      valueName='addressByCity'
                      title={t('common:select_delivery_address')}
                      list={addressesByCity}
                      item={(value) => (
                        <MenuItem key={value.id} value={value.id}>{value.address}</MenuItem>)}
                    />
                    <div
                      className={deliverySchedulerDateTime.data && deliverySchedulerDateTime.data.length === 0 ? classes.disabledSelect : ''}>
                      <MyFormOrderSelect
                        valueName='deliveryDateTime'
                        title={t('common:select_delivery_shipment')}
                        list={deliverySchedulerDateTime}
                        item={(value) =>
                          (<MenuItem key={value.date}
                                     style={{color: value.is_enable === false ? '#838383' : ''}}
                                     value={value.date}>
                            {!/\d\d:\d\d:\d\d/.test(value.comment) ?
                              moment(value.date).format('DD.MM.YYYY HH:mm') + ' ' + days[new Date(value.date).getDay()] : ''}
                            
                            {/\d\d:\d\d:\d\d/.test(value.comment) ?
                              moment(value.date).format('DD.MM.YY') + ' (' + days[new Date(value.date).getDay()] + ') ' + value.comment.substring(0, value.comment.lastIndexOf(':')) + ' - ' + moment(value.date).format('HH:mm') : ''}
                          
                          </MenuItem>)}
                      />
                    </div>
                  </div>
                }
                {formState.values.addressByCity !== '' && formState.values.deliveryType !== 'delivery_service' &&
                  <Template2
                    formState={formState}
                  />
                }
                
                {(formState.values.deliveryType === 'delivery_service' || formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'bus') &&
                  <React.Fragment>
                    <Divider className={classes.divider} />
                    {authentication.loggedIn && formState.values.templateDelivery !== -1 &&
                      <>
                        <Template
                          title={t('common:recipient_template')}
                          formState={formState}
                          typeTemplate={'templateReceiver'}
                          clickTemplate={clickTemplateReceiver}
                          showTemplate={showTemplateReceiver}
                          
                          id='selectTemplateReceiver'
                          name='selectTemplateReceiver'
                          descriptName='templateReceiverName'
                          valueName='templateReceiver'
                          titleSel={t('common:select_recipient_template')}
                          
                          authentication={authentication}
                          actHandleChange={actHandleChange}
                          listTemplate={
                            listTemplateReceiver.data &&
                            (formState.values.deliveryType === 'point_x' || formState.values.deliveryType === 'bus')
                              ? listTemplateReceiver.data.filter(x => (x.recipient_type === 'p' || x.id === 0))
                              : listTemplateReceiver.data
                          }
                        
                        />
                      
                      </>
                    }
                    
                    {(authentication.loggedIn === undefined || formState.values.templateReceiver === 0 || formState.values.templateReceiver === -1) &&
                      <div
                        // style={{display: "contents"}}
                        className={'recipient_info_container'}
                      >
                        
                        {formState.values.deliveryType !== 'point_x' && formState.values.deliveryType !== 'bus'
                          ?
                          <Grid spacing={2} container>
                            <Grid className={'input_grid'} item xs={12} lg={4}>
                              <MyFormOrderSelect
                                // style={{width: '59%'}}
                                valueName='supliers'
                                title={t('common:recipient_type')}
                                list={dataSupliers}
                                item={(value, index) => (
                                  <MenuItem key={index}
                                            value={value.code}>{value.name}</MenuItem>)}
                              />
                            </Grid>
                            
                            <Grid className={'input_grid'} item xs={6} lg={4}>
                              <FormControl
                                item xs={12} lg={6}
                                className={'order_select_container'}
                                variant='outlined'
                                // style={{width: '100%', marginTop: 15}}
                                size='small'
                              >
                                <InputLabel id='operator_type'>{t('common:operator_code')}</InputLabel>
                                <Select
                                  value={formState.values.operatorCodes}
                                  onChange={handleChange}
                                  labelWidth={50}
                                  name='operatorCodes'
                                  labelId='operator_type'
                                  label={t('common:operator_code')}
                                  error={hasError('selectOperatorCodes') ? true : false}
                                  id='operator_type'
                                >
                                  {mobileOperatorCodes && mobileOperatorCodes.map((item, index) =>
                                    <MenuItem
                                      value={`${item.country_code} (${item.operator_name})`}>{item.country_code} ({item.operator_name})</MenuItem>,
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid className={'input_grid'} item xs={6} lg={4}>
                              <InputElement
                                mask='999-99-99'
                                onChange={handleChange}
                                value={formState.values.phone}
                                name='phone'
                                placeholder={t('common:enter_your_phone')}
                                label={t('common:enter_your_phone')}
                                
                                error={hasError('phone') ? true : false}
                              
                              >
                                {(inputProps) => <TextField {...inputProps}
                                                            size='small'
                                                            style={{width: '100%'}}
                                                            variant='outlined'
                                                            className={clsx(classes.textField, 'textField')}
                                                            type='tel' /*disableUnderline*/
                                                            onChange={props.onChange}
                                                            error={hasError('phone') ? true : false}
                                  // helperText={hasError('phone') ? formState.errors.phone[0] : null}
                                />}
                              </InputElement>
                            
                            </Grid>
                          </Grid>
                          :
                          <Grid spacing={2} container>
                            <Grid className={'input_grid'} item xs={6} lg={4}>
                              <FormControl
                                className={'order_select_container'}
                                variant='outlined'
                                size='small'
                              >
                                <InputLabel id='operator_type'>{t('common:operator_code')}</InputLabel>
                                <Select
                                  value={formState.values.operatorCodes}
                                  onChange={handleChange}
                                  labelWidth={50}
                                  name='operatorCodes'
                                  labelId='operator_type'
                                  label={t('common:operator_code')}
                                  error={hasError('selectOperatorCodes') ? true : false}
                                  id='operator_type'
                                >
                                  {mobileOperatorCodes && mobileOperatorCodes.map((item, index) =>
                                    <MenuItem
                                      value={`${item.country_code} (${item.operator_name})`}>{item.country_code} ({item.operator_name})</MenuItem>,
                                  )}
                                
                                
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid className={'input_grid'} item xs={6} lg={8}>
                              <InputElement
                                mask='999-99-99'
                                onChange={handleChange}
                                value={formState.values.phone}
                                name='phone'
                                placeholder={t('common:enter_your_phone')}
                                label={t('common:enter_your_phone')}
                                fullWidth
                                error={hasError('phone') ? true : false}
                              
                              >
                                {(inputProps) => <TextField {...inputProps}
                                                            size='small'
                                                            style={{width: '100%'}}
                                                            variant='outlined'
                                                            className={clsx(classes.textField, 'textField')}
                                                            type='tel' /*disableUnderline*/
                                                            onChange={props.onChange}
                                                            error={hasError('phone') ? true : false}
                                  // helperText={hasError('phone') ? formState.errors.phone[0] : null}
                                />}
                              </InputElement>
                            </Grid>
                          </Grid>
                        }
                        {formState.values.supliers === 'p' &&
                          <Grid container spacing={2} className={clsx(classes.blStrData, 'blStrData')}>
                            <Grid item xs={12} md={4}>
                              <TextField
                                size='small'
                                // style={{width: '40%'}}
                                className={clsx(classes.textField, 'textField')}
                                error={hasError('lastName') ? true : false}
                                fullWidth
                                /*helperText={
                                                            hasError('lastName') ? formState.errors.lastName[0] : null
                                                            }*/
                                label={t('common:Surname')}
                                name='lastName'
                                onChange={handleChange}
                                type='text'
                                value={formState.values.lastName || ''}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                size='small'
                                // style={{width: '25%'}}
                                className={clsx(classes.textField, 'textField')}
                                error={hasError('fierstName') ? true : false}
                                fullWidth
                                /*helperText={
                                                            hasError('fierstName') ? formState.errors.fierstName[0] : null
                                                            }*/
                                label={t('common:Name')}
                                name='fierstName'
                                onChange={handleChange}
                                type='text'
                                value={formState.values.fierstName || ''}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                size='small'
                                // style={{width: '33%'}}
                                className={clsx(classes.textField, 'textField')}
                                error={hasError('fatherName') ? true : false}
                                fullWidth
                                /*helperText={
                                                            hasError('fatherName') ? formState.errors.fatherName[0] : null
                                                            }*/
                                label={t('common:middle_name')}
                                name='fatherName'
                                onChange={handleChange}
                                type='text'
                                value={formState.values.fatherName || ''}
                                variant='outlined'
                              />
                            </Grid>
                          </Grid>
                        }
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={8}>
                            {formState.values.supliers === 'c' &&
                              <TextField
                                size='small'
                                className={clsx(classes.textField, 'textField')}
                                error={hasError('company') ? true : false}
                                fullWidth
                                /*helperText={
                                                            hasError('company') ? formState.errors.company[0] : null
                                                            }*/
                                label={t('common:company_name')}
                                name='company'
                                onChange={handleChange}
                                type='text'
                                value={formState.values.company || ''}
                                variant='outlined'
                              />
                            }
                          </Grid>
                          <Grid item xs={12} md={4}>
                            {((formState.values.deliveryType === 'delivery_service' && formState.values.supliers === 'c') || (formState.values.deliveryType === 'bus' && formState.values.supliers === 'c')) &&
                              <React.Fragment>
                                {/*<MyFormOrderSelect
                                            valueName="payment"
                                            title="Виберіть спосіб оплати"
                                            list={dataPayment}
                                            item={(value, index) => (
                                                <MenuItem key={index} value={value.code}>{value.name}</MenuItem>)}
                                        />*/}
                                {formState.values.payment === 'cashless' && formState.values.edrpouTemplate === false &&
                                  <TextField
                                    size='small'
                                    // style={{width: '59%',}}
                                    className={clsx(classes.textField, 'textField')}
                                    error={hasError('edrpou') ? true : false}
                                    fullWidth
                                    /*helperText={
                                                                        hasError('edrpou') ? formState.errors.edrpou[0] : null
                                                                    }*/
                                    label={t('common:edrpou2')}
                                    name='edrpou'
                                    onChange={handleChange}
                                    type='numeric'
                                    value={formState.values.edrpou || ''}
                                    variant='outlined'
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                    /*error={hasError('deliveryDepartment2')}*/
                                  />
                                }
                              </React.Fragment>
                            }
                          </Grid>
                        </Grid>
                      </div>
                    }
                  </React.Fragment>
                }
              
              </div>
            }
            
            {formState.values.templateDelivery > 0 && formState.values.addressByCity !== '' &&
              <React.Fragment>
                <MyFormOrderSelect
                  valueName='deliveryDateTime'
                  title={t('common:select_delivery_shipment')}
                  list={deliverySchedulerDateTime}
                  item={(value) =>
                    (<MenuItem key={value.date}
                               style={{color: value.is_enable === false ? '#838383' : ''}}
                               value={value.date}>
                      {!/\d\d:\d\d:\d\d/.test(value.comment) ?
                        moment(value.date).format('DD.MM.YYYY HH:mm') + ' ' + days[new Date(value.date).getDay()] : ''}
                      
                      {/\d\d:\d\d:\d\d/.test(value.comment) ?
                        moment(value.date).format('DD.MM.YY') + ' (' + days[new Date(value.date).getDay()] + ') ' + value.comment.substring(0, value.comment.lastIndexOf(':')) + ' - ' + moment(value.date).format('HH:mm') : ''}
                    
                    </MenuItem>)}
                />
                <Template2
                  formState={formState}
                />
              </React.Fragment>
            }
            
            <Grid spacing={2} alignItems={'center'} className={'delivery_options'} container>
              {authentication.loggedIn &&
                (formState.values.deliveryType === 'delivery_service' || formState.values.deliveryType === 'point_x') &&
                formState.values.templateDelivery !== '' &&
                <React.Fragment>
                  <Grid item xs={12} lg={12
                    // authentication.loggedIn && customer_data.dropshipping &&
                    // formState.values.deliveryType === "delivery_service" && !formState.values.returnDelivery ? 4 : 12
                  }>
                    {id_warehouse !== 134 &&
                    formState.values.deliveryService !== 12 ?
                      (
                        <Grid spacing={2} alignItems={'center'} container>
                          
                          <Grid item lg={formState.values.returnDelivery ? 4 : 12}
                                style={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                            
                            <FormControlLabel
                              className={'order_checkbox_container'}
                              style={{marginRight: 0, whiteSpace: 'no-wrap'}}
                              control={
                                <GreenCheckbox
                                  className={'order_checkbox'}
                                  disabled={(customer_data.dropshipping === false || warehouse_dropshipping_enable === false || !authentication.loggedIn)}
                                  name='returnDelivery'
                                  id='returnDelivery'
                                  checked={formState.values.returnDelivery}
                                  onChange={handleChange}
                                  value={formState.values.returnDelivery}
                                />
                              }
                              label={t('common:return_delivery')}
                            />
                            {/* {customer_data.dropshipping===false &&
                                                    <span className={classes.helpModalBtn}
                                                          onClick={() => renderHelpModal('return_delivery')}>?</span>
                                                    } */}
                          
                          
                          </Grid>
                          
                          {formState.values.returnDelivery &&
                            <Grid item xs={12} lg={8}>
                              <TextField
                                size='small'
                                style={{width: '100%'}}
                                className={'return_delivery_sum'}
                                error={hasError('returnDeliverySum') ? true : false}
                                fullWidth
                                /*helperText={
                                                            hasError('returnDeliverySum') ? formState.errors.returnDeliverySum[0] : null
                                                            }*/
                                label={t('common:amount_in_uah')}
                                name='returnDeliverySum'
                                onChange={handleChange}
                                type='numeric'
                                value={formState.values.returnDeliverySum || ''}
                                variant='outlined'
                                /*error={hasError('deliveryDepartment2')}*/
                              />
                            </Grid>
                          }
                        </Grid>
                      ) : null}
                    {formState.values.deliveryType === 'delivery_service' ? (
                      <Grid spacing={2} alignItems={'center'} container>
                        <Grid item xs={4}>
                          
                          <FormControlLabel
                            className={'ttn_checkbox_container'}
                            // style={{width: '40%', marginTop: 10}}
                            control={
                              <GreenCheckbox
                                className={'ttn_checkbox'}
                                disabled={!authentication.loggedIn}
                                name='ttnDelivery'
                                id='ttnDelivery'
                                checked={formState.values.ttnDelivery}
                                onChange={handleChange}
                                value={formState.values.ttnDelivery}
                              />
                            }
                            label={t('common:ttn_delivery')}
                          />
                        
                        </Grid>
                        {formState.values.ttnDelivery &&
                          <>
                            <Grid className={'input_grid'} item xs={6} lg={4}>
                              <FormControl
                                // item xs={12} lg={6}
                                className={'order_select_container'}
                                variant='outlined'
                                // style={{width: '100%', marginTop: 15}}
                                size='small'
                              >
                                <InputLabel id='operator_type'>{t('common:operator_code')}</InputLabel>
                                
                                <Select
                                  value={formState.values.operatorCodesTTN}
                                  // defaultValue = {formState.values.operatorCodesTTN && formState.values.operatorCodesTTN.length > 5 ? formState.values.operatorCodesTTN : onlyPhone[0]+')'}
                                  // value={onlyPhone[0]+')'}
                                  // defaultValue = {onlyPhone[0]+')'}
                                  onChange={handleChange}
                                  labelWidth={50}
                                  name='operatorCodesTTN'
                                  labelId='operator_type'
                                  label={t('common:operator_code')}
                                  error={hasError('operatorCodesTTN') ? true : false}
                                  id='operator_type'
                                >
                                  {mobileOperatorCodes && mobileOperatorCodes.map((item, index) =>
                                    <MenuItem selected
                                              value={`${item.country_code} (${item.operator_name})`}>{item.country_code} ({item.operator_name})</MenuItem>,
                                  )}
                                
                                
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid className={'input_grid'} item xs={6} lg={4}>
                              <InputElement
                                mask='999-99-99'
                                onChange={handleChange}
                                value={formState.values.ttnPhone}
                                // defaultValue = {onlyPhone[1]}
                                // value={ onlyPhone[1]}
                                name='ttnPhone'
                                placeholder={t('common:enter_your_phone')}
                                label={t('common:enter_your_phone')}
                                style={{width: '100%'}}
                                
                                error={hasError('ttnPhone') ? true : false}
                              
                              >
                                {(inputProps) => <TextField {...inputProps}
                                                            size='small'
                                                            style={{width: '100%'}}
                                                            variant='outlined'
                                                            className={clsx(classes.textField, 'textField')}
                                                            type='tel' /*disableUnderline*/
                                                            onChange={props.onChange}
                                                            error={hasError('ttnPhone') ? true : false}
                                  // helperText={hasError('phone') ? formState.errors.phone[0] : null}
                                />}
                              </InputElement>
                            
                            </Grid>
                          </>
                        }
                      </Grid>
                    ) : ''}
                  </Grid>
                </React.Fragment>
              }
              
              
              {/*{authentication.loggedIn && customer_data.dropshipping &&*/}
              {/*(formState.values.deliveryType === "delivery_service" || formState.values.deliveryType === "point_x") &&*/}
              {/*<Grid item xs={12} lg={12}>*/}
              {/*    <FormControlLabel*/}
              {/*        className={"order_checkbox_container"}*/}
              {/*        // style={{width: '40%', marginTop: 10}}*/}
              {/*        control={*/}
              {/*            <GreenCheckbox*/}
              {/*                className={"order_checkbox"}*/}
              {/*                // disabled={false}*/}
              {/*                name='dropshipping'*/}
              {/*                id='dropshipping'*/}
              {/*                checked={formState.values.dropshipping}*/}
              {/*                onChange={handleChange}*/}
              {/*                value={formState.values.dropshipping}*/}
              {/*            />*/}
              {/*        }*/}
              {/*        label={t('common:dropshipping')}*/}
              {/*    />*/}
              {/*</Grid>*/}
              {/*}*/}
            
            
            </Grid>
            {showDropshippingBanner && typeof (formState.values.templateDelivery) !== 'number' &&
              <div className={classes.modalLogoBlock}>
                <img src={dropshipping_banner} alt='dropshipping_banner' />
              </div>
            }
          
          
          </Grid>
          
          <Grid item className={classes.rightContainerOrder}>
            <div>
              <Hidden smDown>
                <div>
                  {authentication.loggedIn ?
                    /* finance block */
                    <>
                      <div className={classes.financeContainer}>
                        <Grid container>
                          
                          <div className={classes.balanceRow}>
                            <Grid item xs={9} className={classes.financeLeft}>
                              {financeCalendarByOrganization.result && financeCalendarByOrganization.result.full_payment * -1 < 0 ? t('common:debt') : t('common:your_balance')}:
                            </Grid>
                            <Grid item xs={3} className={classes.financeRight}>
                              <b>
                                <PriceToStr
                                  data={financeCalendarByOrganization.result && financeCalendarByOrganization.result.full_payment * -1}
                                  text={currencyMainContrSimbol}
                                  grn={t('common:uah')}
                                />
                              </b>
                              <b>{getCurrencySymbol()}</b>
                            </Grid>
                          </div>
                          
                          {financeCalendarByOrganization.result && financeCalendarByOrganization.result.overdue_payment > 0 ?
                            <div className={classes.balanceRow}>
                              <Grid item xs={9} className={classes.financeLeft}
                                    style={{color: '#C43939'}}>{t('common:outdated_debt')}:</Grid>
                              <Grid item xs={3} className={classes.financeRight}>
                                <b style={{color: '#C43939'}}>
                                  <PriceToStr
                                    data={financeCalendarByOrganization.result && financeCalendarByOrganization.result.overdue_payment}
                                    text={currencyMainContrSimbol}
                                    grn={t('common:uah')} />
                                  <b style={{color: '#C43939'}}>{getCurrencySymbol()}</b>
                                </b>
                              </Grid>
                            </div> : null}
                          
                          {financeCalendarByOrganization.result && financeCalendarByOrganization.result.shipment_allowed ?
                            <div className={classes.balanceRow}>
                              <Grid item xs={9}
                                    className={classes.financeLeft}>{t('common:shipment_available_sum_2')}: </Grid>
                              <Grid item xs={3} className={classes.financeRight}>
                                <b>
                                  <PriceToStr
                                    data={shipment_available_sum}
                                    text={currencyMainContrSimbol}
                                    grn={t('common:uah')}
                                  />
                                </b>
                                <b>{getCurrencySymbol()}</b>
                              </Grid>
                            </div> : null}
                          
                          {financeCalendarByOrganization.result && financeCalendarByOrganization.result.recommendedToPay > 0 ?
                            <div className={classes.balanceRow}>
                              <Grid item xs={9} className={classes.financeLeft}><b>{t('common:recommended_to_pay_2')}:</b></Grid>
                              <Grid item xs={3} className={classes.financeRight}>
                                <b>
                                  <PriceToStr data={financeCalendarByOrganization.result.recommendedToPay}
                                  />
                                </b>
                                <b>{getCurrencySymbol()}</b>
                              </Grid>
                            </div>
                            : null}
                        
                        </Grid>
                      </div>
                    </>
                    : null}
                
                </div>
              </Hidden>
              <div className={classes.rightOrderBlock} style={{}}>
                <>
                  <h3>{t('common:your_order')}:</h3>
                  <div className={classes.yourOrderRow}>
                    <Grid container className={classes.orderInfoStyle}>
                      <Grid item xs={8} className={classes.financeLeft}
                            style={{color: showWeightWarning || showBusWarning ? '#c43939' : ''}}>
                        {t('common:weight')} / {t('common:volumetric_weight')}:
                        {/* <span className={classes.helpModalBtn}
                                                      onClick={() => renderHelpModal('weight')}>?</span> */}
                      </Grid>
                      <Grid item xs={4} className={classes.financeRight}>
                        <b style={{color: showWeightWarning || showBusWarning ? '#c43939' : ''}}>
                          {totalVeightOrder} {t('common:kg')}
                        </b>
                      </Grid>
                    </Grid>
                    
                    <Grid container className={classes.orderInfoStyle}>
                      <Grid item xs={8} className={classes.financeLeft}
                            style={{color: formState.values.deliveryMinPrice > totalCost ? '#c43939' : ''}}>
                        <strong>{t('common:goodsWorth')}:</strong>
                      </Grid>
                      <Grid item xs={4} className={classes.financeRight}>
                        <b style={{color: formState.values.deliveryMinPrice > totalCost ? '#c43939' : ''}}>
                          <PriceToStr data={totalCost ? totalCost : 0}
                                      stAll={{marginLeft: 10}}
                                      text={currencyMainContrSimbol}
                                      grn={t('common:uah')}
                          
                          />
                          {getCurrencySymbol()}
                        </b>
                      </Grid>
                    </Grid>
                  </div>
                </>
                <Divider className={classes.divider} />
                <div className={classes.deliveryContainer}>
                  {formState.values.amount_min_amount > 0 || (termsByFreeShipping.results && termsByFreeShipping.results.amount_min_amount > 0) ?
                    <Grid container className={classes.orderInfoStyle}>
                      <Grid item xs={8} className={classes.financeLeft}
                            style={{color: formState.values.deliveryMinPrice > totalCost ? '#c43939' : ''}}>
                        {t('common:minimalCoastOrder')}:
                        {/* <span className={classes.helpModalBtn}
                                                      onClick={() => renderHelpModal('minimalCoastOrder')}>?</span> */}
                      </Grid>
                      <Grid item xs={4} className={classes.financeRight}>
                        {showPrice ?
                          <b style={{color: formState.values.deliveryMinPrice > totalCost ? '#c43939' : ''}}>
                            
                            {/*{formState.values.deliveryType === "delivery_service" ?*/}
                            {/*    <>*/}
                            {/*        <PriceToStr data={termsByFreeShipping && termsByFreeShipping.results.amount_min_amount > 0 ? termsByFreeShipping.results.amount_min_amount : 0}*/}
                            {/*                    stAll={{marginLeft: 10}}*/}
                            {/*        />*/}
                            {/*        {getCurrencySymbol()}*/}
                            {/*    </>*/}
                            {/*    :*/}
                            {/*    <>*/}
                            {/*        <PriceToStr data={termsByFreeShipping && termsByFreeShipping.results.amount_min_amount > 0 ? termsByFreeShipping.results.amount_min_amount : 0}*/}
                            {/*                    stAll={{marginLeft: 10}}*/}
                            
                            {/*        />*/}
                            {/*        {getCurrencySymbol()}*/}
                            {/*    </>*/}
                            {/*}*/}
                            
                            <>
                              <PriceToStr
                                data={termsByFreeShipping && termsByFreeShipping.results.amount_min_amount > 0 ? termsByFreeShipping.results.amount_min_amount : 0}
                                stAll={{marginLeft: 10}}
                                text={currencyMainContrSimbol}
                                grn={t('common:uah')}
                              />
                              {getCurrencySymbol()}
                            </>
                          
                          </b>
                          : <b>...</b>
                        }
                      
                      </Grid>
                    </Grid>
                    : null
                  }
                  
                  {termsByFreeShipping.results && termsByFreeShipping.results.amount_free_shipping !== null && !isDelivery && (formState.values.deliveryDateTime !== '' || formState.values.deliveryDepartment !== '')
                    ?
                    
                    <Grid container className={classes.orderInfoStyle}>
                      <Grid item xs={9} className={classes.financeLeft}>
                        {t('common:until_free_delivery_is_left')}:
                        {/* <span className={classes.helpModalBtn}
                                                      onClick={() => renderHelpModal('freeDeliveryIsLeft')}>?</span> */}
                      </Grid>
                      <Grid item xs={3} className={classes.financeRight}>
                        {showPrice ?
                          <b>
                            <>
                              <PriceToStr
                                data={termsByFreeShipping.results ? termsByFreeShipping.results.amount_free_shipping : 0}
                                stAll={{marginLeft: 10}}
                              
                              />
                              {getCurrencySymbol()}
                            </>
                            
                            {/*{termsByFreeShipping.results && termsByFreeShipping.results.amount_free_shipping}*/}
                          </b>
                          :
                          <b>...</b>
                        }
                      
                      </Grid>
                    </Grid>
                    : null
                  }
                  
                  {formState.values.deliveryType === 'delivery_service' &&
                  ((termsByFreeShipping && termsByFreeShipping.loading === false && termsByFreeShipping.results.amount_free_shipping > 0) ||
                    (termsByFreeShipping && termsByFreeShipping.loading === false && termsByFreeShipping.results.amount_free_shipping === null)) ?
                    <></>
                    :
                    <Grid container className={classes.orderInfoStyle}>
                      <Grid item xs={8} className={classes.financeLeft}>
                        {t('common:cost_of_delivery')}:
                        {/* <span className={classes.helpModalBtn}
                                                      onClick={() => renderHelpModal('cost_of_delivery')}>?</span> */}
                      </Grid>
                      <Grid item xs={4} className={classes.financeRight}>
                        {showPrice ?
                          <>
                            {formState.values.deliveryType === 'delivery_service' && (deliverySchedulerDateTime.data && deliverySchedulerDateTime.data[0].amount_free_shipping === 0) ?
                              <b>
                                <PriceToStr data={0}
                                            stAll={{marginLeft: 10}}
                                            text={currencyMainContrSimbol}
                                            grn={t('common:uah')}
                                />
                                {getCurrencySymbol()}
                              
                              </b>
                              :
                              <b>
                                <PriceToStr data={
                                  formState.values.price &&
                                  termsByFreeShipping.results &&
                                  (termsByFreeShipping.results.amount_free_shipping > 0 || termsByFreeShipping.results.amount_free_shipping === null) ? formState.values.price : 0}
                                            stAll={{marginLeft: 10}}
                                            text={currencyMainContrSimbol}
                                            grn={t('common:uah')}
                                
                                />
                                {getCurrencySymbol()}
                                {/*{formState.values.price}*/}
                              </b>
                              
                              
                            }
                          </>
                          : <b>...</b>
                        }
                      </Grid>
                    </Grid>
                    
                  }
                  
                  <Grid container className={classes.orderInfoStyle}>
                    <Grid item xs={8} className={classes.financeLeft}>
                      {t('common:cost_of_additional_packaging')}:
                    </Grid>
                    <Grid item xs={4} className={classes.financeRight}>
                      {showPrice ?
                        <b>
                          <>
                            <PriceToStr data={
                              // termsByFreeShipping.results && termsByFreeShipping.results.amount_free_shipping !== null && find_massive_param.length > 0
                              termsByFreeShipping.results && find_massive_param.length > 0
                                ? termsByFreeShipping.results.price_massive : 0}
                                        stAll={{marginLeft: 10}}
                                        text={currencyMainContrSimbol}
                                        grn={t('common:uah')}
                            
                            />
                            {getCurrencySymbol()}
                          </>
                        </b>
                        :
                        <b>...</b>
                      }
                    
                    </Grid>
                  </Grid>
                  
                  
                  {formState.values.deliveryType === 'delivery_service' &&
                  ((termsByFreeShipping && termsByFreeShipping.loading === false && termsByFreeShipping.results.amount_free_shipping > 0)
                    || (termsByFreeShipping && termsByFreeShipping.loading === false && termsByFreeShipping.results.amount_free_shipping === null))
                    ?
                    <Grid container className={classes.orderInfoStyle}>
                      <Grid item xs={4} className={classes.financeLeft}>
                        <strong>{t('common:delivery_amount')}:</strong>
                      </Grid>
                      <Grid item xs={8} className={classes.financeRight}>
                        <span>{t('common:At_carrier_rates')}</span>
                      </Grid>
                    </Grid>
                    :
                    <Grid container className={classes.orderInfoStyle}>
                      <Grid item xs={8} className={classes.financeLeft}>
                        <strong>{t('common:delivery_amount')}:</strong>
                      </Grid>
                      <Grid item xs={4} className={classes.financeRight}>
                        {showPrice ?
                          <b>
                            <PriceToStr data={
                              
                              (termsByFreeShipping.results &&
                              (termsByFreeShipping.results.amount_free_shipping > 0 || termsByFreeShipping.results.amount_free_shipping === null) ? formState.values.price : 0) +
                              (find_massive_param.length > 0 ? formState.values.price_massive : 0)}
                                        stAll={{marginLeft: 10}}
                            
                            />
                            {getCurrencySymbol()}
                          </b>
                          : <b>...</b>
                        }
                      
                      </Grid>
                    </Grid>
                  }
                </div>
                <Divider className={classes.divider} />
                <Grid container style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <Grid item xs={7} className={classes.financeLeft}>
                    <h3>{t('common:to_pay')}:</h3>
                  </Grid>
                  <Grid item xs={5} className={classes.financeRight}>
                    {showPrice ?
                      <>
                        
                        {totalCost &&
                          <b style={{fontSize: '20px'}}>
                            <PriceToStr data={
                              parseFloat(totalCost) +
                              parseFloat(
                                (termsByFreeShipping.results &&
                                (termsByFreeShipping.results.amount_free_shipping > 0 || termsByFreeShipping.results.amount_free_shipping === null) ? formState.values.price : 0) +
                                (find_massive_param.length > 0 ? formState.values.price_massive : 0),
                              )
                            }
                                        stAll={{marginLeft: 10}}
                                        text={currencyMainContrSimbol}
                                        grn={t('common:uah')}
                            />
                            {getCurrencySymbol()}
                          </b>
                        }
                      </>
                      :
                      <b>...</b>
                    }
                  
                  </Grid>
                </Grid>
                {totalCost > shipment_available_sum && shipment_available_sum !== 0 ?
                  <h4 style={{color: '#008000', width: '100%', margin: '5px 0'}}>{t('common:shipment_after')}!</h4>
                  : null}
                
                <div style={{width: '100%', textAlign: 'right'}}>
                  <Hidden xsDown>
                    <button
                      style={{margin: '0 0 auto auto'}}
                      className={clsx('btn mb-1', (showWeightWarning === false) &&
                      (showBusWarning === false) &&
                      (showBusWarningPrice === false) &&
                      (formState.isValid === true) &&
                      (formState.values.deliveryMinPrice > totalCost) === false ? false : true ? classes.disabled : '')}
                    
                    >
                      {t('common:order_from_basket2') + '  '}
                      
                      {/*{totalCost &&*/}
                      {/*<PriceToStr data={totalCost}*/}
                      {/*            stAll={{marginLeft: 10}}*/}
                      {/*            beforeText={''}*/}
                      {/*            text={currencyMainContrSimbol}*/}
                      {/*            grn={t('common:uah')}/>*/}
                      {/*}*/}
                    
                    </button>
                  </Hidden>
                  <Hidden smUp>
                    <button
                      style={{width: '100%'}}
                      className={clsx('btn mb-1', (showWeightWarning === false) &&
                      (showBusWarning === false) &&
                      (showBusWarningPrice === false) &&
                      (formState.isValid === true) &&
                      (formState.values.deliveryMinPrice > totalCost) === false ? false : true ? classes.disabled : '')}
                      // color="primary"
                      // disabled={
                      //     (showWeightWarning === false) &&
                      //         (showBusWarning === false) &&
                      //         (showBusWarningPrice === false) &&
                      //         (formState.isValid === true) &&
                      //         (formState.values.deliveryMinPrice > totalCost) === false ? false : true}
                      fullWidth={true}
                      //  size="large"
                      type='submit'
                      //  variant="contained"
                    >
                      {t('common:To issue') + '  '}
                      
                      {/*{totalCost &&*/}
                      {/*<PriceToStr data={totalCost}*/}
                      {/*            stAll={{marginLeft: 10}}*/}
                      {/*            beforeText={''}*/}
                      {/*            text={currencyMainContrSimbol}*/}
                      {/*            grn={t('common:uah')}/>*/}
                      {/*}*/}
                    
                    </button>
                  </Hidden>
                </div>
                <div style={{fontSize: '12px', color: '#606060', textAlign: 'center'}}>
                  {t('common:By_confirming_the_order')}
                  <Link to='/user_agreement' target='_blank'>
                                    <span style={{color: '#315EFB'}}>
                                        {t('common:user_agreement2')}
                                    </span>
                  </Link>
                </div>
                
                <Dialog onClose={handleCloseModal}
                        maxWidth='sm'
                        fullWidth={true}
                        open={handleCloseModal}>
                  <DialogTitle id='customized-dialog-title' style={{padding: '16px 24px 0'}}
                               onClick={() => setHandleCloseModal(false)}>
                    <Grid container item xs={12}
                          className={clsx(classes.gridContainerHr, classes.stMainHeaderHelp)}>
                      <span className={classes.stMainHeader}>{t('common:DearCustomer')}!</span>
                      {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                      <IconButton
                        onClick={() => setHandleCloseModal(false)}
                        className='close' size={'small'}
                        style={{
                          overflow: 'hidden',
                          borderRadius: '50%',
                          width: '24px',
                          height: '24px',
                        }}
                      >
                        <Cansel />
                      </IconButton>
                    </Grid>
                    <Divider className={classes.divider} />
                  
                  </DialogTitle>
                  <DialogContent
                    className={classes.warehouseHelpContent}>
                    {authentication.loggedIn ? (
                      typeModal === 'weight' ?
                        <>
                          <p>{t('common:weight_text')}</p>
                          <p>{t('common:weight_text2')}</p>
                        </>
                        : typeModal === 'cost_of_delivery' ?
                          <p>{t('common:cost_of_delivery_text')}</p>
                          : typeModal === 'minimalCoastOrder' ?
                            <strong className={classes.warningMessage}>
                              {t('common:minimalOrderCaost')}
                              <PriceToStr data={formState.values.deliveryMinPrice}
                                          stAll={{marginLeft: 10}}
                                          beforeText={''}
                                          text={currencyMainContrSimbol}
                                          grn={t('common:uah')} />
                              <br />
                              {t('common:minimalOrderCaost2')}
                              <>
                                <PriceToStr
                                  data={termsByFreeShipping.loading === false && termsByFreeShipping.results.amount_min_amount > 0 ? termsByFreeShipping.results.amount_min_amount : 0}
                                  beforeText={''}
                                  text={currencyMainContrSimbol}
                                  grn={t('common:uah')}
                                  stAll={{margin: '0 5px'}}
                                />,
                                {t('common:minimalOrderCaost3')}
                              </>
                            </strong>
                            // <p>{t('common:minimalCoastOrder_text')}</p>
                            : typeModal === 'freeDeliveryIsLeft' ? <>
                                <p>
                                  {t('common:freeDeliveryIsLeft_text')}
                                  <b>
                                    <PriceToStr data={totalCost ? totalCost : 0}
                                                stAll={{marginLeft: 5}}
                                      // beforeText={''}
                                                text={currencyMainContrSimbol}
                                                grn={t('common:uah')}
                                    />
                                    {getCurrencySymbol()}
                                  </b>
                                
                                </p>
                                <p>{t('common:freeDeliveryIsLeft_text2')}
                                  <b>
                                    <PriceToStr data={
                                      deliverySchedulerDateTime !== undefined && deliverySchedulerDateTime.data !== undefined &&
                                      deliverySchedulerDateTime.data.length > 0 ?
                                        deliverySchedulerDateTime.data[0].min_amount_free_shipping : 0}
                                                stAll={{marginLeft: 5}}
                                      // beforeText={''}
                                      // text={currencyMainContrSimbol}
                                      // grn={t('common:uah')}
                                    />
                                    {getCurrencySymbol()}
                                  </b>
                                </p>
                                {termsByFreeShipping.results && termsByFreeShipping.results.amount_free_shipping > 0 ?
                                  <>
                                    <p>{t('common:freeDeliveryIsLeft_text3')}
                                      <b>
                                        <PriceToStr
                                          data={termsByFreeShipping.results ? termsByFreeShipping.results.amount_free_shipping : 0}
                                          stAll={{marginLeft: 5}}
                                          // beforeText={''}
                                          text={currencyMainContrSimbol}
                                          grn={t('common:uah')}
                                        />
                                        {getCurrencySymbol()}
                                      </b>
                                    </p>
                                    <p>{t('common:freeDeliveryIsLeft_text4')}</p>
                                  </>
                                  :
                                  <p>{t('common:minimalOrderCaost4')}</p>
                                }
                              </>
                              
                              : typeModal === 'return_delivery' ?
                                <p>{t('common:return_delivery_text')}</p> : null
                    
                    
                    ) : (
                      <strong>{t('common:ship_warehouse')}</strong>
                    )
                    }
                  
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          
          </Grid>
          
          
          <Dialog onClose={warningClose}
                  maxWidth='sm'
                  fullWidth={true}
                  open={warningModal}>
            <DialogTitle id='customized-dialog-title' style={{padding: '16px 16px 5px'}} onClick={() => warningClose}>
              
              <Grid container item xs={12}
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    className={clsx(classes.gridContainerHr, classes.stMainHeaderHelp)}>
                <span className={classes.stMainHeader}>{t('common:DearCustomer')}!</span>
                {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                <IconButton onClick={() => warningClose()} className='close'
                            size={'small'}
                            style={{
                              overflow: 'hidden',
                              borderRadius: '50%',
                              width: '24px',
                              height: '24px',
                            }}>
                  <Cansel />
                </IconButton>
              </Grid>
              <Divider className={classes.divider} />
            
            </DialogTitle>
            <DialogContent className={classes.warningMessageContent}>
              <div className={classes.blockWarningMessage}>
                {deliverySchedulerDateTime.data && deliverySchedulerDateTime.data.length === 0 ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <strong className={classes.warningMessage}>{t('common:noDeliverySchedulerDateTime')}</strong>
                    </Grid>
                  </Grid>
                ) : null}
                {showWeightWarning ?
                  <Grid container>
                    <Grid item xs={12}>
                      {/*<strong className={classes.warningMessage}>Вага замовлення  {allWeightNP> totalVeightOrder ? allWeightNP: totalVeightOrder } кг перевищує допустиму вагу відділення, оберіть інше відділення або місто</strong>*/}
                      {(formState.values.deliveryType === 'delivery_service' && formState.values.is_enable === false) ||
                      (formState.values.deliveryType === 'delivery_service' && showWeightWarning) ?
                        <strong className={classes.warningMessage}>{t('common:orderWarningMessage')}</strong>
                        : formState.values.deliveryType !== 'delivery_service' && formState.values.is_enable === false ?
                          <strong className={classes.warningMessage}>{t('common:orderWarningMessage2')}</strong> : null
                      }
                    </Grid>
                  </Grid>
                  : ''}
                
                {formState.values.max_weight_in_schedule !== null && formState.values.max_weight_in_schedule !== 0 && formState.values.max_weight_in_schedule < totalVeightOrder ?
                  <Grid container>
                    <Grid item xs={12}>
                      <strong className={classes.warningMessage}>Максимальна Вага
                        замовлення {formState.values.max_weight_in_schedule} кг </strong>
                    </Grid>
                  </Grid>
                  : ''}
                
                {formState.values.deliveryMinPrice && formState.values.deliveryMinPrice > totalCost ?
                  <Grid container>
                    <Grid item xs={12}>
                      <strong className={classes.warningMessage}>
                        {t('common:minimalOrderCaost')}
                        <PriceToStr data={formState.values.deliveryMinPrice}
                                    stAll={{marginLeft: 10}}
                                    beforeText={''}
                                    text={currencyMainContrSimbol}
                                    grn={t('common:uah')} />
                        <br />
                        {t('common:minimalOrderCaost2')}
                        <>
                          <PriceToStr
                            data={termsByFreeShipping.loading === false && termsByFreeShipping.results.amount_min_amount > 0 ? termsByFreeShipping.results.amount_min_amount : 0}
                            beforeText={''}
                            text={currencyMainContrSimbol}
                            grn={t('common:uah')}
                            stAll={{margin: '0 5px'}}
                          />,
                          {t('common:minimalOrderCaost3')}
                        </>
                      </strong>
                    </Grid>
                  </Grid>
                  : ''}
              </div>
            
            </DialogContent>
          </Dialog>
        
        </Grid>
      </form>
    
    );
  }
;

FormOrder.propTypes = {
  history: PropTypes.object,
  autoComplete: PropTypes.string,
};

function mapState(state) {
  const {customer} = state;
  const {
    // listTypePayment,
    listDepartments,
    listTypeDelivery,
    listDeliveryServices,
    citiesByDeliveryType,
    addressesByCity,
    deliverySchedulerDateTime,
    deliveryScheduler,
    deliverySchedulerTime,
    authentication,
    userSelectedData,
    financeCalendarByOrganization,
    contragentData,
    // userBalance,
    termsByFreeShipping,
  } = state;
  const {customer_data} = state.contragentData;
  const {user} = state.frontendData;
  return {
    customer,
    user,
    listDepartments,
    // listTypePayment,
    listTypeDelivery,
    listDeliveryServices,
    citiesByDeliveryType,
    addressesByCity,
    deliverySchedulerDateTime,
    deliveryScheduler,
    deliverySchedulerTime,
    authentication,
    customer_data,
    userSelectedData,
    financeCalendarByOrganization,
    contragentData,
    // userBalance,
    termsByFreeShipping,
  };
}


const actionCreators = {
  // https://dev-api.daniparts.com/api/delivery-data/ - Доставки???
  //api/delivery/1158/settings_list/ - Шаблоны доставки???
  
  //api/delivery-settings/(customer_id1191)/delivery_services_list/ GET курьерские. службы
  //api/delivery-settings/(id службы)/cities/ POST(без параметров)  города
  //api/delivery/(id города)/departments/ GET        отделения
  
  // getListTypePayment: cartActions.getListTypePayment, //api/pay-type/ - виды оплаты
  // getListTypeDelivery: deliveryActions.getListTypeDelivery,
  // getListDeliveryService: deliveryActions.getListDeliveryService,
  
  getCityByDeliveryType: deliveryActions.getCityByDeliveryType,
  getAddressByCity: deliveryActions.getAddressByCity,
  getScheduler: deliveryActions.getScheduler,
  getSchedulerDateTime: deliveryActions.getSchedulerDateTime,
  getSchedulerTime: deliveryActions.getSchedulerTime,
  getCustomers: customerActions.getCustomers,
  createOrderAct: cartActions.createOrder,
  getListDepartments: cartActions.getListDepartments,
  setOrderFinanceData: cartActions.setOrderFinanceData,
  getTermsByFreeShipping: deliveryActions.getTermsByFreeShipping,
  
  // getListDelivery: cartActions.getListDelivery,
};

class Wrapper extends React.Component {
  
  componentDidMount() {
    if (!this.props.customer.results) this.props.getCustomers();
    
  }
  
  componentDidUpdate(prevProps) {
    
    if (this.props.rememberCartOderData.values.deliveryType !== prevProps.rememberCartOderData.values.deliveryType) {
      this.props.getCityByDeliveryType(this.props.rememberCartOderData.values.deliveryType, this.props.id_warehouse);
    }
    
    if (this.props.rememberCartOderData.values.cityByDeliveryType !== prevProps.rememberCartOderData.values.cityByDeliveryType) {
      if (this.props.rememberCartOderData.values.deliveryType !== '' &&
        this.props.rememberCartOderData.values.cityByDeliveryType !== '') {
        this.props.getAddressByCity(
          this.props.rememberCartOderData.values.deliveryType,
          this.props.rememberCartOderData.values.cityByDeliveryType,
        );
      }
    }
    
    if (this.props.rememberCartOderData.values.addressByCity !== prevProps.rememberCartOderData.values.addressByCity
      || this.props.rememberCartOderData.values.delivery_code_3pl !== prevProps.rememberCartOderData.values.delivery_code_3pl) {
      if (this.props.id_warehouse !== '' &&
        this.props.rememberCartOderData.values.addressByCity !== '' &&
        this.props.rememberCartOderData.values.deliveryType !== '' &&
        this.props.rememberCartOderData.values.cityByDeliveryType !== '' &&
        this.props.rememberCartOderData.values.delivery_code_3pl !== '') {
        
        this.props.getSchedulerDateTime(
          this.props.id_warehouse,
          this.props.rememberCartOderData.values.deliveryType,
          this.props.rememberCartOderData.values.cityByDeliveryType,
          this.props.rememberCartOderData.values.delivery_code_3pl,
          this.props.rememberCartOderData.values.orderLength,
          this.props.rememberCartOderData.values.orderHeight,
          this.props.rememberCartOderData.values.orderWidth,
          this.props.rememberCartOderData.values.allWeightNP,
          this.props.rememberCartOderData.values.totalVeightOrder,
          // this.props.userSelectedData.sell_currency_id,
        );
        /*this.props.getScheduler(
                this.props.id_warehouse,
                this.props.rememberCartOderData.values.deliveryType,
                this.props.rememberCartOderData.values.cityByDeliveryType,
                );*/
      }
    }
    
    
    if (this.props.rememberCartOderData.values.deliveryType === 'delivery_service' &&
      (this.props.rememberCartOderData.values.deliveryService !== '' &&
        // (this.props.rememberCartOderData.values.deliveryDepartment !== '') &&
        (prevProps.rememberCartOderData.values.deliveryService !== this.props.rememberCartOderData.values.deliveryService))) {
      
      let warehouse_city_id = 0;
      let delivery3pl = '';
      
      this.props.listDeliveryServices.data.map((item, index) => {
        if (item.id === this.props.rememberCartOderData.values.deliveryService) {
          delivery3pl = item.inside_number + 'delivery_service';
        }
        return delivery3pl;
      });
      warehouse_city_id = this.props.cityId;
      
      this.props.getSchedulerDateTime(
        this.props.id_warehouse,
        this.props.rememberCartOderData.values.deliveryType,
        warehouse_city_id,
        delivery3pl,
        this.props.rememberCartOderData.values.orderLength,
        this.props.rememberCartOderData.values.orderHeight,
        this.props.rememberCartOderData.values.orderWidth,
        this.props.rememberCartOderData.values.allWeightNP,
        this.props.rememberCartOderData.values.totalVeightOrder,
        // this.props.userSelectedData.sell_currency_id,
      );
    }
    
    if (this.props.rememberCartOderData.values.deliveryCity !== prevProps.rememberCartOderData.values.deliveryCity
      && this.props.rememberCartOderData.values.deliveryCity !== '') {
      this.props.getListDepartments(
        this.props.rememberCartOderData.values.deliveryCity,
        this.props.rememberCartOderData.values.orderLength,
        this.props.rememberCartOderData.values.orderHeight,
        this.props.rememberCartOderData.values.orderWidth,
        this.props.rememberCartOderData.values.allWeightNP,
        this.props.rememberCartOderData.values.totalVeightOrder);
    }
  }
  
  render() {
    return <FormOrder {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
