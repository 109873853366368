import React from 'react';
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  skeleton_innner: {
    width: '100%',
    height: 220,
    backgroundColor: '#DBDDE0',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 25,
  },
  'stoks-skeleton__wrapper': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 20,
    marginBottom: 40,
    '& .promotion__left': {
      height: '115%',
      width: '60%',
    },
  },
}));

export const StocksSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const skeletonBreakpoint = useMediaQuery(theme.breakpoints.down(767));
  const skeletonBreakpoint2 = useMediaQuery(theme.breakpoints.down(1200));

  const skeletonItem = () => (
    <div
      style={{height: skeletonBreakpoint ? 442 : ''}}
      className={clsx('promotion__item', classes.skeleton_innner)}>
      <div className="promotion__left">
        <Skeleton variant="rect" width={'100%'} height={'100%'} />
      </div>
      <div className="promotion__right" style={{height: '100%'}}>
        <Typography className="promotion__title">
          <Skeleton width={'260px'} />
        </Typography>
        <div className="promotion__description">
          <Typography>
            <Skeleton width={'90%'} />
          </Typography>
          <Typography>
            <Skeleton width={'60%'} />
          </Typography>
          <Typography>
            <Skeleton width={'96%'} />
          </Typography>
          <Typography>
            <Skeleton width={'30%'} />
          </Typography>
        </div>
        <div className="promotion__footer">
          <Typography>
            <Skeleton width={150} />
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <div className="wrapper-inner">
      <div className="products-block__header">
        <Typography className="title mb-4" variant="h2">
          <Skeleton width={80} />
        </Typography>
      </div>
      <div className="promotion-swiper swiper">
        {!skeletonBreakpoint && (
          <div className={classes['stoks-skeleton__wrapper']}>
            {skeletonItem()}
            {!skeletonBreakpoint2 && skeletonItem()}
          </div>
        )}
        {skeletonBreakpoint && (
          <div className={classes['stoks-skeleton__wrapper']}>
            {skeletonItem()}
            {skeletonItem()}
          </div>
        )}
      </div>
      <div className="skeleton-interface"></div>
    </div>
  );
};
