import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ProductInfo} from './ProductInfo';
import {ProductItem} from './ProductItem';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import {Info, NextBtn, PrevBtn} from '../SwiperInterface';
import {ProductSkeletons} from './ProductSkeletons';
import AddToCartDialog from '../../views/ProductList/components/AddToCartDialog/AddToCartDialog';
import {dataProcessing, getCurrencyContrData} from '../../_helpers';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {cartActions, searchActions} from '../../_actions';
import {productService} from '../../_services/product.service';
import {authentication} from '../../_reducers/authentication.reducer';

const productSliderBreakpoints = {
  100: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  767: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  830: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1023: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1340: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  1700: {
    slidesPerView: 5,
    spaceBetween: 20,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    '& .swiper-wrapper': {
      height: 450,
    },
  },
}));
const Product = props => {
  const {
    productBrandInfo,
    sell_currency_id,
    customer_data,
    addToCart,
    main_currency,
    customer_id,
    searchPromotions,
    cart,
    listMyFavorite,
    promotionsByProductsList,
    searchAvailability,
    currencies,
    promotions
  } = props;

  const history = useHistory();

  const [filteredProdutsList, setFilteredProdutsList] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slide, setSlide] = useState(null);
  const [dialogData, setDialogData] = useState({});
  const [hideProduct, setHideProduct] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);

  const currencyMainContr = getCurrencyContrData(
    currencyMain,
    main_currency,
    sell_currency_id,
    customer_data,
  );
  const customer_group_id = customer_data && customer_data.discount_group_id;

  useEffect(() => {
    productService
      .getProductByTradeMarks(
        productBrandInfo.id,
        customer_id,
        sell_currency_id,
      )
      .then(res => {
        const filteredList = res.data.results.filter(
          i => !i.balance.every(i => i.quantity === '0'),
        );
        const idsArr = filteredList?.map(item => item.product_id);
        searchPromotions(idsArr);
        setFilteredProdutsList(filteredList);
        setLoading(true);
        if (res.data.results.length === 0) {
          setHideProduct(true);
        }
      });
  }, []);

  const handleClose = props => {
    const {offers, localMainCartId} = props;
    if (offers) {
      Object.keys(offers).map(
        key =>
          offers[key].quantity > 0 &&
          addToCart(
            localMainCartId,
            key,
            offers[key].price,
            offers[key].quantity,
          ),
      );
    }
    setDialogData({});
  };
  let cartAll = cart && cart.list ? cart.list.all : [];
  let resultsMod = dataProcessing(
    currencyMain,
    filteredProdutsList,
    cartAll,
    listMyFavorite,
    1,
    searchAvailability,
    promotionsByProductsList,
  ).results;

  useEffect(() => {
    if (loading) {
      if (resultsMod.length === 0) {
        setHideProduct(true);
      } else {
        setHideProduct(false);
      }
    }
  }, [resultsMod]);

  if (hideProduct) {
    return <></>;
  }
  return (
    <>
      <div
        className={clsx('products-block product home-products', classes.root)}>
        <div className="wrapper-inner">
          <div className="screen d-flex">
            <ProductInfo {...productBrandInfo} />
            <div className="product__list-wrapper" style={{maxWidth: 'auto'}}>
              <div className="swiper">
                {!!resultsMod.length ? (
                  <Swiper
                    onSwiper={e => {
                      setSlide(e);
                    }}
                    onSlideChange={e => setCurrentSlideIndex(e.activeIndex)}
                    breakpoints={productSliderBreakpoints}
                    className="products-mobile"
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                    }}>
                    <div className="d-flex justify-between">
                      <h2
                        onClick={() =>
                          history.push(
                            `/brand_list/${productBrandInfo.url_repr}`,
                          )
                        }
                        className="title in__product">
                        {productBrandInfo.name}
                      </h2>
                      <div className="products-block__slick">
                        <PrevBtn isDeactive={currentSlideIndex + 1 === 1} />
                        <Info
                          currentCount={currentSlideIndex + 1}
                          totalCount={slide?.snapGrid.length}
                        />
                        <NextBtn
                          isDeactive={
                            currentSlideIndex + 1 === slide?.snapGrid.length
                          }
                        />
                      </div>
                    </div>
                    {resultsMod?.map((item, index) => (
                      <SwiperSlide
                        key={index}
                        className={clsx(
                          'product__item',
                          item.balance.every(i => i.quantity === '0')
                            ? 'empty'
                            : '',
                        )}>
                        <ProductItem
                          row={item}
                          setDialogData={setDialogData}
                          promoLabel={item.promoLabel}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <ProductSkeletons
                    productSliderBreakpoints={productSliderBreakpoints}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddToCartDialog
        dialogData={dialogData}
        handleClose={handleClose}
        currencyMainContr={currencyMainContr}
        currencyMain={currencyMain}
        customerGroupId={customer_group_id}
        authentication={authentication}
        discountsByProduct={
          dialogData.row && dialogData.row.discounts_by_quantity_sum_of_order
            ? dialogData.row.discounts_by_quantity_sum_of_order
            : dialogData.row && dialogData.row.balance[0] !== undefined
            ? dialogData.row &&
              dialogData.row.balance[0].discounts_by_quantity_sum_of_order
            : ''
        }
      />
    </>
  );
};

const mapState = state => {
  const {searchByOtherOwn, authentication} = state;

  const {customer_id, sell_currency_id} = state.userSelectedData;
  const {currencies, main_currency, user_perms} = state.frontendData;
  const {customer_data} = state.contragentData;
  const {
    contragentData,
    cart,
    listMyFavorite,
    promotionsByProductsList,
  } = state;
  const {searchAvailability} = state.searchParams;

  return {
    customer_id,
    sell_currency_id,
    currencies,
    main_currency,
    user_perms,
    searchByOtherOwn,
    authentication,
    customer_data,
    contragentData,
    cart,
    listMyFavorite,
    promotionsByProductsList,
    searchAvailability,
  };
};

const actionCreators = {
  addToCart: cartActions.addToCart,
  searchPromotions: searchActions.searchPromotions,
};

export default connect(mapState, actionCreators)(Product);
