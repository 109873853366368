import PropTypes from 'prop-types';
import PtoductsItemColumns from '../../ProductList/components/PtoductsNewTableColumns/PtoductsItemColumns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {dataProcessing} from '../../../_helpers';
import {connect} from 'react-redux';
import CarouselItem from './ProductInlineCarousel/CarouselItem';
import {useHistory, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {ProductItem} from '../../../_components/Product/ProductItem';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Info, NextBtn, PrevBtn} from '../../../_components/SwiperInterface';
import {useState} from 'react';

const sliderBreakpoints = {
  100: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  767: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  930: {
    slidesPerView: 4,
    spaceBetween: 20,
  },

  1340: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

const AnalogInStock = props => {
  const {setDialogData, list} = props;
  const [slide, setSlide] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  return (
    <Swiper
      onSwiper={e => setSlide(e)}
      slidesPerView={'auto'}
      onSlideChange={e => {
        setCurrentSlideIndex(e.activeIndex);
      }}
      breakpoints={sliderBreakpoints}
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        height: slide?.snapGrid.length >= 2 ? 500 : 450,
      }}>
      {slide?.snapGrid.length >= 2 && (
        <div className="products-block__slick">
          <PrevBtn isDeactive={currentSlideIndex + 1 === 1} />
          <Info
            currentCount={currentSlideIndex + 1}
            totalCount={slide?.snapGrid.length}
          />
          <NextBtn
            isDeactive={currentSlideIndex + 1 === slide?.snapGrid.length}
          />
        </div>
      )}

      {list.map(
        (row, index) =>
          row.count > 0 && (
            <SwiperSlide key={row.product_id} className="product__item">
              <ProductItem row={row} setDialogData={setDialogData} />
            </SwiperSlide>
          ),
      )}
    </Swiper>
  );
};

AnalogInStock.propTypes = {
  cartAll: PropTypes.any,
  currencyMain: PropTypes.any,
  currencyMainContr: PropTypes.any,
  data: PropTypes.any,
  handleUuidInfo: PropTypes.any,
  md: PropTypes.any,
  setAnalogList: PropTypes.any,
  setDialogData: PropTypes.any,
  setPhotosGallery: PropTypes.any,
  sm: PropTypes.any,
  xs: PropTypes.any,
};

function mapState(state) {
  const {listMyFavorite, promotionsByProductsList} = state;

  return {
    listMyFavorite,
    promotionsByProductsList,
  };
}

export default connect(mapState)(AnalogInStock);
// export default AnalogInStock;
