import {static_pages_Constants} from '../_constants';

export function staticPage(state = {}, action) {
  switch (action.type) {
    case static_pages_Constants.STATIC_PAGES_REQUEST:
      return {
        undefined,
        loading: true,
      };
    case static_pages_Constants.STATIC_PAGES_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case static_pages_Constants.STATIC_PAGES_FAILURE:
      return {
        error: action.error,
      };
    case static_pages_Constants.STATIC_PAGES_RESET:
      return {
        undefined
      };

    default:
      return state;
  }
}
