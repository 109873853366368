export const newsConstants = {
  GET_ALL_NEWS_REQUEST: 'GET_ALL_NEWS_REQUEST',
  GET_ALL_NEWS_SUCCESS: 'GET_ALL_NEWS_SUCCESS',
  GET_ALL_NEWS_FAILURE: 'GET_ALL_NEWS_FAILURE',

  GET_ALL_BLOGS_REQUEST: 'GET_ALL_BLOGS_REQUEST',
  GET_ALL_BLOGS_SUCCESS: 'GET_ALL_BLOGS_SUCCESS',
  GET_ALL_BLOGS_FAILURE: 'GET_ALL_BLOGS_FAILURE',

  GET_BLOG_REQUEST: 'GET_BLOG_REQUEST',
  GET_BLOG_SUCCESS: 'GET_BLOG_SUCCESS',
  GET_BLOG_FAILURE: 'GET_BLOG_FAILURE',
};
