import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import Dialog from "@material-ui/core/Dialog";

import Close from "../icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import clsx from "clsx";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
    stDialog: {},
    root: {
        //width: 900,
        flexGrow: 1,
    },

    slide: {
        maxHeight: (window.innerHeight - 150),
        height: (window.innerHeight - 150),
    },

    header: {
        // justifyContent: 'space-between',
        // display: 'flex',
        // alignItems: 'center',
        // height: 50,
        // paddingLeft: theme.spacing(4),
        // paddingRight: theme.spacing(4),
        // backgroundColor: theme.palette.background.default,
    },


    img: {
        //height: 500,//255,
        //maxWidth: 400,
        display: 'block',
        overflow: 'hidden',
        // width: '100%',

    },
}));

/*
window.screen.width и window.screen.height - отвечают за размеры экрана.
window.innerWidth и window.innerHeight  - отвечают за размер окна браузера.
window.screen.availWidth и window.screen.availHeight  - отвечают за непосредственный размер области отвечающей за вывод информации (очень полезно в мобильных браузерах)
*/

let id_old = '';
const SliderPhoto = props => {
    const classes = useStyles();

    const {photosGallery, setPhotosGallery} = props;

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up(1380), {
        defaultMatches: true,
    });
    //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let flag = isDesktop ? 'md' : 'sm';
    const [dialogWidth, setDialogWidth] = React.useState(flag);
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        if (photosGallery !== undefined && photosGallery.type === 'product') {
            setActiveStep(photosGallery.active_step);
        }
    }, [photosGallery, setActiveStep])

    if (photosGallery === undefined || !photosGallery || !photosGallery.type) return <React.Fragment/>;
    let type = photosGallery.type;

    let large;
    let id_global;
    let name;
    if (type === 'product') {
        name = photosGallery.result.article.name;
        large = photosGallery.result.article.photo.large_photos;
        id_global = photosGallery.result.article.brand_clean + '#' + photosGallery.result.article.article_clean;

    } else {
        name = photosGallery.row.name;
        type === "search"
            ? large = photosGallery.row.images.large
            : large = photosGallery.row.large_photos;
        id_global = photosGallery.row.brand_clean + '#' + photosGallery.row.article_clean;
    }


    //const {name} = type==='product' ? photosGallery.result.article.name : photosGallery.row.name;

    //const maxSteps = tutorialSteps.length;
    const maxSteps = large.length;

    large.map((row, index2) => {
            let id, bl, bl_div;
            let img = document.createElement('img');
            img.onload = e => {

                id = id_global + '#' + index2;
                bl = document.getElementById(id); //b.clientWidth || b.offsetWidth;
                if (bl !== null) {
                    //bl_div = bl.parentElement; //  <=> bl_div = bl.closest("div");
                    bl_div = bl.closest("div");
                    if (bl_div.offsetWidth > img.width) {//img.width  img.height
                        if (img.width > img.height) {
                            bl.style.width = '100%';
                            bl.style.height = 'auto';
                            //bl.style.maxHeight = bl_dialog.offsetHeight-164;
                        } else {
                            flag = 'sm';
                            //bl.style.width = 'auto';
                            //bl.style.height = '100%';
                        }
                    }
                }
                if (id_old !== (id_global) && type !== "product") {
                    id_old = id_global;
                    setActiveStep(0);
                    setDialogWidth(flag);
                }
            };
            img.src = row;
            return true;
        }
    );


    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    const handleClose = step => {
        setPhotosGallery();
    };

    return (
        <Dialog className={clsx(classes.stDialog, "slider_photo")}
                classes={{
                    paper: "photo_modal"
                }}
                id="dialogPhotos"
                fullWidth={true}
                scroll={'body'}
                maxWidth={dialogWidth}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={photosGallery !== undefined}
        >

            <div className={classes.root}>
                <Paper square elevation={0} className={clsx(classes.header, "header")}>
                    <Close onClick={handleClose} style={{cursor: 'pointer'}}/>
                </Paper>
                <AutoPlaySwipeableViews
                    id="AutoPlaySwipeableViews"
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    autoplay={false}
                >
                    {/*{tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label} />
            ) : null}
          </div>
        ))}*/}
                    {large.map((step, index) => (
                        <div className={clsx(classes.slide, "slide")} key={index} style={{widht: '100%'}}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img
                                    id={id_global + '#' + index}
                                    className={classes.img} src={step} alt={name}/>
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                            Back
                        </Button>
                    }
                />
            </div>

        </Dialog>
    );
};

class Wrapper extends React.Component {

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return <SliderPhoto {...this.props} />;
    }
}

export default Wrapper;
//export default SliderPhoto;
