import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function ShowTable(props) {
  const {isActive} = props;
  return (
    <SvgIcon {...props} viewBox={'0 0 24 24'}>
      <path
        d="M3,4H21V8H3V4M3,10H21V14H3V10M3,16H21V20H3V16Z"
        fill={isActive ? '#FFBE00' : '#777777'}
      />
    </SvgIcon>
  );
}
