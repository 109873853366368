import React, { useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';



// import {useTranslation} from 'react-i18next';




// import clsx from "clsx";

import TableBody from "@material-ui/core/TableBody";
// import NalStamp from "../../../images/daniBeznal.png";




/* ===================================================== */

const useStyles = makeStyles(theme => ({




  print_container: {
    padding: '15px',
    background: '#fff',
    width: '100%',
    margin: '0 auto',
  },

  formTemplate: {
    padding: '15px 15px 15px 70px',
    // minHeight: '1200px',
    position: 'relative',
  },

  headerWarningText: {
    fontSize: '12px',
    background: '#eee',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '3px 0'
  },


  watermark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-20deg)',
    width: '100%',
    textAlign: 'center',
    fontSize: '40px',
    opacity: 0.2,
    textTransform: 'uppercase'
  },

  stamp: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(90px, -50%)  rotate(-18deg)',
    width: '120px',
  },

  table: {
    // minWidth: 750,
    border: '1px solid #000',

    "& td": {
      padding: '10px !important',
    }
  },

  headerPrintBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px solid #000',
    padding: '0 0 10px',
    margin: '0 0 10px',
    fontSize: '15px',
    fontWeight: 600,
  },

  footerTable: {
    margin: '15px 0',
    padding: '0 0 10px',
    fontSize: 12,
    borderBottom: '1px solid #000',
  },

  footerPrintForm: {
    display: 'flex',

    "& strong": {
      margin: '0 0 5px',
      display: 'block',
      fontSize: 12,
    },

    "& span": {
      fontSize: 12,
    }
  },

  printFormHeader: {
    whiteSpace: 'nowrap',
    fontSize: 11,
    fontWeight: 600,
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    borderBottom: '1px solid #000',
  },

  itemCol: {
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    borderBottom: '1px solid #000',
    fontSize: 11,
    lineHeight: 1,
  },

  headerCompanyBlock: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'flex-start',
    margin: '5px 0 10px',
    "& span": {
      width: '120px',
    },
    "& strong": {
      textTransform: 'uppercase',
    }
  },
}));

/* ================================================================================== */

const PrintInvoiceForm = props => {
  const {
    printData

  } = props;
  const classes = useStyles();
  let printFormData = printData[0]?.data;

  /* ========================================================================== */


  useEffect(() => {

    // eslint-disable-next-line
  }, []);

  /* =============================================================================== */

  // const {t} = useTranslation();
  return (
    <>
      <React.Fragment>
        <div className={classes.print_container}>
          {/*========форма1=================*/}
          <div className={classes.formTemplate} style={{ pageBreakBefore: 'always' }}>
            <div className={classes.watermark}>не є офiцiйним документом</div>
            <Grid className={classes.grid} container>
              <Grid className={classes.content} item lg={12} xs={12}>
                <div className={classes.headerPrintBlock}>
                  <div>
                    Видаткова накладна {printFormData?.invoice_number} вiд {printFormData?.invoice_date}
                  </div>
                  <div>
                    Заказ {printFormData?.order_number_1с}
                  </div>
                </div>


                <div className={classes.headerCompanyBlock}>
                  <span>Постачальник:</span>
                  <strong>{printFormData?.provider}</strong>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Покупець:</span>
                  <strong>{printFormData?.buyer}</strong>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Договiр:</span>
                  <div style={{ fontSize: 13 }}>{printFormData?.contract}</div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Table className={classes.table} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.printFormHeader} align="center" style={{ maxWidth: '50px' }}>№</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style={{ maxWidth: '50px' }}>Артикул</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style={{ minWidth: '200px' }}>Товар</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style={{ maxWidth: '70px' }}>Кiль-сть</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style={{ maxWidth: '50px' }}>Од.</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style={{ maxWidth: '60px' }} item xs={12}>Цiна <br />з ПДВ</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style={{ maxWidth: '60px' }} item xs={12}>Сума <br />з ПДВ</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {printFormData?.items && printFormData?.items.map((el, index) => (
                      <TableRow key={'row' + index}>
                        <TableCell className={classes.itemCol} align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell className={classes.itemCol} align="center">
                          {el.article}
                        </TableCell>
                        <TableCell className={classes.itemCol}>
                          {el.name}
                        </TableCell>
                        <TableCell className={classes.itemCol} align="center">
                          {el.quantity}
                        </TableCell>
                        <TableCell className={classes.itemCol} align="center">
                          {el.units_of_measurement}
                        </TableCell>
                        <TableCell className={classes.itemCol} align="right">
                          {parseFloat(el.price).toFixed(2)}
                        </TableCell>
                        <TableCell className={classes.itemCol} align="right">
                          {parseFloat(el.sum).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow style={{ width: '100%' }}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                        Всього:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {parseFloat(printFormData?.sum).toFixed(2)}
                      </TableCell>
                    </TableRow>


                  </TableBody>
                </Table>
              </Grid>

            </Grid>
            <div className={classes.footerTable}>
              <div style={{ textAlign: 'left' }}>Всього найменувань {printFormData?.total_items}, на суму {parseFloat(printFormData.sum).toFixed(2)} {printFormData.currency} </div>
              <strong style={{ textAlign: 'left' }}>{printFormData?.sum_in_cuirsive}</strong>
            </div>

            <div className={classes.footerPrintForm}>
              <div style={{ width: '50%', margin: '0 20px 0 0' }}>
                <strong style={{ padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000' }}>Вiд постачальника*</strong>
                <span>Менеджер</span>
              </div>
              <div style={{ width: '50%', position: 'relative' }}>
                <strong style={{ padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000' }}>Отримав(ла)</strong>
                {/*<img className={classes.stamp} src={NalStamp} align="stamp"/>*/}
                <span>За довiренiстю</span>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    </>
  );
};

class WrapperPrint extends React.Component {

  render() {
    return <PrintInvoiceForm {...this.props} />;
  }
}

export default WrapperPrint;