import { cities as citiesConstants } from "../_constants/cities.constants";
import { positions as positionsConstants } from "../_constants/positions.constants";
import axInst from "../_helpers/axios-instance";

function getCities() {
  return dispacth => {
    dispacth(request());
    axInst.get('/api/get-cities')
      .then(response => {
        dispacth(success(response?.data?.cities));
      })
      .catch((e) => {
        dispacth(failrule(e.message));
      })
  };
  function request() {
    return { type: citiesConstants.GET_CITIES_REQUEST };
  }
  function success(data) {
    return { type: citiesConstants.GET_CITIES_SUCCESS, payload: data };
  }
  function failrule(error) {
    return { type: citiesConstants.GET_CITIES_ERROR, payload: error };
  }
}

function getPositions() {
  return dispacth => {
    dispacth(request());
    axInst.get('/api/get-positions')
      .then(response => {
        dispacth(success(response?.data?.positions));
      })
      .catch((e) => {
        dispacth(failrule(e.message));
      })
  };
  function request() {
    return { type: positionsConstants.GET_POSITIONS_REQUEST };
  }
  function success(data) {
    return { type: positionsConstants.GET_POSITIONS_SUCCESS, payload: data };
  }
  function failrule(error) {
    return { type: positionsConstants.GET_POSITIONS_ERROR, payload: error };
  }
}

export const autocompleteActions = {
  getCities,
  getPositions,
};