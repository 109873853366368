import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../_actions';
import axInst from '../../_helpers/axios-instance';
import PhoneVerificationModal from './PhoneVerificationModal';

const PhoneVerificationContext = createContext();

export const usePhoneVerification = () => useContext(PhoneVerificationContext);

const PhoneVerificationProvider = ({children}) => {
  const [frontendData, setFrontendData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [customer_id, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [personalManager, setPersonalManager] = useState(null);
  const [emptyPhone, setEmptyPhone] = useState(false);
  const customerNameValid = 'Новий покупець';

  // useEffect(() => {
  //   axInst.post('/api/get-frontend-data/', {}).then(res => {
  //     setFrontendData(res.data);
  //     const customerId = res.data.data.customer_default.customer_id;
  //     const customerName = res.data.data.customer_default.customer_name;
  //     setCustomerId(customerId);
  //     setCustomerName(customerName);
  //
  //     axInst
  //       .post('/api/get-customer-data/', {customer: customerId})
  //       .then(response => {
  //         setCustomerData(response.data);
  //       });
  //   });
  // }, []);
  //
  // const getPersonalManager = useCallback(id => {
  //   axInst
  //     .post('/api/get-personal-information-manager/', {customer_id: id})
  //     .then(response => {
  //       setPersonalManager(response.data);
  //     });
  // }, []);

  // useEffect(() => {
  //   if (customer_id) {
  //     getPersonalManager(customer_id);
  //     // setEmptyPhone(true);
  //   }
  // }, [customer_id, getPersonalManager]);
  //
  // useEffect(() => {
  //   if (customerName !== customerNameValid) {
  //     if (personalManager && !emptyPhone) {
  //       if (
  //         personalManager.phone.length < 1 ||
  //         !personalManager.phone.length === 0
  //       ) {
  //         setEmptyPhone(true);
  //       }
  //     }
  //   }
  // }, [personalManager, emptyPhone]);

  return (
    <PhoneVerificationContext.Provider value={{frontendData, customerData}}>
      {children}
      <PhoneVerificationModal
        open={emptyPhone}
        onClose={() => setEmptyPhone(false)}
      />
    </PhoneVerificationContext.Provider>
  );
};

const mapState = state => ({
  customer_id: state.userSelectedData.customer_id,
  personalManager: state.personalManager,
});

const mapAction = {
  getPersonalManager: userActions.getPersonalManager,
};

export default connect(mapState, mapAction)(PhoneVerificationProvider);
