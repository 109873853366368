import {newsConstants} from '../_constants';

export function blog(state = {}, action) {
  switch (action.type) {
    case newsConstants.GET_BLOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case newsConstants.GET_BLOG_SUCCESS:
      return {
        ...state,
        data: {
          ...action.data?.data,
        },
        loading: false,
      };
    case newsConstants.GET_BLOG_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
