import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';
// import Slider from 'react-slick';
import {Info, NextBtn, PrevBtn} from '../../_components/SwiperInterface';
import {useTheme, useMediaQuery} from '@material-ui/core';

import sliderLeftArrow from '../../images/left-arrow.svg';
import sliderRightArrow from '../../images/right-arrow.svg';
import {newsActions} from '../../_actions/news.actions';
import ImageWithFallback from '../../_components/ImageWithFallback/index';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination, Autoplay} from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import TopSliderSkeleton from './TopSliderSkeleton';
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    width: '100%'
    // maxWidth: 1920,

    /*  '& .slick-slide': {
            // backgroundColor: '#ccc',
            // height: 380,
            '&>div': {
                height: '100%',
            },
            '& a': {
                height: '100%',
            },
            '& img': {
                width: '100%',
                // height: '100%',
                objectFit: 'cover',
            },
            // [theme.breakpoints.up(1440)]: {
            //     height: 400,
            // },
            // [theme.breakpoints.up(1700)]: {
            //     height: 440,
            // },
            // [theme.breakpoints.up(1900)]: {
            //     height: 460,
            // },
        },*/
  },
  imageWithFallback: {
    width: '100%'
  }
}));

const TopSlider = props => {
  const classes = useStyles();
  const theme = useTheme();
  const topSliderBreakpoint4 = useMediaQuery(theme?.breakpoints.down(760));

  // const settings = {
  //     dots: true,
  //     autoplay: true,
  //     autoplaySpeed: 6000,
  //     speed: 800,
  //     adaptiveHeight: true,
  // };
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    props.getNews();
    // eslint-disable-next-line
  }, []);

  const {data} = props.news;

  if (!data || data.length === 0) {
    return (
        <div>
            <TopSliderSkeleton/>
        </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Swiper
          // navigation
          // pagination={{clickable: true}}
          slidesPerView={1}
          simulateTouch
          preloadImages={true}
          updateOnImagesReady={true}
          autoplay={{
            delay: 8000,
          }}
          // style={{marginTop:'39px'}}
          speed={1000}
          // breakpoints={{
          //     0 : {
          //         slidesPerView: 1
          //     },
          //     460 : {
          //         slidesPerView: 1,
          //         spaceBetween: 20
          //     },
          //     1200 : {
          //         slidesPerView : 1,
          //         spaceBetween: 20
          //     }
          // }}
          onSlideChange={e => setCurrentSlide(e.activeIndex)}>
          {data.data.map(item => (
            <SwiperSlide key={item.id}>
              <Link to={`/news/${item.id}`} key={item.id}>
                <ImageWithFallback
                  images={
                    window.innerWidth > 900
                      ? item.banner_desktop
                      : item.banner_tablet
                  }
                  imgAlt="Product silhouette"
                  imgClass={classes.imageWithFallback}
                />
              </Link>
            </SwiperSlide>
          ))}
          {data.data.length > 1 &&
            (topSliderBreakpoint4 ? (
              <div className="home-slider-nav-horizontal">
                <PrevBtn
                  isDeactive={!(currentSlide + 1 > 1)}
                  arrowType="home-slider"
                />
                <Info
                  currentCount={currentSlide + 1}
                  totalCount={data.data.length > 0 ? data.data.length : 1}
                />
                <NextBtn
                  isDeactive={!(currentSlide + 1 < data.data.length)}
                  arrowType="home-slider"
                />
              </div>
            ) : (
              <div className="home-slider-nav">
                <PrevBtn
                  isDeactive={!(currentSlide + 1 > 1)}
                  arrowType="home-slider"
                />
                <Info
                  currentCount={currentSlide + 1}
                  totalCount={data.data.length > 0 ? data.data.length : 1}
                />
                <NextBtn
                  isDeactive={!(currentSlide + 1 < data.data.length)}
                  arrowType="home-slider"
                />
              </div>
            ))}
        </Swiper>

        {/*<Slider {...settings}>*/}
        {/*    {data.data.map(item => (*/}
        {/*        <Link to={`/news/${item.id}`} key={item.id}>*/}
        {/*            <ImageWithFallback images={item.banner_handset} imgAlt='Product silhouette'/>*/}
        {/*            /!*<picture>*!/*/}
        {/*            /!*    {item.banner_tablet.map((item, id) => {*!/*/}
        {/*            /!*        let type = item.split(".").pop();*!/*/}
        {/*            /!*        if (type === "svg") {*!/*/}
        {/*            /!*            type = "svg+xml";*!/*/}
        {/*            /!*        }*!/*/}

        {/*            /!*        if (type === "jpg") {*!/*/}
        {/*            /!*            return null;*!/*/}
        {/*            /!*        }*!/*/}

        {/*            /!*        return <source key={id} srcSet={item} type={`image/${type}`} />;*!/*/}
        {/*            /!*    })}*!/*/}

        {/*            /!*    <img src={item.image} type={`image/${item.image.split(".").pop()}`} alt="news"/>*!/*/}
        {/*            /!*</picture>*!/*/}

        {/*            /!*<img src={item.image_url} alt="news"/>*!/*/}
        {/*        </Link>*/}
        {/*    ))}*/}
        {/*</Slider>*/}
      </div>
    );
  }
};

function mapState(state) {
  const news = state.news;
  return {news};
}

const actionCreators = {
  getNews: newsActions.getNews,
};

export default connect(mapState, actionCreators)(TopSlider);
