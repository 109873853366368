import {userConstants} from '../../_constants';

export function catalogTree(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_CATALOG_TREE_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_CATALOG_TREE_SUCCESS:
      return {
        data: {...state.data,...action.data},
      };
    case userConstants.GET_CATALOG_TREE_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
