import React from 'react';
import {Link} from 'react-router-dom';

export const BreadCrumbs = ({items}) => {

  const removeSlash = str => {
    const res = str.split('');
    if (res[res.length - 1] === '/') {
      res.pop();
    }
    return res.join('');
  };
  return (
    <div className="wrapper-inner">
      <ul className="breadcrumbs mb-0">
        <li>
          <Link to="/">
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.216179 3.47961C0.216362 3.47943 0.216545 3.47925 0.216728 3.47906L3.48009 0.21582C3.61919 0.0766602 3.80413 0 4.00085 0C4.19756 0 4.3825 0.0765991 4.52166 0.215759L7.78332 3.47736C7.78442 3.47845 7.78551 3.47961 7.78661 3.48071C8.07226 3.76801 8.07177 4.23413 7.78521 4.52069C7.65429 4.65167 7.48138 4.72754 7.2965 4.73547C7.28899 4.73621 7.28142 4.73657 7.2738 4.73657H7.14373V7.13812C7.14373 7.61334 6.75707 8 6.28173 8H5.005C4.8756 8 4.77062 7.89508 4.77062 7.76562V5.88281C4.77062 5.66595 4.59423 5.48956 4.37737 5.48956H3.62432C3.40746 5.48956 3.23107 5.66595 3.23107 5.88281V7.76562C3.23107 7.89508 3.12615 8 2.9967 8H1.71996C1.24462 8 0.857964 7.61334 0.857964 7.13812V4.73657H0.737358C0.540703 4.73657 0.355766 4.65997 0.216545 4.52081C-0.0703201 4.23376 -0.0704422 3.76685 0.216179 3.47961Z"
                fill="#777777"
              />
            </svg>
          </Link>
        </li>
        {items?.find(i => i.type)
          ? items?.map(item => (
              <li key={item.path}>
                {!item.path ? (
                  <span>{item.title}</span>
                ) : (
                  <Link to={removeSlash(`/${item.type}/${item.path}`)}>
                    <span>{item.title}</span>
                  </Link>
                )}
              </li>
            ))
          : items?.map(item => (
              <li key={item.path}>
                {!item.path ? (
                  <span>{item.title}</span>
                ) : (
                  <Link to={removeSlash(item.path)}>
                    <span>{item.title}</span>
                  </Link>
                )}
              </li>
            ))}
      </ul>
    </div>
  );
};
