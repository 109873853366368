import React from 'react'
import { useTranslation } from 'react-i18next';
// import mui
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: "8px",
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    list: {
        padding: 0,
        margin: 0,
    },
}));

const PaymentItem = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <section className={`dashboard-card ${classes.wrapper}`}>
            <h2 className="dashboard-title" style={{ fontSize: 14, fontWeight: 700 }}>{t("common:payment_conditions")}</h2>
            <ul className={`${classes.list} ${classes.wrapper} dashboard-text`}>
                <li>{t("common:delivery_condition_no_payment")}</li>
                <li>{t("common:delivery_condition_with_payment")}</li>
                <li>{t("common:delivery_compromise_option")}</li>
                <li>{t("common:delivery_additional_info")}</li>
            </ul>
        </section>
    )
}

export default PaymentItem