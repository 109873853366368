import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';
import {searchActions} from '../../../_actions';
import {ProductHeader} from '../../../_components/Product/ProductHeader';
import {ProductItem} from '../../../_components/Product/ProductItem';
import {ProductSkeleton} from '../../../_components/Product/ProductSkeleton';
import {dataProcessing} from '../../../_helpers';
import {productService} from '../../../_services/product.service';

const useStyles = makeStyles(() => ({
  product_list: {
    marginTop: 40,
  },
  root: {
    '& .swiper-wrapper': {
      height: 450,
    },
  },
}));
const productSliderBreakpoints = {
  100: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  767: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  830: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1023: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1340: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  1700: {
    slidesPerView: 6,
    spaceBetween: 20,
  },
};
const CategoryProductList = props => {
  const {
    boundProduct,
    setDialogData,
    sell_currency_id,
    currencyMain,
    cart,
    listMyFavorite,
    searchAvailability,
    promotionsByProductsList,
    searchPromotions,
  } = props;
  const classes = useStyles();
  let cartAll = cart && cart.list ? cart.list.all : [];

  const [productsSliderInfo, setProductsSliderInfo] = useState(null);
  const [productSliderIndex, setProductSliderIndex] = useState(0);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    productService.getBoundProductsByGroupId(boundProduct.id).then(data => {
      const filteredList1 = data.data.results.filter(
        i => !i.balance.every(i => i.quantity === '0'),
      );
      const filteredList2 = data.data.results.filter(i =>
        i.balance?.every(i => i.quantity === '0'),
      );
      const filteredList = [...filteredList1, ...filteredList2];
      setProduct({...data.data, results: filteredList});
    });
  }, []);

  useEffect(() => {
    if (product.results) {
      searchPromotions(product.results.map(item => item.product_id));
    }
  }, [product]);

  const resultsMod = dataProcessing(
    currencyMain,
    product.results,
    cartAll,
    listMyFavorite,
    1,
    searchAvailability,
    promotionsByProductsList,
  ).results;

  return (
    <div>
      <div className="products-block product product-5 products-mobile">
        <div className="wrapper-inner">
          <div className={clsx('product__list', classes.product_list)}>
            <Swiper
              onSlideChange={e => setProductSliderIndex(e.activeIndex)}
              onSwiper={e => setProductsSliderInfo(e)}
              breakpoints={productSliderBreakpoints}
              spaceBetween={20}
              className={classes.root}
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              <ProductHeader
                slider={productSliderIndex + 1}
                title={boundProduct.name}
                totalCount={productsSliderInfo?.snapGrid?.length}
              />
              {resultsMod?.length > 0 ? (
                resultsMod.map((el, index) => (
                  <SwiperSlide
                    key={index}
                    className={clsx(
                      'product__item',
                      el.balance.every(i => i.quantity === '0') ? 'empty' : '',
                    )}>
                    <ProductItem
                      currency={sell_currency_id}
                      row={el}
                      setDialogData={setDialogData}
                      promoLabel={el.promoLabel}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <div className="d-flex" style={{gap: 20}}>
                  {new Array(6).fill(6).map((_, index) => (
                    <SwiperSlide key={index} className="product__item">
                      <ProductSkeleton key={index} />
                    </SwiperSlide>
                  ))}
                </div>
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionCreators = {
  //   getBoundProductsByGroupId: productsActions.getBoundProductsByGroupId,
  searchPromotions: searchActions.searchPromotions,
};
const mapState = state => {
  const {sell_currency_id} = state.userSelectedData;
  const {cart, listMyFavorite, promotionsByProductsList} = state;
  const {searchAvailability} = state.searchParams;
  return {
    sell_currency_id,
    cart,
    listMyFavorite,
    searchAvailability,
    promotionsByProductsList,
  };
};

export default connect(mapState, actionCreators)(CategoryProductList);
