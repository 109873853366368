import React from 'react';
import {useEffect} from 'react';
import {Banner} from './Banner';
import {BannerSkeleton} from './BannerSkeleton';

export const Banners = ({catalogs}) => {
  // const newCatalogItems = [
  //   {icon: frBannerIconVintage},
  //   {icon: frBannerIconLight},
  // ];
  // const newCatalog = catalogs?.map((catalog, index) => ({
  //   ...catalog,
  //   ...newCatalogItems[index],
  // }));

  useEffect(() => {
    localStorage.setItem('bannersCount', catalogs?.length);
  }, [catalogs]);
  useEffect(() => {
  }, []);

  return catalogs ? (
    <div className="banners">
      <div className="wrapper-inner">
        <div className="banners-inner">
          {catalogs &&
            catalogs.map((item, index) => (
              <React.Fragment key={item.id}>
                <Banner {...item} />
              </React.Fragment>
            ))}
        </div>
      </div>
    </div> 
  ) : (
    <BannerSkeleton />
  );
};
