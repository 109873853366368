import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        margin: '32px 0 0 0',
        minHeight: '54px',
    },
    button: {
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: '44px',
        height: '36px',
        border: '1px solid rgb(200, 202, 216)',
        transition: 'all 0.3s ease',

        "&:hover": {
            transform: 'scale(1.2)',
            border: '1px solid rgb(47, 128, 237)',

            '& svg': {
                '& path': {
                  fill: 'rgb(47, 128, 237)'
                }
              }
        },
    }
}));

const SwiperButtons = (props) => {
    const classes = useStyles();
    const { swiperRef } = props;

    const handlePrev = () => {
        swiperRef.current.slidePrev();
    };

    const handleNext = () => {
        swiperRef.current.slideNext();
    };

    return (
        <div className={classes.buttons}>
          <div className={`${classes.button}`} onClick={handlePrev}>
          <svg width="11.484863" height="19.952148" viewBox="0 0 11.4849 19.9521" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs/>
                <path id="Vector" d="M11.04 0.43C11.32 0.72 11.48 1.1 11.48 1.49C11.48 1.89 11.32 2.27 11.04 2.56L3.62 9.98L11.04 17.41C11.31 17.69 11.47 18.07 11.46 18.46C11.46 18.85 11.3 19.23 11.02 19.51C10.74 19.79 10.37 19.94 9.97 19.95C9.58 19.95 9.2 19.8 8.92 19.53L0.43 11.04C0.15 10.76 0 10.38 0 9.98C0 9.58 0.15 9.2 0.43 8.92L8.92 0.43C9.2 0.15 9.58 0 9.98 0C10.38 0 10.76 0.15 11.04 0.43Z" fill="#C8CAD8" fillOpacity="1.000000" fillRule="nonzero"/>
            </svg>
          </div>
          <div className={`${classes.button}`} onClick={handleNext}>
            <svg width="11.484863" height="19.952148" viewBox="0 0 11.4849 19.9521" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs/>
                <path id="Vector" d="M0.43 0.43C0.15 0.72 0 1.1 0 1.49C0 1.89 0.15 2.27 0.43 2.56L7.86 9.98L0.43 17.41C0.16 17.69 0.01 18.07 0.01 18.46C0.02 18.85 0.17 19.23 0.45 19.51C0.73 19.79 1.11 19.94 1.5 19.95C1.89 19.95 2.27 19.8 2.56 19.53L11.04 11.04C11.32 10.76 11.48 10.38 11.48 9.98C11.48 9.58 11.32 9.2 11.04 8.92L2.56 0.43C2.27 0.15 1.89 0 1.49 0C1.1 0 0.72 0.15 0.43 0.43Z" fill="#C8CAD8" fillOpacity="1.000000" fillRule="nonzero"/>
            </svg>
          </div>
        </div>
    );
}

export default SwiperButtons;