import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import DashboardButtonBack from '../../../_components/DashboardButtonBack';
import MainCard from './components/MainCard';
import NovaPoshtaCard from './components/NovaPoshtaCard';
import InfoTtnCard from './components/InfoTtnCard';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px"
    },

    body: {
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0"
        },
    },

    content: {
        paddingTop: "24px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: 'column',
        alignItems: "flex-start",
        gap: 32,
    },

    card: {
        padding: '32px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            padding: '16px',
            flexDirection: 'column',
        },
    },
    item: {
        width: 'calc((100% - 16px)/2)',
        color: 'rgb(28, 42, 83)',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    p400: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        color: 'rgb(28, 42, 83)',

        '& span': {
            color: 'rgb(255, 190, 0)'
        }
    },
    p500: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px'
    },
    strong: {
        fontWeight: 700,
        fontSize: '14px',
        color: 'rgb(28, 42, 83)',
    }
}));

const DropShipping = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <main className="dashboard">
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <div className={classes.content}>
                        <h1 className="dashboard__title">{t("common:dropshipping")}</h1>
                        <MainCard />
                        <NovaPoshtaCard />
                        <InfoTtnCard />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default DropShipping;