import React from "react";
import AddButton from "./AddButton";
import { makeStyles } from "@material-ui/styles";
import DashboardSearch from "../../../_components/DashboardSearch";

const useStyles = makeStyles((theme) => ({
    block: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'flex-start',
            width: '100%'
        }
    }
}))

const SearchAndAddNewManager = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.block}>
            <DashboardSearch 
              {...props}
            />
            <AddButton />
        </div>
    );
}

export default SearchAndAddNewManager;