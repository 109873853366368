import clsx from 'clsx';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import axInst from '../../../_helpers/axios-instance';
import axios from 'axios';
import {authentication} from '../../../_reducers/authentication.reducer';
import {invoice} from '../../../_reducers/cart/invoice.reducer';
import {store} from '../../../_helpers';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export const NavBtns = props => {
  const {
    invoices,
    setActiveStep,
    activeStep,
    disableBtn,
    setShowLastContent,
    selectedInvoiceItem,
    selectedResults,
    invoiceItemArray,
    returnReason,
    returnMethod,
    formState,
    setFormState,
    invoiceItem,
    comment,
    setIsExist
  } = props;
  const state = store.getState();
  const {userSelectedData, invoicesListParams} = state;
  const {t} = useTranslation();
  const [feedback, setFeedback] = useState({});
  const [responseStatus, setResponseStatus] = useState(null);
  const reason = useRef(null);
  const return_meth = useRef(null);
  const Token = '36c9b92726c91e3c5cb95d89793be15f1da839ad';
  const config = {
    headers: {
      Authorization: `Token ${Token}`,
      'Content-Type': 'application/json',
    },
  };
  const [snack, setSnack] = useState(false);

  const handleClick = () => {
    setSnack(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(false);
  };
  const nextStep = () => {
    if (activeStep >= 3) return setShowLastContent(true);

    setActiveStep(activeStep + 1);
  };
  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    axInst.post('/api/get-feedback-data/', {}, config).then(data => {
      setFeedback(data.data);
    });
  }, []);
  const sendReturnData = () => {
    const data_product = [];
    const data_send_dict = {
      seats: parseInt(formState.values.placeCount),
      sender_payed:
        formState.values.user == t('common:Recipient') ? false : true,
      sender: formState.values.otpravitel,
      recipient: formState.values.poluchatel,
      shipping_amount: formState.values.price,
      doc_return_id: formState.values.ttnNumber || null,
      warehouse: invoiceItem?.storage_id,
      reason: returnReason.id,
      return_method: returnMethod,
      tracking_number: formState.values.ttnNumber,
      customer: userSelectedData.customer_id,
      complaint: 'complaint',
      zakaz_n: invoiceItem?.number,
      payment_bill: invoiceItem?.order_number,
      order_date: invoiceItem?.date,
      comment: comment,
      synced: false,
    };
    selectedResults.map((invoiceItem, i) => {
      data_product.push({
        order: Number(invoiceItemArray[i]?.order_number),
        message: 'comment',
        order_item: Number(invoiceItem.id_good.replace(/\s/g, '')),
        quantity_return: invoiceItem.quantity,
        article: invoiceItem.article,
        brand: invoiceItem.brand,
        name: invoiceItem.name,
        price_sell: invoiceItem.price,
        forfeit: 'forfeit',
        order_date: invoiceItem.order_date,
      });
    });
    const jsonData = JSON.stringify({data_product, data_send_dict});

    axInst
      .post('/api/moneyback-data/', jsonData, config)
      .then(response => {
        if (response.status == 200) {
          setIsExist(response.data.is_exist);
          setShowLastContent(true);
        };
      })
      .catch(err => {
        setShowLastContent(false);
        setSnack(true);
        console.log('snacky', snack);
      });
  };

  useEffect(() => {
    console.log('selectedInvoiceItem', selectedInvoiceItem);
  }, []);
  const action = (
    <React.Fragment>
      <btn onClick={handleClose}>UNDO</btn>
    </React.Fragment>
  );

  return (
    <>
      <div className="d-flex justify-between items-center mt-4">
        <Box
          onClick={prevStep}
          className={clsx(
            'd-flex items-center gap-10 cursor-pointer',
            activeStep === 0 ? 'disabled' : '',
          )}
          style={{opacity: `${activeStep == 0 ? '0' : ''}`}}>
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.5"
              d="M5.54918 1L1.00264 6.23494L5.54918 11M1 6.18882H13H1Z"
              stroke="#414D5F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="styled-text">{t('common:Back')}</span>
        </Box>
        <button
          onClick={activeStep >= 3 ? sendReturnData : nextStep}
          className={clsx(
            'btn px-15 py-3',
            disableBtn || (activeStep >= 2 && !returnMethod) ? 'disabled' : '',
          )}>
          {activeStep >= 3 ? t('common:To issue') : t('common:next')}
        </button>
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        open={snack}
        action={action}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Ошибка">
        <Alert severity="error" variant="filled">
        {t('common:DataNotSent')}
        </Alert>
      </Snackbar>
    </>
  );
};