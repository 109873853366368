import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',

        '& .data-field': {
            width: "100%",
            background: "#EBF0F8",
            border: "1px solid #EBF0F8",
            borderRadius: 20,
            padding: 20,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }
    },
    slider: {
        padding: '0px 7px',
    },
}));

const Slider1 = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 18,
        width: 18,
        backgroundColor: '#315EFB',
        marginTop: -9,
        marginLeft: -9,
        '&::before':{
            position: "absolute",
            content: "''",
            backgroundColor: "#fff",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 10,
            height: 10,
            borderRadius: "100%"
        }
    },
    track: {
        height: 3
    }
})(Slider)

let timerId;
const Range = props => {
    const classes = useStyles();
    const {
        k,
        title,
        min,
        max,
        unit,
        setValue,
        onChangeCommitted,
        disabled,
        currencyMainContr,
        price_max_current,
        price_min_current,
    } = props;


    const [value2, setValue2] = React.useState([price_min_current, price_max_current]);
    const [prevValue2, setPrevValue2] = React.useState([min, max]);

    if (typeof min !== 'number' || typeof max !== 'number') return <React.Fragment/>;
    if (min === max === 0) return <React.Fragment/>;
    if (Math.abs(min) === Infinity || Math.abs(max) === Infinity) return <React.Fragment/>;

    const handleMouse = () => {
        clearTimeout(timerId);
        if (value2[0] >= prevValue2[0] || value2[1] >= prevValue2[1]) {
            setPrevValue2(value2);
            let valPar = [];
            /*if (currencyMainSite !== undefined) {
                valPar[0] = parseInt(value2[0] / currencyMainSite.koef);
                valPar[1] = parseInt(value2[1] / currencyMainSite.koef) + 1;
            }*/
            if (currencyMainContr !== undefined) {
                valPar[0] = Math.floor(value2[0] / currencyMainContr.koef);
                valPar[1] = Math.ceil(value2[1] / currencyMainContr.koef);
            }
            setValue(valPar);
            onChangeCommitted();
        }
    };
    const handleChange = (event, newValue) => {
        clearTimeout(timerId);
        if (newValue[0] <= newValue[1]
            && newValue[0] >= min
            && newValue[1] <= max
        ) {
            setValue2(newValue);
            timerId = setTimeout(function () {
                handleMouse();
            }, 400);
        }
    };

    const valuetext = value => `${value} ${unit}`;

    return (
        <div key={`${'A-'}${k}`} className={classes.root}>
            {title && <h3 className="filter-title mb-4">{title}</h3>}
            <div className='d-flex gap-10 mb-4'>
                <div className='data-field'>{min}</div>
                <div className='data-field'>{max}</div>
            </div>
            <div className={classes.slider} key={`${'B-'}${k}`}>
                <Slider1
                    disabled={disabled}
                    key={`${'R'}${k}`}
                    value={value2}
                    onChange={handleChange}
                    onMouseUp={handleMouse}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    min={min}
                    max={max}
                />
            </div>
        </div>
    );
};

Range.propTypes = {
    title: PropTypes.string,
    k: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    // unit: PropTypes.any,
    onChangeCommitted: PropTypes.func.isRequired,
};

export default Range;
