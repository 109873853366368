import React from 'react';
import {useTranslation} from 'react-i18next';
import StarRatings from 'react-star-ratings';

export const ReviewItem = ({isQuestion, reply, review}) => {
  const {t} = useTranslation();
  if (!review) return <>Loading...</>;

  const replyAnswers = review?.review_answers ?? review?.question_answers;
  const createdDate = date => {
    const padTo2Digits = n => n.toString().padStart(2, '0');
    const dt = new Date(date);
    const day = padTo2Digits(dt?.getDate());
    const month = padTo2Digits(dt?.getMonth() + 1);
    const year = dt?.getFullYear();
    return [day, month, year].join('.');
  };

  return (
    <div className="review">
      <div className="review-header">
        <h3 className="review-name">
          {review?.customer_name ?? review?.name}
          <span className="review-date ml-2">
            {createdDate(review?.created)}
          </span>
        </h3>
        {!isQuestion && (
          <div className="review-stars">
            <StarRatings
              rating={review?.rate}
              starDimension="15px"
              starSpacing="2.25px"
              starRatedColor="#FFBE00"
            />
          </div>
        )}
      </div>
      <div className="review-body">
        <p className="review-desc">{review?.feedback ?? review?.question}</p>
        {!isQuestion && (
          <>
            <div className="review-title">{t('common:benefits')}</div>
            <p className="review-desc">{review?.benefits}</p>
            <div className="review-title">{t('common:disadvantages')}</div>
            <p className="review-desc">{review?.disadvantages}</p>
          </>
        )}
      </div>

      {reply && (
        <div className="review-inner">
          {replyAnswers?.map(ansver => (
            <div className="review">
              <div className="review-header">
                <h3 className="review-name">
                  {ansver?.admin_name}
                  <span className="review-date ml-2">
                    {createdDate(ansver?.created)}
                  </span>
                </h3>
              </div>
              <div className="review-body">
                <p className="review-desc">{ansver?.content}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
