import {TextField, makeStyles, withStyles} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import React, {useEffect, useRef, useContext} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDebouncer} from '../../../../hooks/useDebouncer';
import axInst from '../../../../_helpers/axios-instance';
import FormField from '../../../Authorization/FormField';
import {TableProductItem1} from '../TableProductItem1';
import {TableHeader} from './TableHeader';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ModalContext} from '../../../../views/Profile/components/SalesInvoice';

import ukLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import {store} from '../../../../_helpers';

const Input = withStyles({
  root: {
    borderRadius: 40,
    background: '#EBF0F8',
    border: 'none',

    '& .MuiInputBase-input': {
      marginLeft: 20,
    },
    '& .MuiFormLabel-root': {
      left: 20,
      color: '#000',

      '&.Mui-focused': {
        color: '#315EFB',
      },
    },

    '& .MuiFilledInput-root': {
      background: 'transparent',
    },
    '& .MuiFilledInput-underline:after': {
      border: 'none',
      content: '',
    },
    '& .MuiFilledInput-underline:before': {
      border: 'none',
      content: '',
    },
  },
})(TextField);

const localeMap = {
  en: enLocale,
  uk: ukLocale,
  ru: ruLocale,
};

// {
//   "short_name": "React App",
//   "name": "Create React App Sample",
//   "icons": [
//     {
//       "src": "favicon.ico",
//       "sizes": "64x64 32x32 24x24 16x16",
//       "type": "image/x-icon"
//     },
//     {
//       "src": "logo192.png",
//       "type": "image/png",
//       "sizes": "192x192"
//     },
//     {
//       "src": "logo512.png",
//       "type": "image/png",
//       "sizes": "512x512"
//     }
//   ],
//   "start_url": ".",
//   "display": "standalone",
//   "theme_color": "#000000",
//   "background_color": "#ffffff"
// }

const useStyles = makeStyles(() => ({
  dateFromInput: {
    marginRight: 20,
    '& input': {
      textAlign: 'center',
    },
  },
  dateUntilInput: {
    '& input': {
      textAlign: 'center',
    },
  },
  loader4: {
    width: '65px',
    height: '65px',
    display: 'inline-block',
    padding: '0px',
    borderRadius: '100%',
    border: '5px solid #ddd',
    borderTopColor: '#FFBE00',
    borderBottomColor: '#FFBE00',
    animation: 'loader4 1s ease-in-out infinite',
    zIndex: 999,
    transform: 'translate(50%, 50%)',
  },
}));

export const FirstStep = props => {
  const {
    orders,
    // setFilteredOrders,
    // filteredOrders,
    setSelectedInvoiceItem,
    selectedInvoiceItem,
    invoiceItemArray,
    setSelectedResults,
    selectedResults,
    setDisableBtn,
    invoices,
    userSelectedData,
    filterUntilDate,
    filterFromDate,
    setUntilDate,
    setFromDate,
  } = props;
  const classes = useStyles();
  const state2 = store.getState();

  const {languageReducer} = state2;
  let lang = 'uk';
  if (languageReducer.ln_ch !== undefined) lang = languageReducer.ln_ch; //'UA';

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {codeNumber: ''},
    touched: {},
    errors: {},
  });
  const [locale] = useState(lang);
  const {t} = useTranslation();
  const {
    openReturnDialog,
    setOpenReturnDialog,
    setInvoiceItem,
    invoiceItem,
    invoiceOrderItems,
    setInvoiceOrderItems,
  } = useContext(ModalContext);

  useEffect(() => {
    if (selectedResults.length > 0) {
      setDisableBtn(false);
    }
  }, [selectedResults]);
  useEffect(() => {
    if (invoices?.invoices?.length > 0) {
      setSelectedInvoiceItem(invoiceItem);
      invoiceItemArray.push(invoiceItem);
    }
  }, [formState, invoices, selectedInvoiceItem]);
  const handleChangeAutocomplete = (_, order) => {
    if (!order) {
      return setFilteredOrders(orders.order_items);
    }

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        codeNumber: order,
      },
    });
    // setFilteredOrders(
    //   orders?.order_items?.filter(item => item?.order__id === order),
    // );

    axInst
      .post(
        '/api/get-invoice-items/',
        JSON.stringify({
          customer_id: userSelectedData.customer_id,
          number: order.number,
          date: order.date,
        }),
      )
      .then(res => {
        setFilteredOrders(res.data.invoices);
      });
  };

  const handleSetFromDate = value => {
    setFromDate(value);
  };
  const handleSetUntilDate = value => {
    setUntilDate(value);
  };

  return (
    <div>
      <div className="mb-2 d-flex items-center gap-10">
        {/* <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[locale]}>
          <DatePicker
            className={classes.dateFromInput}
            disableToolbar
            autoOk
            format="dd.MM.yyyy"
            maxDate={filterUntilDate}
            variant="inline"
            value={filterFromDate}
            onChange={value => handleSetFromDate(value)}
          />
          <DatePicker
            className={classes.dateUntilInput}
            disableToolbar
            autoOk
            format="dd.MM.yyyy"
            minDate={filterFromDate}
            variant="inline"
            value={filterUntilDate}
            onChange={value => handleSetUntilDate(value)}
          />
        </MuiPickersUtilsProvider> */}
      </div>
      {invoices.loading ? (
        <div className="d-flex items-center justify-center mt-4">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          {/* <div className="user-form p-0">
            <Autocomplete
              id="combo-box-demo"
              options={invoices.invoices}
              getOptionLabel={option => option.description_number}
              style={{borderRadius: '40px'}}
              onChange={(e, order) => handleChangeAutocomplete(e, order)}
              defaultValue={invoiceItem}
              renderInput={params => (
                <Input
                  {...params}
                  label={t('common:id_zakazu')}
                  variant="filled"
                />
              )}
            />
          </div> */}
          {(filteredOrders.length > 0 || invoiceItem) && (
            <>
              <div className="results-by-search">
                <h3 className="title mt-4 mb-5">
                  {t('common:return_terms_text_2')}
                </h3>
                {/* <TableHeader /> */}
                <div className="products-all">
                  {invoiceOrderItems?.map(order => (
                    <React.Fragment key={order.id}>
                      <TableProductItem1
                        order={order}
                        setSelectedResults={setSelectedResults}
                        selectedResults={selectedResults}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
