import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AvailableProduct from "./components/AvailableProduct";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '16px 16px 32px 16px',
    borderRadius: '14px',
    [theme.breakpoints.down(700)]: {
      width: '90%'
    }, 
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 27px 0'
  },
  title: {
    color: 'rgb(39, 159, 81)',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    flex: 1,
    textAlign: 'center',
  },
  img: {
    cursor: 'pointer'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: '0 0 24px 0',
  },
  link: {
    padding: '8px 16px',
    border: '1px solid rgb(47, 128, 237)',
    borderRadius: '20px',
    fontSize: '14px',
    fontWeight: 400,
    color: 'rgb(47, 128, 237)',
  
    '&:hover': {
        cursor: 'pointer'
      }
  }
}));

const AlreadyAvailableDialog = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(true);

    function handleClose() {
      setIsOpen(false);
    }

    return (
        <Dialog
          open={isOpen}
          onClose={handleClose}
        >
          <div className= "modal">
            <div className={`modal-content ${classes.content}`}>
            <div className={classes.header}>
                <p className={classes.title}>Вже є в наявності</p>
                <svg className={classes.img} onClick={() => setIsOpen(false)}  width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs/>
                    <path id="Vector" d="M24 2.41L21.58 0L12 9.58L2.41 0L0 2.41L9.58 12L0 21.58L2.41 24L12 14.41L21.58 24L24 21.58L14.41 12L24 2.41Z" fill="#2F80ED" fill-opacity="1.000000" fill-rule="nonzero"/>
                </svg>
            </div>
            <div className={classes.list}>
              <AvailableProduct />
              <AvailableProduct />
              <AvailableProduct />
              <AvailableProduct />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Link to="/wishlist" className={classes.link}>Перейти до товарів</Link>
            </div>
            </div>
          </div>
        </Dialog>
    );
}

export default AlreadyAvailableDialog;