import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  Grid,
  Tabs,
  Tab,
  Paper,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

// import Balance from './components/Balance';
import ProfileComponent from './components/ProfileComponent';
// import Delivery from './components/Delivery';
import Orders from './components/Orders';
import Finance from './components/Finance';
import SalesInvoice from './components/SalesInvoice';
import Returns from './components/Returns';
import Reconciliation from './components/Reconciliation';
import {userActions} from '../../_actions/user.actions';
import clsx from 'clsx';
import {BreadCrumbs} from '../../_components/BreadCrumbs/BreadCrumbs';
import UserInfo from '../../layouts/Main/components/Topbar/components/UserInfo/UserInfo';
import {TabPanel} from '@material-ui/lab';
import BannerCarousel from '../Product/components/BannerCarousel';
//import MyFavorite from "./components/MyFavorite";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '15px',
    paddingBottom: '100px',
    [theme.breakpoints.down(450)]: {
      gap: '10px',
    },
    [theme.breakpoints.down(355)]: {
      gap: '5px',
    },
  },
  root2: {
    background: '#FFFFFF',
    boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.02)',
    borderRadius: '4px',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.down(450)]: {
      padding: '6px 10px',
    },
    [theme.breakpoints.down(355)]: {
      padding: '0px 3px',
    },
    // backgroundColor: "ghostwhite",
    // paddingRight: 18
  },
  indicator: {
    bottom: '5px',
    backgroundColor: '#315EFB',
  },

  tab__root: {
    color: '#414D5F',
    opacity: '0.6',
    fontWeight: '600',
    fontSize: '18px',
    '&.MuiTab-root.Mui-selected': {
      color: '#315EFB',
      opacity: '1',
    },
    [theme.breakpoints.down(768)]: {
      fontSize: '16px',
      padding: '6px 10px',
      minWidth: '120px',
    },
    [theme.breakpoints.down(490)]: {
      fontSize: '14px',
      padding: '6px 8px',
      minWidth: '70px',
    },
  },
  paper: {
    backgroundColor: '#F7F6F6',
  },
  //   touch_ripple_tab: {
  //     color: '#315EFB',
  //   },
}));

const Profile = props => {
  const {history} = props;

  //const {userProfile, openOrdersList, frontendData} = props;
  const {openOrdersList, frontendData} = props;

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isTablet = useMediaQuery(theme.breakpoints.down(1080), {
    defaultMatches: true,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down(450), {
    defaultMatches: true,
  });

  const [userData, setUserData] = useState({});

  const classes = useStyles();
  const {t} = useTranslation();

  let tabs = {};

  if (window.innerWidth > 680) {
    tabs = {
      // profile: t('common:profile'),
      orders: t('common:orders'),
      invoice: t('common:salesInvoice'),
      finance: t('common:finance'),
      reconciliation: t('common:reconciliation'),
      returns: t('common:return'),
    };
  } else {
    tabs = {
      // profile: t('common:profile'),
      orders: t('common:orders'),
      invoice: t('common:salesInvoice'),
      finance: t('common:finance'),
      returns: t('common:return'),
    };
  }

  const getActiveTab = function() {
    if (Object.keys(tabs).indexOf(props.match.params.type) !== -1) {
      return {tabValue: props.match.params.type};
    } else {
      return {tabValue: Object.keys(tabs)[0]};
    }
  };

  const [values, setValues] = React.useState(() => {
    return getActiveTab();
  });

  useEffect(() => {
    setValues(getActiveTab());
    // eslint-disable-next-line
  }, [props.match.params.type]);

  const handleChangeTab = (event, value) => {
    history.replace(`/profile/${value}/`);
    //setValues({...values, tabValue: value});
  };

  useEffect(() => {
    checkObjectIsEmpty(frontendData)
      ? setUserData(JSON.parse(localStorage.getItem('frontendData')))
      : setUserData(frontendData);

    return () => {
      openOrdersList(false);
    };
    // eslint-disable-next-line
  }, []);

  const checkObjectIsEmpty = obj => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const TabContainer = props => {
    return (
      // <Typography component="div" style={{padding: 8 * 3}}>
      <div>{props.children}</div>
    );
  };

  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

  if (checkObjectIsEmpty(userData)) return null;
  return (
    <>
      {values.tabValue === 'profile' ? (
        <BreadCrumbs items={[{title: t('common:profile'), path: '/profile'}]} />
      ) : (
        <BreadCrumbs
          items={[
            {title: t('common:profile'), path: '/profile'},
            {
              title: t(`common:${props.match.params.type}`),
              path: props.match.url,
            },
          ]}
        />
      )}
      <Grid
        container
        className={clsx(classes.root, 'profile_page', 'wrapper-inner', 'wrapper-profile')}
        style={{FontFace: 'Roboto'}}>
        <Hidden xsDown>
          <Grid xs={!isTablet ? 2 : 4}>
            <UserInfo profilePage />
            {!isTablet && (
              <div style={{marginTop: '20px'}}>
                <BannerCarousel />
              </div>
            )}
          </Grid>
        </Hidden>
        <Grid style={{width: '100%'}}>
          <Grid item md={12} xs={12}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Tabs
                  variant="scrollable"
                  // variant="fullWidth"
                  // aria-label="scrollable auto tabs example"
                  // aria-label="disabled tabs example"
                  value={values.tabValue}
                  onChange={handleChangeTab}
                  // indicatorColor="primary"
                  classes={{
                    root: 'profile_tab',
                    indicator: classes.indicator,
                  }}
                  // textColor="primary"

                  scrollButtons="auto">
                  {Object.keys(tabs).map(
                    value => (
                      (
                        <Tab
                          // TouchRippleProps={{
                          //   classes: {child: classes['touch_ripple_tab']},
                          // }}
                          key={value}
                          label={tabs[value]}
                          value={value}
                          className={`${classes.tab__root} title`}
                          fontFamily="Roboto"
                        />
                      )
                    ),
                  )}
                  {/*<Tab label={t('common:delivery')} />*/}
                  {/*<Tab label={'Избранное'}/>*/}
                  {/*<Tab label={t('common:my_garage')} />*/}
                  {/*<Tab label={t('common:security')} />*/}
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{marginTop: '10px', height: '100%'}}
            item
            md={12}
            xs={12}>
            <Paper
              elevation={0}
              className={classes.paper}
              style={{height: '100%'}}>
              {/* <Grid container> */}
              {/* <Grid item xs={12}> */}
              <Grid container style={{minHeight: '360px', height: '100%'}}>
                {values.tabValue === 'profile' && (
                  <Grid item md={12} xs={12} className={classes.root2}>
                    <TabContainer>
                      <ProfileComponent frontendData={userData} />
                    </TabContainer>
                  </Grid>
                )}
                {/*<Grid item md={6} xs={12} className={classes.root2}>*/}
                {/*  {values.tabValue === 1 && (*/}
                {/*    <TabContainer>*/}
                {/*      <Delivery userSelectedData={userSelectedData} />*/}
                {/*    </TabContainer>*/}
                {/*  )}*/}
                {/*</Grid>*/}
                {values.tabValue === 'orders' && (
                  <Grid item md={12} xs={12} className={classes.root2}>
                    <TabContainer>
                      <Orders />
                    </TabContainer>
                  </Grid>
                )}
                {values.tabValue === 'finance' && (
                  <Grid item md={12} xs={12} className={classes.root2}>
                    <TabContainer>
                      <Finance />
                    </TabContainer>
                  </Grid>
                )}
                {values.tabValue === 'invoice' && (
                  <Grid item md={12} xs={12} className={classes.root2}>
                    <TabContainer>
                      <SalesInvoice />
                    </TabContainer>
                  </Grid>
                )}
                {values.tabValue === 'reconciliation' && (
                  <Grid item md={12} xs={12} className={classes.root2}>
                    <TabContainer>
                      <Reconciliation />
                    </TabContainer>
                  </Grid>
                )}
                {values.tabValue === 'returns' && (
                  <Grid item md={12} xs={12} className={classes.root2}>
                    <TabContainer>
                      <Returns />
                    </TabContainer>
                  </Grid>
                )}
                {/*<Grid item md={12} xs={12} className={classes.root2}>
                                    {values.tabValue === 2 && (
                                        <TabContainer>
                                            <TabContainer>
                                                <MyFavorite/>
                                            </TabContainer>
                                        </TabContainer>
                                    )}
                                </Grid>*/}
                {/*<Grid item md={9} xs={12} className={classes.root2}>*/}
                {/*  {values.tabValue === 3 && (*/}
                {/*    <TabContainer>{t('common:my_garage')}</TabContainer>*/}
                {/*  )}*/}
                {/*</Grid>*/}
                {/*<Grid item md={9} xs={12} className={classes.root2}>*/}
                {/*  {values.tabValue === 4 && (*/}
                {/*    <TabContainer>{t('common:security')}</TabContainer>*/}
                {/*  )}*/}
                {/*</Grid>*/}
              </Grid>
              {/* </Grid> */}
              {/* </Grid> */}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

class Wrapper extends React.Component {
  componentDidMount() {
    // this.props.getCatalogTree();
  }

  render() {
    return <Profile {...this.props} />;
  }
}

function mapState(state) {
  const {userSelectedData, userProfile, frontendData} = state;
  return {userSelectedData, userProfile, frontendData};
}

const actionCreators = {
  openOrdersList: userActions.openOrdersList,
};

export default connect(mapState, actionCreators)(Wrapper);
