import {MenuItem, Select, styled} from '@material-ui/core';
import React, {useState} from 'react';
// import './style.scss';

const StyledSelect = styled(Select)({
  marginBottom: 12,
  borderRadius: 100,
  background: '#EBF0F8',
  width: '100%',
  '& .MuiSelect-select': {
    padding: '15px 20px',
  },

  '& .arrow-icon': {
    position: 'absolute',
    right: 20,
  },
});
const arrowIcon = (
  <svg
    className="arrow-icon"
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L6.23285 6L11 1"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1L6.23285 6L11 1"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StepSelector = () => {
  const handleChange = e => {};

  return (
    <StyledSelect
      disableUnderline
      id="selectedCart"
      // value={'Виберіть перевізника'}
      defaultValue={'Виберіть перевізника'}
      onChange={handleChange}
      IconComponent={() => arrowIcon}>
      <MenuItem value={'Виберіть перевізника'}>Виберіть перевізника</MenuItem>
      <MenuItem value={'Нова Пошта'}>Нова Пошта</MenuItem>
      <MenuItem value={'Автолюкс'}>Автолюкс</MenuItem>
      <MenuItem value={'Гюнсел'}>Гюнсел</MenuItem>
      <MenuItem value={'САТ'}>САТ</MenuItem>
    </StyledSelect>
  );
};
