import CloseIcon from '@material-ui/icons/Close';
import React, {useState, useEffect, useCallback} from 'react';
import {Modal, Box, Typography, Button} from '@mui/material';
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';
import FormField from '../Authorization/FormField';

const useStyles = makeStyles(() => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#fff',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    padding: 32,
    borderRadius: 8,
  },
  title: {
    marginBottom: '10px !important',
    fontSize: 20,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
  },
  submitButton: {
    marginTop: '30px !important',
    width: '100%',
    background: '#FFBE00 !important',
    '&:hover': {
      background: '#ffe000 !important',
    },
  },
}));

const PhoneVerificationModal = ({open, onClose}) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    isSend: false,
    values: {tel: ''},
    touched: {},
    errors: {},
  });

  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const {t} = useTranslation();

  function myvalidate(formData) {
    let errors = {};
    const phoneNumber = formData.tel.replace(/\D/g, '');

    if (phoneNumber.length !== 12) {
      errors.tel = errors.tel || [];
      errors.tel = ['Invalid phone number length'];
    }

    if (!phoneNumber.startsWith('380')) {
      errors.tel = errors.tel || [];
      errors.tel = ('Phone number must start with +380');
    }
    return errors;
  }

  const formatPhoneNumber = (value) => {
    const cleanValue = value.replace(/\D/g, '');

    if (!cleanValue || cleanValue === '380') {
      return '';
    }

    if (cleanValue.length <= 3) {
      if (cleanValue === '380') return '';
      return '+380';
    }

    let normalizedValue = cleanValue;
    if (!cleanValue.startsWith('380')) {
      normalizedValue = '380' + cleanValue;
    }

    const groups = [
      '+380',
      normalizedValue.slice(3, 5),
      normalizedValue.slice(5, 8),
      normalizedValue.slice(8, 10),
      normalizedValue.slice(10, 12)
    ];
    
    return groups.filter(group => group).join(' ');
  };

  const handleChange = useCallback(event => {
    event.persist();
    const { name, value } = event.target;

    if (name === 'tel') {
      const prevValue = formState.values.tel;
      const isDeleting = value.length < prevValue.length;
      const cleanPrevValue = prevValue.replace(/\D/g, '');
      const cleanNewValue = value.replace(/\D/g, '');

      if (isDeleting && cleanPrevValue === '380') {
        setFormState(prev => ({
          ...prev,
          values: { ...prev.values, [name]: '' },
          touched: { ...prev.touched, [name]: true },
        }));
        return;
      }

      const validInput = value.replace(/[^\d\s+]/g, '');
      const formattedValue = formatPhoneNumber(validInput);
      
      setFormState(prev => ({
        ...prev,
        values: { ...prev.values, [name]: formattedValue },
        touched: { ...prev.touched, [name]: true },
      }));
    }
  }, [formState.values.tel]);

  useEffect(() => {
    if (!formState.touched.tel) return;

    let errors = myvalidate(formState.values);
    const isValid = Object.keys(errors).length === 0;

    setFormState(prev => ({
      ...prev,
      isValid,
      errors,
    }));
  }, [formState.values.tel, formState.touched]);

  const handleSubmit = () => {
    setFormState(prev => ({...prev, isSend: true}));

    setTimeout(() => {
      setVerificationSuccess(true);
      // setTimeout(() => onClose(), 1500);
    }, 1000);
  };

  return (
    <Modal open={open} onClose={verificationSuccess ? onClose : undefined}>
      <Box className={classes.modalBox}>
        {verificationSuccess && (
          <Button
            onClick={onClose}
            sx={{position: 'absolute', right: 8, top: 8}}>
            <CloseIcon />
          </Button>
        )}

        <Typography className={classes.title}>
          {t('common:verif_mobile_title')}
        </Typography>

        {!verificationSuccess ? (
          <>
            <Typography variant="h7" className={classes.subtitle}>
              {t('common:verif_mobile_description')}
              <br/>
              <br/>
              {t('common:verif_mobile_description2')}
            </Typography>
            <FormField
              type="tel"
              name="tel"
              title={t('common:enter_phone')}
              handleChange={handleChange}
              formState={formState}
              hasErrorText={t('common:enter_phone3')}
            />

            <Button
              variant="contained"
              className={classes.submitButton}
              onClick={handleSubmit}
              disabled={!formState.isValid || formState.isSend}>
              {formState.isSend ? `${t('common:verif_mobile_wait')}` : 'Підтвердити'}
            </Button>
          </>
        ) : (
          <Typography color="success.main">
            {t('common:verif_mobile_complete')}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default PhoneVerificationModal;