import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom';
import useAnimate from '../../hooks/useAnimate';
// import material ui
import { makeStyles, useMediaQuery, useTheme, Tooltip, IconButton } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow, Collapse, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import DashboardAnimate from '../../_components/DashboardAnimate';
import PrintOrder from './components/PrintOrder';
import Preloader from '../../_components/Preloader';
import DashboardBanner from '../../_components/DashboardBanner';
// redux
import { store } from '../../_helpers';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import moment
import moment from 'moment';
// import for print
import { useReactToPrint } from 'react-to-print';
// date io
import DateFnsUtils from '@date-io/date-fns';
import ukLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px",
        overflowX: "hidden",
    },

    body: {
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0"
        },
    },

    item: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },

    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "15px",
    },

    content: {
        display: "grid",
        gap: 32,
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
    },

    back: {
        paddingBottom: "50px",
        [theme.breakpoints.down(768)]: {
            paddingBottom: "30px",
        },
    },

    table: {
        position: "relative",
        paddingTop: 24,
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#ccc",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#8E95A9",
            borderRadius: 10,
        },
        "& table thead th:first-child": {
            maxWidth: "50px",
            boxSizing: "border-box",
        },
        "& table tbody td:first-child": {
            maxWidth: "50px",
            boxSizing: "border-box",
        },
        "& table thead th:nth-child(2)": {
            textAlign: "left",
        },
        "& table tbody td:nth-child(2)": {
            textAlign: "left",
        },
        "& table tbody td": {
            color: "#555F7E",
        },
    },

    input: {
        width: 340,
        padding: "12px 50px 12px 25px",
        boxSizing: "border-box",
        border: "1px solid #C8CAD8",
        borderRadius: "50px",
        color: "black",
        fontSize: 13,
        fontWeight: 400,

        "&::placeholder": {
            color: "#C8CAD8",
        },
        [theme.breakpoints.down(600)]: {
            width: "100%"
        },
    },

    button: {
        backgroundColor: "transparent",
        border: 0,
        color: "#555F7E",
        padding: 0,
        cursor: "pointer",
        transition: "0.3s",

        "&:hover": {
            color: "#000"
        },
    },

    more_table: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        "& tbody": {
            borderBottom: "1px solid #CDD1DE",
            "&:last-child": {
                borderBottom: "none",
            },
        },
    },

    actions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "10px",

        "& button": {
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
        },

        "& a": {
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
        },
    },

    print: {
        position: 'absolute',
        display: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        width: '70vw',
        height: '70vh',
        zIndex: 999,
    },

    datePicker: {
        '& input': {
            textAlign: 'center',
        },
    },
}));

const localeMap = {
    en: enLocale,
    uk: ukLocale,
    ru: ruLocale,
};

const Row = (props) => {
    const { data } = props;
    const { id, order_items, delivery, created, status_name, nova_post_document_number, seats_amount } = data;
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    // logic print
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onDownload = (e) => {
        e.stopPropagation();
        handlePrint();
    };

    const editIcon = () => {
        if (nova_post_document_number) return (
            <Tooltip style={{ padding: 0 }} title={t("common:ttn_was_created_label")}>
                <IconButton>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 13.3335H14" stroke="#C8CAD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11 2.33316C11.2652 2.06794 11.6249 1.91895 12 1.91895C12.1857 1.91895 12.3696 1.95553 12.5412 2.0266C12.7128 2.09767 12.8687 2.20184 13 2.33316C13.1313 2.46448 13.2355 2.62038 13.3066 2.79196C13.3776 2.96354 13.4142 3.14744 13.4142 3.33316C13.4142 3.51888 13.3776 3.70277 13.3066 3.87435C13.2355 4.04593 13.1313 4.20184 13 4.33316L4.66667 12.6665L2 13.3332L2.66667 10.6665L11 2.33316Z" stroke="#C8CAD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </IconButton>
            </Tooltip>
        )

        return (
            <Link to={`/dashboard/ttn-create/${id}`}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 13.3335H14" stroke="#C8CAD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11 2.33316C11.2652 2.06794 11.6249 1.91895 12 1.91895C12.1857 1.91895 12.3696 1.95553 12.5412 2.0266C12.7128 2.09767 12.8687 2.20184 13 2.33316C13.1313 2.46448 13.2355 2.62038 13.3066 2.79196C13.3776 2.96354 13.4142 3.14744 13.4142 3.33316C13.4142 3.51888 13.3776 3.70277 13.3066 3.87435C13.2355 4.04593 13.1313 4.20184 13 4.33316L4.66667 12.6665L2 13.3332L2.66667 10.6665L11 2.33316Z" stroke="#C8CAD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Link>
        )
    }

    return (
        <>
            <TableBody onClick={() => setOpen(!open)} sx={{ backgroundColor: open ? "#F5F7FB" : "unset" }}>
                <TableRow style={{
                    backgroundColor: status_name.id === 3 ? "rgba(255, 190, 0, 0.1)" : "unset",
                    borderBottom: status_name.id === 3 ? "1px solid #FFBE00" : "unset",
                }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                        >
                            {open ? (
                                <KeyboardArrowUpIcon style={{ color: '#008CB4' }} />
                            ) : (
                                <KeyboardArrowDownIcon style={{ color: '#C8CAD8' }} />
                            )}
                        </IconButton>
                    </TableCell>
                    <TableCell>#{id}</TableCell>
                    <TableCell>{delivery}</TableCell>
                    <TableCell style={{ color: status_name.id === 3 && "#FFBE00" }}>{created}</TableCell>
                    <TableCell style={{ color: status_name.id === 3 && "#FFBE00" }}>{status_name?.name}</TableCell>
                    <TableCell>
                        <Box className={classes.actions}>
                            <Tooltip style={{ padding: 0 }} title={t("common:download")}>
                                <IconButton>
                                    <button onClick={onDownload}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM0 16V11H2V14H14V11H16V16H0Z" fill="#C8CAD8" />
                                        </svg>
                                    </button>
                                </IconButton>
                            </Tooltip>
                            {editIcon()}
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "#F5F7FB" }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gap: "15px", padding: "16px 0" }}>
                                <Box sx={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(1, 1fr)",
                                    gap: "4px",
                                    textAlign: "left",
                                    padding: "12px 16px",
                                    backgroundColor: "#fff",
                                }}>
                                    <p className="dashboard-text"><span style={{ color: "#1C2A53" }}>{t("common:TTN_number")}:</span> {nova_post_document_number || "-"}</p>
                                    <p className="dashboard-text"><span style={{ color: "#1C2A53" }}>{t("common:quantity_items_label")}:</span> {seats_amount || "-"}</p>
                                </Box>
                                {order_items?.length > 0 && (
                                    <Table size="small" aria-label="purchases" className={classes.more_table}>
                                        {order_items?.map((item, index) => (
                                            <TableBody key={`order-product-${index}`}>
                                                <TableRow>
                                                    <TableCell style={{ whiteSpace: "nowrap", maxWidth: "fit-content" }}>{item?.brand}</TableCell>
                                                    <TableCell style={{ textAlign: "center", whiteSpace: "nowrap" }}>{item?.article}</TableCell>
                                                    <TableCell>{item?.name}</TableCell>
                                                    <TableCell style={{ whiteSpace: "nowrap" }}>{item?.quantity} шт.</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                    </Table>
                                )}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </TableBody>
            {data && (
                <div className={classes.print}>
                    <PrintOrder data={data} ref={componentRef} />
                </div>
            )}
        </>
    )
}

const DashboardStorekeeper = (props) => {
    const {
        userSelectedData,
        warehouseman,
        getWarehouseman,
    } = props;
    useAnimate();
    const getParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [valueDate, setValueDate] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.up(600), {
        defaultMatches: true,
    });

    // get lang
    const state = store.getState();
    const { languageReducer } = state;
    let lang = 'uk';
    if (languageReducer.ln_ch !== undefined) lang = languageReducer.ln_ch;

    useEffect(() => {
        const dateParam = getParams.get('date');
        setValueDate(dateParam ? moment(dateParam, "DD-MM-YYYY") : moment());
    }, []);

    useEffect(() => {
        if (!valueDate) return;
        getParams.set('date', moment(valueDate).format('DD-MM-YYYY'));
        history.push({ search: getParams.toString() });
        getWarehouseman(userSelectedData.customer_id, moment(valueDate).format("DD.MM.YYYY"));
    }, [valueDate]);

    const handleChangeDate = (value) => {
        setValueDate(value);
    };

    return (
        <main className="dashboard">
            <DashboardBanner />
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <DashboardAnimate style={{ transform: "scale(0.97)" }}>
                        <div className={`dashboard-card ${classes.item}`}>
                            {warehouseman?.loading ? (
                                <div className="dashboard-card-empty">
                                    <Preloader />
                                </div>
                            ) : warehouseman?.error ? (
                                <div className="dashboard-card-empty">
                                    {t("common:unknown_error_label")}
                                </div>
                            ) : warehouseman?.is_no_access ? (
                                <div className="dashboard-card-empty">
                                    {t("common:storekeeper_access_label")}
                                </div>
                            ) : null}
                            <div className={classes.header}>
                                <h2 className="dashboard-title">{t("common:store_instruction")}</h2>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={localeMap[lang]}>
                                    <DatePicker
                                        className={classes.datePicker}
                                        disableToolbar
                                        autoOk
                                        format="dd.MM.yyyy"
                                        maxDate={moment()}
                                        variant="inline"
                                        value={valueDate || moment()}
                                        onChange={value => handleChangeDate(value)}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.table}>
                                {warehouseman?.orders && warehouseman?.orders?.length === 0 ? (
                                    <div className="dashboard-card-empty">
                                        {t("common:missing_orders_by_date")}
                                    </div>
                                ) : null}
                                <Table className="dashboard-table" aria-label="full width table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>№ {t('common:orders')}</TableCell>
                                            <TableCell>{t('common:delivery')}</TableCell>
                                            <TableCell>{t('common:date')}</TableCell>
                                            <TableCell>{t('common:orderTableHeaderLabelStatus')}</TableCell>
                                            <TableCell>{t('common:actions_label')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {warehouseman?.orders && warehouseman?.orders?.length > 0 ? warehouseman?.orders?.map((item, index) => (
                                        <Row key={`order-${index}`} data={item} />
                                    )) : [1, 2, 3]?.map((item, index) => (
                                        <Row key={`order-${index}`} data={{
                                            id: item,
                                            order_items: [],
                                            delivery: "Доставка",
                                            created: "12-07-2024",
                                            status_name: {
                                                id: 3,
                                                name: "В обробці",
                                            },
                                            remark: {},
                                            payment: "",
                                            return_delivery: "",
                                        }} />
                                    ))}
                                </Table>
                            </div>
                        </div>
                    </DashboardAnimate>
                </div>
            </div>
        </main>
    )
}

function mapState(state) {
    const {
        warehouseman,
        userSelectedData,
    } = state;
    return {
        warehouseman,
        userSelectedData,
    };
}

const actionCreators = {
    getWarehouseman:
        userActions.getWarehouseman,
};

export default connect(mapState, actionCreators)(DashboardStorekeeper);