import { userConstants } from '../_constants';

export function warehouseman(state = {}, action) {
  switch (action.type) {
    case userConstants.WAREHOUSEMAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.WAREHOUSEMAN_SUCCESS:
      return {
        ...state,
        orders: [...action.data],
        loading: false,
      };
    case userConstants.WAREHOUSEMAN_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}