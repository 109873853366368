import React from "react";
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import AttentionBlock from './AttentionBlock';
import ListItem from './ListItem';
import boxes from '../images/boxes.png';
import Title from './Title';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '0 0 64px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px 0'
        },
    },
    box: {
        display: 'flex',
        gap: '24px',
        justifyContent: 'space-between',
        margin: '0 0 64px 0',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            margin: '0 0 32px 0',
        },
    },
    strong: {
        color: 'rgb(85, 95, 126)',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        margin: '24px 0',
        display: 'block'
    },
    list: {
        display: 'flex',
        gap: '48px',
        margin: '0 0 64px 0',
        padding: '0 0 0 67px',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            margin: '0 0 32px 0'
        },

        '&--col': {
            flexDirection: 'column',
            gap: '16px',
            padding: '0'
        }
    }
}));

function ReturnNecessaryConditions() {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <section className={classes.section}>
            <Box
                sx={{
                    margin: '0 0 32px 0'
                }}
            >
                <AttentionBlock
                    title={t("common:return")}
                    paragraph={t("common:if_part_not_fit")}
                />
            </Box>
            <ul className={classes.list}>
                <ListItem number={1} content={t("common:if_nova_post_delivery_return")} />
                <ListItem number={2} content={t("common:if_self_delivery_return")} />
            </ul>
            <Box
                className={classes.box}
            >
                <img
                    src={boxes}
                    alt="boxes"
                    style={{ height: '220px', maxWidth: '314px', alignSelf: 'center' }}
                />
                <div>
                    <Title
                        content={t("common:return_conditions_required")}
                        variant="h2"
                        style={{ fontSize: '24px', lineHeight: '36px' }}
                    />
                    <strong className={classes.strong}>{t("common:follow_steps_for_return")}</strong>
                    <ul className={`${classes.list} ${classes.list}--col`}>
                        <ListItem number={1} content={t("common:product_was_not_used")} />
                        <ListItem number={2} content={t("common:product_is_preserved")} />
                    </ul>
                </div>
            </Box>
            <AttentionBlock
                title={`${t("common:attention")}!`}
                paragraph={t("common:do_not_stick_scotch")}
            />
        </section>
    );
}

export default ReturnNecessaryConditions;