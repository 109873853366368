import React, { useEffect, useMemo, useState } from "react";
import useAnimate from '../../hooks/useAnimate';
import { useDebouncer } from "../../hooks/useDebouncer";
// import i18n
import { useTranslation } from 'react-i18next';
// import mui
import { makeStyles, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import components
import DashboardAnimate from '../../_components/DashboardAnimate';
import DashboardBanner from "../../_components/DashboardBanner";
import DashboardButtonBack from "../../_components/DashboardButtonBack";
import SearchAndAddNewManager from "./components/SearchAndAddNewManager";
import TableManagers from "./components/TableManagers";
import PaginationComponent from "./components/PaginationComponent";
import Preloader from "../../_components/Preloader";
// import redux
import { connect } from "react-redux";
import { userActions } from "../../_actions";
// import fetch
import axInst from "../../_helpers/axios-instance";

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px",
        overflowX: "hidden",
    },

    body: {
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0"
        },
    },

    title: {
        margin: '0 0 35px 0'
    },
    card: {
        padding: '28px 32px 36px 28px'
    },

    back: {
        paddingBottom: "34px",
    },

    content: {
        paddingTop: "24px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: "column",
        gap: 32,
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
    },
}));

const ListOfManagers = (props) => {
    const {
        getPersonalManagers,
        userSelectedData,
        personalManagers,
        financeCalendarByOrganization,
    } = props;
    useAnimate();
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [managers, setManagers] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [snack, setSnack] = useState({
        active: false,
        variant: "",
        text: "",
    });
    const appliedQuery = useDebouncer(searchQuery, 500);

    useEffect(() => {
        getPersonalManagers(userSelectedData.customer_id);
    }, []);

    useEffect(() => {
        const currentData = personalManagers?.data?.filter((item) => !item?.is_deactivate && item?.id != 1126 && item?.id != 1939) || [];
        setManagers(currentData);
    }, [personalManagers]);

    function changePage(value) {
        setCurrentPage(value);
    }

    function handleChange(e) {
        setSearchQuery(e.target.value);
    }

    const searchedManagers = useMemo(() => {
        if (!appliedQuery) {
            return managers;
        }

        const filteredManagers = managers.filter(manager => {
            if (manager.user__first_name.toLowerCase().includes(appliedQuery.toLowerCase()) || manager.user__last_name.toLowerCase().includes(appliedQuery.toLowerCase()) || manager.city__name.toLowerCase().includes(appliedQuery.toLowerCase() || manager.id.toLowerCase().includes(appliedQuery.toLowerCase())) || manager.phone.toLowerCase().includes(appliedQuery.toLowerCase())) {
                return manager;
            }
        });

        return filteredManagers;
    }, [appliedQuery, currentPage, managers]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchedManagers.slice(indexOfFirstItem, indexOfLastItem);
    const count = Math.ceil(searchedManagers.length / itemsPerPage);

    const onDelete = (id, name) => {
        setDisabled(false);
        axInst.put("/api/deactivate-manager", { customer_user_id: id }).then(response => {
            setSnack({
                active: true,
                variant: "success",
                text: `${t("common:manager_was_deleted").split(' ')[0]} ${name?.first_name} ${name?.last_name} ${t("common:manager_was_deleted").split(' ').slice(1).join(' ')}`
            });
            setManagers((prev) => prev?.filter((item) => item?.id !== id));
        }).catch(err => {
            setSnack({
                active: true,
                variant: "error",
                text: err.message || "Delete error",
            });
        });
        setDisabled(true);
    };

    const handleCloseAction = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnack({
            active: false,
            variant: "",
            text: "",
        });
    };

    const action = (
        <React.Fragment>
            <btn onClick={handleCloseAction}>UNDO</btn>
        </React.Fragment>
    );

    return (
        <main className="dashboard">
            <DashboardBanner />
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <h1 className={`dashboard-title ${classes.title}`}>{t("common:managers_label")}</h1>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        <div className={`dashboard-card ${classes.card}`}>
                            {personalManagers?.loading && (
                                <div className="dashboard-card-empty">
                                    <Preloader />
                                </div>
                            )}
                            <SearchAndAddNewManager
                                search={handleChange}
                            />
                            <TableManagers
                                data={currentItems}
                                currency={financeCalendarByOrganization?.result?.current_symbol}
                                disabled={disabled}
                                onDelete={onDelete}
                            />
                            {count > 1 && (
                                <PaginationComponent
                                    pages={count}
                                    currentPage={currentPage}
                                    onClick={changePage}
                                />
                            )}
                        </div>
                    </DashboardAnimate>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snack?.active}
                action={action}
                autoHideDuration={6000}
                onClose={handleCloseAction}
                message={t("common:error")}
            >
                <Alert severity={snack?.variant} variant="filled">
                    {snack?.text}
                </Alert>
            </Snackbar>
        </main>
    );
}

function mapState(state) {
    const {
        personalManagers,
        userSelectedData,
        financeCalendarByOrganization,
    } = state;
    return {
        personalManagers,
        userSelectedData,
        financeCalendarByOrganization,
    };
}

const actionCreators = {
    getPersonalManagers:
        userActions.getPersonalManagers,
};

export default connect(mapState, actionCreators)(ListOfManagers);