import React, { useEffect, useState, useRef } from 'react'
// import config
import config from '../../_services/config';
// import axInst from '../../_helpers/axios-instance'
// import img
import EmptyPage from '../../images/EmptyPage.png';
// import styles
import { makeStyles } from '@material-ui/core/styles';
// import components for print
import PrintInvoiceForm from "../Profile/components/PrintInvoiceForm"
import PrintInvoiceForm2 from "../Profile/components/PrintInvoiceForm2"
import PrintInvoiceForm4 from "../Profile/components/PrintInvoiceForm4"
// import for print
import { useReactToPrint } from 'react-to-print';
// import translate
import { useTranslation } from 'react-i18next';

const useRowStyles = makeStyles({
    printHolder: {
        position: 'absolute',
        display: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        width: '70vw',
        height: '70vh',
        zIndex: 999,
        // fontSize: 6,
    },
});

const InvoicePdf = () => {
    const { t } = useTranslation();
    const classes = useRowStyles();
    const [printInvoiceData, setPrintInvoiceData] = useState([]);
    const [error, setError] = useState(false);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: printInvoiceData?.description_number,
    });

    const getInvoicePrint = async (item_code, number, order_number_1c, date, organisation_item_code) => {
        try {
            const response = await fetch(`${config.apiUrl}/api/get-invoice-link/?item_code_in_accounting_system=${item_code}&number=${number}&order_number_1c=${order_number_1c}&date=${date}&organisation_item_code_in_accounting_system=${organisation_item_code}`);
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
            }
            const data = await response.json();
            setPrintInvoiceData(data.invoices);
        } catch (error) {
            setError(true);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        const itemCode = searchParams.get('item_code_in_accounting_system');
        const number = searchParams.get('number');
        const orderNumber1c = searchParams.get('order_number_1c');
        const date = searchParams.get('date');
        const organisationItemCode = searchParams.get('organisation_item_code_in_accounting_system');

        if (itemCode && number && orderNumber1c && date && organisationItemCode) {
            getInvoicePrint(itemCode, number, orderNumber1c, date, organisationItemCode);
        } else {
            setError(true);
        }
    }, []);

    useEffect(() => {
        handlePrint();
    }, [printInvoiceData])

    return (
        <>
            {error ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                    }}>
                    <img src={EmptyPage} />
                    <p className='no__products-title'>
                        {t('common:missing_data')}
                    </p>
                </div>
            ) : (
                <div>
                    {printInvoiceData && printInvoiceData.map((item, index) => (
                        item.html_template === 1 ? (
                            <div key={index} className={classes.printHolder}>
                                <PrintInvoiceForm printData={printInvoiceData} ref={componentRef} htmlTemplate={item.html_template} />
                            </div>
                        ) : item.html_template === 2 || item.html_template === 4 ? (
                            <div key={index} className={classes.printHolder}>
                                <PrintInvoiceForm2 printData={printInvoiceData} ref={componentRef} htmlTemplate={item.html_template} />
                            </div>
                        ) : item.html_template === 3 ? (
                            <div key={index} className={classes.printHolder}>
                                <PrintInvoiceForm4 printData={printInvoiceData} ref={componentRef} htmlTemplate={item.html_template} />
                            </div>
                        ) : ''
                    ))}
                </div>
            )}
        </>
    )
}

export default InvoicePdf