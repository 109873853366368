import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Divider, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Cansel from '../../../../icons/Cancel';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PriceToStr from '../../../../_components/PriceToStr';
import React, {useEffect, useState} from 'react';
// import {connect} from "react-redux";
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  stMainHeader: {
    color: 'red',
  },

  slash: {
    margin: '0 5px',
  },
}));

const DiscountModal = props => {
  const classes = useStyles();
  const {t} = useTranslation();

  const {currencyMain, result, openDiscountsModal, setDiscountsModal} = props;

  const [openModal, setOpenModal] = useState(openDiscountsModal);

  const discounts_by_quantity_sum_of_order =
    result.discounts_by_quantity_sum_of_order &&
    result.discounts_by_quantity_sum_of_order;

  const discountsClose = () => {
    setDiscountsModal(false);
  };

  useEffect(() => {
    setOpenModal(openDiscountsModal);
  }, [openDiscountsModal]);

  return (
    <Dialog
      onClose={discountsClose}
      maxWidth="xs"
      fullWidth={true}
      open={openModal}>
      <DialogTitle id="customized-dialog-title">
        <Grid
          container
          item
          xs={12}
          style={{display: 'flex', justifyContent: 'space-between'}}>
          <span className={classes.stMainHeader}>
            {t('common:promotional_offers')}
          </span>
          {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
          <IconButton
            onClick={() => discountsClose()}
            className="close"
            size={'small'}>
            <Cansel />
          </IconButton>
        </Grid>
        <Divider className={classes.divider} />
      </DialogTitle>

      <DialogContent className={classes.warehouseHelpContent}>
        {discounts_by_quantity_sum_of_order !== undefined &&
          discounts_by_quantity_sum_of_order !== null &&
          discounts_by_quantity_sum_of_order.discounts_by_product !==
            undefined &&
          discounts_by_quantity_sum_of_order.discounts_by_product.map(
            (type, index) => (
              <React.Fragment key={index}>
                {type.promotion_type === 'FROM_THE_QUANTITY_OF_GOODS' ? (
                  <List key={type.promotion_type}>
                    {type.discounts.map((item, index) => (
                      <ListItem
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                        <div>
                          <span style={{margin: '0 5px 0 0'}}>
                            {t('common:when_ordering_from')}
                          </span>

                          {item.quantity}
                          <span style={{margin: '0 5px'}}>
                            {t('common:pieces')}
                          </span>
                        </div>
                        <div
                          style={{
                            // width: "115px",
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: "space-between"
                          }}>
                          <div
                            style={{
                              // width: "115px",
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <PriceToStr
                              stSumma={{
                                fontStyle: 'normal',
                                fontSize: 16,
                                fontWeight: 'normal',
                              }}
                              stAll={
                                {
                                  // marginBottom: -3,
                                  // textAlign: 'right',
                                }
                              }
                              data={item.price_with_discount}
                              beforeText={''}
                              text={
                                currencyMain && currencyMain.simbol
                                  ? currencyMain.simbol
                                  : ''
                              }
                              grn={t('common:uah')}
                            />
                            <span className={classes.slash}>/</span>
                            <span>{t('common:pieces')}</span>
                          </div>
                        </div>
                      </ListItem>
                    ))}
                  </List>
                ) : type.promotion_type === 'FROM_THE_SUM_OF_ORDER' ? (
                  <React.Fragment key={type.promotion_type}>
                    <Divider />
                    <List>
                      {type.discounts.map((item, index) => (
                        <ListItem
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            <div style={{margin: '0 5px 0 0'}}>
                              {t('common:when_ordering_from')}
                            </div>
                            <PriceToStr
                              stSumma={{
                                fontStyle: 'normal',
                                fontSize: 16,
                                fontWeight: 'normal',
                              }}
                              stAll={
                                {
                                  // marginBottom: -3,
                                  // textAlign: 'right',
                                }
                              }
                              data={
                                item.discount_conditions_document__sum_of_order
                              }
                              beforeText={''}
                              text={
                                currencyMain && currencyMain.simbol
                                  ? currencyMain.simbol
                                  : ''
                              }
                              grn={t('common:uah')}
                            />
                            {/*<span style={{margin:'0 5px'}}>{t('common:price2')}</span>*/}
                          </div>
                          <div
                            style={{
                              // width: "115px",
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}>
                            <span>
                              <PriceToStr
                                stSumma={{
                                  fontStyle: 'normal',
                                  fontSize: 16,
                                  fontWeight: 'normal',
                                }}
                                stAll={
                                  {
                                    // marginBottom: -3,
                                    // textAlign: 'right',
                                  }
                                }
                                data={item.price_with_discount}
                                beforeText={''}
                                text={
                                  currencyMain && currencyMain.simbol
                                    ? currencyMain.simbol
                                    : ''
                                }
                                grn={t('common:uah')}
                              />
                            </span>
                            <span className={classes.slash}>/</span>
                            <span>{t('common:pieces')}</span>
                          </div>
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ),
          )}
      </DialogContent>
    </Dialog>
  );
};

class Wrapper extends React.Component {
  componentDidMount() {
    // this.props.getCart();
  }

  componentDidUpdate(prevProps) {}

  /*shouldComponentUpdate(nextProps, nextState) {

        return true;
    }*/

  render() {
    return <DiscountModal {...this.props} />;
  }
}

export default Wrapper;
