import React from 'react';

const SignInSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00019 3.3333C9.07802 3.3333 10.0677 3.70231 10.8577 4.316L13.2824 2.00271C11.8727 0.759961 10.0273 0 8.00019 0C4.92849 0 2.2647 1.73326 0.924133 4.27319L3.6204 6.40179C4.27355 4.61287 5.9852 3.3333 8.00019 3.3333Z"
        fill="#F5F7FB"
      />
      <path
        d="M15.9306 9.00185C15.9723 8.67409 15.9999 8.33974 15.9999 8.00063C15.9999 7.42874 15.9375 6.87186 15.8234 6.33398H8V9.66728H12.3241C11.9743 10.5765 11.3514 11.3458 10.5586 11.8802L13.2649 14.0168C14.6995 12.7575 15.6812 10.9942 15.9306 9.00185Z"
        fill="#F5F7FB"
      />
      <path
        d="M3.3333 8.00017C3.3333 7.43788 3.43787 6.90126 3.62012 6.40204L0.92386 4.27344C0.336219 5.38687 0 6.65362 0 8.00017C0 9.33165 0.330034 10.5844 0.905468 11.6888L3.60515 9.55744C3.43226 9.06978 3.3333 8.54712 3.3333 8.00017Z"
        fill="#F5F7FB"
      />
      <path
        d="M7.99997 12.666C5.96964 12.666 4.24696 11.367 3.6052 9.55664L0.905518 11.688C2.23953 14.2483 4.91321 15.9993 7.99997 15.9993C10.0185 15.9993 11.8591 15.2494 13.2649 14.0155L10.5586 11.879C9.82743 12.3719 8.95223 12.666 7.99997 12.666Z"
        fill="#F5F7FB"
      />
      <path
        opacity="0.1"
        d="M7.99998 15.8336C5.64555 15.8336 3.52851 14.8621 2.03174 13.3145C3.49693 14.9588 5.62431 16.0002 7.99998 16.0002C10.3537 16.0002 12.4634 14.9793 13.9253 13.3608C12.433 14.8833 10.332 15.8336 7.99998 15.8336Z"
        fill="#F5F7FB"
      />
      <path
        opacity="0.1"
        d="M8 9.5V9.66667H12.3241L12.3916 9.5H8Z"
        fill="#F5F7FB"
      />
      <path
        d="M15.9963 8.09831C15.9968 8.06547 16 8.0332 16 8.00028C16 7.99097 15.9985 7.98197 15.9985 7.97266C15.998 8.01465 15.9959 8.05615 15.9963 8.09831Z"
        fill="#F5F7FB"
      />
      <path
        opacity="0.2"
        d="M8 6.33398V6.50065H15.857C15.8465 6.44564 15.835 6.38859 15.8234 6.33398H8Z"
        fill="#F5F7FB"
      />
      <path
        d="M15.8233 6.33327H7.99992V9.66657H12.324C11.6516 11.4143 9.98468 12.6665 7.99992 12.6665C5.42263 12.6665 3.3333 10.5772 3.3333 7.99992C3.3333 5.42259 5.42263 3.3333 7.99992 3.3333C8.93448 3.3333 9.79584 3.62041 10.5256 4.09375C10.6373 4.16634 10.7525 4.2345 10.8574 4.316L13.2822 2.00271L13.2275 1.96063C11.8245 0.744703 10.0023 0 7.99992 0C3.58167 0 0 3.58167 0 7.99992C0 12.4181 3.58167 15.9998 7.99992 15.9998C12.0783 15.9998 15.4368 12.9457 15.9305 9.00113C15.9722 8.67338 15.9998 8.33903 15.9998 7.99992C15.9998 7.42803 15.9374 6.87115 15.8233 6.33327Z"
        fill="#F5F7FB"
      />
      <path
        opacity="0.1"
        d="M10.5255 3.92633C9.79579 3.45299 8.93443 3.16588 7.99987 3.16588C5.42259 3.16588 3.33325 5.25518 3.33325 7.8325C3.33325 7.86062 3.33363 7.88259 3.33412 7.91063C3.37908 5.3722 5.4507 3.33255 7.99987 3.33255C8.93443 3.33255 9.79579 3.61965 10.5255 4.093C10.6372 4.16559 10.7524 4.23374 10.8574 4.31524L13.2821 2.00195L10.8574 4.14858C10.7524 4.06708 10.6372 3.99892 10.5255 3.92633Z"
        fill="#F5F7FB"
      />
      <path
        opacity="0.2"
        d="M7.99992 0.166665C9.98326 0.166665 11.7885 0.898917 13.1861 2.09438L13.2822 2.00271L13.2088 1.93883C11.8059 0.722899 10.0023 0 7.99992 0C3.58167 0 0 3.58167 0 7.99992C0 8.02804 0.00390623 8.05518 0.00419102 8.08325C0.0493566 3.70386 3.60979 0.166665 7.99992 0.166665Z"
        fill="#F5F7FB"
      />
    </svg>
  );
};

export default SignInSvg;
